import React, { useState, useEffect } from 'react';
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import { Terminosycondiciones } from './terminosycondiciones';
import { IoClose } from "react-icons/io5";
import Autocomplete from '@mui/material/Autocomplete';
import "../../../Style/Registro/registro-form.css";
import { RxCalendar } from "react-icons/rx";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from "react-icons/fi";
import { Validacion } from './validacion';
import { Link,  useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { IoIosArrowBack } from "react-icons/io";
import { styled } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Modal,  CircularProgress } from '@mui/material';
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoAlertCircleOutline } from "react-icons/io5";




const StyledSelect = styled(Select)(({ theme, error }) => ({
  padding: '1px 13px',
  color:'#694B81',
  position:'relative',
  left:'0.5%',
  fontFamily: "Poppins",
  width:'90%',
  height:'55px',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
  
}));

const StyledAutocomplete  = styled(Autocomplete)(({ theme, error }) => ({
  
  fontFamily: "Poppins",
  width:'90%',
  height:'55px',
  position:'relative',
  left:'0.5%',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: `3px solid ${error ? '#D26999' : '#F0EDF3'}`, // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px', // Establece el radio de borde
    border: '0px', // Establece el borde a 0
    height: '52px', // Establece la altura
    fontSize: '0.8rem', // Establece el tamaño de fuente
    textTransform: 'capitalize', // Transforma el texto a mayúsculas
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
 
  
}));




export const Registroform = () => {
  const location = useLocation();
  const { suscripcion } = location.state;
  const [selectedPack, setSelectedPack] = useState('');
  const [loading, setLoading] = useState(false); // Estado para controlar la carga
  const [openModal, setOpenModal] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [funeraria, setFuneraria] = useState(null);
  const [funerariaSeleccionada, setFunerariaSeleccionada] = useState('');
  const [funerarias, setFunerarias] = useState([]);
  const [age, setAge] = React.useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [grupoFamiliarData, setGrupoFamiliarData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    name: '',
    apellido: '',
    fecha_nacimiento: '',
    genero: '',
    email: '',
    telefono: '+56 ',
    funeraria: funeraria,
    password: '',
    codigo: suscripcion.codigo,
    id_grupo_familiar: suscripcion.id_grupo_familiar,
  });
 


 
  const fetchGrupoFamiliarData = async (idGrupoFamiliar) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get/GrupoFamiliar/public/${idGrupoFamiliar}`);
      if (!response.ok) {
        throw new Error('Error al obtener los datos del Grupo Familiar');
      }
      const data = await response.json();
      setFuneraria(data.id_funeraria);
      setFormData(prevState => ({
        ...prevState,
        funeraria: data.id_funeraria // Actualizar formData con el ID de la funeraria
      }));
      setGrupoFamiliarData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching group data:', error.message);
    }
  };

  useEffect(() => {
    if (suscripcion) {
      setSelectedPack(suscripcion.id_suscripcion);
    }
  }, [suscripcion]);

  useEffect(() => {
    if (suscripcion && suscripcion.id_grupo_familiar) {
      fetchGrupoFamiliarData(suscripcion.id_grupo_familiar);
    }
  }, [suscripcion]);

  useEffect(() => {
    if (grupoFamiliarData && grupoFamiliarData.id_funeraria) {

     setFuneraria(grupoFamiliarData.id_funeraria);
     
    }
  }, [grupoFamiliarData]);

    const handleChange = (event) => {
      const { name, value } = event.target;
    
      // Actualizar el estado de formData
      setFormData({
        ...formData,
        [name]: value
      });
    
      // Limpiar el error asociado con el campo actual
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: '' // Limpiar el error del campo actual
      }));
    
      // Reinicia el estado de emailExistsError cuando el usuario modifica el campo de correo electrónico
      if (name === 'email') {
        setEmailExistsError(false);
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault();
    
      const formDataFromEvent = new FormData(event.target);
      const formDataObject = {};
    
      formDataFromEvent.forEach((value, key) => {
        formDataObject[key] = value;
      });
    
      const formattedDate = dayjs(formDataObject.fecha_nacimiento, 'DD-MM-YYYY').format('YYYY-MM-DD');
      formDataObject.fecha_nacimiento = formattedDate;
    
      let formErrors = {};
      for (let [key, value] of formDataFromEvent.entries()) {
        if (value.trim() === '' || value.trim() === '+56') {
          formErrors[key] = 'Campo no completado';
        }
      }
    
      setErrors(formErrors);
    
      // Si hay errores, mostrar la alerta de campos vacíos y no enviar el formulario
      if (Object.keys(formErrors).length > 0) {
        setErrorAlertOpen(true);
        setAlertMessage('Tienes campos vacíos que deben ser completados.');
        return;
      }
    
      if (!termsAccepted) {
        setTermsError('Debes aceptar los términos y condiciones para registrarte.');
        return;
      }
    
      try {
        setenviandoAlert(true); // Muestra el modal de carga al hacer clic en el botón de registro
    
        const response = await fetch(`${backendURL}/api/registerUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });
    
        const data = await response.json();
    
        if (!response.ok || response.status !== 201) {

          throw new Error(data.error || 'Error al registrar usuario');
        }
    
        const emailExistsError = data.error === 'El correo electrónico ya está registrado';
    
        if (!emailExistsError) {
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Su registro ha sido exitoso.');
          setTimeout(() => {
            setSuccessAlertOpen(false);
            handleNextPage();
          }, 3000);
    
        } else if (emailExistsError) {
          setFormData(prevState => ({
            ...prevState,
            emailExistsError: true
          }));
    
        
        }
      } catch (error) {
        setErrorAlertOpen(true);
        setAlertMessage('Error al crear su registro, vuelva a intentar.');
      } finally {
        setenviandoAlert(false);
      }
    
      // Simulación de tiempo de carga
      setTimeout(() => {
        setenviandoAlert(false);
      }, 3000);
    
      // Simulación de tiempo de carga
      setTimeout(() => {
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
      }, 5000);
    };

  const fetchFunerarias = async () => {
    try {
      const response = await fetch(`${backendURL}/api/funerariasGetRegister`);
      if (!response.ok) {
        throw new Error('Error al obtener las funerarias');
      }
      const data = await response.json();
      const funerariasFiltradas = data.filter(funeraria => funeraria.grupo_familiar.some(grupo => !grupo.id_usuario));
      setFunerarias(funerariasFiltradas);
    } catch (error) {
      console.error('Error fetching funeral homes:', error.message);
    }
  };

  useEffect(() => {
    if (suscripcion && suscripcion.id_grupo_familiar) {
      fetchGrupoFamiliarData(suscripcion.id_grupo_familiar);
    }
  }, [suscripcion]);

  useEffect(() => {
    fetchFunerarias();
  }, []);

  useEffect(() => {
    if (grupoFamiliarData && grupoFamiliarData.id_funeraria) {
      setFuneraria(grupoFamiliarData.id_funeraria);
    }
  }, [grupoFamiliarData]);




  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };


  const handleChangeGenero = (event) => {
    setAge(event.target.value);
    handleChange(event); // Actualizar el estado formData con el nuevo valor de 'genero'

    setFormData({
      ...formData,
      genero: ''
    });
    setErrors(prevErrors => ({
      ...prevErrors,
      genero: false // Limpiar el error de género
    }));
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordcorrectVisibility = () => {
    setShowPasswordcorrect(!showPasswordcorrect);
  };



  const handleChangeInput = (event) => {
    const { name } = event.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
      // Limpiar el error correspondiente
    }));
  };

  const handleChangeFecha = (event) => {
    const { value } = event.target;
    // Convertir el valor de la fecha a un objeto Date
    const date = new Date(value);
  
    if (!isNaN(date)) {
      // Limpiar el error asociado con la fecha cuando se selecciona una nueva fecha
      setErrors(prevErrors => ({
        ...prevErrors,
        fecha_nacimiento: '' // Limpiar el error de fecha
      }));
  
      // Formatear la fecha para que no incluya la hora
      const fechaFormateada = date.toISOString().split('T')[0];
  
      // Actualizar el estado del formulario con la nueva fecha seleccionada
      setFormData({
        ...formData,
        fecha_nacimiento: fechaFormateada // Actualizar con la fecha formateada
      });
  
      
      handleChange({ target: { name: 'fecha_nacimiento', value: fechaFormateada } });
    } else {
      console.error("Invalid date value");
    }
  };


  

  const handleChangeContraseña = (event) => {
    setPassword(event.target.value);
    if (password.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  };


  // Validar que la contraseña sea igual a la contraseña repetida
  const handleChangeRepetirContraseña = (event) => {
    const confirmPassword = event.target.value;
  
    // Validar la longitud mínima de caracteres
    if (confirmPassword.length < 6) {
      setPasswordError('La contraseña debe tener al menos 6 caracteres');
    } else {
      setPasswordError('');
    }
  
    // Validar si la contraseña repetida coincide con la contraseña principal
    if (confirmPassword !== password) {
      setPasswordError('Las contraseñas no coinciden');
    } else {
      setPasswordError(''); // Limpiar el mensaje de error si las contraseñas coinciden
    }
  };
  
  

  /* modal terminos y condiciones */
  const handleOpenModal = () => {
    setOpenModal(true);
    setTermsAccepted(true); 
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  /* correoverificacion */
  const handleBlur = async () => {
    try {
      const response = await fetch(`${backendURL}/api/checkemailexists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: formData['email'] })
      });
  
      const data = await response.json();
  
      // Manejo del error basado en el código de estado
      if (response.status === 400) {
        setEmailExistsError(true);
       
      } else {
        setEmailExistsError(false);
        
      }
    } catch (error) {
      console.error('Error al verificar el correo electrónico:', error);
    }
  };
 
  const handleTermsChange = () => {
    setTermsAccepted(!termsAccepted);
  };

  return (
    
    <>
  
       
  
      <Container  maxWidth="sm" sx={{paddingLeft:'0px', paddingRight:'0px'}}>

        <Box
          sx={{
           
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: 'center',
            padding: '0px 10px'
          }}
        >
          <section className={`pagina-${currentPage}`}>
            {currentPage === 1 && (
              <>
                <section className='retroceso-1'>
                <Link to="/verificacion-de-registro">
                <IoIosArrowBack />
                </Link>
                </section>
                <div className="contiene-titulo-registro-form">
                  <h1 className="titulo-registro-form">
                   Es muy simple
                  </h1>
                  <h1 className="tituloregistroform">Ingresa tus datos</h1>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="input-registor-form">
                    <label htmlFor='name'>Nombres</label>
                   <div className='contieneinputregister'> <input onChange={(e) => {
                      handleChange(e);
                      handleChangeInput(e);
                    }} className={`input-registro ${errors.name && 'input-error'}`} name="name" id="name"
                      value={formData['name']} />
                  </div>  {errors.name && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.name}</span>}
                  </div>
                  <div className="input-registor-form">
                    <label htmlFor='apellido'>Apellidos</label>
                    <div className='contieneinputregister'> <input onChange={handleChange} className={`input-registro ${errors.apellido && 'input-error'}`} name="apellido" id="apellido" value={formData['apellido']} />
                   </div> {errors.apellido && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.apellido}</span>}
                  </div>
                  <div className="input-registor-form">
  <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
  <div className='contieneinputregister'>
    <div className="inputregistrocontainer">
      <input
        type="date"
        className={`inputregistrofecha ${errors.fecha_nacimiento && 'input-error'}`}
        name='fecha_nacimiento'
        id='fecha_nacimiento'
        onChange={handleChangeFecha} 
        error={errors.fecha_nacimiento}   
        value={formData.fecha_nacimiento} // Asegúrate de que el valor sea el correcto del estado
      />
      <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" />
        </div>
      
    </div>
  </div>
  {errors.fecha_nacimiento && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.fecha_nacimiento}</span>}
</div>
                  
                  <div className="input-registor-form">
                    <label htmlFor='genero'>Género</label>
                    <div className='contieneinputregister'> < StyledSelect
                      name='genero'
                      id='genero'
                      sx={{textAlign:"left"}}
                      className={`input-select-registro ${errors.genero && 'input-error'}`}
                      value={formData.genero}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                      error={errors.genero}
                    >
                      <MenuItem value="">
                        <em>Seleccionar género...</em>
                      </MenuItem>
                      <MenuItem value={"Masculino"}>Masculino</MenuItem>
                      <MenuItem value={"Femenino"}>Femenino</MenuItem>
                      <MenuItem value={"Me identifico con otro género"}>Me identifico con otro género </MenuItem>
                    </ StyledSelect>
                  </div>  {errors.genero && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.genero}</span>}
                  </div>
                  <div className="input-registor-form">
  <label htmlFor='email'>Correo electrónico</label>
  <div className='contieneinputregister'>
  <input
      type="email"
      name="email"
      value={formData['email']}
      onChange={handleChange} // Manejador de cambios para actualizar formData
      onBlur={handleBlur} // Se llama a handleBlur cuando se pierde el enfoque
      className={`input-registro ${emailExistsError && 'input-error'}`}
    />
  </div> 
  {errors.email && (
    <span className="error-message">
      <FiAlertCircle className='icon-error' />
      {errors.email}
    </span>
  )}
  {emailExistsError && (
    <span className="error-message">
      <FiAlertCircle className='icon-error' />
      El correo electrónico ya está registrado
    </span>
  )}
  <div className='aletainformativacorreo'>
    <div className='svgaletainformativa'><IoAlertCircleOutline /></div>
     <div className='infoaletainformativa'>
       <span className='tituloaletainformativa'>
          IMPORTANTE:
        </span>
        <span className='descripcionaletainformativa'>
           El correo electrónico NO debe ser de carácter institucional
        </span>
      </div>
  </div>
</div>
                  <div className="input-registor-form">
                    <label htmlFor='telefono'>Número de celular</label>
                    
                    <div className='contieneinputregister'>
                      <input
                        value={formData['telefono']}
                        onChange={(e) => {
                          handleChange(e);
                          handleChangeInput(e);
                        }}
                        className={`input-registro ${errors.telefono && 'input-error'}`}
                        name="telefono"
                        id="telefono"
                      /></div>
                   
                    {errors.telefono && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.telefono}</span>}
                 </div>
                  <div className="input-registor-form">
                    <label htmlFor='pack'>Pack Memorial contratado</label>
                    <div className='contieneinputregister'>
                    <StyledSelect
            name='pack'
            id='pack'
            value={selectedPack}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            IconComponent={KeyboardArrowDownIcon}
            disabled
            className="input-select-registro"
            sx={{ textAlign: "left" }}
          >
            <MenuItem value="">
              <em>Selecciona el tipo Pack Memorial...</em>
            </MenuItem>
            <MenuItem value={1}> Pack Smart Memorial Full</MenuItem>
            <MenuItem value={2}> Pack SmartMemorial Básico</MenuItem>
          </StyledSelect>
                       </div> 
                   
                  </div>
                  <div className="input-registor-form">
                    <label htmlFor='funeraria'>Selección de servicio</label>
                    <div className='contieneinputregister'>
            
                      <StyledSelect
                        labelId="funeraria-label"
                        id="funeraria"
                        value={funeraria}
                        onChange={(event) => setFuneraria(event.target.value)} // Manejar cambios no es necesario si está deshabilitado
                          displayEmpty
                          disabled
                          inputProps={{ 'aria-label': 'Selecciona la funeraria' }}
                          className="input-select-registro"
                          sx={{ textAlign: "left", textTransform: "capitalize" }}
                        >
                          <MenuItem value={funeraria}>{funerarias.find(f => f.id == funeraria)?.nombre || 'Selecciona el servicio'}</MenuItem>
          
                      </StyledSelect>
                    </div>
                    {errors.funeraria && <span className="error-message">{errors.funeraria}</span>}
                  </div>
                  <div className="input-registor-form">
                    <label>Contraseña</label>
                    <div className='input-contraseña'>
                     <input
                        onChange={(e) => { handleChange(e); handleChangeInput(e); handleChangeContraseña(e) }}
                        type={showPassword ? 'text' : 'password'}
                        className={`input-registro ${errors.password && 'input-error'} ${passwordError && 'input-error'}`}
                        name="password"
                        value={formData['password']}
                        placeholder="Contraseña"
                      />
                      <span className="togglepassword" onClick={togglePasswordVisibility}>
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span></div>
                    {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                    {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                  </div>

                  <div className="input-registor-form">
                    <label>Repetir contraseña</label>
                    <div className='input-contraseña'>
                      <input
                        onChange={(e) => { handleChangeInput(e); handleChangeRepetirContraseña(e); }}
                        type={showPasswordcorrect ? 'text' : 'password'}
                        className={`input-registro ${errors.passwords && 'input-error'} ${passwordError && 'input-error'}`}
                        name="passwords"
                        placeholder="Contraseña"
                      />
                      <span className="togglepassword" onClick={togglePasswordcorrectVisibility}>
                        {showPasswordcorrect ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span>
                    </div>
                    {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                    {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                  </div>
                  <div className="unoterminos">
          <label className="container">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => {
                setTermsAccepted(e.target.checked);
                setTermsError(''); // Limpiar el error cuando se marca/desmarca el checkbox
              }}
            />
            <svg viewBox="0 0 64 64" height="2em" width="2em">
              <path
                d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                pathLength="575.0541381835938"
                className="path"
              ></path>
            </svg>
          </label>
          <span>
            Acepto <strong  onClick={handleOpenModal} style={{cursor:"pointer"}} >términos y condiciones</strong>
          </span>
          
        </div>{termsError && <span className="error-message"> <FiAlertCircle className='icon-error' />{termsError}</span>}

                 <Modal open={openModal} onClose={handleCloseModal}>
                  <section className='contiene-modal-terminos'>
        <div>
          <button className='boton-close-termino' onClick={handleCloseModal}><IoClose /></button>
        </div>
        <section>
          <Terminosycondiciones   onCloseModal={handleCloseModal} /></section></section>
      </Modal>   
                  
                  <div className="boton-registro">

                  <button type="submit" disabled={emailExistsError || !termsAccepted}> Registrarse</button>

                  </div>

                </form>
              </>
            )}
            {currentPage === 2 && (
              <>
                <Validacion handlePrevPage={handlePrevPage} email={formData.email} />
              </>

            )}
          </section>
        </Box>
      </Container>
  
      <Modal open={successAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
    
    </>
  ); 
};
