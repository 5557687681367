import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from "axios-rate-limit";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import SucursalLayout from "../../../../../SucursalLayout"

/* libreria UI */
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Modal,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { FiAlertCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { RxCalendar } from "react-icons/rx";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
  maxRequests: 2, // Número máximo de solicitudes permitidas
  perMilliseconds: 1000, // Tiempo en milisegundos
});

export const PerfilUsuarioSucursal = ({ setShowNavbarFooter }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const { sucursal, user } = location.state || {};
  const { Token, Empresa, UserDataMarca, Sucursal, Uusuario } = useUserEmpresa();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [usuarioData, setUsuarioData] = useState([]);
  const [empresaData, setEmpresaData] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showModals, setShowModals] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
  const [error, setError] = useState("");
  const [password, setPassword] = useState({
    old_password: "",
    password: "",
    new_password: "",
  });

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);

  /* Accordeon */
  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchEmpresaData = async () => {
    if (!Token || !id) return; // Asegúrate de que Token e id estén disponibles
    setLoading(true);
    try {
      const response = await https.get(
        `${backendURL}/api/usuarioSucursal/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      if (response.status === 200) {
        setUsuarioData(response.data.user);
        setEmpresaData(response.data.empresa); // Guarda los datos de la empresa
      } else {
        console.error("Error al obtener datos de la empresa");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmpresaData(); // Llamar a la función al montar el componente
  }, [Token, id, backendURL]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuarioData({ ...usuarioData, [name]: value });
  };

  const handleUpdatePerfil = async (e) => {
    e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

    try {
      setIsLoading(true);
      setenviandoAlert(true);
      const requestBody = { ...usuarioData };

      await axios.post(
        `${backendURL}/api/updateUserSucursales/${usuarioData.id}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage("Se ha editado correctamente su información.");
      await fetchEmpresaData(usuarioData);

      // Simulación de tiempo de carga para cerrar la alerta
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage("Error al editar su información, vuelva a intentar.");
    } finally {
      setIsLoading(false);
      setenviandoAlert(false);
    }
    setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }, 3000);
  };

  /* contraseñas */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      setenviandoAlert(true);
      const response = await axios.post(
        `${backendURL}/api/updatePasswordMenuSucursal`,
        {
          user_id: usuarioData.id,
          password: password.new_password,
          confirm_password: password.confirm_new_password,
        },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setIsLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage("Se ha actualizado correctamente su contraseña.");
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage("Error al actualizar su contraseña, vuelva a intentar.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setenviandoAlert(false);
    }
    setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }, 3000);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordcorrectVisibility = () => {
    setShowPasswordcorrect(!showPasswordcorrect);
  };

  const handleChangeContraseña = (event) => {
    const { value } = event.target;
    setPassword({ ...password, new_password: value });
    if (value.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres");
    } else {
      setPasswordError("");
    }
  };
  const handleChangeRepetirContraseña = (event) => {
    const { value } = event.target;
    setPassword({ ...password, confirm_new_password: value });
    if (value.length < 6) {
      setPasswordError("La contraseña debe tener al menos 6 caracteres");
    } else if (value !== password.new_password) {
      setPasswordError("Las contraseñas no coinciden");
    } else {
      setPasswordError("");
    }
  };
  return (
    <SucursalLayout>
      <Container maxWidth="lg">
        
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleAccordionChange("panel1")}
                  sx={{
                    marginTop: "50px",
                    marginBottom: "10px",
                    background:'#ffff'
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon   sx={{
                      color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                      ? UserDataMarca.color_1 
                      : '#694B81',
                    }}/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography sx={StyleTitulo}>
                      Perfil {usuarioData.name} {usuarioData.apellido}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <form
                        style={{
                          width: "60%",
                          position: "relative",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label
                                className="titulocrearperfillabel"
                                htmlFor=""
                              >
                                Nombres
                              </label>
                              <input
                              style={{
                                width:'100%'
                              }}
                                className="crearinputperfil"
                                type="text"
                                name="name"
                                value={usuarioData.name}
                                onChange={handleChange}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label
                                className="titulocrearperfillabel"
                                htmlFor=""
                              >
                                Apellidos
                              </label>
                              <input
                               style={{
                                width:'100%'
                              }}
                                className="crearinputperfil"
                                type="text"
                                name="apellido"
                                value={usuarioData.apellido}
                                onChange={handleChange}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label
                                className="titulocrearperfillabel"
                                htmlFor="editfecha"
                              >
                                Fecha de Nacimiento
                              </label>
                              <div className="contienencalendarioempresaperfil">
                                <input
                                 style={{
                                  width:'100%'
                                }}
                                  type="date"
                                  className="crearinputempresaperfil"
                                  name="fecha_nacimiento"
                                  value={usuarioData.fecha_nacimiento}
                                  onChange={handleChange}
                                />
                                <div className="calendarioempresaicono" style={{ right:'20px'}}>
                                  <RxCalendar className="iconoperfilcalendario" />
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label className="titulocrearperfillabel">
                                Género
                              </label>
                              <StyledSelect
                                name="genero"
                                sx={{ textAlign: "left" }}
                                className={`input-select-registro ${
                                  errors.genero && "input-error"
                                }`}
                                value={usuarioData.genero}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                IconComponent={KeyboardArrowDownIcon}
                                error={errors.genero}
                              >
                                <MenuItem value={usuarioData.genero}>
                                  <em>{usuarioData.genero}</em>
                                </MenuItem>
                                <MenuItem value={"Masculino"}>Masculino</MenuItem>
                                <MenuItem value={"Femenino"}>Femenino</MenuItem>
                                <MenuItem value={"Me identifico con otro género"}>
                                  Me identifico con otro género{" "}
                                </MenuItem>
                              </StyledSelect>
                              {errors.genero && (
                                <span className="error-message">
                                  <FiAlertCircle className="icon-error" />
                                  {errors.genero}
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label
                                className="titulocrearperfillabel"
                                htmlFor=""
                              >
                                Correo electrónico
                              </label>
                              <input
                               style={{
                                width:'100%'
                              }}
                                className="crearinputperfil"
                                type="text"
                                name="email"
                                value={usuarioData.email}
                                onChange={handleChange}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sx={{ marginBottom: "10px" }}>
                            <div className="inputcrearperfil">
                              <label
                                className="titulocrearperfillabel"
                                htmlFor=""
                              >
                                Número de celular
                              </label>
                              <input
                               style={{
                                width:'100%'
                              }}
                                className="crearinputperfil"
                                type="text"
                                name="telefono"
                                value={usuarioData.telefono}
                                onChange={handleChange}
                              />
                            </div>
                          </Grid>
                        </Grid>

                        <section className="botonguardarperfilempresacreado">
                          <button
                            type="submit"
                            disabled={isLoading}
                            onClick={handleUpdatePerfil}
                          >
                            {isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Guardar Cambios"
                            )}
                          </button>
                        </section>
                      </form>

                      <Modal
                        open={successAlertOpen}
                        onClose={() => setSuccessAlertOpen(false)}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "350px",
                            height: "auto",
                            bgcolor: "#ffff",
                            boxShadow: 24,
                            p: 1,
                            borderRadius: "10px",
                            "@media (max-width: 500px)": {
                              width: "90%",
                            },
                          }}
                        >
                          <section
                            className="closemodal"
                            onClick={() => setSuccessAlertOpen(false)}
                          >
                            <IoClose />
                          </section>
                          <section style={{ textAlign: "center" }}>
                            <FaRegCircleCheck
                              style={{
                                color: "green",
                                fontSize: "2rem",
                                marginBottom: "1rem",
                              }}
                            />
                            <p
                              style={{
                                fontFamily: "Poppins",
                                color: "#585857",
                                fontSize: "0.8rem",
                                fontWeight: "600",
                                marginTop: "-5px",
                              }}
                            >
                              {alertMessage}
                            </p>
                          </section>
                        </Box>
                      </Modal>

                      {/* Popup de error */}
                      <Modal
                        open={errorAlertOpen}
                        onClose={() => setErrorAlertOpen(false)}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "350px",
                            height: "auto",
                            bgcolor: "#ffff",
                            boxShadow: 24,
                            p: 1,
                            borderRadius: "10px",
                            "@media (max-width: 500px)": {
                              width: "90%",
                            },
                          }}
                        >
                          <section
                            className="closemodal"
                            onClick={() => setErrorAlertOpen(false)}
                          >
                            <IoClose />
                          </section>
                          <section style={{ textAlign: "center" }}>
                            <BiErrorCircle
                              style={{
                                color: "red",
                                fontSize: "2rem",
                                marginBottom: "1rem",
                              }}
                            />
                            <p
                              style={{
                                fontFamily: "Poppins",
                                color: "#694B81",
                                fontSize: "0.8rem",
                                fontWeight: "600",
                              }}
                            >
                              {alertMessage}
                            </p>
                          </section>
                        </Box>
                      </Modal>

                      {/* Popup de carga */}
                      <Modal
                        open={enviandoAlert}
                        onClose={() => setenviandoAlert(false)}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "350px",
                            height: "auto",
                            bgcolor: "#ffff",
                            boxShadow: 24,
                            p: 1,
                            borderRadius: "10px",
                            "@media (max-width: 500px)": {
                              width: "90%",
                            },
                          }}
                        >
                          <section
                            style={{ textAlign: "center", padding: "1rem" }}
                          >
                            <CircularProgress style={{ color: "#694B81" }} />
                            <p
                              style={{
                                fontFamily: "Poppins",
                                color: "#694B81",
                                fontSize: "0.8rem",
                                fontWeight: "600",
                                marginTop: "20px",
                              }}
                            >
                              Procesando solicitud...
                            </p>
                          </section>
                        </Box>
                      </Modal>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleAccordionChange("panel2")}
                  sx={{
                    marginTop: "0px",
                    marginBottom: "50px",
                    background:'#ffff'
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon  sx={{
                      color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                      ? UserDataMarca.color_1 
                      : '#694B81',
                    }}/>}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography sx={StyleTitulo}>
                      Contraseña {usuarioData.name} {usuarioData.apellido}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <h1 className="titulocontraseñacorrecta">
                        Cambiar contraseña
                      </h1>
                      <form onSubmit={handleSubmit}>
                        <Grid container sx={{ width: "100%" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{ width: "100%", marginBottom: "10px" }}
                          >
                            <div className="inputcrearcontraseñanueva">
                              <label
                                className="titulocontraseñalabel"
                                htmlFor="new_password"
                              >
                                Contraseña nueva
                              </label>
                              <div className="contienentodocontraseñanueva">
                                <input
                                style={{
                                  background:'#ffff',
                                  width:'100%'
                                }}
                                  className={`crearinputcontraseña ${
                                    error.password && "input-error"
                                  } ${passwordError && "input-error"}`}
                                  type={showPassword ? "text" : "password"}
                                  name="new_password"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeContraseña(e);
                                    handleChangeContraseña(e);
                                  }}
                                  value={password.new_password}
                                />
                                <span
                                  className="togglenuevoempresapassword"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <FaRegEyeSlash />
                                  ) : (
                                    <FaRegEye />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>
                              {error.password && (
                                <span className="errorcontraseñamensaje">
                                  <FiAlertCircle className="icon-error" />
                                  {error.password}
                                </span>
                              )}
                              {passwordError && (
                                <span className="errorcontraseñamensaje">
                                  <FiAlertCircle className="icon-error" />
                                  {passwordError}
                                </span>
                              )}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{ width: "100%", marginBottom: "10px" }}
                          >
                            <div className="inputcrearcontraseñanueva">
                              <label
                                className="titulocontraseñalabel"
                                htmlFor="confirm_new_password"
                              >
                                Repetir contraseña
                              </label>
                              <div className="contienentodocontraseñanueva">
                                <input
                                style={{
                                  background:'#ffff',
                                  width:'100%'
                                }}
                                  className={`crearinputcontraseña ${
                                    error.password && "input-error"
                                  } ${passwordError && "input-error"}`}
                                  type={showPasswordcorrect ? "text" : "password"}
                                  name="confirm_new_password"
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleChangeRepetirContraseña(e);
                                  }}
                                  value={password.confirm_new_password}
                                />
                                <span
                                  className="togglenuevoempresapassword"
                                  onClick={togglePasswordcorrectVisibility}
                                >
                                  {showPasswordcorrect ? (
                                    <FaRegEyeSlash />
                                  ) : (
                                    <FaRegEye />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>
                              {error.password && (
                                <span className="errorcontraseñamensaje">
                                  <FiAlertCircle className="icon-error" />
                                  {error.password}
                                </span>
                              )}
                              {passwordError && (
                                <span className="errorcontraseñamensaje">
                                  <FiAlertCircle className="icon-error" />
                                  {passwordError}
                                </span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <section className="botonguardarcontraseñanuevacreado">
                          <button type="submit" disabled={isLoading}>
                            {isLoading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Guardar cambios"
                            )}
                          </button>
                        </section>
                      </form>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              
      </Container>
    </SucursalLayout>
  );
};

const StyledSelect = styled(Select)(({ theme, error }) => ({
  padding: "1px 13px",
  color: "#694B81",
  position: "relative",
  left: "0.5%",
  fontFamily: "Poppins",
  width: "100%",
  height: "44px",
  fontSize: "0.8rem",
  borderRadius: "50px",
  border: ` ${error ? "1px solid #D26999" : "3px solid #F0EDF3"}`, // Cambia el color del borde si hay un error
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#F0EDF3", // Color del borde por defecto
    },
    "&:hover fieldset": {
      borderColor: "#585857", // Color del borde al pasar el mouse
    },
    "&.Mui-focused fieldset": {
      borderColor: "#585857", // Color del borde en foco
    },
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#585857", // Color del borde en foco
    borderWidth: "2px", // Ancho del borde en foco
  },
  "@media (max-width: 310px)": {
    fontSize: "0.7rem",
    width: "100%", // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: "100%",
  },
}));
const StyleTitulo = {
  fontFamily: "Poppins",
  color: "#585857",
  fontWeight: "600",
};
