import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../../context/usecontext";
import { useNavigate, useLocation } from "react-router-dom";
import "../../../../Style/admi/adminuser.css";
import axios from "axios";

/* libreria UI */
import { Container, Modal, Box, CircularProgress,  Select, MenuItem,  } from '@mui/material';
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


/* react iconos */
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";

import { datadireccion } from "../../../../data/api direccion/datadireccion";

/* imagenes */
import logoinicio from "../../../../Image/administrador/logoadmi.png";


export const Crearsucursal = () =>{
    const { adminToken, setAdminAuthToken } = useAuth();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [step, setStep] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedComuna, setSelectedComuna] = useState('');
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const today = new Date().toISOString().split('T')[0];
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [emailExistsError, setEmailExistsError] = useState(false);
    const location = useLocation();
    const { empresaId } = location.state || {}; // Desestructurar el id
    /* procesadores */
    const [errors, setErrors] = useState({});
    const [error, setError] = useState('');
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [empresas, setEmpresas] = useState([]);


    useEffect(() => {
        const fetchEmpresas = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/empresas`, {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${adminToken}`
                    },
                });
                setEmpresas(response.data.empresas);
            } catch (error) {
                console.error('Error al obtener las empresas:', error);
             
            }
        };

        if (adminToken) {
            fetchEmpresas(); // Llama a la función solo si el token está disponible
        } else {
            navigate("/"); // Redirige si no hay token
        }
    }, [adminToken, navigate]);

    const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    };
    const togglePasswordcorrectVisibility = () => {
        setShowPasswordcorrect(!showPasswordcorrect);
    };

    const handleSelectChange = (event) => {
        setFormData({
          ...formData,
          id_empresa: event.target.value,
        });
      };

    const handleCountryChange = (event) => {
        const country = event.target.value;
        setSelectedCountry(country);
        setRegions(Object.keys(datadireccion.pais[country].region));
        setSelectedRegion('');
        setCommunes([]);
        setFormData(prevData => ({
          ...prevData,
          pais: country,
          region: '', // Reinicia la región cuando cambias el país
          comuna: '', // Reinicia la comuna cuando cambias el país
        }));
      };
      
      const handleRegionChange = (event) => {
        const region = event.target.value;
        setSelectedRegion(region);
        setCommunes(datadireccion.pais[selectedCountry].region[region].comuna);
        setFormData(prevData => ({
          ...prevData,
          region: region,
          comuna: '', // Reinicia la comuna cuando cambias la región
        }));
      };
      
      const handlePrevStep = () => {
        setStep(step - 1);
      };
    
      const handleNextStep = () => {
        setStep(step + 1);
      };
    
      const handleComunaChange = (event) => {
        const comuna = event.target.value;
        setSelectedComuna(comuna);
        setFormData(prevData => ({
          ...prevData,
          comuna: comuna,
        }));
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        setFormData(prevData => {
            let newData = { ...prevData, [name]: value };
            
            // Sincroniza 'correo' y 'email' si uno de ellos cambia
            if (name === 'correo') {
                newData.email = value;
                setEmailExistsError(false);
            } else if (name === 'email') {
                newData.correo = value;
            }
    
            // Sincroniza 'nombre_empresa' y 'nombre_social' si uno de ellos cambia
            if (name === 'nombre_empresa') {
                newData.nombre_social = value;
            } else if (name === 'nombre_social') {
                newData.nombre_sucursal = value;
            }
            
    
            return newData;
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
    
         // Validar longitud mínima de la contraseña
         if (formData.password.length < 8) {
              newErrors.password = 'La contraseña debe tener al menos 8 caracteres.';
          }
    
          // Validar coincidencia de la contraseña
          if (formData.password !== formData.password_confirmation) {
              newErrors.password_confirmation = 'La confirmación de la contraseña no coincide.';
          }
    
          // Actualizar el estado de errores
          setErrors(newErrors);
    
        // Verificar que todos los campos requeridos estén completos
        if (!formData.nombre ||
          !formData.apellido ||
          !formData.nombre_sucursal ||
          !formData.telefono ||
          !formData.correo ||
          !formData.password ||
          !formData.pais ||
          !formData.region ||
          !formData.comuna ||
          !formData.direccion ||
          !formData.web ||
          !formData.id_empresa) {
          setAlertMessage('Por favor, completa todos los campos requeridos.');
          setErrorAlertOpen(true);
          return; // Detiene el envío del formulario
        }
    
        const formDataToSend = new FormData();
        setenviandoAlert(true);
        
      
        // Agrega todos los datos del estado formData al FormData
        for (const [key, value] of Object.entries(formData)) {
          formDataToSend.append(key, value);
        }
    
        try {
          const response = await fetch(`${backendURL}/api/create/sucursales`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${adminToken}`
            },
            body: formDataToSend,
          });
    
          const result = await response.json();
          if (response.ok) {
            setenviandoAlert(false);
            setAlertMessage('Sucursal creada con éxito.');
            setSuccessAlertOpen(true);
            setTimeout(() => {
              setSuccessAlertOpen(false);
              navigate('/todas-empresas'); 
            }, 3000);
          } else {
            // Verifica si el error es por correo electrónico ya registrado
            if (result.error === 'El correo electrónico ya está registrado') {
              setFormData(prevState => ({
                ...prevState,
                emailExistsError: true
              }));
              setError('El correo electrónico ya está registrado');
            } else {
              setAlertMessage(result.message || 'Error al crear la sucursal.');
              setErrorAlertOpen(true);
            }
            setenviandoAlert(false);
          }
        } catch (error) {
          // Error de red: Ocultar la alerta de envío y mostrar la de error
          setenviandoAlert(false);
          setAlertMessage('Error de red. Por favor, inténtalo de nuevo más tarde.');
          setErrorAlertOpen(true);
        }
      };

     /* formulario */
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    nombre_sucursal: '',
    id_sucursal:'',
    telefono: '',
    correo: '',
    fecha_nacimiento: today,
    email: '',
    password: '',
    genero: 'indefinido',
    password_confirmation: '',
    logo: null,
    web: '',
    estado: 1,
    direccion: '',
    pais: '',
    region: '',
    comuna: '',
    id_empresa: empresaId || '',
  });

    return(
        <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "50px"
  
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "150px"
          }}>
  
            <img
              src={logoinicio}
              alt="smart memorial"
              title="smart memorial"
              width="100%"
            />
  
          </Box>
          <section className="titulocodigoadmi"><h1>Bienvenido a la sesión de creación sucursales</h1></section>
          <section className="informacioncrearempresa"><p>Completa los campos para crear la nueva sucursal. </p></section>
  
          <form className="formulariocrearempresa" onSubmit={handleSubmit}>
            {step === 1 && (
              <>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombre'>Nombre</label>
                  <div className='contieneinputempresaregister'>
                    <input value={formData.nombre} onChange={handleInputChange} className="input-crear-empresa-adminsmart" name="nombre" id="nombre" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='apellido'>Apellido</label>
                  <div className='contieneinputempresaregister'>
                    <input value={formData.apellido} onChange={handleInputChange} className="input-crear-empresa-adminsmart" name="apellido" id="apellido" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Nombre Sucursal</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart" value={formData.nombre_sucursal} onChange={handleInputChange} name="nombre_sucursal" id="nombre_sucursal" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='id_tipo_empresa'>Empresa</label>
                    <div className='contieneinputregister'>
                      <StyledSelect
                        name="id_tipo_empresa"
                        id="id_empresa"
                        sx={{ textAlign: "left" }}
                        className="input-select-registro"
                        value={formData.id_empresa}
                        onChange={handleSelectChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value="">
                          <em>Seleccionar empresa...</em>
                        </MenuItem>
                        {empresaId ? (
                          // Si hay un empresaId, filtra las empresas
                          empresas
                            .filter(empresa => empresa.id == empresaId) // O cualquier otra lógica de filtrado
                            .map(empresa => (
                              <MenuItem key={empresa.id} value={empresa.id}>
                                {empresa.nombre_empresa}
                              </MenuItem>
                            ))
                        ) : (
                          // Si no hay empresaId, muestra todas las empresas
                          empresas.map(empresa => (
                            <MenuItem key={empresa.id} value={empresa.id}>
                              {empresa.nombre_empresa}
                            </MenuItem>
                          ))
                        )}
                      </StyledSelect>
                    </div>
                  </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='telefono'>Teléfono</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart" value={formData.telefono} onChange={handleInputChange} name="telefono" id="telefono" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='email'>Correo electrónico</label>
                  <div className='contieneinputempresaregister'>
                    <input type="email" className={`input-crear-empresa-adminsmart ${emailExistsError && 'input-error'}`} name="correo" id="correo" value={formData.correo} onChange={handleInputChange} />
                  </div>
                  {error && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{error}</div>}
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='password'>Contraseña</label>
                  <div className='contieneinputempresaregister'>
                      <input
                          type={showPassword ? 'text' : 'password'}
                          className="input-crear-empresa-adminsmart"
                          value={formData.password}
                          onChange={handleInputChange}
                          name="password"
                          id="password"
                      />
                      <span className="toggleempresapassword" onClick={togglePasswordVisibility}>
                          {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span>
                  </div>
                  {errors.password && <p className="error-message">{errors.password}</p>}
              </div>
  
              <div className="input-crearadminsmartempresa">
                  <label htmlFor='password_confirmation'>Confirmar Contraseña</label>
                  <div className='contieneinputempresaregister'>
                      <input
                           type={showPassword ? 'text' : 'password'}
                          className="input-crear-empresa-adminsmart"
                          value={formData.password_confirmation}
                          onChange={handleInputChange}
                          name="password_confirmation"
                          id="password_confirmation"
                      />
                      <span className="toggleempresapassword" onClick={togglePasswordVisibility}>
                          {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                      </span>
                  </div>
                  {errors.password_confirmation && <p className="error-message">{errors.password_confirmation}</p>}
              </div>
                <div className="buttonsiguientecrearempresa">
                  <button type="button" onClick={handleNextStep}>Siguiente</button>
                </div>
              </>
            )}
  
            {step === 2 && (
              <>
                <div className="buttonretroceedercrearempresa" onClick={handlePrevStep}>
                  <IoIosArrowBack />
                </div>
            
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Link web</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart" placeholder="ejemplo:http://www.example.com" value={formData.web} onChange={handleInputChange} name="web" id="web" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Dirección</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart" name="direccion" id="direccion" value={formData.direccion} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>País</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='pais'
                      id='pais'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar país...</em></MenuItem>
                      {Object.keys(datadireccion.pais).map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Región</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='region'
                      id='region'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      displayEmpty
                      disabled={!selectedCountry}
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar región...</em></MenuItem>
                      {regions.map(region => (
                        <MenuItem key={region} value={region}>{region}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Comuna</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='comuna'
                      id='comuna'
                      value={selectedComuna}
                      onChange={handleComunaChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Comuna' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar comuna...</em></MenuItem>
                      {communes.map((comuna) => (
                        <MenuItem key={comuna} value={comuna}>{comuna}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="buttonsiguientecrearempresa">
                  <button type="submit">Crear Sucursal</button>
                </div>
              </>
            )}
          </form>
        </Box>
        <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>
  
        {/* Popup de error */}
        <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>
  
        {/* Popup de carga */}
        <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section style={{ textAlign: 'center', padding: '1rem' }}>
              <CircularProgress style={{ color: '#694B81' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                Procesando solicitud...
              </p>
            </section>
          </Box>
        </Modal>
      </Container>
    )
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color: '#694B81',
    position: 'relative',
    left: '0.5%',
    fontFamily: "Poppins",
    width: '100%',
    height: '55px',
    fontSize: '0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize: '0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
  
  }));