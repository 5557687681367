import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Style/navbar/footer.css";
import { useAuth } from "../context/usecontext";

export const Footer =({ onLogin, onLogout }) => {
    const { adminToken, setAdminAuthToken } = useAuth();
    const navigate = useNavigate();
    
    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
            const onLoginState = sessionStorage.getItem('onLogin'); // Obtener el estado de onLogin
            if (onLoginState) {
                onLogin(parseInt(onLoginState)); // Convertir a número y llamar a onLogin
            }
        } else {
            navigate("/"); // Si no hay token almacenado, redirige a la página de inicio de sesión
        }
    }, []);

    return(
        <section className="footersmart">
            <div className="titulofooter">
                <h2>Smart Memorial 2024</h2>
            </div>
            <hr className="lineafooter"/>
        
        
        </section>
    )
}