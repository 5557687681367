import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { EncabezadoAdmiSucursal } from "../Index/ComponenteEstructura/EncabezadoSucursalAdmin";
import { ReporteFuncionSucursal } from "../FuncionesSucursalAdmin/Tareas/ReportefuncionSucursal";
import SucursalLayout from "../../../../../SucursalLayout"
import { NavTareasSucursal } from "../Index/ComponenteEstructura/navTareas";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
/* MATERIAL UI */
import { Container, Box, Modal, Grid, Select, MenuItem, CircularProgress} from '@mui/material';

export const IndexTareaBaseDatosSucursal = ({setShowNavbarFooter}) => {
    const location = useLocation();
    const { Token, Empresa, Sucursal, Usuario, UserDataMarca } = useUserEmpresa();
    const {  id } = location.state || {};
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
    return (
        <SucursalLayout>
            <Container maxWidth="lg">
               
                    <ReporteFuncionSucursal  sucursal={Sucursal}  Token={Token} id={id} />
                
            </Container>
        </SucursalLayout>
   

    )
}

const styleGrid = {
    maxWidth:'100%',
    display:'flex',
    alignItems:'start',
    justifyContent:'center',

}
const styleGriddos = {
    maxWidth:'100%',
    display:'flex',
    alignItems:'start',
    justifyContent:'flex-start',

}