import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import rateLimit from 'axios-rate-limit';
import dayjs from 'dayjs';
import jsPDF from 'jspdf'; 
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
/* libreria UI */
import {  Modal, Box, CircularProgress, Grid, Card, CardContent, Typography, Container,} from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import QrCodeIcon from '@mui/icons-material/QrCode';

/* IMAGENES  */

import QrMorado from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrmorado.png"




const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 1000 });


export const CodigosQrInnicioEmpresaAdmi = () => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const { id } = useParams();
    const [qrData, setQrData] = useState([]);
    const [AllQr, setAllQr] = useState([])
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const [mes, setMes] = useState("");
    const [totalQr, setTotalQr] = useState(0);
    const [qrAsignados, setQrAsignados] = useState(0);
    const [qrDisponibles, setQrDisponibles] = useState(0);
    const theme = useTheme();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    
  
  

 
    useEffect(() => {
      // Obtener la fecha actual
      const fechaActual = new Date();
      const dia = fechaActual.getDate(); // Día
      const mes = fechaActual.getMonth(); // Mes (de 0 a 11)
      const año = fechaActual.getFullYear(); // Año

      // Arreglo con los nombres de los meses
      const meses = [
          "enero", "febrero", "marzo", "abril", "mayo", "junio",
          "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];

      const fechames = ` ${meses[mes]}`;

      // Establecer la fecha en el estad
      setMes(fechames);

  }, []);
  //funcion qr de este mes
  useEffect(() => {
    // Obtenemos el mes actual (de 0 a 11) y el año actual
    const mesActual = dayjs().month();
    const anioActual = dayjs().year();

    // Filtrar los QR que fueron creados en el mes y año actual
    const qrDelMesActual = AllQr.filter(qr => {
      const fechaCreacion = dayjs(qr.fecha_creacion);
      return fechaCreacion.month() === mesActual && fechaCreacion.year() === anioActual;
    });

    // Contar los QR asignados y disponibles para el mes actual
    const totalQrMes = qrDelMesActual.length;
    const qrAsignadosMes = qrDelMesActual.filter(qr => qr.id_usuario).length;
    const qrDisponiblesMes = qrDelMesActual.filter(qr => !qr.id_usuario).length;

    // Actualizamos los estados
    setTotalQr(totalQrMes);
    setQrAsignados(qrAsignadosMes);
    setQrDisponibles(qrDisponiblesMes);
  }, [AllQr]);


   
    

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true); // Activa el estado de carga antes de iniciar las solicitudes
    
        try {
          // Realiza todas las solicitudes de datos necesarias
          await Promise.all([
            fetchQrData(),
            fetchGruposFamiliares(),
          ]);
        } catch (error) {
          console.error("Error al cargar los datos:", error);
        } finally {
          setLoading(false); // Desactiva el estado de carga después de finalizar las solicitudes
        }
      };
    
      // Comprueba si la empresa y su ID están disponibles para iniciar la carga de datos
      if (Empresa && Empresa.id) {
        fetchData();
      }
    }, [Empresa, Token]);
  

  const fetchQrData = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true)
      const response = await http.get(`${backendURL}/api/qrempresa-admi/${Empresa.id}`, {
          headers: {
              Authorization: `Bearer ${Token}`,
          },
      });
      setQrData(response.data.data);
      setLoadings(false)
  };

  const fetchGruposFamiliares = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true)
      const response = await http.get(`${backendURL}/api/empresa/${Empresa.id}/grupos-familiares`, {
          headers: {
              Authorization: `Bearer ${Token}`,
          },
      });
      setAllQr(response.data);
      setLoadings(false)
  };

 



      
    
      if (loading) {
        return(
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
          <Skeleton variant="text" width="80%" height="100px" />
          <Skeleton variant="rectangular" width="80%" height="400px" />
      </Box>)
      }
    

  


    return(
        <Box >
         
          <Box sx={StyleBoxBlanco}>
            <Container maxWidth='lg' >

             <h3 className="SessionVistaQrContratadosEmpresa">Codigos QR {Empresa.nombre_social}</h3>
          <Grid container spacing={2} >
      {/* Título de la sección */}
     
      <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#694B81",
              border:'1px solid #694B81',
              width:'5%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem', fontWeight:'500', color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Creados</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#694B81' : '#694B81'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{totalQr}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
        
      <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#D26999",
              border:'1px solid #D26999',
              width:'5%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem', fontWeight:'500',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Asignados</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#D26999' : '#D26999'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{qrAsignados}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#68A354",
              border:'1px solid #68A354',
              width:'5%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem', fontWeight:'500',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Disponible</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#68A354' : '#68A354'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{qrDisponibles}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
     
    </Grid>
    </Container>
  

  </Box>
        
       
               
           
           
      
      </Box>
    )
}


/* estilo material UI */
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius:'10px',
    height:'650px',
    overflowY:'auto'
  };

  const modalStyleClose = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end'
  }

  
 
const StyleBoxBlanco ={
 width:'100%',
  border:'1px solid #FFFF',
  margin:'0px',
  borderRadius:'10px',
 
  background:'#FFFF',
  margin:'10px 0px'
}
const StyleGridQR = {
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  width:'100%'

}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));