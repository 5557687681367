import React, { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [adminToken, setAdminToken] = useState(null);

  const setAdminAuthToken = (token) => {
    setAdminToken(token);
  };

  return (
    <AuthContext.Provider value={{ adminToken, setAdminAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);