import React from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { useNavigate, Link} from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { Terminosycondiciones } from "../../../Log/Registrar/terminosycondiciones";
import "../../../../Style/Registro/terminos.css"



export const Terminosusuario = () => {
    const location = useLocation();
    const ClientToken = location.state.user; // Obtener la información del usuario desde las props
    const grupoFamiliar = location.state.familia; 
    const navigate = useNavigate();
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
      const fetchFuneraria = async () => {
        try {
          if (grupoFamiliar && grupoFamiliar.id_funeraria) {
            const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
            const funerariaData = response.data;
            setLink(funerariaData);
            if (funerariaData.nombre === 'smart memorial') {
              setLogoUrl(null); // No mostrar logo
            } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
            } else {
              setLogoUrl(null); // No hay logo disponible
            }
        
          } else {
            console.error('Group family or funerary ID is missing');
          }
        } catch (error) {
          console.error('Error fetching funeraria data:', error);
        }
      };
    
      fetchFuneraria();
    }, [grupoFamiliar]); 

  const handleGoBack = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  };
    return(
      <section className="contienenpaginainiciousuario">
      {logoUrl && (
            <Link to={link.web} className="logofunerariamemorial" target="black"> 
          
           <img
             src={`${backendURL}/${logoUrl}`}
             alt="Logo de la funeraria"
             className="logofun"
  
           />
         </Link>
          )}
          <Container className="contieneregistro"component="main" maxWidth="md">
       <Box sx={{
        padding:'20px 40px',
        '@media (max-width: 450px)': {
            padding:'20px 10px' // Margen reducido para pantallas menores a 450px
          }
      
       }}>
<section className="closeterminopage"  onClick={handleGoBack}><IoClose/></section>
<section className="tituloterminos">
                <strong><h1>Términos</h1></strong>
                <h1 className="subtitulotermino">y condiciones</h1>
            </section>
            <section className="descripciontermino">
                <h1 style={{color:"#585857", fontSize:"0.75rem",  marginBottom:"-10px",}}>1. CONDICIONES GENERALES Y ACEPTACIÓN</h1>
           <p style={{color:"#585857", fontSize:"0.6rem"}}>Estas condiciones generales (en adelante, las &quot;Condiciones Generales&quot;) regulan el uso del
portal y pone a disposición de los usuarios de Internet a través de smart-memorial (en
adelante, la &quot;Plataforma&quot;).</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>El acceso a la Plataforma y la utilización de los Servicios, según se define más adelante
entrega al usuario la condición de usuario de los mismos (en adelante, el &quot;Usuario&quot;) y
expresa la aceptación plena y sin reservas del Usuario de todas y cada una de las
Condiciones Generales en la versión publicada por <strong><strong>SMART-MEMORIAL</strong></strong> en el momento
mismo en que el Usuario acceda a los Servicios.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>La utilización de los Servicios se encuentra sometida igualmente a todos los avisos,
reglamentos de uso e instrucciones puestos en conocimiento del Usuario por <strong>SMART-
MEMORIAL.</strong></p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>El usuario se compromete a hacer un uso adecuado de los servicios y/o contenidos en la
Plataforma y a no emplearlos para realizar actividades ilícitas o constitutivas de delito y/o
que infrinjan la regulación sobre propiedad intelectual e industrial, o cualesquiera otras
normas del ordenamiento jurídico aplicable. Además, el usuario se abstendrá de utilizar
cualquiera de los servicios ofrecidos por <strong>SMART-MEMORIAL</strong> con fines o efectos ilícitos,
lesivos de los derechos e intereses de terceros, o que puedan dañar, inutilizar,
sobrecargar, deteriorar o impedir la normal utilización de los servicios, los equipos
informáticos o los documentos, archivos y cualquier contenido almacenado en <strong>SMART-
MEMORIAL</strong> o servidores externos enlazados desde el mismo.</p>
           
           
<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>2. OBJETO</h1> 
<p style={{color:"#585857", fontSize:"0.6rem"}}>Las presentes Condiciones Generales regulan la prestación de los Servicios, según se
definen en la Cláusula 3, por parte de <strong><strong>SMART-MEMORIAL</strong></strong> y la utilización de los Servicios
por parte de los Usuarios.</p>
<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>3. LOS SERVICIOS</h1> 
<p style={{color:"#585857", fontSize:"0.6rem"}}> <strong><strong>SMART-MEMORIAL</strong></strong> es un servicio que permite a los usuarios registrados, crear a través de
la Plataforma uno o más memoriales de sus seres queridos fallecidos y acceder a través de
un código QR personalizado en el cual podrán compartir con sus redes de contactos,
imprimir y adherir a la lápida o ánfora si lo desea.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>Se entienden incluidos en los &quot;Servicios&quot; todos aquellos servicios prestados por <strong>SMART-
MEMORIAL</strong> a través de la Plataforma, actualmente o en el futuro, y que actualmente son
los siguientes:</p><div>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    a) Los usuarios podrán crear memoriales de sus seres queridos fallecidos,
    incorporando imágenes, un video y textos a su elección. (formato y cantidad de
    textos, videos e imágenes regulados y establecidos por la Plataforma)
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    b) Los usuarios podrán seleccionar fondos del memorial disponibles en la Plataforma.
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    c) Los usuarios podrán crear eventos indicando motivo del evento, fecha, hora y
    lugar donde se desarrollará la actividad y compartir la información con sus redes
    de contactos.
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    d) Los usuarios podrán crear como máximo 5 memoriales por código enlazado al
    cementerio seleccionado.
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    e) Los usuarios podrán recibir y visualizar mensajes y/o saludos de condolencias
    compartidos por todos quienes visiten el memorial, así como también podrán
    eliminarlos o aceptarlos para su publicación en el memorial.
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    f) Los usuarios podrán eliminar, agregar o modificar los memoriales cuando deseen.
  </p>
  <p style={{color:"#585857", fontSize:"0.6rem"}}>
    g) Los usuarios podrán acceder al código QR personal desde el memorial y descargar
    o compartir con sus redes de contactos cuando deseen.
  </p>
</div>
<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>4. CONDICIONES DE ACCESO Y UTILIZACIÓN DEL SERVICIO</h1>
<p style={{color:"#585857", fontSize:"0.7rem"}}>4.1. Capacidad</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}> Los Servicios sólo están disponibles para personas que tengan capacidad legal para
contratar. No podrán utilizar los servicios las personas que no tengan esa capacidad, los
menores de edad o Usuarios de <strong>SMART-MEMORIAL</strong> que hayan sido suspendidos
temporalmente o inhabilitados definitivamente.   </p>     

<p style={{color:"#585857", fontSize:"0.7rem"}}>4.2. Registro para la utilización de los Servicios.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}> La prestación de los Servicios por parte de <strong>SMART-MEMORIAL</strong> exige la previa suscripción
o registro del Usuario previo pago del servicio detallado en la Plataforma.  </p>  
<p style={{color:"#585857", fontSize:"0.6rem"}}> En el caso de que el Usuario deba identificarse para la utilización de determinados
Servicios a través de un nombre (en adelante, el &quot;Nombre del Usuario&quot;), no podrá elegir
como Nombre de Usuario palabras, expresiones o conjuntos gráfico-denominativos
malsonantes, injuriosos, coincidentes con marcas, nombres comerciales, rótulos de
establecimientos, denominaciones sociales, expresiones publicitarias, nombres y
pseudónimos de personajes de relevancia pública o famosos para cuya utilización no esté
autorizado y, en general, contrarios a la ley o a las exigencias de la moral y buenas
costumbres generalmente aceptadas. </p>  
<p style={{color:"#585857", fontSize:"0.6rem"}}>La asignación del Nombre de Usuario se produce de manera automática y el único criterio
empleado al efecto es la inexistencia de un Nombre de Usuario previo que sea idéntico al
elegido por el Usuario. No obstante, lo anterior <strong>SMART-MEMORIAL</strong> se reserva el derecho
a cancelar o suspender aquellos Nombres de Usuarios que resulten ser contrarios a lo
dispuesto anteriormente.</p>
<p style={{color:"#585857", fontSize:"0.7rem"}}>4.3. Obligación de hacer un uso correcto de los Servicios</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>El Usuario se compromete a utilizar los Servicios de conformidad con la ley, estas
Condiciones Generales, así como con la moral y buenas costumbres generalmente
aceptadas y el orden público.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>El Usuario se obliga a abstenerse de utilizar los Servicios con fines o efectos ilícitos,
contrarios a lo establecido en las Condiciones Generales, lesivos de los derechos e
intereses de terceros o que de cualquier forma puedan dañar, inutilizar, sobrecargar o
deteriorar el Servicio o impedir la normal utilización o disfrute del Servicio por parte de los
Usuarios. En particular, y a título meramente indicativo y no exhaustivo, el Usuario se
compromete a no captar datos con finalidad publicitaria, a no enviar publicidad on-line y a
no transmitir, difundir o poner a disposición de terceros a través del Servicio
informaciones, mensajes, gráficos, archivos de sonido y/o imagen, fotografías,
grabaciones y en general cualquier clase de material, datos o contenidos que (a) de
cualquier forma contravengan, menosprecien o atenten contra los derechos
fundamentales y libertades públicas reconocidas constitucionalmente, en los tratados
internacionales y en el resto del ordenamiento jurídico; (b) induzcan, inciten o promuevan
actuaciones delictivas, denigratorias, difamatorias, infamantes, violentas o, en general,
contrarias a la ley, a la moral y buenas costumbres generalmente aceptadas o al orden
público; (c) induzcan, inciten o promuevan actuaciones, actitudes o ideas discriminatorias
por razón de sexo, raza, religión, creencias, edad o condición; (d) incorporen mensajes
delictivos, violentos, pornográficos, degradantes o, en general, contrarios a la ley, a la
moral y buenas costumbres generalmente aceptadas o al orden público; (e) induzcan o

puedan inducir a un estado inaceptable de ansiedad o temor; (f) induzcan o inciten a
involucrarse en prácticas peligrosas, de riesgo o nocivas para la salud y el equilibrio
psíquico; (g) sean falsos, ambiguos, inexactos, exagerados o extemporáneos, de forma que
puedan inducir a error sobre su objeto o sobre las intenciones o propósitos del
comunicante; (h) se encuentren protegidos por cualesquiera derechos de propiedad
intelectual o industrial pertenecientes a terceros, sin que el Usuario haya obtenido
previamente de sus titulares la autorización necesaria para llevar a cabo el uso que
efectúa o pretende efectuar; (i) violen los secretos empresariales de terceros; (j) sean
contrarios al derecho al honor, a la intimidad personal y familiar o a la propia imagen de
las personas; (k) infrinjan la normativa sobre secreto de las comunicaciones; (l)
constituyan, en su caso, publicidad ilícita, engañosa o desleal y, en general, que
constituyan competencia desleal; (m) provoquen por sus características (tales como
formato, extensión, etc.) dificultades en el normal funcionamiento del Servicio; (n)
contengan etiquetas HTML (como, por ejemplo, banners, botones, hiperenlaces).</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>Al participar en <strong>SMART-MEMORIAL</strong> el Usuario acepta expresamente no agregar enlaces o
publicar contenidos con material abusivo, obsceno, vulgar, odioso, tendencioso,
amenazante, discriminatorio o ningún otro que de alguna forma viole las leyes de los
países donde <strong>SMART-MEMORIAL</strong> ofrece sus servicios. Si el Usuario publicara material de
esa índole, su cuenta de usuario registrado será bloqueada y su proveedor de acceso a
Internet será debidamente informado. Al registrarse en <strong>SMART-MEMORIAL</strong> el Usuario
entrega su consentimiento expreso para que <strong>SMART-MEMORIAL</strong> pueda borrar o editar
cualquier mensaje si considera que incumple las normas básicas de participación en esta
comunidad.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> se reserva el derecho de desactivar o eliminar un registro de usuario
si considera que su actitud va contra la buena fe y normas de conducta y uso de la
Plataforma. La falsedad u omisión de los datos personales de registro que impidan a la
empresa editora de esta Plataforma, llegado el caso, a ponerse en contacto

personalmente con un usuario registrado podrá ser causa de desactivación de su código
de usuario.</p>
<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>5. EXCLUSIÓN DE GARANTÍAS Y DE RESPONSABILIDAD</h1>
<p style={{color:"#585857", fontSize:"0.7rem"}}>5.1. Exclusión de garantías y de responsabilidad por el funcionamiento del Servicio</p>
<p style={{color:"#585857", fontSize:"0.7rem"}}>5.1.1. Disponibilidad, continuidad, utilidad y falibilidad</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no garantiza la disponibilidad y continuidad del funcionamiento del
Servicio. Cuando ello sea razonablemente posible, <strong>SMART-MEMORIAL</strong> advertirá
previamente las interrupciones en el funcionamiento del Servicio. <strong>SMART-MEMORIAL</strong> tampoco garantiza la utilidad del Servicio para la realización de ninguna actividad en
particular, ni su infalibilidad y, en particular, aunque no de modo exclusivo, que los
Usuarios puedan efectivamente acceder al Servicio, acceder a las distintas páginas web
desde donde se presta el Servicio, ni que a través de éste se pueda transmitir, difundir,
almacenar o poner a disposición de terceros los Contenidos, o recibir, obtener o acceder a
los Contenidos, o ejecutar cualquiera de las prestaciones previstas en estas Condiciones
Generales.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> A excluye cualquier responsabilidad por los daños y perjuicios de toda
naturaleza que puedan deberse a la falta de disponibilidad o de continuidad del
funcionamiento del servicio, a la defraudación de la utilidad que los usuarios hubieren
podido atribuir al servicio, a la fiabilidad del servicio, y en particular, aunque no de modo
exclusivo, a los fallos en el acceso a las distintas páginas web del servicio, así como a los
fallos en la transmisión, difusión, almacenamiento o puesta a disposición de terceros de
los contenidos o en la recepción, obtención o acceso a los contenidos, o en la ejecución de
cualquiera de las prestaciones previstas en estas condiciones generales, así como a los
fallos en el acceso a informaciones sobre la rentabilidad de la cartera definida por el
usuario.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}> Tanto <strong>SMART-MEMORIAL</strong> como las empresas de servicios relacionadas que son parte de la
Plataforma, no son responsables por la instalación de los códigos QR como tampoco de su
mantención, los daños ocasionados por factores ambientales o de mantención de las
áreas verdes del cementerio donde se encuentra operativa la plataforma, como también
por daños mal intencionados de terceros, son factores predecibles e inevitables que los
usuarios deben considerar, por tanto es de total responsabilidad del usuario por la
instalación de los códigos QR y de su mantención y reposición.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>Los usuarios podrán reemplazar el código QR otorgado inicialmente por <strong> SMART-
MEMORIAL</strong> cuando deseen, ya sea remplazo de materialidad como de dimensiones,
siempre y cuando cumplan con las normativas internas establecidas por la empresa
cementerio contratado.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> entregará al usuario el código QR personal por una sola vez, en
formato de 5cms x 5cms respectivamente, en metal resistente a la intemperie, dicho
código será enviado al domicilio del usuario indicado en formulario de registro, en un
periodo de 14 días hábiles contados desde la fecha de contratación del servicio. El usuario
es responsable de reemplazar el código otorgado por <strong>SMART-MEMORIAL</strong> cuando sea
necesario.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no es responsable por la reposición de los códigos QR instalados en
ánforas o lápidas respectivamente, como tampoco por la mala instalación de éste.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>Toda la información que contienen los datos personales del usuario y de los fallecidos,
imágenes, videos y textos publicados por el usuario, es de total responsabilidad del
usuario administrador del memorial.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no es responsable de los mensajes y su contenido que las personas
publiquen en el memorial, el usuario es quien podrá eliminar o aceptar su publicación

previa revisión y aprobación de estos. Deberá entenderse que <strong>SMART-MEMORIAL</strong> es un
servicio abierto a la información contenida y otorgada por el usuario a cualquier persona
que visite el memorial, por lo tanto, la información contenida en el o los memoriales es de
carácter público y no privado.</p>

<p style={{color:"#585857", fontSize:"0.7rem"}}>5.1.2. Privacidad y seguridad en la utilización del Servicio</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no garantiza la privacidad y seguridad de la utilización del Servicio y,
en particular, no garantiza que terceros no autorizados no puedan tener conocimiento de
la clase, condiciones, características y circunstancias del uso que los Usuarios hacen del
Servicio o que puedan acceder y, en su caso, interceptar, eliminar, alterar, modificar o
manipular los mensajes y comunicaciones de cualquier clase que los Usuarios difundan o
pongan a disposición de terceros a través del Servicio.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> excluye toda responsabilidad por los daños y perjuicios de toda
naturaleza que pudieran deberse al conocimiento que puedan tener terceros no
autorizados de la clase, condiciones, características y circunstancias del uso que los
usuarios hacen del servicio o que pudieran deberse al acceso y, en su caso, a la
interceptación, eliminación, alteración, modificación o manipulación por parte de terceros
no autorizados de las comunicaciones de cualquier clase que los usuarios difundan o
pongan a disposición de terceros a través del servicio.</p>

<p style={{color:"#585857", fontSize:"0.7rem"}}>5.2. Exclusión de garantías y de responsabilidad por los Contenidos</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no controla ni garantiza la ausencia de virus ni de otros elementos en
los Contenidos suministrados por los usuarios sobre la base de información proporcionada
por terceros proveedores o suministrados directamente por terceros proveedores o por
los Usuarios ni en cualesquiera otros contenidos proporcionados a través del Servicio que
puedan producir alteraciones en su sistema informático (software y hardware) o en los
documentos electrónicos y ficheros almacenados en su sistema informático.</p>

<p style={{color:"#585857", fontSize:"0.7rem"}}>5.3 Veracidad, exactitud, exhaustividad y actualidad</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no garantiza la veracidad, exactitud, exhaustividad y actualidad de los
Contenidos suministrados por <strong>SMART-MEMORIAL</strong> sobre la base de información
proporcionada por terceros proveedores o suministrados directamente por terceros
proveedores o por otros Usuarios ni de cualesquiera otros contenidos proporcionados a
través del Servicio.</p>

<p style={{color:"#585857", fontSize:"0.7rem"}}>5.3. Responsabilidad sobre enlaces</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> puede facilitar enlaces, websites o servicios de otras compañías a
través de su plataforma. El Usuario entiende y acepta que <strong>SMART-MEMORIAL</strong> no controla
y no es responsable de estos servicios y productos.</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}>El Usuario acepta y reconoce que <strong>SMART-MEMORIAL</strong> no controla, ni supervisa, ni asume
responsabilidad acerca de la calidad, seguridad, veracidad y exactitud de la descripción y
demás elementos de los productos y/o servicios promocionados u ofrecidos en su
Plataforma, ni acerca del cumplimiento de los requisitos legales para ofrecer y vender los
productos o servicios, ni sobre la capacidad y legitimación de los solicitantes y/o
anunciantes del espacio publicitario para promocionar, ofrecer y/o vender sus bienes o
servicios. El material publicitario es propiedad y responsabilidad de los solicitantes y/o
anunciantes del espacio.</p>

<p style={{color:"#585857", fontSize:"0.7rem"}}>5.4. Exclusión de garantías y de responsabilidad por la utilización del Servicio por los
Usuarios</p>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> no tiene obligación de controlar y no controla la utilización que los
Usuarios hacen del Servicio. En particular, <strong>SMART-MEMORIAL</strong> no garantiza que los
Usuarios utilicen el Servicio de conformidad con estas Condiciones Generales, ni que lo
hagan de forma diligente y prudente. <strong>SMART-MEMORIAL</strong> tampoco tiene la obligación de
verificar y no verifica la identidad de los Usuarios, ni la veracidad, vigencia, exhaustividad

y/o autenticidad de los datos que los Usuarios proporcionan sobre sí mismos a otros
Usuarios.</p>

<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>6. DENEGACIÓN Y RETIRADA DEL ACCESO A LA PLATAFORMA Y/O A LOS SERVICIO</h1>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> se reserva el derecho a denegar o retirar el acceso al Servicio y a
retirar las informaciones, datos y contenidos difundidos o puestos a disposición de
terceros mediante el Servicio, en cualquier momento y sin necesidad de preaviso, a
aquellos Usuarios que incumplan estas Condiciones Generales.</p>

<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>7. DURACIÓN Y TERMINACIÓN</h1>
<p style={{color:"#585857", fontSize:"0.6rem"}}>La prestación del Servicio tiene, en principio, una duración de un año, tiempo que está
definido de acuerdo al plan contratado por el usuario, posteriormente a dicho plazo, el
usuario deberá activar el servicio anualmente, por el costo señalado en la Plataforma.
<strong>SMART-MEMORIAL</strong> está autorizado para dar por terminada la relación contractual o
suspender la prestación del Servicio una vez caducado el plazo del servicio establecido de
acuerdo al plan contratado y de activarlo nuevamente previo pago de algún plan
disponible. Cuando ello sea razonablemente posible, <strong>SMART-MEMORIAL</strong> advertirá
previamente la terminación o suspensión de la prestación del Servicio.</p>
<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>8. LEGISLACION APLICABLE</h1>
<p style={{color:"#585857", fontSize:"0.6rem"}}>Estas Condiciones Generales se rigen por la ley chilena.</p>

<h1 style={{color:"#585857", fontSize:"0.75rem", marginBottom:"-10px", marginTop:"40px"}}>9. JURISDICCIÓN</h1>
<p style={{color:"#585857", fontSize:"0.6rem"}}><strong>SMART-MEMORIAL</strong> y el Usuario, se someten la jurisdicción de los Juzgados y Tribunales
ordinarios de la ciudad de Santiago.</p>




            </section>


 


       </Box>
       </Container>
        </section>
    )
}