import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EmpresaLayout from "../../../../../../EmpresaLayout";
/* componenete */
import { EncabezadoAdmiEmpresa } from "../../ComponenteEstructura/EncabezadoEmpresaAdmi";
import { CreaUsuariormemorialDemo } from "../../FuncionesEmpresaAdmi/CrearMemorialDemo/UsuarioMmemorialDemo";
import { GaleriaMemorialDemo } from "../../FuncionesEmpresaAdmi/CrearMemorialDemo/GaleriaMemorialDemo";
import { MensajeDemoEmpresaMemorial } from "../../FuncionesEmpresaAdmi/CrearMemorialDemo/MensajeMemorialDemo";
/* libreria UI */
import { Container} from '@mui/material';
import { styled } from '@mui/material/styles'; 
import MuiAlert from '@mui/material/Alert';




export const CrearMemorialDemo =({setShowNavbarFooter}) =>{
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const { id } = useParams();
    const location = useLocation();
    const { empresa, user, grupoFamiliar} = location.state || {};
    const [step, setStep] = useState(1);
    const [memorialId, setMemorialId] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;


   
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

   
    const handleNextStep = (id) => {
        setMemorialId(id); // Guardar el memorialId
        setStep(prevStep => prevStep + 1); // Avanzar al siguiente paso
    };

  

  

    return(
        <EmpresaLayout>
        <Container maxWidth='lg' sx={{fontFamily:'Poppins'}}>
            
            <Container maxWidth='md'>
            {step === 1 && (
                <>
                <CreaUsuariormemorialDemo
                    id={id}
                    empresa={Empresa}
                    user={Usuario}
                    grupoFamiliar={grupoFamiliar}
                    Token={Token}
                    onNextStep={handleNextStep}
                />
                </>

            )}
             {step === 2 && (
                <>
                <GaleriaMemorialDemo
                    id={id}
                    empresa={Empresa}
                    user={Usuario}
                    grupoFamiliar={grupoFamiliar}
                    Token={Token}
                    memorialId={memorialId}
                    onNextStep={handleNextStep}
                />
                </>
                
            )}
             {step === 3 && (
                <>
                     <MensajeDemoEmpresaMemorial
                    id={id}
                    empresa={Empresa}
                    user={Usuario}
                    grupoFamiliar={grupoFamiliar}
                    Token={Token}
                    memorialId={memorialId}
                    onNextStep={handleNextStep}
                />
                </>
            )}
                
            </Container>
        
        
        </Container>
        </EmpresaLayout>
    )
}