import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { EncabezadoAdmiEmpresa } from "../../ComponenteEstructura/EncabezadoEmpresaAdmi";
import { Marca } from "../../FuncionesEmpresaAdmi/marca";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout"
/* MATERIAL UI */
import { Container} from '@mui/material';

export const  MarcaEmpresa =({setShowNavbarFooter}) =>{
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
  
    return(
        <EmpresaLayout>
            <Container maxWidth="lg">
            <Marca empresa={Empresa}  Token={Token} />
            </Container>
        </EmpresaLayout>
    )
}