import React, { useEffect, useState } from "react";
import axios from "axios";


/* imagenes */
import logomemorialdos from "../../../../../../Image/generales/grupo-familia-2.png";
import perfil from "../../../../../../Image/generales/foto-predeterminada-memorial.jpg"
import crear from "../../../../../../Image/generales/crear-memorial.png";

/* elmentos */
import Box from "@mui/material/Box";
import { ImSearch } from "react-icons/im";
import Modal from '@mui/material/Modal';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { PiScrollFill } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilos por defecto



export const CreaUsuariormemorialDemo = ({ id, user, empresa, grupoFamiliar, Token, onNextStep }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [imagenPerfil, setImagenPerfil] = useState(null);
    const [imagenPredeterminadaBlob, setImagenPredeterminadaBlob] = useState(null);
    const [historia, setHistoria] = useState('');
    const [nombreFallecido, setNombreFallecido] = useState('');
    const [cementerio, setCementerio] = useState(empresa? `${empresa.direccion}, ${empresa.comuna}, ${empresa.region}, ${empresa.pais}`: '');
    const [imagenPreview, setImagenPreview] = useState(null);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [error, setError] = useState('');
    const [errorAlertOpens, setErrorAlertOpens] = useState(false);
    const [MemorialId, setMemorialId] = useState(null);
    

    /* URL del backend */
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    /* crear memorial */
    const Submit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Validación de la imagen de perfil
        if (!imagenPerfil && !imagenPredeterminadaBlob) {
            setErrorAlertOpen(true);
            setAlertMessage('Debe subir una imagen de perfil.');
            setIsSubmitting(false);
            return; // Detener la ejecución si no hay imagen
        }

        const formData = new FormData();
        formData.append('nombre', nombreFallecido);
        formData.append('cementerio', cementerio);
        formData.append('id_empresa', empresa.id);
        formData.append('id_usuario', id);

        // Añadir la imagen si ha sido seleccionada
        if (imagenPerfil) {
            formData.append('imagen_perfil', imagenPerfil);
        } else if (imagenPredeterminadaBlob) {
            formData.append('imagen_perfil', imagenPredeterminadaBlob, 'foto-predeterminada-memorial.jpg');
        }
        formData.append('historia', historia);

        try {
            setenviandoAlert(true);
            const response = await axios.post(`${backendURL}/api/memorialCrearEmpresa/${grupoFamiliar.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${Token}` // Asegúrate de tener el token de autenticación disponible
                }
            });

            if (response.status === 201) {
                const memorialId = response.data.id;
                setMemorialId(memorialId)
                setSuccessAlertOpen(true);
                setAlertMessage('El memorial se ha creado con éxito.');
            }
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Hubo un error al crear el memorial.');
        } finally {
            setIsSubmitting(false);
            setenviandoAlert(false);
        }

        setTimeout(() => {
            handleCloseModal();
        }, 3000);
    };
    const handleAccept = () => {
        setSuccessAlertOpen(false);
        if (MemorialId) {
            onNextStep(MemorialId); // Pasa el memorialId al siguiente paso
        } else {
            setErrorAlertOpen(true);
            setAlertMessage("No se pudo obtener el ID del memorial.");
        }
    };
 
    /* historia */
    const handleChange = (value) => {
        setHistoria(value);
    };

    /* imagen perfil */
    useEffect(() => {
        // Cargar la imagen predeterminada como Blob
        fetch(perfil)
            .then(response => response.blob())
            .then(blob => setImagenPredeterminadaBlob(blob));
    }, []);

    const handleImagenSeleccionada = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagenPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setImagenPerfil(file);
        }
    };

    const handleCloseModal = () => {
        setenviandoAlert(false);
    };

   
    const validarCampos = () => {
        let erroresTemp = {};
        
        if (!nombreFallecido.trim()) {
            erroresTemp.nombreFallecido = "El nombre del fallecido es obligatorio.";
        }

        if (!cementerio.trim()) {
            erroresTemp.cementerio = "El campo cementerio es obligatorio.";
        }

        if (!imagenPerfil && !imagenPredeterminadaBlob) {
            erroresTemp.imagenPerfil = "Debe subir una imagen de perfil.";
        }

        setError(erroresTemp);
        return Object.keys(erroresTemp).length === 0; // Retorna true si no hay errores
    };


    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                background: "#ffff",
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily:'Poppins'

            }}
        >

            <section  className="contienetodoempresacrearmemorial" style={{marginTop:'50px', display:'flex', alignItems:'start', width:'100%'}}>

                <section className="titulo-crear-familia">
                    <h1 className="titulofamiliafinal">Empresa</h1>
                    <div className="apellido-crear">
                        <h1>{empresa.id_tipo_empresa == 1 ? 'Cementerio' :'Funeraria'} {empresa.nombre_social}</h1>

                        <img
                            src={logomemorialdos}
                            className="imagen-seccion2"
                            alt="smart memorial"
                        />
                    </div>
                </section>
            </section>
            <section className="contienetodocrearmemorial">

                <div className="titulopartedos">
                    <img src={crear} alt="smart memorial" />
                    <h1 className="titulo-memoria">Crear Memorial Empresa</h1>
                </div>


                <form onSubmit={Submit}  className="formcrearempresamemorial">
                    <div className="inputregistorform">
                        <label>Nombre Sucursal Empresa</label>
                        <div className="contieneinputmemorial">
                            <input value={nombreFallecido} className={`crearinputmemorialempresa ${error && 'input-error'}`} name="nombres"
                                id="name" onChange={(e) => setNombreFallecido(e.target.value)} />
                        </div>           {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
                    </div>
                    <div className="inputregistormemorialempresaform">
                <label>Ubicaciòn</label>
                <div className="input-empresamemorialcementerio">

                  <input
                    type='text'
                    className="crearinputmemorialempresa"
                    name="cementerio"
                    id="cementerio"
                    placeholder="Indicar nombre del cementerio"
                    value={cementerio} onChange={(e) => setCementerio(e.target.value)}
                  />
                  <span className="buscadorempresamemorial">
                    <ImSearch />
                  </span>
                </div>
              </div>
                    <section className="contiene-input-imagen-perfil">
                      <label htmlFor="file-input" className="input-subir">
                            <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
                            <div className="contiene-icono">
                                <FaArrowUpFromBracket className="imgicono" />
                                <span>Subir Foto</span>
                            </div>
                            <img src={imagenPreview || perfil} alt="Vista previa de la imagen" />

                        </label>
                        
                    </section>
                    <section className="historia-crear">
                        <div className="titulohitoriacrear">
                            <PiScrollFill />
                            <h1>Su historia</h1>
                        </div>
                        <ReactQuill

                            className="input-historia"
                            placeholder="Escriba hechos importantes en la vida de su ser querido..."
                            value={historia}
                            onChange={handleChange}
                            modules={{
                                toolbar: [
                                    [],
                                    [],
                                    ['link'],

                                ],
                            }}
                            formats={[
                                'header', 'font', 'size',
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                'list', 'bullet', 'indent',
                                'link', 'image', 'video'
                            ]}
                        />
                    </section>

                    <div className="botones">

                        <div className="botonregistro">
                            <button type="submit">{isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Siguiente'}</button>
                        </div>
                    </div>


                </form>
            </section>

            <Modal open={successAlertOpen} onClose={() => handleAccept}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 2,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >

                    <section style={{ textAlign: 'center' }}>

                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <FaRegCircleCheck
                                    style={{
                                        color: 'green',
                                        fontSize: '2rem',
                                        marginBottom: '1rem',
                                    }}
                                />
                                <p
                                    style={{
                                        fontFamily: 'Poppins',
                                        color: '#585857',
                                        fontSize: '1rem',
                                        fontWeight: '600',
                                        marginTop: '-5px',
                                    }}
                                >
                                    {alertMessage}
                                </p>
                                
                                <div className="contienebotonaceptar">
                                    <button className="botonaceptar" onClick={handleAccept}>
                                        Aceptar
                                    </button>
                                </div>
                            </div>
                    
                    </section>
                </Box>
            </Modal>
            {/* Popup de error */}
            <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            <Modal open={errorAlertOpens} onClose={() => setErrorAlertOpens(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setErrorAlertOpens(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Popup de carga */}
            <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
            </Modal>
        </Box>
    )
}