import React, { createContext, useContext, useState } from 'react';

const GrupoFamiliarContext = createContext();

export const useGrupoFamiliar = () => useContext(GrupoFamiliarContext);

export const GrupoFamiliarProvider = ({ children }) => {
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);

  return (
    <GrupoFamiliarContext.Provider value={{ grupoFamiliar, setGrupoFamiliar }}>
      {children}
    </GrupoFamiliarContext.Provider>
  );
};