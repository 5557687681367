import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import rateLimit from 'axios-rate-limit';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { GeneradorQREmpresa } from "../FuncionesEmpresaAdmi/CreacionQrEmpresa";
import { DataGrid } from '@mui/x-data-grid'; 
import { Link, useNavigate, useParams } from 'react-router-dom';

/* libreria UI */
import {  Modal, Box, CircularProgress, Grid, Card, CardContent, Typography, Container,} from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { useTheme } from '@mui/material/styles';


/* IMAGENES  */
import QrBlanco from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrblanco.png"
import QrMorado from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrmorado.png"
import fondo from "../../../../../Image/generales/carta.png";

/* react icons */
import { IoIosClose } from "react-icons/io";

import { IoSearch } from "react-icons/io5";
import { TbFaceIdError } from "react-icons/tb";
import { TbUserPlus } from "react-icons/tb";
import { TbUserHeart } from "react-icons/tb";
import { TbUser } from "react-icons/tb";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 1000 });


export const CodigosQrEmpresaAdmi = () => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [qrData, setQrData] = useState([]);
    const [AllQr, setAllQr] = useState([])
    const [codigos, setCodigos] = useState([]);
    const [open, setOpen] = useState(false); 
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [mes, setMes] = useState("");
    const [marcas, setMarcas] = useState([])
    const [totalQr, setTotalQr] = useState(0);
    const [qrAsignados, setQrAsignados] = useState(0);
    const [qrDisponibles, setQrDisponibles] = useState(0);
    const navigate = useNavigate()
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [gruposfamilias, setGrupofamilias] = useState([]);
    const theme = useTheme();
  

 
    useEffect(() => {
      // Obtener la fecha actual
      const fechaActual = new Date();
      const dia = fechaActual.getDate(); // Día
      const mes = fechaActual.getMonth(); // Mes (de 0 a 11)
      const año = fechaActual.getFullYear(); // Año

      // Arreglo con los nombres de los meses
      const meses = [
          "enero", "febrero", "marzo", "abril", "mayo", "junio",
          "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];

      const fechames = ` ${meses[mes]}`;

      // Establecer la fecha en el estad
      setMes(fechames);

  }, []);
  //funcion qr de este mes
  useEffect(() => {
    // Obtenemos el mes actual (de 0 a 11) y el año actual
    const mesActual = dayjs().month();
    const anioActual = dayjs().year();

    // Filtrar los QR que fueron creados en el mes y año actual
    const qrDelMesActual = AllQr.filter(qr => {
      const fechaCreacion = dayjs(qr.fecha_creacion);
      return fechaCreacion.month() === mesActual && fechaCreacion.year() === anioActual;
    });

    // Contar los QR asignados y disponibles para el mes actual
    const totalQrMes = qrDelMesActual.length;
    const qrAsignadosMes = qrDelMesActual.filter(qr => qr.id_usuario).length;
    const qrDisponiblesMes = qrDelMesActual.filter(qr => !qr.id_usuario).length;

    // Actualizamos los estados
    setTotalQr(totalQrMes);
    setQrAsignados(qrAsignadosMes);
    setQrDisponibles(qrDisponiblesMes);
  }, [AllQr]);

    const handleAssignUser = (qr) => {
      navigate(`/registro/${Usuario.id}/usuario/empresa`, { state: { QR: qr, Empresa, Usuario, Token } });
    };

    const handleUserqr = (qr) => {
      navigate(`/empresa/${Usuario.id}/qr/memorial/${qr.id}`, { state: { QR: qr, Empresa, Usuario, Token } });
    };
   
   
    

    useEffect(() => {
      const fetchData = async () => {
        setLoading(true); // Activa el estado de carga antes de iniciar las solicitudes
    
        try {
          // Realiza todas las solicitudes de datos necesarias
          await Promise.all([
            fetchQrData(),
            fetchCodigos(),
            fetchGruposFamiliares(),
            fetchMarcas(),
          ]);
        } catch (error) {
          console.error("Error al cargar los datos:", error);
        } finally {
          setLoading(false); // Desactiva el estado de carga después de finalizar las solicitudes
        }
      };
    
      // Comprueba si la empresa y su ID están disponibles para iniciar la carga de datos
      if (Empresa && Empresa.id) {
        fetchData();
      }
    }, [Empresa, Token]);
  

  const fetchQrData = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true)
      const response = await http.get(`${backendURL}/api/qrempresa-admi/${Empresa.id}`, {
          headers: {
              Authorization: `Bearer ${Token}`,
          },
      });
      setQrData(response.data.data);
      setLoadings(false)
  };

  const fetchGruposFamiliares = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true)
      const response = await http.get(`${backendURL}/api/empresa/${Empresa.id}/grupos-familiares`, {
          headers: {
              Authorization: `Bearer ${Token}`,
          },
      });
      setAllQr(response.data);
      setLoadings(false)
  };

 

  const fetchMarcas = async () => {
    if (!Empresa || !Token) return;
    if (Empresa.id) {
        const response = await http.get(`${backendURL}/api/marcaempresa/${Empresa.id}`, {
          headers: {
              Authorization: `Bearer ${Token}`,
          },
      });
        const marcaData = response.data.data[0];
        if (marcaData) {
          
            setMarcas(marcaData);
        }
    }
};

  const fetchCodigos = async () => {
    setLoadings(true)
    if (!Empresa || !Token) return;
      try {
          const response = await http.get(`${backendURL}/api/getcodigosempresa`, {
              headers: {
                  Authorization: `Bearer ${Token}`
              }
          });
         
          setCodigos(response.data);
          setLoadings(false)
      } catch (error) {
          console.error("Error al obtener los códigos:", error);
          setLoadings(false)
        }
  };

  const handleEstadoChange = async (userId, isChecked) => {
    const newStatus = isChecked ? 'ACTIVO' : 'INACTIVO';

    try {
        const response = await axios.get(`${backendURL}/api/estadosuscripcion/${userId}/${newStatus}`, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        });
       
    } catch (error) {
        console.error('Error al actualizar el estado:', error.response ? error.response.data : error.message);
    }
};

    
  
    const refreshQrData = async () => {
      if (Empresa && Empresa.id) {
          setLoading(true);
          setLoadings(true)
          try {
              const response = await  http.get(`${backendURL}/api/empresa/${Empresa.id}/grupos-familiares`, {
                  headers: { Authorization: `Bearer ${Token}` },
              });
              setAllQr(response.data);
              setLoadings(false)
          } catch (error) {
              console.error("Error al actualizar los grupos familiares:", error);
          } finally {
              setLoading(false);
              setLoadings(false)
          }
      }
  };

      // Funciones para abrir y cerrar el modal
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
      // abrir modal crear administrador sucursal
    
   

        const filteredQrData = AllQr.filter(
          (qr) =>
            (qr.qr.toLowerCase().includes(searchTerm.toLowerCase()) || qr.id == Number(searchTerm)) &&
            (qr.tipo_memorial == 1 || qr.tipo_memorial == 2 || qr.tipo_memorial == null) &&
            qr.id_empresa && // Verificar que id_empresa no sea null o vacío
            (!qr.id_sucursal || qr.id_sucursal === null) // Verificar que id_sucursal sea null o vacío
        );
   
        /* descargar carta  */
       
        const fetchCodigosqr = async () => {
          setLoadings(true)
          if (!Empresa || !Token) return;
          try {
              const response = await http.get(`${backendURL}/api/getAllGruposFamiliaresempresas`, {
                headers: { Authorization: `Bearer ${Token}` },
              });
              setGrupofamilias(response.data);
             
          } catch (error) {
              console.error("Error al obtener los códigos:", error);
          } finally {
              setLoading(false);
              setLoadings(false)
          }
      };
      useEffect(() => {
          fetchCodigosqr();
      }, [backendURL, Token]);

    
      const handleDownloadPDF = async (codigoId) => {
        try {
            const grupoFamiliar = gruposfamilias.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliar || !grupoFamiliar.qr) {
                console.error("Código QR no encontrado para el grupo familiar:", codigoId);
                return;
            }
    
            const qrImageUrl = `${backendURL}/api/descargarqrempresa/${codigoId}`;
            const cartaImageUrl = `${backendURL}/api/descargarCarta/${Empresa.id}`; // Usa el ID correcto aquí
            const grupoFamiliares = gruposFamiliaresConSuscripcion.find(grupo => grupo.id === codigoId);
    
            if (!grupoFamiliares || !grupoFamiliares.codigo) {
                console.error("Código no encontrado para el grupo familiar con suscripción:", codigoId);
                return;
            }
    
            const loadImage = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = url;
                    img.crossOrigin = "Anonymous";
                    img.onload = () => resolve(img);
                    img.onerror = (err) => reject(err);
                });
            };
    
            const qrImage = await loadImage(qrImageUrl);
            const fondoImage = await loadImage(fondo);
            let cartaImage;
    
            // Intenta cargar la imagen de la carta
            try {
                cartaImage = await loadImage(cartaImageUrl);
            } catch (error) {
                console.warn("No se pudo cargar la imagen de la carta. Usando el fondo predeterminado.");
                cartaImage = null; // Establece cartaImage a null si no se puede cargar
            }
    
            const canvas = document.createElement('canvas');
            canvas.width = qrImage.width;
            canvas.height = qrImage.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(qrImage, 0, 0, qrImage.width, qrImage.height);
            const qrDataURL = canvas.toDataURL('image/png');
    
            const doc = new jsPDF();
    
            // Usa la imagen de la carta como fondo si existe
            const imgWidth = doc.internal.pageSize.getWidth();
            const imgHeight = doc.internal.pageSize.getHeight();
            if (cartaImage) {
                // Si hay imagen de carta, usarla como fondo
                doc.addImage(cartaImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            } else {
                // Si no, usar el fondo específico
                doc.addImage(fondoImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            }
    
            // Define el tamaño y la posición del QR
            const qrSize = 44;
            const qrX = (imgWidth - qrSize) / 2; // Centra el QR horizontalmente
            const qrY = 140; // Ajusta esta posición según tus necesidades
            doc.addImage(qrDataURL, 'PNG', qrX, qrY, qrSize, qrSize);
    
            const idText = ` ${grupoFamiliar.id}`; // Formato para el ID
    
            // Establece un tamaño de fuente más pequeño
            const originalFontSize = doc.internal.getFontSize(); // Guarda el tamaño de fuente original
            doc.setFontSize(10); // Establece el tamaño de fuente a 10 (ajusta este valor según tus necesidades)
    
            const idTextWidth = doc.getStringUnitWidth(idText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const idTextX = (imgWidth - idTextWidth) / 2; // Centra el ID horizontalmente
            const idTextY = qrY + qrSize + 5; // Ajusta esta posición según tus necesidades
            doc.text(idText, idTextX, idTextY);
    
            // Restaura el tamaño de fuente original
            doc.setFontSize(originalFontSize);
    
            // Agrega el código de texto debajo del ID
            const codeText = grupoFamiliares.codigo; // Código de grupo familiar
            const codeTextWidth = doc.getStringUnitWidth(codeText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const codeTextX = (imgWidth - codeTextWidth) / 2; // Centra el código horizontalmente
            const codeTextY = idTextY + 32; // Ajusta esta posición según tus necesidades
            doc.text(codeText, codeTextX, codeTextY);
    
            doc.save(`codigo_${grupoFamiliar.id}.pdf`);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
        }
    };
      if (loading) {
        return(
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh" sx={{ flexDirection:'column'}}>
          <Skeleton variant="text" width="80%" height="100px" />
          <Skeleton variant="rectangular" width="80%" height="400px" />
      </Box>)
      }
    

      const gruposFamiliaresConSuscripcion = gruposfamilias.map((grupo) => {
        const suscripcion = codigos.find(codigo => codigo.id_grupo_familiar == grupo.id);
        return {
            id: grupo.id,
            id_empresa: grupo.id_empresa,
            id_sucursal: grupo.id_sucursal,
            id_usuario: grupo.id_usuario,
            nombre_familia: grupo.nombre_familia,
            estado_suscripcion: suscripcion ? suscripcion.estado : null,
            codigo: suscripcion ? suscripcion.codigo : null,
            fecha_inicio: suscripcion ? suscripcion.fecha_inicio : null,
            fecha_final: suscripcion ? suscripcion.fecha_final : null,
            qr: grupo.qr,
            tipo_memorial: grupo.tipo_memorial,
            created_at: grupo.created_at,
            updated_at: grupo.updated_at,
        };
    });
    
     
       /* navegacion */
       const handleNavigateCrearSucursal = () => {
        navigate(`/empresa/${Usuario.id}/crear-sucursal`, {
            state: { Usuario, Empresa, Token},
        });
    };

    const handleVerSucursal = (sucursales) => {
      // Navegamos a la ruta deseada y pasamos la información
      navigate(`/sucursales/${Usuario.id}/empresa`, { state: { sucursales, Usuario, Empresa, Token } });
    };
  

    /* descargas QR Unico*/
    const downloadQR = async (qr) => {
     
      try {
          const response = await axios.post(`${backendURL}/api/download/qr/empresa`, {
              qr: qr.qr,
              id: qr.id,
              id_empresa: Empresa.id
          }, {
              responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
          });
  
          if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const a = document.createElement('a');
              a.href = url;
              a.download = qr.qr; // Nombre del archivo
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
          } else {
              console.error('Error:', response.status);
          }
      } catch (error) {
          console.error('Error al enviar la solicitud:', error);
      }
  };

  const columns = [
    { field: 'id', headerName: 'QR', width: 100 },
    { field: 'nombreFamilia', headerName: 'Nombre Familia', width: 200 },
    { field: 'tipoQR', headerName: 'Tipo QR', width: 150 },
    { field: 'cantidadMemorial', headerName: 'Cantidad Memorial', width: 150 },
    { field: 'sucursal', headerName: 'Sucursal', width: 200 },
    { field: 'usuario', headerName: 'Usuario', width: 200 },
    { field: 'rut', headerName: 'Rut', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'telefono', headerName: 'Teléfono', width: 150 },
    {
        field: 'estado',
        headerName: 'Estado',
        width: 150,
        renderCell: (params) => (
          params.row.idUsuario ? (
            <div className="switchqr">
              <IOSSwitch
                checked={params.row.estadoUsuario == 1} // Verifica si el estadoUsuario es 1
                onChange={(event) => handleEstadoChange(params.row.idUsuario, event.target.checked)} // Cambia el estado cuando el switch se mueve
              />
            </div>
          ) : (
            <p>No asignado</p> // Si no hay idUsuario, puedes mostrar algo por defecto
          )
        ),
      },
    {
    field: 'accionesUsuario',
    headerName: 'Acciones Usuario',
    width: 350,
    renderCell: (params) => (
        params.row.idUsuario ? (
        <div style={{ display: 'flex', gap: '8px' }}>
            <button className="BotonPerfil" onClick={() => handleUserqr(params.row)}>
            <TbUser /> Perfil
            </button>
            {params.row.nombreFamilia && (
            <a href={`/familiar/group/${params.row.id}`} target="_blank" rel="noopener noreferrer">
                <button className="BotonMemorial">
                <TbUserHeart /> Memorial
                </button>
            </a>
            )}
            
        </div>
        ) : (
        <button onClick={() => handleAssignUser(params.row)} className="botonAsignar">
            <TbUserPlus /> Asignar Usuario
        </button>
        )
    ),
    },
    {
    field: 'accionesQR',
    headerName: 'Acciones QR',
    width: 300,
    renderCell: (params) => (
        <div style={{ display: 'flex', gap: '8px' }}>
        <button className="BotonQrdescarga" onClick={() => downloadQR(params.row)}>
            Descargar QR
        </button>
        <button className="cartadescargar" onClick={() => handleDownloadPDF(params.row.id)}>
            Descargar Carta
        </button>
        </div>
    ),
    },
    { field: 'qr', headerName: 'QR imagen', width: 100 }
];

const rows = filteredQrData.map((qr) => ({
    id: qr.id,
    nombreFamilia: qr.nombre_familia || 'No hay memorial asignado',
    tipoQR: qr.tipo_memorial == 1
    ? 'Grupo Familiar'
    : qr.tipo_memorial == 2
    ? 'Memorial'
    : qr.tipo_memorial == 3
    ? 'Histórico'
    : qr.tipo_memorial == 4
    ? 'Empresa'
    : '-',
    cantidadMemorial: qr.memorial ? qr.memorial.length : 0,
    sucursal: qr.sucursal ? qr.sucursal.nombre_sucursal : `Empresa ${Empresa?.nombre_empresa}`,
    usuario: qr.usuario ? `${qr.usuario.name} ${qr.usuario.apellido}` : 'No hay usuario asignado',
    rut: qr.usuario?.rut || 'No hay usuario asignado',
    email: qr.usuario?.email || 'No hay usuario asignado',
    telefono: qr.usuario?.telefono || 'No hay usuario asignado',
    idUsuario: qr.id_usuario,
    nombreFamilia: qr.nombre_familia,
    estadoUsuario: qr.usuario?.estado,
    qr: qr.qr,
}));

    return(
        <Box >
         
          <Box sx={StyleBoxBlanco}>
            <Container maxWidth='lg' >
            <Grid container spacing={2} >
      {/* Título de la sección */}
     
      <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#694B81",
              border:'1px solid #694B81',
              width:'10%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Creados</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#694B81' : '#694B81'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{totalQr}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
        
      <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#D26999",
              border:'1px solid #D26999',
              width:'10%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Asignados</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#D26999' : '#D26999'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{qrAsignados}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={4} sx={StyleGridQR}>
         <Card sx={{ width:'100%', background:theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF' }}>
            <CardContent sx={{
              display:"flex",
              flexDirection:'row',
              p:0,
              '&:last-child': {
                paddingBottom: 0, // Elimina el padding aplicado por Material-UI
              },
            }} > 
              <div style={{
               marginRight:'10px',
              background:"#68A354",
              border:'1px solid #68A354',
              width:'10%',
              height:'120px',
              
            }}></div>
            <Grid container  sx={StyleGridQR}>
            <Grid item xs={12} sx={{paddingTop:'10px'}} >
  <Typography variant="h6" sx={{
                    fontSize:'0.9rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'
                }}>Total QR Disponible</Typography>
                
            </Grid>
            <Grid item xs={6}>
               <div className="ContieneniconoyTitulo" style={{ width:'100%'}}>
                <QrCodeIcon sx={{  fontSize:'4.5rem',  color:theme.palette.mode === 'light' ? '#68A354' : '#68A354'}}/>
              </div>
              </Grid>
              <Grid item xs={6}> 
              
                {loadings ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress sx={{ color: "D26999" }} />
                </Box>
              ) : (
                <Typography variant="h6" sx={{fontSize:'1.3rem',   color:theme.palette.mode === 'light' ? '#585857' : '#585857'}}>{qrDisponibles}</Typography>
              )}
              </Grid>
              </Grid>
             
              
            </CardContent>
          </Card>
        </Grid>
     
    </Grid>
    </Container>
  
    <section className='footeraSucursalempresa'>
                 <div className="contienenqrbotones">
                    
                  <button onClick={handleOpen} className="BotonQrGenerar">
                      <img src={QrBlanco} alt="smart memorial" title="smart memorial" width={35}/>
                      <h3>Generar QR {Empresa.nombre_empresa}
                    </h3>
                  </button>
               
              </div>
                </section>
  </Box>
          {/* section qr generar y verificar */}
          <Box sx={StyleBoxBlanco}>
            <section className='CabeceraSucursalempresa'>
            <div className="SessionsucursarTitulo">
              <h5>Codigos QR</h5>
             </div>
            </section>
            <div className="buscadorlupaqr">
            <input
              className="inputregistroqrempresa"
              type="text"
              placeholder="Buscar código QR"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
              <div className="lupa">
                <IoSearch /> 
              </div>
            </div>
           
               <div style={{ height: 400, width: '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              checkboxSelection
              disableSelectionOnClick
              sortModel={[
                {
                  field: 'id', // Reemplaza 'id' con el campo de tu columna que deseas usar para ordenar
                  sort: 'desc', // Orden descendente
                },
              ]}
              sx={{

                marginTop: '50px',
                fontFamily: 'Poppins',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
                borderRadius: '8px', // Borde redondeado (si lo deseas)
                '& .MuiDataGrid-columnHeaders': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
                  fontWeight: '600', // Estilo de fuente de la cabecera
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
                },
                '& .MuiDataGrid-cell': {
                  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
                },
                '& .MuiCheckbox-root': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
                },
                '& .MuiTablePagination-selectLabel':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiTablePagination-displayedRows' : {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                   fontFamily:'Poppins'
               },
               '& .MuiInputBase-input':{
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                   fontFamily:'Poppins'
               },
               '& .Mui-disabled':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                   fontFamily:'Poppins'
               },
               '& .MuiSelect-icon':{
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                   fontFamily:'Poppins'
               }
              }}
            />
          )}
        </div>
               
            {/* Modal que contiene el componente GeneradorQREmpresa */}
            <Modal open={open} onClose={handleClose}>
              <Box sx={modalStyle}>
                <Box sx={modalStyleClose}>
                    <button onClick={handleClose}><IoIosClose /></button>
                </Box>
                
                <GeneradorQREmpresa 
                  empresa={Empresa}
                  user={Usuario} 
                  QR={qrData}
                  Token={Token}
                  refreshQrData={refreshQrData}
                  close = {handleClose}
                />
              
              </Box>
            </Modal>
         
           
        </Box>
      </Box>
    )
}


/* estilo material UI */
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius:'10px',
    height:'650px',
    overflowY:'auto'
  };

  const modalStyleClose = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end'
  }

  
 
const StyleBoxBlanco ={
 width:'100%',
  border:'1px solid #FFFF',
  margin:'0px',
  borderRadius:'10px',
 
  background:'#FFFF',
  margin:'10px 0px'
}
const StyleGridQR = {
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  width:'100%'

}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));