import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../Style/generales/exito.css";

export const Registroexitoso = () => {
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(5);

    // Función para redirigir al inicio después de 5 segundos
    useEffect(() => {
        const countdown = setInterval(() => {
            setSeconds(prevSeconds => prevSeconds - 1); // Decrementar el contador cada segundo
        }, 1000);

        // Redirigir al inicio después de 5 segundos
        const timer = setTimeout(() => {
            navigate("/");
        }, 5000);

        // Limpiar los temporizadores en el desmontaje del componente
        return () => {
            clearInterval(countdown);
            clearTimeout(timer);
        };
    }, [navigate]);
    return(
        <>
        <Container className="contieneregistro"component="main" maxWidth="sm" sx={{paddingLeft:'0px', paddingRight:'0px'}}>
          <Box
            sx={{
                marginTop:"30px",
              width:"100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="successexitocheckmark">
  <div className="check-icon">
    <span className="icon-line line-tip"></span>
    <span className="icon-line line-long"></span>
    <div className="icon-circle"></div>
    <div className="icon-fix"></div>
  </div>
</div>
<center>
 <h1 className="tituloexitoregistro">Registro exitoso </h1>
 <h2 className="subexitotitulo">Serás redirigido al inicio de Smart Memorial {seconds} segundos</h2>
</center>
           
            </Box>
             </Container>
        
        
        </>
    )
}