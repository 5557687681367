import React, { useState, useEffect } from "react";
import axios from "axios";
import {  useParams } from "react-router-dom";
import { Box, Typography, Grid, Button } from '@mui/material';
import { useUserEmpresa } from '../../../../../../context/UsuarioEmpresa';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, IconButton } from '@mui/material';

const ClienteReporteSucursal = ({ selectedOption, handleOptionSelect, sucursal, selectedOptioncliente, setselectedOptioncliente, selectedOptionsucursal, setSelectedOptionsucursal,  selectedDate, setSelectedDate }) => {
  const [fechasRegistradas, setFechasRegistradas] = useState([]);
  const [fechasRegistradasClientes, setFechasRegistradasClientes] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [vistaFecha, setVistaFecha] = useState("");
  const [vistaFechaCliente, setVistaFechaCliente] = useState(""); // 'dia' o 'mes'
  const { Token } = useUserEmpresa();
  const { id } = useParams();


   // Obtener sucursales de la API
   useEffect(() => {
    const fetchSucursales = async () => {
      if (!id) return; // Verificar que el id de empresa esté disponible

      try {
        const response = await axios.get(`${backendURL}/api/usuarios/sucursal/admin/${id}`, {
          
          headers: {
            'Authorization': `Bearer ${Token}`,
          },
        });
        const usuarios = response.data;
        const fechas = usuarios.map(usuario => ({
          dia: usuario.created_at.split('T')[0], // "YYYY-MM-DD"
          mes: usuario.created_at.slice(0, 7)    // "YYYY-MM"
        }));
       
        // Eliminar duplicados por "dia" o "mes"
        const fechasUnicas = Array.from(new Set(fechas.map(fecha => fecha.dia)))
          .map(dia => fechas.find(fecha => fecha.dia === dia));
  
        setFechasRegistradasClientes(fechasUnicas);
        setVistaFechaCliente(true)
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
      }
    };

    fetchSucursales();
  }, [backendURL, Token, id]); 

  useEffect(() => {
    const fetchSucursalesgeneral = async () => {
      if (!sucursal) return; // Verificar que el id de empresa esté disponible
  
      try {
        const response = await axios.get(`${backendURL}/api/usuarios/sucursal/general/${sucursal.id}`, {
          headers: {
            'Authorization': `Bearer ${Token}`,
          },
        });
  
        const usuarios = response.data;
        
        // Extraer fechas de los usuarios y mapearlas a "dia" y "mes"
        const fechas = usuarios.map(usuario => ({
          dia: usuario.created_at.split('T')[0], // "YYYY-MM-DD"
          mes: usuario.created_at.slice(0, 7)    // "YYYY-MM"
        }));
  
        // Eliminar duplicados por "dia" o "mes"
        const fechasUnicas = Array.from(new Set(fechas.map(fecha => fecha.dia)))
          .map(dia => fechas.find(fecha => fecha.dia === dia));
  
        setFechasRegistradas(fechasUnicas); 
        setVistaFecha(true);
        
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
      }
    };
  
    fetchSucursalesgeneral();
  }, [backendURL, Token, sucursal.id]);

  // Función que limpia la sucursal y la fecha cuando se selecciona un cliente
  const handleClienteSelect = (option) => {
    if (selectedOptioncliente === option) {
      // Si ya está seleccionado, no haces nada (o puedes limpiar todos los valores si deseas un "reset")
      setselectedOptioncliente(null);
    } else {
      // Si se selecciona una nueva opción, se limpia el estado de los demás filtros (sucursal y fecha)
      setselectedOptioncliente(option);
      setSelectedOptionsucursal(null);  // Limpia sucursal
      setSelectedDate(null);  // Limpia la fecha
      // Aquí también puedes agregar cualquier otro estado que debas resetear
    }
  };

  
 

  const formatDate = (date, isMonth = false) => {
    // Validación de entrada
    if (!date || typeof date !== 'string') return { full: 'Fecha no válida' };
  
    try {
      if (isMonth) {
        const [year, month] = date.split('-');
        // Validación adicional para año y mes
        if (!year || !month || isNaN(year) || isNaN(month)) return { monthYear: 'Fecha no válida' }; 
        
        const parsedDate = new Date(year, month - 1); // Se asume formato 'YYYY-MM'
        if (isNaN(parsedDate)) return { monthYear: 'Fecha no válida' }; // Validar si la fecha es correcta
        return {
          monthYear: parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' })
        };
      } else {
        const parsedDate = new Date(date);  // Verifica que la fecha esté en formato correcto
        if (isNaN(parsedDate)) return { full: 'Fecha no válida' };  // Si la fecha no es válida
        return {
          full: parsedDate.toLocaleDateString('es-ES')
        };
      }
    } catch (error) {
      console.error("Error al formatear fecha:", error);
      return { full: 'Fecha no válida' };
    }
  };

  // Función para eliminar fechas duplicadas en la vista de "mes"
  function getUniqueMonths() {
    const allMonths = fechasRegistradas.map(fecha => fecha.mes); // Extrae todos los meses
    const uniqueMonths = [...new Set(allMonths)].sort(); // Elimina duplicados y ordena
    return uniqueMonths;
  }
  const handleCheckboxChangeMonth = (mes) => {
    const isMonthSelected = selectedDate?.mes === mes;
    setSelectedDate(isMonthSelected ? null : { mes });
  };
  
  const handleCheckboxChangeDay = (dia) => {
    const isDaySelected = selectedDate?.dia === dia;
    setSelectedDate(isDaySelected ? null : { dia });
  };
  // Cambiar vista (día o mes)
  const cambiarVista = (vista) => {
    setVistaFecha(vista);
  };

 
  
  /* clientes */
  const cambiarVistaCliente = (vista) => {
    setVistaFechaCliente(vista);
  };
  const formatDatescliente = (date, esMes = false) => {
      // Validación de entrada
      if (!date || typeof date !== 'string') return { full: 'Fecha no válida' };
    
      try {
        if (esMes) {
          const [year, month] = date.split('-');
          
          // Validación adicional para año y mes
          if (!year || !month || isNaN(year) || isNaN(month)) return { monthYear: 'Fecha no válida' };
    
          // Crear el objeto Date con año y mes, asumiendo el primer día del mes
          const parsedDate = new Date(year, month - 1); // El mes comienza desde 0 (enero)
          
          // Validar si la fecha es correcta
          if (isNaN(parsedDate)) return { monthYears: 'Fecha no válida' };
    
          // Retornar el mes y el año en formato largo (mes en español)
          return {
            monthYears: parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' })
          };
        } else {
          // Para el caso de fecha completa
          const parsedDate = new Date(date);  // Verifica que la fecha esté en formato correcto
    
          // Validar si la fecha no es válida
          if (isNaN(parsedDate)) return { full: 'Fecha no válida' };
    
          // Retornar la fecha completa en formato 'DD/MM/YYYY' en español
          return {
            full: parsedDate.toLocaleDateString('es-ES')
          };
        }
      } catch (error) {
        console.error("Error al formatear fecha:", error);
        return { full: 'Fecha no válida' };
      }
    };
  

 
/* 
  useEffect(() => {
    const fetchData = async () => {
      if (selectedOptioncliente) {
        try {
          const response = await axios.get(`${backendURL}/api/usuarios/empresaselecion/${empresa.id}`, {
            headers: { 'Authorization': `Bearer ${Token}` },
          });
          const usuarios = response.data.data;
          const fechas = usuarios.map(usuario => ({
            dia: usuario.created_at.split('T')[0], // "YYYY-MM-DD"
            mes: usuario.created_at.slice(0, 7)    // "YYYY-MM"
          }));
          setFechasRegistradasClientes(fechas);
        } catch (error) {
          console.error("Error al obtener usuarios de la sucursal:", error);
        }
      }
    };
    fetchData();
  }, [selectedOptioncliente, backendURL, Token]);
 */
  const uniqueMeses = [...new Set(fechasRegistradasClientes.map(fecha => fecha.mes))];
  const uniqueDias = [...new Set(fechasRegistradasClientes.map(fecha => fecha.dia))];

  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
<Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOption}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '90px' }}>
        <Grid item>
          <button
            className={`botonseleccion ${ selectedOptioncliente === 'Clientes' ? 'selected' : ''}`}
            onClick={() =>  handleClienteSelect('Clientes')}
          >
          Clientes registrados 
          </button>
        </Grid>
        <Grid item>
          <button
            className={`botonseleccion ${ selectedOptioncliente === 'Sucursal' ? 'selected' : ''}`}
            onClick={() =>  handleClienteSelect('Sucursal')}
          >
            Clientes por Sucursal
          </button>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOptioncliente}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      {/* codigo en selecion cliente */}
      {selectedOptioncliente === 'Clientes' && (
        <>
          <Box display="flex" justifyContent="center" mb={2} sx={{ marginTop: '30px' }}>
            <Button
              variant="contained"
              onClick={() => cambiarVistaCliente('dias')}
              sx={{
                marginRight: 1,
                width: '100px',
                backgroundColor: vistaFechaCliente === 'dias' ? '#694B81' : 'transparent',
                color: vistaFechaCliente === 'dias' ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              Día
            </Button>
            <Button
              variant="contained"
              onClick={() => cambiarVistaCliente('mes')}
              sx={{
                width: '100px',
                backgroundColor: vistaFechaCliente === 'mes' ? '#694B81' : 'transparent',
                color: vistaFechaCliente === 'mes' ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              Mes
            </Button>
          </Box>

          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          <h3 className="titulosfechassucursalesselecionreporte">Fechas Seleccionadas</h3>
          <TableContainer component={Paper} elevation={3} sx={{ width: '50%', background:'#ffff', borderRadius: '20px', marginBottom: '100px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                    <IconButton color="secondary" aria-label="Fecha" sx={{ marginRight: 0.5 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    Fecha
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vistaFechaCliente === 'mes' ? (
                  uniqueMeses.map((mes, index) => {
                    const { monthYears } = formatDatescliente(mes, true);
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedDate?.mes === mes}
                            onChange={() => handleCheckboxChangeMonth(mes)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{monthYears}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  uniqueDias.map((dia, index) => {
                    const { full } = formatDatescliente(dia);
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedDate?.dia === dia}
                            onChange={() => handleCheckboxChangeDay(dia)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{full}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </>
      )}
   {selectedOptioncliente === 'Sucursal' && (
  <>
    
    {selectedOptioncliente === 'Sucursal' && (
      <>
        <Box display="flex" justifyContent="center" mb={2} sx={{ marginTop: '30px' }}>
          {['Día', 'Mes'].map((periodo) => (
            <Button
              key={periodo}
              variant="contained"
              onClick={() => cambiarVista(periodo.toLowerCase())}
              sx={{
                marginRight: 1,
                width: '100px',
                backgroundColor: vistaFecha === periodo.toLowerCase() ? '#694B81' : 'transparent',
                color: vistaFecha === periodo.toLowerCase() ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              {periodo}
            </Button>
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          <h3 className="titulosfechassucursalesselecionreporte">Fechas Seleccionadas</h3>
          <TableContainer component={Paper} elevation={3} sx={{ width: '50%', background:'#ffff', borderRadius: '20px', marginBottom: '100px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                    <IconButton color="secondary" aria-label="Fecha" sx={{ marginRight: 0.5 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    Fecha
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(vistaFecha === 'mes' ? uniqueMeses : uniqueDias).map((fecha, index) => {
                  const fechaFormateada = vistaFecha === 'mes'
                    ? formatDatescliente(fecha, true).monthYears
                    : formatDatescliente(fecha).full;
                  const isSelected = vistaFecha === 'mes'
                    ? selectedDate?.mes === fecha
                    : selectedDate?.dia === fecha;
                  const handleCheckboxChange = vistaFecha === 'mes'
                    ? () => handleCheckboxChangeMonth(fecha)
                    : () => handleCheckboxChangeDay(fecha);

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={isSelected}
                          onChange={handleCheckboxChange}
                        />
                      </TableCell>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{fechaFormateada}</TableCell>
                    </TableRow>
                  );
                })}

                {((vistaFecha === 'mes' && uniqueMeses.length === 0) || (vistaFecha === 'dias' && uniqueDias.length === 0)) && (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: 'center', fontFamily: 'Poppins', color: '#585857' }}>
                      No hay registro para esta sucursal
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    )}
  </>
)}
    </Box>)}




    export default ClienteReporteSucursal;



    const tituloStyledescripcion = {
      fontFamily:'Poppins',
      color:'#585857',
      fontSize:'0.8rem',
      fontWeight:'500',
      marginBottom:'0px',
      marginTop:'50px',
      display:'flex'
    }
    
    const Linea = {
      color:'#a8a6a6',
      background:'#a8a6a6',
      Border: '1px solid #a8a6a6',
      marginBottom:'50px'
    }