import React, { useEffect, useState } from "react";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout";
import { Container, Grid, Box, CircularProgress, Modal,  Card, CardContent, Typography} from '@mui/material';
import { MemorialDemoEmpresa } from "../../ComponenteEstructura/MemorialEmpresa";

export const PaginaIndexQREmpresa = ({setShowNavbarFooter}) => {
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    return(
        <EmpresaLayout>
        <Container maxWidth="lg" sx={{
            fontFamily:'Poppins'
        }}>
        <MemorialDemoEmpresa/>
      
        </Container>
        </EmpresaLayout>
    )
}