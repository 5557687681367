import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import { CrearEncuestaIndex } from "../../../FuncionesEmpresaAdmi/Tareas/fidelizacion/CrearEncuesta";
import { EnviarEncuesta } from "../../../FuncionesEmpresaAdmi/Tareas/fidelizacion/EnviarEncuesta";
import {
    Container,
    Grid,
    Box,
    CircularProgress,
    Modal,
    Typography,
    Button,
    IconButton,
    Alert,
  } from "@mui/material";
  import MailOutlineIcon from '@mui/icons-material/MailOutline';
  import CloseIcon from '@mui/icons-material/Close';
  import WarningAmberIcon from "@mui/icons-material/WarningAmber"
  import { DataGrid } from "@mui/x-data-grid";
  import EditNoteIcon from '@mui/icons-material/EditNote';
  import SendIcon from "@mui/icons-material/Send";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import WebIcon from "@mui/icons-material/Web";

export const IndexFidelizacion = ({ setShowNavbarFooter }) => {
    const { Token, UserDataMarca, Empresa } = useUserEmpresa();
    const { id } = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [openModalEnviar, setOpenModalEnviar] = useState(false);
    const [encuestas, setEncuestas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEncuestaId, setSelectedEncuestaId] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    
   
  
    const fetchEncuestas = async () => {
      if (!Empresa?.id || !Token) return;
      setLoading(true); // Inicia el estado de carga
      try {
        const response = await axios.get(
          `${backendURL}/api/get/encuestas/empresa/${Empresa.id}`, // Ruta que coincide con tu backend
          {
            headers: {
              Authorization: `Bearer ${Token}`, // Token para autenticar
            },
          }
        );
  
        // Asignar encuestas y mapearlas para el DataGrid
        const formattedData = response.data.data.map((encuesta) => ({
          ...encuesta,
          id: encuesta.id, // DataGrid necesita un campo `id`
        }));
        setEncuestas(formattedData);
      } catch (error) {
        console.error("Error al obtener encuestas:", error);
      } finally {
        setLoading(false); // Finaliza el estado de carga
      }
    };


  useEffect(() => {
      fetchEncuestas();  // Llamar a la función al montar el componente
    }, [Token, backendURL, Empresa]);
    

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);



  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "nombre_encuesta", headerName: "Nombre", width: 200 },
    { field: "created_at", headerName: "Fecha de Creación", width: 200 },
    {
      field: "acciones",
      headerName: "Acciones",
      width: 500,
      renderCell: (params) => (
        <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          flexDirection:'row'
        }}>
        <div style={{margin:'5px'}}>
          <Button
            variant="contained"
            color="primary"
            sx={{
           
              fontFamily:'Poppins',
               textTransform:'capitalize',
               color:'#ffff',
               background: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                    ? UserDataMarca?.color_1
                    : "#3f51b5"
            }}
            startIcon={<MailOutlineIcon />}
            onClick={() => handleEnviarEncuesta(params.row.id)}
          >
            Enviar
          </Button>
          </div>
           <div style={{margin:'5px'}}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
             
              fontFamily:'Poppins',
               textTransform:'capitalize',
               color:'#ffff',
               background: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                    ? UserDataMarca?.color_2
                    : "#3f51b5"
            }}
            startIcon={<VisibilityIcon />}
            onClick={() => handleVerEncuesta(params.row.id)}
          >
            Ver Encuesta
          </Button></div>
           <div style={{margin:'5px'}}>
          <Button
            variant="text"
            startIcon={<WebIcon />}
            onClick={() => handleVerPaginaEncuesta(params.row.id)}
            sx={{
             
              fontFamily:'Poppins',
               textTransform:'capitalize',
               color: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
               ? UserDataMarca?.color_1
               : "#585857",
               background: UserDataMarca?.color_3 && UserDataMarca?.color_3 !== "undefined"
                    ? UserDataMarca?.color_3
                    : "#3f51b5"
            }}
          >
            Ver Página
          </Button>
          </div>
        </div>
      ),
    },
  ];

  const handleEnviarEncuesta = (id) => {
    setSelectedEncuestaId(id); // Guardar ID de encuesta seleccionada
    setOpenModalEnviar(true); // Mostrar modal
  };

  const handleModalCloseEnviar = () => {
    setSelectedEncuestaId(null); // Limpiar ID de encuesta seleccionada
    setOpenModalEnviar(false); // Cerrar modal
  };
  const handleVerEncuesta = (id) => {
    console.log("Ver encuesta", id);
    // Lógica para mostrar detalles de la encuesta
  };

  const handleVerPaginaEncuesta = (id) => {
    console.log("Ver página de encuesta", id);
    // Lógica para redirigir a la página de la encuesta
  };

  const handleEncuestaCreada = () => {
    fetchEncuestas(); // Vuelve a cargar las encuestas desde el backend
};

    return(
        <EmpresaLayout>
        <Container maxWidth="lg">
          <Box sx={formStyle}>
            <div>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={tituloStyle}
              >
                Fidelización Inteligente
              </Typography>
            </div>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={subtituloStyle}
            >
              La fidelización de clientes comienza entendiendo sus opiniones. Por
              ello, esta sección es para crear y enviar encuestas directamente a
              los correos y WhatsApp de los clientes que se encuentran integrados
              con {Empresa?.nombre_social}.
            </Typography>
          </Box>
  
          <Box sx={{ marginBottom: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                background:(UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                color:'#ffff',
                fontFamily:'Poppins',
                textTransform:'capitalize'
              }}
              startIcon={<EditNoteIcon  sx={{
                fontSize:'2rem'
              }}/>}
              onClick={handleModalOpen}
            >
              Crear Encuesta
            </Button>
          </Box>
  
          <Box sx={{ height: 400, width: "100%" }}>
          

          {loading ? (
            // Mostrar CircularProgress mientras se cargan los datos
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ height: "100%" }}
            >
              <CircularProgress
                sx={{
                  color:
                    UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                      ? UserDataMarca?.color_1
                      : "#3f51b5",
                }}
              />
            </Box>
          ) : encuestas.length === 0 ? (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 200,
        border: "1px dashed rgba(0, 0, 0, 0.2)",
        borderRadius: 8,
        padding: 2,
        backgroundColor: "#fefefe",
      }}
    >
      <WarningAmberIcon
        sx={{
          fontSize: 40,
          color: "#FF9800",
          marginBottom: 1,
        }}
      />
      <Typography variant="h6" color="textSecondary">
        No hay encuestas disponibles
      </Typography>
    </Box>
  ) : (
    <DataGrid
      rows={encuestas}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection={false}
      disableSelectionOnClick
      sortModel={[
        {
          field: "id",
          sort: "desc",
        },
      ]}
      sx={{
        marginTop: "50px",
        fontFamily: "Poppins",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        "& .MuiDataGrid-columnHeaders": {
          color:
            UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
              ? UserDataMarca?.color_1
              : "#3f51b5",
          fontWeight: "600",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
          borderBottom: "2px solid rgba(0, 0, 0, 0.2)",
        },
        "& .MuiDataGrid-cell": {
          color:
            UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
              ? UserDataMarca?.color_2
              : "#3f51b5",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
        },
        "& .MuiCheckbox-root": {
          color:
            UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
              ? UserDataMarca?.color_1
              : "#3f51b5",
        },
        "& .MuiTablePagination-selectLabel": {
          color:
            UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
              ? UserDataMarca?.color_1
              : "#3f51b5",
          fontFamily: "Poppins",
        },
      }}
    />
  )}
          </Box>
  
          {/* Modal para crear encuesta */}
          <Modal open={openModal} onClose={handleModalClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#ffff",
                borderRadius: 2,
                boxShadow: 24,
                p: 2,
                width: 600,
                height: 600, // Altura fija o máxima
                overflowY: "auto", // Habilita el scroll vertical
                overflowX: "hidden",
              }}
            >
              <Box
              sx={{
                display:"flex",
                alignItems:'flex-end',
                justifyContent:'flex-end'
              }}>
                 <CloseIcon onClick={handleModalClose} sx={{
                   color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                   ? UserDataMarca?.color_2
                   : "#585857",
                 }}/>
              </Box> 
              <Typography  sx={tituloStyle} variant="h6" component="h2" gutterBottom>
              <EditNoteIcon  sx={{
                fontSize:'2rem'
              }}/>Crear Encuesta
              </Typography>
             
             <CrearEncuestaIndex close={handleModalClose} onEncuestaCreada={handleEncuestaCreada}/>
            </Box>
          </Modal>
          <Modal open={openModalEnviar} onClose={handleModalCloseEnviar}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "#ffff",
                borderRadius: 2,
                boxShadow: 24,
                p: 3,
                width: '80%',
                height: 600, // Altura fija o máxima
                overflowY: "auto", // Habilita el scroll vertical
                overflowX: "hidden",
              }}
            >
              <Box
              sx={{
                display:"flex",
                alignItems:'flex-end',
                justifyContent:'flex-end'
              }}>
                 <CloseIcon onClick={handleModalCloseEnviar} sx={{
                   color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                   ? UserDataMarca?.color_2
                   : "#585857",
                 }}/>
              </Box> 
              <Typography  sx={tituloStyle} variant="h6" component="h2" gutterBottom>
              <MailOutlineIcon  sx={{
                fontSize:'2rem'
              }}/>Enviar Encuesta
              </Typography>
             
            <EnviarEncuesta  encuestaId={selectedEncuestaId} />
            </Box>
          </Modal>
        </Container>
      </EmpresaLayout>
    )
}


const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop:'50px',
    padding:'10px'
};


const tituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1.3rem',
    fontWeight:'600',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center'
}
const titulomarco = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row'
}


const subtituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'0.8rem',
    fontWeight:'500',
    marginBottom:'40px',
    textAlign:'center',
    padding:'0px 150px'
}
