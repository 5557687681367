import icono1 from "../Image/obituario/m1.png";
import icono2 from "../Image/obituario/m2.png";
import icono3 from "../Image/obituario/m3.png";
import icono4 from "../Image/obituario/m4.png";
import icono5 from "../Image/obituario/m5.png";
import icono6 from "../Image/obituario/m6.png";
import icono7 from "../Image/obituario/m7.png";
import icono8 from "../Image/obituario/m8.png";

/* verde */
import v1 from "../Image/obituario/verde/v1.png"
import v2 from "../Image/obituario/verde/v2.png"
import v3 from "../Image/obituario/verde/v3.png"
import v4 from "../Image/obituario/verde/v4.png"
import v5 from "../Image/obituario/verde/v5.png"
import v6 from "../Image/obituario/verde/v6.png"
import v7 from "../Image/obituario/verde/v7.png"
import v8 from "../Image/obituario/verde/v8.png"

/* naranjo */
import n1 from "../Image/obituario/naranjo/n1.png"
import n2 from "../Image/obituario/naranjo/n2.png"
import n3 from "../Image/obituario/naranjo/n3.png"
import n4 from "../Image/obituario/naranjo/n4.png"
import n5 from "../Image/obituario/naranjo/n5.png"
import n6 from "../Image/obituario/naranjo/n6.png"
import n7 from "../Image/obituario/naranjo/n7.png"
import n8 from "../Image/obituario/naranjo/n8.png"


export const Iconos = [
  { src: icono1, alt: "Icono 1", value: "1" },
  { src: icono2, alt: "Icono 2", value: "2" },
  { src: icono3, alt: "Icono 3", value: "3" },
  { src: icono4, alt: "Icono 4", value: "4" },
  { src: icono5, alt: "Icono 5", value: "5" },
  { src: icono6, alt: "Icono 6", value: "6" },
  { src: icono7, alt: "Icono 7", value: "7" },
  { src: icono8, alt: "Icono 8", value: "8" },
];

export const Iconosverdes = [
  { src: v1, alt: "Icono 1", value: "1" },
  { src: v2, alt: "v 2", value: "2" },
  { src: v3, alt: "v 3", value: "3" },
  { src: v4, alt: "v 4", value: "4" },
  { src: v5, alt: "v 5", value: "5" },
  { src: v6, alt: "v 6", value: "6" },
  { src: v7, alt: "v 7", value: "7" },
  { src: v8, alt: "v 8", value: "8" },
];

export const Iconosnaranjos = [
  { src: n1, alt: "n 1", value: "1" },
  { src: n2, alt: "n 2", value: "2" },
  { src: n3, alt: "n 3", value: "3" },
  { src: n4, alt: "n 4", value: "4" },
  { src: n5, alt: "n 5", value: "5" },
  { src: n6, alt: "n 6", value: "6" },
  { src: n7, alt: "n 7", value: "7" },
  { src: n8, alt: "n 8", value: "8" },
];