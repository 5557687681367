import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../../../../Style/admi/adminuser.css";
import axios from "axios";
/* libreria UI */
import { Container, Modal, Box, CircularProgress, Select, MenuItem, } from '@mui/material';
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTheme } from '@mui/material/styles';
/* react icnos */
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout";

/* componenete */
import { EncabezadoAdmiEmpresa } from "../../ComponenteEstructura/EncabezadoEmpresaAdmi";
import { datadireccion } from "../../../../../../data/api direccion/datadireccion";

export const CrearsucursalAdminEmpresa = ({setShowNavbarFooter}) => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const { id } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [step, setStep] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedComuna, setSelectedComuna] = useState('');
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [emailExistsError, setEmailExistsError] = useState(false);
    const theme = useTheme();
     /* procesadores */
     const [errors, setErrors] = useState({});
     const [error, setError] = useState('');
     const [successAlertOpen, setSuccessAlertOpen] = useState(false);
     const [errorAlertOpen, setErrorAlertOpen] = useState(false);
     const [enviandoAlert, setenviandoAlert] = useState(false);
     const [alertMessage, setAlertMessage] = useState('');
    
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);  
        const handleSelectChange = (event) => {
            setFormData({
              ...formData,
              id_empresa: event.target.value,
            });
          };
    
        const handleCountryChange = (event) => {
            const country = event.target.value;
            setSelectedCountry(country);
            setRegions(Object.keys(datadireccion.pais[country].region));
            setSelectedRegion('');
            setCommunes([]);
            setFormData(prevData => ({
              ...prevData,
              pais: country,
              region: '', // Reinicia la región cuando cambias el país
              comuna: '', // Reinicia la comuna cuando cambias el país
            }));
          };
          
          const handleRegionChange = (event) => {
            const region = event.target.value;
            setSelectedRegion(region);
            setCommunes(datadireccion.pais[selectedCountry].region[region].comuna);
            setFormData(prevData => ({
              ...prevData,
              region: region,
              comuna: '', // Reinicia la comuna cuando cambias la región
            }));
          };
      
          const handleComunaChange = (event) => {
            const comuna = event.target.value;
            setSelectedComuna(comuna);
            setFormData(prevData => ({
              ...prevData,
              comuna: comuna,
            }));
          };
    
          const handleInputChange = (e) => {
            const { name, value } = e.target;

            if (name === 'web') {
              // Verificar si ya empieza con 'http://' o 'https://'
              if (!value.startsWith('http://') && !value.startsWith('https://')) {
                  setFormData((prevData) => ({
                      ...prevData,
                      [name]: `http://${value}`, // Agregar 'http://' si no está presente
                  }));
                  return; // Salir para evitar que se llame a setFormData nuevamente
              }
          }
        
            setFormData(prevData => {
                let newData = { ...prevData, [name]: value };
                
                // Sincroniza 'correo' y 'email' si uno de ellos cambia
                if (name === 'correo') {
                    newData.email = value;
                    setEmailExistsError(false);
                } else if (name === 'email') {
                    newData.correo = value;
                }       
                return newData;
            });
        };
    
        const handleSubmit = async (event) => {
            event.preventDefault();
            const newErrors = {};
              // Actualizar el estado de errores
              setErrors(newErrors);      
            // Verificar que todos los campos requeridos estén completos
            if (
              !formData.correo ||
              !formData.nombre_sucursal ||
              !formData.telefono ||
              !formData.pais ||
              !formData.region ||
              !formData.comuna ||
              !formData.direccion ||
              !formData.id_empresa) {
              setAlertMessage('Por favor, completa todos los campos requeridos.');
              setErrorAlertOpen(true);
              return; // Detiene el envío del formulario
            }
        
            const formDataToSend = new FormData();
            setenviandoAlert(true);
            
          
            // Agrega todos los datos del estado formData al FormData
            for (const [key, value] of Object.entries(formData)) {
              formDataToSend.append(key, value);
            }
        
            try {
              const response = await fetch(`${backendURL}/api/crear/sucursal`, {
                method: 'POST',
                headers: {
                  'Authorization': `Bearer ${Token}`
                },
                body: formDataToSend,
              });
        
              const result = await response.json();
              if (response.ok) {
                setenviandoAlert(false);
                setAlertMessage('Sucursal creada con éxito.');
                setSuccessAlertOpen(true);
                setTimeout(() => {
                  setSuccessAlertOpen(false);
                  navigate(`/Inicio-Empresa/${id}`); 
                }, 3000);
              } else {
                // Verifica si el mensaje de error contiene "The correo has already been taken."
                if (result.error && result.error.includes('The correo has already been taken')) {
                    setFormData(prevState => ({
                        ...prevState,
                        emailExistsError: true
                    }));
                    setError('El correo electrónico ya está registrado');
                    setAlertMessage('El correo electrónico ya está registrado');
                } else {
                    setAlertMessage(result.message || 'Error al crear la sucursal.');
                }
                setErrorAlertOpen(true);
                setenviandoAlert(false);
            }
            } catch (error) {
              // Error de red: Ocultar la alerta de envío y mostrar la de error
              setenviandoAlert(false);
              setAlertMessage('Error de red. Por favor, inténtalo de nuevo más tarde.');
              setErrorAlertOpen(true);
            }
          };
    
         /* formulario */
      const [formData, setFormData] = useState({
        nombre_sucursal: '',
        correo: '',
        telefono: '',
        logo: null,
        web: '',
        estado: 1,
        direccion: '',
        pais: '',
        region: '',
        comuna: '',
        id_empresa: Empresa.id || '',
      });

      
    
    return(
      <EmpresaLayout>
        <Container maxWidth="xxl">
        

        <Container component="main" maxWidth="xxl" sx={{ position: "relative", fontFamily:'Poppins' }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "50px"
  
        }}>
          
          <section className="titulocodigoadmi"><h1>Bienvenido a la sesión de creación sucursales</h1></section>
          <section className="informacioncrearempresa"><p>Completa los campos para crear la nueva sucursal. </p></section>
  
          <form className="formulariocrearempresa" onSubmit={handleSubmit}>
       
               
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Nombre Sucursal</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart" style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} value={formData.nombre_sucursal} onChange={handleInputChange} name="nombre_sucursal" id="nombre_sucursal" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='email'>Correo electrónico</label>
                  <div className='contieneinputempresaregister'>
                    <input type="email"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} className={`input-crear-empresa-adminsmart ${emailExistsError && 'input-error'}`} name="correo" id="correo" value={formData.correo} onChange={handleInputChange} />
                  </div>
                  {error && <div className="error-message"><BiErrorCircle style={{ color:"#D26999", fontSize:"1rem"}}/>{error}</div>}
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='telefono'>Teléfono</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} value={formData.telefono} onChange={handleInputChange} name="telefono" id="telefono" />
                  </div>
                </div>
              <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Link web</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} placeholder="ejemplo://www.example.com" value={formData.web} onChange={handleInputChange} name="web" id="web" />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Dirección</label>
                  <div className='contieneinputempresaregister'>
                    <input className="input-crear-empresa-adminsmart"  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} name="direccion" id="direccion" value={formData.direccion} onChange={handleInputChange} />
                  </div>
                </div>
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>País</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='pais'
                      id='pais'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar país...</em></MenuItem>
                      {Object.keys(datadireccion.pais).map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Región</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='region'
                      id='region'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      displayEmpty
                      disabled={!selectedCountry}
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar región...</em></MenuItem>
                      {regions.map(region => (
                        <MenuItem key={region} value={region}>{region}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="input-crearadminsmartempresa">
                  <label htmlFor='nombreEmpresa'>Comuna</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='comuna'
                      id='comuna'
                      value={selectedComuna}
                      onChange={handleComunaChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Comuna' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar comuna...</em></MenuItem>
                      {communes.map((comuna) => (
                        <MenuItem key={comuna} value={comuna}>{comuna}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
  
                <div className="buttonsiguientecrearempresa">
                  <button type="submit">Crear Sucursal</button>
                </div>
                </form>
                </Box>
                </Container>
                <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>
  
        {/* Popup de error */}
        <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>
  
        {/* Popup de carga */}
        <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section style={{ textAlign: 'center', padding: '1rem' }}>
              <CircularProgress style={{ color: '#694B81' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                Procesando solicitud...
              </p>
            </section>
          </Box>
        </Modal>
        </Container>
        </EmpresaLayout>
    )
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color: '#694B81',
    position: 'relative',
    left: '0.5%',
    fontFamily: "Poppins",
    width: '100%',
    height: '55px',
    fontSize: '0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize: '0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
  
  }));