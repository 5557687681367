import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../Style/usuario/evento.css";
import axios from "axios";
import { FaRegCircleCheck } from "react-icons/fa6";
import { format } from "date-fns";
import { MdOutlineAutorenew } from "react-icons/md";
import full from "../../../../Image/generales/full.jpg"
import basico from "../../../../Image/generales/basico.jpg"
import uno from "../../../../Image/generales/1.png"
import dos from "../../../../Image/generales/2.png"
import tres from "../../../../Image/generales/3.png"
import cuatro from "../../../../Image/generales/4.png"
import cinco from "../../../../Image/generales/5.png"
import seis from "../../../../Image/generales/6.png"
import siete from "../../../../Image/generales/7.png"
import ocho from "../../../../Image/generales/8.png"
import { useUsers } from "../../../context/userscontexts";

import { IoIosArrowDown } from "react-icons/io";

export const Suscripcionusuario = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { userData } = useUsers();
    const [loading, setLoading] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const location = useLocation();
    const ClientToken = location.state.user;
    const [suscripciones, setSuscripciones] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        const fetchSuscripciones = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getsuscripcionusuario/${ClientToken.userId}`, {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`
                    }
                });
                setSuscripciones(response.data);
            } catch (error) {
                console.error('Error al obtener los datos de las suscripciones:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSuscripciones();
    }, [ClientToken, backendURL]);



    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress sx={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }} />
            </Box>
        );
    }

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return format(date, 'dd/MM/yyyy');
    };

    const calculateDaysRemaining = (startDate, endDate) => {
        if (!startDate || !endDate) return 'N/A'; // Verificar si las fechas están definidas

        const start = new Date(startDate);
        const end = new Date(endDate);
        const currentDate = new Date(); // Obtener la fecha actual

        // Verificar si la fecha de término es anterior a la fecha actual
        if (end < currentDate) {
            return 0; // Si la fecha de término ha pasado, devolver 0
        }

        const oneDay = 1000 * 60 * 60 * 24; // Milisegundos en un día
        const diffInTime = end.getTime() - currentDate.getTime(); // Diferencia en milisegundos desde la fecha actual hasta la fecha de término
        const diffInDays = Math.ceil(diffInTime / oneDay); // Convertir la diferencia en días

        return diffInDays < 0 ? 0 : diffInDays; // Devolver 0 si la diferencia es negativa
    };


    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", width: "100%" }}>


            {suscripciones.length === 0 ? (
                <CircularProgress color="secondary" />
            ) : (
                <section className="contienensuscripc">
                    {suscripciones.map(suscripcion => (
                        <div key={suscripcion.id} style={{ width: "100%" }}>
                            {suscripcion.estado === 'ACTIVO' ? (
                                <section className="doscontiene"> <div className="activo">
                                    <FaRegCircleCheck />  <div className="contienetituloactivo" >
                                        <p style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}>Su suscripción se </p>
                                        <p style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}>encuentra al día</p>
                                    </div>
                                </div>


                                    <section className="pack" >
                                        {suscripcion.id_suscripcion == 1 ? (
                                            <> <div className="cardpack" style={{ background: (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#F0EDF3' }}>
                                                <h1 style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#D26999;' }}>Memorial Full</h1>
                                                <h3 style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}>Placa para lápida + servicio digital
                                                </h3>
                                            </div>
                                                <div className="suscripcionvence"> <p style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="cardpack" style={{ background: (userData?.color_4 && userData.color_4 !== 'undefined') ? userData.color_4 : '#F0EDF3' }} >
                                                    <h1 style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#D26999;' }}>Memorial Básico</h1>
                                                    <h3 style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}> servicio digital</h3>
                                                </div>
                                                <div className="suscripcionvence"> <p style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81' }}> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div> </>


                                        )}
                                    </section>

                                </section>) : (
                                <>
                                    <section className="inactivo">
                                        <h1>Su suscripción se </h1>
                                        <h1>encuentra vencida</h1>
                                        <span className="inforinactivo">El grupo memorial se mantendrá inhabilitado</span>
                                    </section>

                                    <section className="pack">
                                        {suscripcion.id_suscripcion == 1 ? (
                                            <>  <section className="subtituloservicionifo">
                                                <span>Servicio contratado:</span>

                                            </section>
                                                <section className="titulonifocontradatorenovar">
                                                    <h1> Memorial Full</h1>
                                                </section>

                                                <div className="recuadroinfomemorial">
                                                    <div style={{ width: "100%", height: "100%", borderRadius: '10px' }}>
                                                        <img src={full} alt="smart memorial full" style={{ width: "100%", height: "100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                                    </div>

                                                    <div className="contieneninfodetalleprecio" onClick={toggleExpand}>
                                                        <span className='detallesmemorialcompra'>Ver detalles</span>
                                                        <IoIosArrowDown className={`arrow-icon ${expanded ? 'expanded' : ''}`} />
                                                    </div>

                                                    <section className={`listadosservicio ${expanded ? 'expanded' : ''}`}>
                                                        <div className="tituloservicioincluido">
                                                            <h2>Servicio incluye</h2>
                                                        </div>
                                                        <div className="infolistaincluido">
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">2 Placas metálicas con QR para lápida o ánfora</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Edición del memorial cuando desee</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Compartir memoriales con sus contactos</span>
                                                            </div>
                                                        </div>
                                                    </section>

                                                </div>
                                                <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div>

                                                <section className="renovacion">
                                                    <button className="botonrenovar"> <MdOutlineAutorenew /> Renovar suscripcion </button>
                                                </section>

                                            </>
                                        ) : (
                                            <>
                                                <section className="subtituloservicionifo">
                                                    <span>Servicio contratado:</span>

                                                </section>
                                                <section className="titulonifocontradatorenovar">
                                                    <h1> Memorial Básico</h1>
                                                </section>
                                                <div className="recuadroinfomemorial">
                                                    <div style={{ width: "100%", height: "100%", borderRadius: '10px' }}>
                                                        <img src={basico} alt="smart memorial full" style={{ width: "100%", height: "100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }} />
                                                    </div>

                                                    <div className="contieneninfodetalleprecio" onClick={toggleExpand}>
                                                        <span className='detallesmemorialcompra'>Ver detalles</span>
                                                        <IoIosArrowDown className={`arrow-icon ${expanded ? 'expanded' : ''}`} />
                                                    </div>

                                                    <section className={`listadosservicio ${expanded ? 'expanded' : ''}`}>
                                                        <div className="tituloservicioincluido">
                                                            <h2>Servicio incluye</h2>
                                                        </div>
                                                        <div className="infolistaincluido">
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Código QR para descargar e imprimir</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Edición del memorial cuando desee</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                                                            </div>
                                                            <div className="itemdosservicio">
                                                                <div className="contieneimagensmart">
                                                                    <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                                                                </div>
                                                                <span className="textoitemservicio">Compartir memoriales con sus contactos</span>
                                                            </div>
                                                        </div>
                                                    </section>


                                                </div>
                                                <div className="suscripcionvence"> <p>Vence el {formatDate(suscripcion.fecha_termino)} </p> <span> (restan {calculateDaysRemaining(suscripcion.fecha_inicio, suscripcion.fecha_termino)} días)</span></div>

                                                <section className="renovacion">
                                                    <button className="botonrenovar"> <MdOutlineAutorenew /> Renovar suscripción </button>
                                                </section>
                                            </>


                                        )}
                                    </section>
                                </>
                            )}
                        </div>
                    ))}
                </section>
            )}
        </Box>

    )
}