import React from "react";
import { Container, Box } from "@mui/material";
import { FaUsersGear } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

export const Errormemorial =() => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };
    return(

        <>
        <Container component="main" maxWidth="xs" sx={{ paddingLeft:"0px",  height: "100vh", paddingRight:"0px", background:"#F0EDF3"}} >
            
              <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#ffff",
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:"#F0EDF3",
                        padding:"50px 0px"
                    }}
                >   <a hhref="/" onClick={handleRedirect} style={{ cursor: "pointer", textDecoration: "none", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column" }} >  
                <FaUsersGear style={{ color:"#D26999", fontSize:"6rem", marginTop:"40px"}} /> 
                
                <h1 style={{fontFamily:"Poppins", fontSize:"1.3rem", fontWeight:"500", color:"#585857" ,marginBottom:"-20px"}}>Ups! te has perdido</h1>
                <h1 style={{fontFamily:"Poppins", fontSize:"1.3rem", fontWeight:"500", color:"#585857" }}>Regresa al inicio </h1></a></Box>
                </Container>
      
        </>
    )
}