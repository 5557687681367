import React, { useEffect,useState } from "react";
import { CodigosQrSucursalAdmi } from "../../Index/ComponenteEstructura/CodigosQrSucursalAdmin";
import SucursalLayout from "../../../../../../SucursalLayout"

export const PaginaCodigoQrSucursal = ({setShowNavbarFooter}) => {
    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    return(
        <SucursalLayout> <CodigosQrSucursalAdmi/> </SucursalLayout>
    )
}