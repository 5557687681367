import React, { useState, useEffect } from "react";
import StepTipoReporte from "./FuncionExtraReporte/SeptFuncionReporte";
import ComentarioReporte from "./FuncionExtraReporte/FuncionReporteComentario";
import ClienteReporte from "./FuncionExtraReporte/FuncionReporteCliente";
import VistaReporte from "./FuncionExtraReporte/VistaReporte";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";

/* material UI */
import { Box,  CircularProgress, Snackbar, Stepper, Step, StepLabel, Button, Grid, Typography  } from '@mui/material';
import { BsCloudArrowDownFill } from "react-icons/bs";
/* imagenes */
import marco from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/m25.png"
import marcodos from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/m26.png"


const steps = ["Seleccionar Tipo de Reporte", "Seleccion Fecha", "Reporte"];


export const ReporteFuncion = () => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptioncomentario, setSelectedOptioncomentario] = useState("");
  const [selectedOptioncliente, setSelectedOptioncliente] = useState("");
  const [selectedOptionsucursal, setSelectedOptionsucursal] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleReset = () => {
    setActiveStep(0);
    setSelectedOption("");
    setSelectedOptioncomentario("");
    setSelectedDate(null);
  };

  const handleOptionSelect = (option) => setSelectedOption(option);
  const componentMapping = {
    Clientes: ClienteReporte,
    Comentarios: ComentarioReporte,
  };
  const RenderedComponent = componentMapping[selectedOption];

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StepTipoReporte
            selectedOption={selectedOption}
            handleOptionSelect={handleOptionSelect}
          />
        );
      case 1:
        return RenderedComponent ? (
          <RenderedComponent
            selectedOption={selectedOption}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedOptioncomentario ={selectedOptioncomentario}
            setSelectedOptioncomentario={ setSelectedOptioncomentario}
            selectedOptioncliente={selectedOptioncliente}
            setselectedOptioncliente={setSelectedOptioncliente}
            selectedOptionsucursal={selectedOptionsucursal}
            setSelectedOptionsucursal={setSelectedOptionsucursal}
            handleNext={handleNext}
            empresa={Empresa}
            id={Usuario?.id}
          />
        ) : (
          <p>Por favor, seleccione una opción válida.</p>
        );
      case 2:
        return (
          
          <VistaReporte 
            selectedOption={selectedOption} 
            selectedOptioncomentario={selectedOptioncomentario} 
            selectedDate={selectedDate} 
            selectedOptioncliente={selectedOptioncliente} 
            selectedOptionsucursal={selectedOptionsucursal}
            id={Usuario?.id} 
            empresa={Empresa}
            handleNext={handleNext}
          />
           
          
        );
        case 3:
          return (
            
            <Box sx={{
              display:"flex",
              alignContent:'center',
              justifyContent:'center'
              ,alignItems:"center",
              flexDirection:'column'
            }}>
              <BsCloudArrowDownFill  style={{fontSize:'2rem', color:'green'}} />
           
          <Typography variant="h6"  sx={{ color: '#585857', fontFamily:'Poppins', fontSize:'1rem' }}>
          ¡Reporte creado con exito!
          </Typography>
          <Button
               variant="contained"
                color="success"
              onClick={handleReset}
              sx={{ marginTop: 2,  textTransform: 'none', borderRadius:'40px', fontFamily:'Poppins' }}
            >
              Realizar un nuevo reporte
            </Button>
             
            </Box>
             
            
          );
      default:
        return <p>Pasos no reconocidos</p>;
    }
  };
    return(
      <>
        <Box sx={formStyle}>
       
     <div style={titulomarco}> 
       
        <Typography variant="h4" align="center" gutterBottom sx={tituloStyle} >
        Reporte de Clientes y Comentarios de Memoriales
      </Typography>

      </div>
      <Typography variant="h6" align="center" gutterBottom sx={subtituloStyle} >
      Selecciona el tipo de reporte que necesitas descargar para obtener
       información detallada sobre tus clientes y los comentarios asociados a sus memoriales.
        Este sistema de reportes permite personalizar los datos según tus necesidades específicas.
        
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel 
            sx={{
              padding: '5px 10px',
              borderRadius: '5px',
              color: activeStep === index 
                ? (UserDataMarca?.color_3 && UserDataMarca.color_3 !== "undefined") 
                  ? UserDataMarca.color_3 
                  : '#694B81' // Color de texto
                : undefined,
              fontFamily: 'Poppins', // Establecer fuente correcta
              '.MuiStepLabel-label': {
                fontFamily: 'Poppins', // Aplicar fuente también al label
              },
              '.MuiStepIcon-root': {
                color: UserDataMarca?.color_3 && UserDataMarca.color_3 !== "undefined"
                ? UserDataMarca.color_3
                : '#E1DBE6', 
              },
              ' .MuiStepIcon-text': {
                fill: '#ffff', // Cambiar el color del texto dentro del círculo activo
              },
              '.Mui-active .MuiStepIcon-root': {
                color: UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined"
                  ? UserDataMarca.color_1
                  : '#694B81', // Cambiar color del círculo activo
              },
              '.Mui-active .MuiStepIcon-text': {
                fill: '#ffff', // Cambiar el color del texto dentro del círculo activo
              },
              '.Mui-completed .MuiStepIcon-root': {
                color: '#4CAF50', // Cambiar color del ícono cuando el paso está completado
              },
            }}
          >
                    {label}
                    
                        </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
             <Box sx={{
              display:"flex",
              alignContent:'center',
              justifyContent:'center'
              ,alignItems:"center",
              flexDirection:'column'
            }}>
              <BsCloudArrowDownFill  style={{fontSize:'2rem', color:'green'}} />
           
          <Typography variant="h6"  sx={{ color: '#585857', fontFamily:'Poppins', fontSize:'1rem' }}>
          ¡Reporte creado con exito!
          </Typography>
          <Button
               variant="contained"
                color="success"
              onClick={handleReset}
              sx={{ marginTop: 2,  textTransform: 'none', borderRadius:'40px', fontFamily:'Poppins' }}
            >
              Realizar un nuevo reporte
            </Button>
             
            </Box>
          </div>
        ) : (
         <div> {renderStepContent(activeStep)}
              <Grid container style={{ marginTop: "50px", marginBottom: "100px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={2}>
                      {/* Renderizar el botón "Atrás" solo si el paso activo es mayor que 0 */}
                      {activeStep > 0 && (
                        <button
                          style={{
                            background: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== "undefined") 
                              ? UserDataMarca.color_2 
                              : '#694B81', // Color de texto
                          }}
                          className="botonmarcaatras"
                          onClick={handleBack}
                        >
                          Atrás
                        </button>
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <button
                        style={{
                          background: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== "undefined") 
                            ? UserDataMarca.color_1 
                            : '#694B81', // Color de texto
                        }}
                        className="botonmarcaeditar"
                        onClick={handleNext}
                        disabled={activeStep === 2 && !selectedOption}
                      >
                        {activeStep === steps.length - 1 ? "Reporte creado" : "Siguiente"}
                      </button>
                    </Grid>
                  </Grid>
        </div>
        )}
      </div>
         
        </Box>
        
             
          </>
    )
}


const titulomarco = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'row'
}
const Marco = {
    width:'100px',
    Height:'100%',
    padding:'5px'
}
const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop:'50px',
    padding:'10px'
};


const tituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1.3rem',
    fontWeight:'600'
}


const subtituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'0.8rem',
    fontWeight:'500',
    marginBottom:'40px',
    textAlign:'center',
    padding:'0px 150px'
}

