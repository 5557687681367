import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { EncabezadoAdmiEmpresa } from "../../ComponenteEstructura/EncabezadoEmpresaAdmi";
import"../../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import QrMorado from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrmorado.png"
import { EditSucursalEMpresa } from "../../FuncionesEmpresaAdmi/editarsucursal";
import { EditSucursalInfoEmpresa } from "../../FuncionesEmpresaAdmi/editarinfosucursal";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout";
/* libreria UI */
import {  Modal, Box, CircularProgress, Grid, Container, Typography, List, ListItem} from '@mui/material';
import Switch from '@mui/material/Switch';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { FaEdit } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});


export const SucursalesEmpresaEspecifico = ({setShowNavbarFooter}) =>{
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa(); 
  const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [AllQr, setAllQr] = useState([])
    const { sucursales} = location.state || {};
    const backendURL = process.env.REACT_APP_BACKEND_URL;
      const [usuariosPorSucursal, setUsuariosPorSucursal] = useState({});
      const [loading, setLoading] = useState(true);
      const [openEditModal, setOpenEditModal] = useState(false); 
      const [selectedSucursal, setSelectedSucursal] = useState(null);
      const [selectedUsuarios, setSelectedUsuarios] = useState([]);
      const [openEditsucuralModal, setOpenEditsucursalModal] = useState(false);
      const [selectedSucursaledit, setSelectedSucursaledit] = useState(null);

   
      useEffect(() => {
          setShowNavbarFooter(false);
          return () => {
              setShowNavbarFooter(true);
          };
      }, [setShowNavbarFooter]);
    
    
     
       // Fetch usuarios y sucursales
    const fetchUserAndSucursal = async () => {
      if (!sucursales || !Token) return;
      setLoading(true); // Muestra el estado de carga
      try {
          const response = await https.get(`${backendURL}/api/getUserId/empresa/${sucursales.id}`, {
              headers: {
                  Authorization: `Bearer ${Token}`,
              },
          });
          setUsuariosPorSucursal(response.data || []); // Asegúrate de que sea un arreglo
      } catch (error) {
          console.error("Error al obtener usuario y sucursal:", error);
      } finally {
          setLoading(false); // Oculta el estado de carga
      }
  };

  // Fetch grupos familiares
  const fetchGruposFamiliares = async () => {
      try {
          const response = await https.get(`${backendURL}/api/empresa/${Empresa.id}/grupos-familiares`, {
              headers: {
                  Authorization: `Bearer ${Token}`,
              },
          });
          setAllQr(response.data);
      } catch (error) {
          console.error("Error al obtener grupos familiares:", error);
      }
  };

  const handleToggleUserStatus = async (userId) => {
    try {
        const response = await axios.get(`${backendURL}/api/usuarios/${userId}/estado`, {
            headers: {
                Authorization: `Bearer ${Token}` // Si es necesario
            }
        });
        await fetchUserAndSucursal()
        // Actualiza la UI según sea necesario
    } catch (error) {
        console.error('Error al actualizar el estado del usuario:', error.response ? error.response.data : error.message);
        // Manejar errores según sea necesario
    }
};
  // Efecto para obtener datos iniciales
  useEffect(() => {
      fetchUserAndSucursal();
      fetchGruposFamiliares();
  }, [sucursales?.id, Empresa?.id, Token]);

 
  const handleOpenEditModal = (usuario) => {
    setSelectedSucursal(usuario);
    setOpenEditModal(true);
};

const handleCloseEditModal = () => {
  setOpenEditModal(false);
  setSelectedSucursal(null);
  setSelectedUsuarios([]);
};
  const handleOpenEditsucursalModal = (sucursal) => {
    setSelectedSucursaledit(sucursal); 
    setOpenEditsucursalModal(true);
};

const handleCloseEditsucursalModal = () => {
  setOpenEditsucursalModal(false);
  setSelectedSucursaledit(null); 
 
};

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

 
    
  return (
    <EmpresaLayout>
    <Container maxWidth='lg'>
     
        <Box sx={{width:'100%', marginBottom:'50px', marginTop:'50px'}} >
          <Grid container sx={{width:'100%', display:'flex'}}> 
            <Grid item xs={7}>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.4rem', fontWeight: '600', color: '#585857' }}>
                Sucursal {sucursales?.Sucursal}
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ fontSize: '1rem', fontWeight: '500', color: '#694B81' }}>
                Total QR generados {sucursales?.QR}
            </Typography>
            </Grid>
            </Grid>
            <Grid>
            <Grid item xs={3}>
               <button className="botoncrearmemorilaempresa"  onClick={() => handleOpenEditsucursalModal(sucursales)}> Editar Sucursal</button>
              </Grid>
            </Grid>
            
        </Box>

        {loading ? (
            <Box>
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="text" width="80%" />
            </Box>
        ) : usuariosPorSucursal.length > 0 ? (
            usuariosPorSucursal.map((admi) => (
                <Box key={admi.id} mb={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Avatar {...stringAvatar(`${capitalizeFirstLetter(admi.name)} ${capitalizeFirstLetter(admi.apellido)}`)} />
                                <Box>
                                    <Typography sx={Styletitulos} variant="body2">Administrador</Typography>
                                    <Typography sx={Styletitulos} variant="body2">{capitalizeFirstLetter(admi.name)} {capitalizeFirstLetter(admi.apellido)}</Typography>
                                </Box>
                            </Stack>
                            <Box mt={2}>
                                <List>
                                    <ListItem>
                                        <Typography  sx={Styletitulos} variant="body2">Dirección: {sucursales?.Direccion}, {sucursales?.Comuna}, {sucursales?.Region}, {sucursales?.Pais}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography  sx={Styletitulos} variant="body2">Teléfono: {admi.telefono}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography sx={Styletitulos} variant="body2">Correo: {admi.email}</Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                       
                        <Grid item xs={12} sm={3} sx={StyleGridItemestruc}>
                            <div className="switchqr" style={StyleGridItemestruc}>
                                <p style={StyleP}>Estado Administrador Sucursal</p>
                                <IOSSwitch checked={admi.estado == 1} onChange={(event) => handleToggleUserStatus(admi.id, event.target.checked)} />
                            </div>
                        </Grid>
                          <Grid item xs={12} sm={3} sx={StyleGridItemestruc}>
                              <div className="editusuariosucursal"  onClick={() => {handleOpenEditModal(admi)}}><FaEdit /></div>
                          </Grid>
                    </Grid>
                </Box>
            ))
        ) : (
            <Typography>No hay usuarios asociados.</Typography>
        )}

      <Modal open={openEditModal} onClose={handleCloseEditModal} aria-labelledby="edit-sucursal-modal" aria-describedby="edit-sucursal-form">
          <Box sx={style}>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <div className="editusuariosucursal">
                      <IoIosClose style={{ fontSize: '2.3rem' }} onClick={handleCloseEditModal} />
                  </div>
              </Box>
              <Box>
                  <EditSucursalEMpresa usuario={selectedSucursal} onclose={handleCloseEditModal} onUserUpdated={fetchUserAndSucursal} />
              </Box>
          </Box>
      </Modal>
      <Modal open={openEditsucuralModal} onClose={handleCloseEditsucursalModal} aria-labelledby="edit-sucursal-modal" aria-describedby="edit-sucursal-form">
          <Box sx={style}>
              <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                  <div className="editusuariosucursal">
                      <IoIosClose style={{ fontSize: '2.3rem' }} onClick={handleCloseEditsucursalModal} />
                  </div>
              </Box>
              <Box>
                  <EditSucursalInfoEmpresa sucursal={selectedSucursaledit}  onclose={handleCloseEditsucursalModal}  />
              </Box>
          </Box>
      </Modal>
    </Container>
    </EmpresaLayout>
);
};

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const Styletitulos = {
    color: '#585857',
    fontFamily:'Poppins',
    fontWeight:'600'
  }
  const StyleGridItem = {
    border:'1px solid #F0EDF3',
    borderRadius:'10px'
  }

  const StyleGridItemestruc = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column'
  }
  const StyleP ={
    color: '#585857',
    fontFamily:'Poppins',
    fontWeight:'500',
    fontSize:'0.75rem',
    textAlign: 'center'
  }
  
  const style = {
    fontFamily:'Poppins',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#ffff',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    height:'600px',
    overflowY:'auto'
  };
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
  
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));