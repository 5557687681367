import React, { useState } from "react";
import { useEffect } from 'react'
import "../../../../Style/usuario/memorialesusuario.css"
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";
import { FaEllipsis } from "react-icons/fa6";
import { RiFileEditFill } from "react-icons/ri";
import { IoShareSocialSharp } from "react-icons/io5";
import { CgClose } from "react-icons/cg";
import axios from 'axios';
import { FaCirclePlus } from "react-icons/fa6";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container } from "@mui/material";
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { FiAlertCircle } from "react-icons/fi";
import CircularProgress from '@mui/material/CircularProgress';
import { IoIosArrowBack } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useUsers } from "../../../context/userscontexts";
import { MdGroups } from "react-icons/md";

/* imagenes */
import qr from "../../../../Image/generales/icono-qr.png"
import grupo from "../../../../Image/generales/grupo-familia-2.png"



export const MemorialUsuario = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [nombreFamilia, setNombreFamilia] = useState('');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [link, setLink] = useState(null); 
  const [obituario, setObituario] = useState([]);
  const {  userData } = useUsers(); 
  const [memorialData, setMemorialData] = useState([]); 


 
  useEffect(() => {
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
        const grupoFamiliarData = response.data[0];
        setGrupoFamiliar(grupoFamiliarData);
        if (grupoFamiliarData && grupoFamiliarData.memorial) {
          setMemorialData(grupoFamiliarData.memorial);
        }
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
      }
    };

    fetchGrupoUser();
  }, [ClientToken, backendURL]);

  useEffect(() => {
    const fetchObituarios = async () => {
        try {
            const obituariosPromises = grupoFamiliar.memorial.map(async (memorial) => {
                try {
                    const response = await axios.get(`${backendURL}/api/obtenerObituario/${memorial.id}`, {
                        headers: { Authorization: `Bearer ${ClientToken.token}` }
                    });

                    // Verifica si la respuesta tiene datos válidos
                    if (response.status === 200 && response.data) {
                        return { id: memorial.id, data: response.data };
                    } else {
                        return { id: memorial.id, data: null };
                    }
                } catch (error) {
                    // En caso de error, devuelve null para este memorial
                    return { id: memorial.id, data: null };
                }
            });

            const obituariosData = await Promise.all(obituariosPromises);
            const obituariosMap = obituariosData.reduce((acc, { id, data }) => {
                if (data) {
                    acc[id] = data;
                } else {
                    acc[id] = null; // Asegúrate de que los memoriales sin obituarios estén representados
                }
                return acc;
            }, {});

            setObituario(obituariosMap);
        } catch (error) {
            console.error('Error fetching obituarios:', error);
        }
    };

    if (grupoFamiliar && grupoFamiliar.memorial) {
        fetchObituarios();
    }
}, [grupoFamiliar, ClientToken, backendURL]);


const esReciente = (fechaDefuncion) => {
  const ahora = new Date();
  const difTiempo = ahora - new Date(fechaDefuncion);
  const diasDiferencia = Math.floor(difTiempo / (1000 * 60 * 60 * 24));
  return diasDiferencia <= 3;
};

const manejarObituario = (memorial) => {
  const tieneObituario = obituario[memorial.id] !== null;
  const fechaReciente = esReciente(memorial.fecha_defuncion);

  if (!tieneObituario && fechaReciente) {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorial.id };
    navigate(`/Obituario-crear`, { state });
  } else if (tieneObituario) {
    navigate(`/Obituario-editar/${memorial.id}`);
  } else {
    console.error('No es necesario crear o editar un obituario en este momento.');
  }
};

  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/compartirusuarioqr', { state });
  };
  const handleClickedit = (memorialId) => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId }; // Pasar el ID del memorial como parte del estado
    navigate('/editarmemorial', { state });
  };
  const handleClickvista = (memorialId) => {
    window.open(`/familiar/memorial/${memorialId}`, '_blank', 'noopener');
  };

  const toggleDrawers = () => setOpens(!opens);
  const handleEditClick = () => {
    toggleDrawer();
    setModalOpen(true); // Abre el modal después de hacer clic en editar
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Cierra el modal
  };

  const handleGoBack = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  };
  const handlecrearmemorial = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/crearmemorial', { state });
  };

  const handleNombreChange = (event) => {
    setNombreFamilia(event.target.value);
  };

  const handleGuardarNombre = async (event) => {
    event.preventDefault();

    try {
        setenviandoAlert(true);
        const response = await axios.post(
            `${backendURL}/api/updatedFamiliarName/${grupoFamiliar.id}`,
            { nombre_familia: nombreFamilia },
            {
                headers: {
                    Authorization: `Bearer ${ClientToken.token}` // Encabezado de autorización
                }
            }
        );

        // Cierra el modal después de editar el nombre de la familia
        handleCloseModal();
        setenviandoAlert(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Editado nombre familiar con éxito.');

        // Actualiza el objeto grupoFamiliar
        const updatedGrupoFamiliar = { ...grupoFamiliar, nombre_familia: nombreFamilia };

        // Retardo antes de navegar
        setTimeout(() => {
            navigate('/usuariomemoriales', { state: { user: ClientToken, familia: updatedGrupoFamiliar } });
        }, 2000); // 2000 ms = 2 segundos
    } catch (error) {
        setErrorAlertOpen(true);
        setAlertMessage('Error al editar nombre familiar, vuelva a intentar.');
    } finally {
        // Cierra las alertas después de un pequeño retardo para permitir que se vean
        setTimeout(() => {
            setErrorAlertOpen(false);
            setSuccessAlertOpen(false);
        }, 2000); // 2000 ms = 2 segundos
    }
};

 

  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData);
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        } 
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [grupoFamiliar]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};



  /* apertura boton */
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  const list = (
    <Box
      sx={{ minWidth: "100%", color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
      {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length > 0 && (
    <ListItem button onClick={handleClick}>
      <ListItemIcon style={{minWidth:"25px" , marginBottom:'5px'}}>
        <IoShareSocialSharp style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize:"1.3rem" }} /> {/* Cambiar el color del icono */}
      </ListItemIcon>
      <ListItemText primary={<span style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontFamily: "Poppins", fontSize: "1rem", fontWeight: "500"}}>Compartir grupo familiar</span>} /> {/* Cambiar el color del texto */}
    </ListItem>
  )}

        <ListItem button onClick={handleEditClick}>
          <ListItemIcon  style={{minWidth:"25px",  marginBottom:'5px'}}>
            <RiFileEditFill style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',fontSize:"1.3rem" }} /> {/* Cambiar el color del icono */}
          </ListItemIcon>
          <ListItemText primary={<span style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontFamily: "Poppins", fontSize: "1rem", fontWeight: "500" }}>Editar nombre del grupo</span>} /> {/* Cambiar el color del texto */}
        </ListItem>
        {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length < 5 && (
    <ListItem button onClick={handlecrearmemorial}>
      <ListItemIcon  style={{minWidth:"25px",  marginBottom:'5px'}}>
        <FaCirclePlus style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',fontSize:"1.3rem" }} /> {/* Cambiar el color del icono */}
      </ListItemIcon>
      <ListItemText primary={<span style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontFamily: "Poppins", fontSize: "1rem", fontWeight: "500" }}>Crear nuevo memorial</span>} /> {/* Cambiar el color del texto */}
    </ListItem>
  )}
      </List>
    </Box>
  );

  

  return (
    <section className="contienenpaginainiciousuario" >
    <>   
      
    {logoUrl && (
          <Link to={link.web} className="logofunerariamemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
        
          {grupoFamiliar ? (
  <>
      <Container className="contieneregistro" component="main" maxWidth="xs">
        <Box sx={{
          width:"100%",
          padding: '0px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '0px',
          '@media (max-width: 700px)': {
            marginTop: '10px'
          }
        }}>

           <button className='boton-anterior' onClick={handleGoBack}>
           <IoIosArrowBack style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}/>
          </button>

          <button className='boton-editar' onClick={toggleDrawer(true)} >
            <FaEllipsis  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}/>
          </button>

        </Box>

       

        <Box sx={{width:"100%", padding: '0px 0px', display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '250px', '@media (max-width: 420px)': {
            padding: '0px 0px'
          },'@media (max-width: 376px)': {
            padding: '0px 0px'
          }, '@media (max-width: 350px)': {
            padding: '0px 0px'
          }  }}> <Box  sx={{
            paddingLeft:" 10px",
            width:"90%",
            display: 'flex',
            flexDirection:"column",
            alignItems:"flex-start",
            }}>
       
          <h1 className="grupofmailiausuario" style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Grupo Familiar</h1>
          <div className="nombre-familia-usuario">
           <h1 style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999;'}}>{grupoFamiliar.nombre_familia}</h1>

           <MdGroups style={{fontSize:'2.5rem', padding:'0px 5px',color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999'}} />
          </div>
        </Box >
          <div className="titulomemorialperfiles">
            <h1 translate="no" style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}> Memorial</h1>
          </div>
          <Box sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
  {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length > 0 ? (
    grupoFamiliar.memorial.map((memorial) => (
      <div className="contienenmemorialcompleto" key={memorial.id} >
        
          <div className="card" >
           
           <Grid container sx={{width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } }>
            <Grid item xs={12}  sx={{ width:"100%", height:"auto", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' } } onClick={() => handleClickvista(memorial.id)}>
            <div className="circle">
              {memorial && (
                <img
                  src={`${backendURL}/imagenes/memoriales/${memorial.carpeta_perfil}/${memorial.imagen_perfil}`}
                  alt="smart memorial"
                  className="profile-img"
                  style={{
                    borderColor: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#E1EDDD';
                        case 'atardecerVerano':
                          return '#F9E7D7';
                        default:
                          return (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#E1DBE6';
                      }
                    })()
                  }}
                />
              )}
              </div>
              </Grid>
              <Grid item xs={12}  sx={{ width:"100%", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop:"-95px", '@media (max-width: 400px)': {
            marginTop: '-80px'
          }, '@media (max-width: 290px)': {
            marginTop: '-70px'
          }, '@media (max-width: 246px)': {
            marginTop: '-55px'
          } }}>
              <div
                className="cuadrado"
                style={{
                  background: (() => {
                    switch (memorial?.fondo) {
                      case 'naturalezaAustral':
                        return '#E1EDDD';
                      case 'atardecerVerano':
                        return '#F9E7D7';
                      default:
                        return (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#E1DBE6';
                    }
                  })()
                }}
              >
                <div
                onClick={() => handleClickvista(memorial.id)}
                  className="content"
                  style={{
                    color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999';
                      }
                    })()
                  }}
                >
                  <h2 className="name" style={{
                    color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#27632D';
                        case 'atardecerVerano':
                          return '#A04424';
                        default:
                          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999';
                      }
                    })()
                  }}>{memorial.nombre}</h2>
                  <p className="dates"  style={{
                     color: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81';
                      }
                    })(),
                  }}>
                    {formatDate(memorial.fecha_nacimiento)} • {formatDate(memorial.fecha_defuncion)}
                  </p>
                </div>
                <div className="contienenvuttonobituario">
             
                {!obituario[memorial.id] && esReciente(memorial.fecha_defuncion) && (
                  <section className="obituariobuttonmemorial" onClick={() => manejarObituario(memorial)} >
                  <button 
                   className="buttonovituariomemorialentrada"
                  style={{
                    borderColor: (() => {
                      switch (memorial?.fondo) {
                        case 'naturalezaAustral':
                          return '#68A354';
                        case 'atardecerVerano':
                          return '#E28939';
                        default:
                          return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81';
                      }
                    })(),
                               background: (() => {
                                switch (memorial?.fondo) {
                                  case 'naturalezaAustral':
                                    return '#68A354';
                                  case 'atardecerVerano':
                                    return '#E28939';
                                  default:
                                    return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81';
                                }
                              })(),
                            }} >
                     Crear Obituario
                  </button>
              </section>

          )}
           {obituario[memorial.id] && (
              <section className="obituariobuttonmemorial" onClick={() => navigate(`/Obituario-editar/${obituario[memorial.id].obituario.id}`)} >
              <button 
               className="buttonovituariomemorialentrada"
              style={{
                borderColor: (() => {
                  switch (memorial?.fondo) {
                    case 'naturalezaAustral':
                      return '#68A354';
                    case 'atardecerVerano':
                      return '#E28939';
                    default:
                      return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81';
                  }
                })(),
                           background: (() => {
                            switch (memorial?.fondo) {
                              case 'naturalezaAustral':
                                return '#68A354';
                              case 'atardecerVerano':
                                return '#E28939';
                              default:
                                return (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 : '#694B81';
                            }
                          })(),
                        }} >
                 Editar Obituario
              </button>
          </section>
           
          )}
   

            </div>
              </div>
            </Grid>
  
            
            </Grid>

            
            
          
          </div>
          

          <section className="editmemorialespecifico">
            <button onClick={() => handleClickedit(memorial.id)} style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>
              <RiFileEditFill /> Editar memorial
            </button>
          </section>
       
      </div>
    ))
  ) : (
    <section className="alertadenomemorialusuario">
         <FiAlertCircle/>
    <h1 style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>No hay memoriales</h1>
    <button style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} onClick={handlecrearmemorial}>Crear Memorial</button>
    </section>
  )}
</Box>

        </Box>
        {grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length > 0 && (
      <button className="buttonqrcompartir" onClick={handleClick} style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>
         <div className="qrcompartir">
          <img src={qr} className="imagenqrsmartmemorial" alt="smart memorial"/>
           </div>
           <div className="contieneninfobuttonqr">
            <span>Ver</span>
             <p className="contieneninfoqrdescripcion">código QR</p>
             </div>
             </button>
        )}
      </Container>

      <SwipeableDrawer
        anchor={"bottom"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 10px' }}>
          <Button onClick={toggleDrawer(false)} >
            <CgClose className="botoncierremenutodomemorial" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
          </Button>
        </Box>
        {list}
      </SwipeableDrawer>


      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #585857', borderRadius: "5px", boxShadow: 24, p: 0,  '@media (max-width: 500px)': {
                width: '90%',
                
              },
              '@media (max-width: 325px)': {
                width: '90%',
               

              } }}>
          <div className="botoncerrar">  <Button onClick={handleCloseModal}><IoClose  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} /></Button></div>
          <div className="cardeditnombrefamilia">
            <div className="tituloeditarfamilia">
            
              <RiFileEditFill style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} className="iconoeditarnombrefamilia" />
           
            <h2 className="labeleditarnombre" style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Editar Apellido Familiar</h2></div>
            <div className="inputlabelfamilia">
              <form  className="formularioeditarnombre">
              <label style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Grupo Familiar</label>
              
                <input type="text" name="nombre" placeholder="ej: Rodriguez Fernandez" value={nombreFamilia} onChange={handleNombreChange} />
                <button className="buttonguardareditarfamilia" onClick={handleGuardarNombre} style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}> Guardar</button>
              </form>

            </div>
          </div> 
        </Box>
      </Modal>
      </>
    ) : (
      <Container className="contieneregistro" component="main" maxWidth="xs">
      <Box sx={{
        padding: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '100px',
       
      }}>
        <CircularProgress sx={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
     
</Box>
</Container>
    )}
    </>
    <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
            <p style={{ fontFamily: 'Poppins', ccolor:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
    </section>
  );
};