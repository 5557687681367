import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { Box, Modal, CircularProgress, Grid, Container, Typography, List, ListItem, Select,MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from "@mui/system";
import { FiAlertCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FaRegCircleCheck } from "react-icons/fa6";
import Skeleton from '@mui/material/Skeleton';
import { datadireccion } from "../../../../../data/api direccion/datadireccion";
import { useTheme } from '@mui/material/styles';

// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 1, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});

export const EditSucursalInfoEmpresa = ({sucursal, onclose}) => {
    const { Token } = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedComuna, setSelectedComuna] = useState('');
    const [regions, setRegions] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [SelectSucursal, setSelectedSucursaledit ] = useState([])
    const theme = useTheme();

    // Obtener la sucursal específica por ID
const fetchSucursalById = async () => {
    if (!Token || !sucursal.id) return; 
    try {
        const response = await https.get(`${backendURL}/api/Sucursal/especifica/${sucursal.id}`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Token}`
            }
        });

        const sucursalData = response.data.sucursal;
        setSelectedSucursaledit(sucursalData);
         setSelectedCountry(sucursalData.pais || '');
         setSelectedRegion(sucursalData.region || '');
         setSelectedComuna(sucursalData.comuna || '');
          // Si el país tiene regiones disponibles, cargarlas
        if (sucursalData.pais) {
            setRegions(Object.keys(datadireccion.pais[sucursalData.pais].region));
        }
        // Si la región tiene comunas disponibles, cargarlas
        if (sucursalData.region && sucursalData.pais) {
            setCommunes(datadireccion.pais[sucursalData.pais].region[sucursalData.region].comuna);
        }
    } catch (error) {
        console.error('Error fetching sucursal:', error);
    }
};



useEffect(() => {
    if (sucursal && sucursal.id) {
        fetchSucursalById(sucursal.id);
    }
}, [sucursal]);

const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelectedSucursaledit((prevState) => ({
        ...prevState,
        [name]: value,
    }));
};

const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setRegions(Object.keys(datadireccion.pais[country].region));
    setSelectedRegion('');
    setCommunes([]);

    // Actualizar el estado de la sucursal editada
    setSelectedSucursaledit((prevState) => ({
        ...prevState,
        pais: country,
        region: '', // Limpiar región al cambiar de país
        comuna: ''  // Limpiar comuna al cambiar de país
    }));
};

// Método para manejar los cambios en la región
const handleRegionChange = (event) => {
    const region = event.target.value;
    setSelectedRegion(region);
    setCommunes(datadireccion.pais[selectedCountry].region[region].comuna);

    // Actualizar el estado de la sucursal editada
    setSelectedSucursaledit((prevState) => ({
        ...prevState,
        region: region,
        comuna: '' // Limpiar comuna al cambiar de región
    }));
};

// Método para manejar los cambios en la comuna
const handleComunaChange = (event) => {
    const comuna = event.target.value;
    setSelectedComuna(comuna);

    // Actualizar el estado de la sucursal editada
    setSelectedSucursaledit((prevState) => ({
        ...prevState,
        comuna: comuna
    }));
};

const editarSucursal = async (event) => {
    event.preventDefault();
    if (!Token || !sucursal.id) return;

    setIsLoading(true);
    setenviandoAlert(true);

    try {
        const response = await axios.put(`${backendURL}/api/sucursales/editar/${sucursal.id}`, SelectSucursal, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Token}`,
            },
        });

        if (response.status === 200) {
            setAlertMessage('Sucursal actualizada exitosamente');
            setSuccessAlertOpen(true);
            onclose();
        } else {
            setAlertMessage('Error al actualizar la sucursal');
            setErrorAlertOpen(true);
        }
    } catch (error) {
        console.error('Error al editar la sucursal:', error);
        setAlertMessage('Error al editar la sucursal');
        setErrorAlertOpen(true);
    } finally {
        setIsLoading(false);
        setenviandoAlert(false);
    }
};

    return(
        <Box style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '30px'
        }}>

                <Box >
                    <h2 className="tituloeditarsucempresa">Editar  Sucursal {sucursal.nombre_sucursal}</h2>
                </Box>

                {loading ? ( // Condicional para mostrar el loader
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />

                    </Box> // Cambia esto por tu componente de loader
                ) : (  <form
                    onSubmit={editarSucursal}
                    style={{
                        width: '100%',
                        position: 'relative'
                    }}>

                    <Grid container >
                        <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                        <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Nombre sucursal</label>
                                    <input className="crearinputperfil" style={{ width:'100%'}} type="text" name="nombre_sucursal" 
                                      value={SelectSucursal.nombre_sucursal }
                                      onChange={handleInputChange}
                                    />
                                </div>
                            
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                        <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Dirección sucursal</label>
                                    <input className="crearinputperfil" style={{ width:'100%'}} type="text" name="nombre_sucursal"
                                       value={SelectSucursal.direccion}
                                       onChange={handleInputChange}
                                    />
                                </div>
                            
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <div className="inputcrearperfil">
                  <label className="titulocrearperfillabel" htmlFor='nombreEmpresa'>País</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='pais'
                      id='pais'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar país...</em></MenuItem>
                      {Object.keys(datadireccion.pais).map(country => (
                        <MenuItem key={country} value={country}>{country}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <div className="inputcrearperfil">
                            <label className="titulocrearperfillabel" htmlFor='nombreEmpresa'>Región</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='region'
                      id='region'
                      sx={{ textAlign: "left" }}
                      className="input-select-registro"
                      value={selectedRegion}
                      onChange={handleRegionChange}
                      displayEmpty
                      disabled={!selectedCountry}
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar región...</em></MenuItem>
                      {regions.map(region => (
                        <MenuItem key={region} value={region}>{region}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>

                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                            <div className="inputcrearperfil">
                            <label className="titulocrearperfillabel" htmlFor='nombreEmpresa'>Comuna</label>
                  <div className='contieneinputregister'>
                    <StyledSelect
                      name='comuna'
                      id='comuna'
                      value={selectedComuna}
                      onChange={handleComunaChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Comuna' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value=""><em>Seleccionar comuna...</em></MenuItem>
                      {communes.map((comuna) => (
                        <MenuItem key={comuna} value={comuna}>{comuna}</MenuItem>
                      ))}
                    </StyledSelect>
                  </div>
                </div>

                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                        <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Telefono</label>
                                    <input className="crearinputperfil" style={{ width:'100%'}} type="tel" name="telefono" id="telefono"
                                     value={SelectSucursal.telefono}
                                     onChange={handleInputChange}
                                    />
                                </div>

                            </Grid>


                            </Grid>
                            <section className="botonguardarcontraseñanuevacreado">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : 'Editar sucursal'}
                        </button>
                    </section>
                         </form>   
                            
                            )}

                             <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Popup de error */}
            <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Popup de carga */}
            <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
            </Modal>
        </Box>
    )
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color: '#694B81',
    position: 'relative',
    right:'0%',
    fontFamily: "Poppins",
    width: '100%',
    height: '47px',
    fontSize: '0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize: '0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
  
  }));