import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { GeneradorQRSucursal } from "../../FuncionesSucursalAdmin/CreacionQrSucursal";

/* libreria UI */
import {  Modal, Box, CircularProgress, Grid, Container, Snackbar, Alert, Button} from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { DataGrid } from '@mui/x-data-grid'; 
import { useTheme } from '@mui/material/styles';

/* IMAGENES  */
import luto from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/moradoluto.png"
import QrBlanco from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrblanco.png"
import QrMorado from "../../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrmorado.png"
import fondo from "../../../../../../Image/generales/carta.png";

/* react icons */
import { IoIosClose } from "react-icons/io";
import { BsFillHouseAddFill } from "react-icons/bs";
import { BsFillHouseCheckFill } from "react-icons/bs";
import { IoSearch } from "react-icons/io5";
import { TbFaceIdError } from "react-icons/tb";
import { TbUserPlus } from "react-icons/tb";
import { TbUserHeart } from "react-icons/tb";
import { TbUser } from "react-icons/tb";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });

export const CodigosQrSucursalAdmi = () => {
  const { Usuario, Token, Empresa, UserDataMarca, Sucursal} = useUserEmpresa();
    const { id } = useParams();
    const [qrData, setQrData] = useState([]);
    const [AllQr, setAllQr] = useState([])
    const [sucursales, setSucursales] = useState([]);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false); 
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [mes, setMes] = useState("");
    const [totalQr, setTotalQr] = useState(0);
    const [qrAsignados, setQrAsignados] = useState(0);
    const [qrDisponibles, setQrDisponibles] = useState(0);
    const currentMonth = dayjs().month(); // Mes actual
    const currentYear = dayjs().year();
    const navigate = useNavigate()
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [gruposfamilias, setGrupofamilias] = useState([]);
    const [marcas, setMarcas] = useState([])
    const [codigos, setCodigos] = useState([]);
    const [Qrsucursal, setQrsucursal] = useState(0);
    const [Qrsucursaladmi, setQrsucursaladmi] = useState(0);


    useEffect(() => {
      // Obtener la fecha actual
      const fechaActual = new Date();
      const dia = fechaActual.getDate(); // Día
      const mes = fechaActual.getMonth(); // Mes (de 0 a 11)
      const año = fechaActual.getFullYear(); // Año

      // Arreglo con los nombres de los meses
      const meses = [
          "enero", "febrero", "marzo", "abril", "mayo", "junio",
          "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];

      const fechames = ` ${meses[mes]}`;

      // Establecer la fecha en el estad
      setMes(fechames);

  }, []);
  //funcion qr de este mes
  useEffect(() => {
    // Obtenemos el mes actual (de 0 a 11) y el año actual
    const mesActual = dayjs().month();
    const anioActual = dayjs().year();

    // Filtrar los QR que fueron creados en el mes y año actual
    const qrDelMesActual = AllQr.filter(qr => {
      const fechaCreacion = dayjs(qr.fecha_creacion);
      return fechaCreacion.month() === mesActual && fechaCreacion.year() === anioActual;
    });

    // Contar los QR asignados y disponibles para el mes actual
    const totalQrMes = qrDelMesActual.length;
    const qrAsignadosMes = qrDelMesActual.filter(qr => qr.id_usuario).length;
    const qrDisponiblesMes = qrDelMesActual.filter(qr => !qr.id_usuario).length;

    // Actualizamos los estados
    setTotalQr(totalQrMes);
    setQrAsignados(qrAsignadosMes);
    setQrDisponibles(qrDisponiblesMes);
  }, [AllQr]);

  const handleUserqr = (qr) => {
    navigate(`/sucursal/${Usuario.id}/qr/memorial/${qr.id}`, { state: { QR: qr, sucursal:Sucursal, user:Usuario, Token } });
  };

 

    const handleAssignUser = (qr) => {
      navigate(`/registro/${Usuario.id}/usuario/sucursal`, { state: { QR: qr, sucursal:Sucursal, user:Usuario } });
    };
    const token = sessionStorage.getItem('Token');

    useEffect(() => {
      if (!Sucursal?.id || !Token) return;
    
      const fetchData = async () => {
        setLoading(true);
        try {
          // Llamada a fetchGruposFamiliares
          const fetchGruposFamiliares = async () => {
            const response = await http.get(
              `${backendURL}/api/sucursal/${Sucursal.id}/grupos-familiares`, {
                headers: { Authorization: `Bearer ${Token}` },
              }
            );
    
            const filteredsucursal = response.data.filter(
              (grupo) => grupo.id_sucursal == Sucursal.id
            );
            const totalQrSucursal = filteredsucursal.length;
            setQrsucursal(totalQrSucursal);
    
            const filteredGrupos = response.data.filter(
              (grupo) => grupo.id_sucursal == Sucursal.id && grupo.id_admin_sucursal == Usuario.id
            );
            const totalQrAsignados = filteredGrupos.filter(
              (grupo) => grupo.id_usuario !== null
            ).length;
            const totalQrDisponibles = filteredGrupos.filter(
              (grupo) => grupo.id_usuario === null
            ).length;
    
            setAllQr(filteredGrupos);
            setQrsucursaladmi(filteredGrupos.length);
            setQrAsignados(totalQrAsignados);
            setQrDisponibles(totalQrDisponibles);
          };
    
          // Llamada a fetchMarcas
          const fetchMarcas = async () => {
            if (!Empresa?.id || !Token) return;
            const response = await http.get(
              `${backendURL}/api/marcaempresa/sucursal/${Empresa.id}`, {
                headers: { Authorization: `Bearer ${Token}` },
              }
            );
            const marcaData = response.data.data[0];
            if (marcaData) {
              setMarcas(marcaData);
            }
          };
    
          // Llamada a fetchCodigos
          const fetchCodigos = async () => {
            const response = await http.get(`${backendURL}/api/getcodigosSucursal`, {
              headers: { Authorization: `Bearer ${Token}` },
            });
            setCodigos(response.data);
          };
    
          // Llamada a fetchCodigosqr
          const fetchCodigosqr = async () => {
            const response = await http.get(`${backendURL}/api/getAllGruposFamiliaresSucursal`, {
              headers: { Authorization: `Bearer ${Token}` },
            });
            setGrupofamilias(response.data);
          };
    
          // Ejecutar las funciones en secuencia
          await fetchGruposFamiliares();
          await fetchCodigos();
          await fetchCodigosqr();
          await fetchMarcas();
        } catch (error) {
          console.error("Error al obtener los datos:", error);
        } finally {
          setLoading(false);
        }
      };
    
      fetchData();
    }, [Sucursal, backendURL, Token, Usuario.id]);
      // Funciones para abrir y cerrar el modal
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);

        
         const refreshQrData = async () => {
            if (!Sucursal?.id || !Token) return;
            setLoading(true);
            try {
              const response = await http.get(
                `${backendURL}/api/sucursal/${Sucursal.id}/grupos-familiares`, {
                  headers: { Authorization: `Bearer ${Token}` },
                }
              );
          
              const filteredGrupos = response.data.filter(
                (grupo) => grupo.id_sucursal ==Sucursal.id && grupo.id_admin_sucursal == Usuario.id
              );
          
              setAllQr(filteredGrupos);
            } catch (error) {
              console.error("Error al actualizar los grupos familiares:", error);
            } finally {
              setLoading(false);
            }
    };



        const filteredQrData = AllQr.filter((qr) =>
          qr.qr.toLowerCase().includes(searchTerm.toLowerCase()) || qr.id == Number(searchTerm)
        );
    
        const fetchCodigosqr = async () => {
          setLoading(true);
          if (!Sucursal || !Token) return;
        
          try {
            const response = await http.get(`${backendURL}/api/getAllGruposFamiliaresSucursal`, {
              headers: { Authorization: `Bearer ${Token}` },
            });
        
            setGrupofamilias(response.data);
          } catch (error) {
            console.error("Error al obtener los códigos QR:", error);
          } finally {
            setLoading(false);
          }
        };
        
        // Llamada inicial al cargar el componente
        useEffect(() => {
          fetchCodigosqr();
        }, [backendURL, Token]);

      const handleDownloadPDF = async (codigoId) => {
        try {
            const grupoFamiliar = gruposfamilias.find(grupo => grupo.id == codigoId);
    
            if (!grupoFamiliar || !grupoFamiliar.qr) {
                console.error("Código QR no encontrado para el grupo familiar:", codigoId);
                return;
            }
    
            const qrImageUrl = `${backendURL}/api/descargarqrempresa/${codigoId}`;
            const cartaImageUrl = `${backendURL}/api/descargarCarta/${Sucursal.id_empresa}`; // Usa el ID correcto aquí
            const grupoFamiliares = gruposFamiliaresConSuscripcion.find(grupo => grupo.id == codigoId);
    
            if (!grupoFamiliares || !grupoFamiliares.codigo) {
                console.error("Código no encontrado para el grupo familiar con suscripción:", codigoId);
                return;
            }
    
            const loadImage = (url) => {
                return new Promise((resolve, reject) => {
                    const img = new Image();
                    img.src = url;
                    img.crossOrigin = "Anonymous";
                    img.onload = () => resolve(img);
                    img.onerror = (err) => reject(err);
                });
            };
    
            const qrImage = await loadImage(qrImageUrl);
            const fondoImage = await loadImage(fondo);
            let cartaImage;
    
            // Intenta cargar la imagen de la carta
            try {
                cartaImage = await loadImage(cartaImageUrl);
            } catch (error) {
                console.warn("No se pudo cargar la imagen de la carta. Usando el fondo predeterminado.");
                cartaImage = null; // Establece cartaImage a null si no se puede cargar
            }
    
            const canvas = document.createElement('canvas');
            canvas.width = qrImage.width;
            canvas.height = qrImage.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(qrImage, 0, 0, qrImage.width, qrImage.height);
            const qrDataURL = canvas.toDataURL('image/png');
    
            const doc = new jsPDF();
    
            // Usa la imagen de la carta como fondo si existe
            const imgWidth = doc.internal.pageSize.getWidth();
            const imgHeight = doc.internal.pageSize.getHeight();
            if (cartaImage) {
                // Si hay imagen de carta, usarla como fondo
                doc.addImage(cartaImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            } else {
                // Si no, usar el fondo específico
                doc.addImage(fondoImage.src, 'PNG', 0, 0, imgWidth, imgHeight);
            }
    
            // Define el tamaño y la posición del QR
            const qrSize = 44;
            const qrX = (imgWidth - qrSize) / 2; // Centra el QR horizontalmente
            const qrY = 140; // Ajusta esta posición según tus necesidades
            doc.addImage(qrDataURL, 'PNG', qrX, qrY, qrSize, qrSize);
    
            const idText = ` ${grupoFamiliar.id}`; // Formato para el ID
    
            // Establece un tamaño de fuente más pequeño
            const originalFontSize = doc.internal.getFontSize(); // Guarda el tamaño de fuente original
            doc.setFontSize(10); // Establece el tamaño de fuente a 10 (ajusta este valor según tus necesidades)
    
            const idTextWidth = doc.getStringUnitWidth(idText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const idTextX = (imgWidth - idTextWidth) / 2; // Centra el ID horizontalmente
            const idTextY = qrY + qrSize + 5; // Ajusta esta posición según tus necesidades
            doc.text(idText, idTextX, idTextY);
    
            // Restaura el tamaño de fuente original
            doc.setFontSize(originalFontSize);
    
            // Agrega el código de texto debajo del ID
            const codeText = grupoFamiliares.codigo; // Código de grupo familiar
            const codeTextWidth = doc.getStringUnitWidth(codeText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
            const codeTextX = (imgWidth - codeTextWidth) / 2; // Centra el código horizontalmente
            const codeTextY = idTextY + 32; // Ajusta esta posición según tus necesidades
            doc.text(codeText, codeTextX, codeTextY);
    
            doc.save(`codigo_${grupoFamiliar.id}.pdf`);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
        }
    };


    const gruposFamiliaresConSuscripcion = gruposfamilias.map((grupo) => {
      const suscripcion = codigos.find(codigo => codigo.id_grupo_familiar == grupo.id);
      return {
          id: grupo.id,
          id_empresa: grupo.id_empresa,
          id_sucursal: grupo.id_sucursal,
          id_usuario: grupo.id_usuario,
          nombre_familia: grupo.nombre_familia,
          estado_suscripcion: suscripcion ? suscripcion.estado : null,
          codigo: suscripcion ? suscripcion.codigo : null,
          fecha_inicio: suscripcion ? suscripcion.fecha_inicio : null,
          fecha_final: suscripcion ? suscripcion.fecha_final : null,
          qr: grupo.qr,
          tipo_memorial: grupo.tipo_memorial,
          created_at: grupo.created_at,
          updated_at: grupo.updated_at
      };
  });
  
  const columns = [
    { field: 'id', headerName: 'QR', width: 100 },
    { field: 'nombre_familia', headerName: 'Nombre Familia', width: 200 },
    { field: 'tipo_memorial', headerName: 'Tipo QR', width: 150 },
    { field: 'memorial', headerName: 'Cantidad Memorial', width: 150 },
    { field: 'sucursal', headerName: 'Sucursal', width: 200 },
    { field: 'usuario', headerName: 'Usuario', width: 200 },
    { field: 'rut', headerName: 'Rut', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'telefono', headerName: 'Teléfono', width: 150 },
    {
        field: 'estado',
        headerName: 'Estado',
        width: 150,
        renderCell: (params) => (
          params.row.id_usuario ? (
            <div className="switchqr">
              <IOSSwitch
                checked={params.row.estadoUsuario == 1} // Verifica si el estadoUsuario es 1
                onChange={(event) => handleEstadoChange(params.row.idUsuario, event.target.checked)} // Cambia el estado cuando el switch se mueve
              />
            </div>
          ) : (
            <p>No asignado</p> // Si no hay idUsuario, puedes mostrar algo por defecto
          )
        ),
      },
    {
    field: 'accionesUsuario',
    headerName: 'Acciones Usuario',
    width: 350,
    renderCell: (params) => (
        params.row.id_usuario ? (
        <div style={{ display: 'flex', gap: '8px' }}>
            <button className="BotonPerfil" onClick={() => handleUserqr(params.row)}>
            <TbUser /> Perfil
            </button>
            {params.row.nombre_familia && (
            <a href={`/familiar/group/${params.row.id}`} target="_blank" rel="noopener noreferrer">
                <button className="BotonMemorial">
                <TbUserHeart /> Memorial
                </button>
            </a>
            )}
            
        </div>
        ) : (
        <button onClick={() => handleAssignUser(params.row)} className="botonAsignar">
            <TbUserPlus /> Asignar Usuario
        </button>
        )
    ),
    },
    {
    field: 'accionesQR',
    headerName: 'Acciones QR',
    width: 300,
    renderCell: (params) => (
        <div style={{ display: 'flex', gap: '8px' }}>
        <button className="BotonQrdescarga" onClick={() => downloadQR(params.row)}>
            Descargar QR
        </button>
        <button className="cartadescargar" onClick={() => handleDownloadPDF(params.row.id)}>
            Descargar Carta
        </button>
        </div>
    ),
    },
    { field: 'qr', headerName: 'QR imagen', width: 100 }
];

const rows = filteredQrData.map((qr) => ({
    id: qr.id,
    id_admin_sucursal:qr.id_admin_sucursal,
    id_empresa:qr.id_empresa,
    id_sucursal:qr.id_sucursal,
    nombre_familia: qr.nombre_familia,
    tipo_memorial: qr.tipo_memorial == 1
    ? 'Grupo Familiar'
    : qr.tipo_memorial == 2
    ? 'Memorial'
    : qr.tipo_memorial == 3
    ? 'Histórico'
    : qr.tipo_memorial == 4
    ? 'Empresa'
    : '-',
    memorial: qr.memorial ? qr.memorial.length : 0,
    sucursal: qr.sucursal ? qr.sucursal.nombre_sucursal : `Empresa ${Empresa?.nombre_empresa}`,
    usuario: qr.usuario ? `${qr.usuario.name} ${qr.usuario.apellido}` : 'No hay usuario asignado',
    rut: qr.usuario?.rut || 'No hay usuario asignado',
    email: qr.usuario?.email || 'No hay usuario asignado',
    name: qr.usuario?.name || 'No hay usuario asignado',
    apellido: qr.usuario?.apellido || 'No hay usuario asignado',
    telefono: qr.usuario?.telefono || 'No hay usuario asignado',
    id_usuario: qr.id_usuario,
    estadoUsuario: qr.usuario?.estado,
    qr: qr.qr,
}));


      if (loading) {
        return(
           
              <Box display="flex" justifyContent="center" alignItems="center" sx={{
            marginTop:'30px', flexDirection:'column'
          }}  >
           
          <Skeleton variant="rectangular" width="80%" height="200px" />  
          <Skeleton variant="rectangular" width="80%" height="400px" sx={{
            marginTop:'10px'
          }} />  
      </Box>
          );
      }
    
     
    /* descargas QR Unico*/
    const downloadQR = async (qr) => {
      try {
          const response = await axios.post(`${backendURL}/api/download/qr/empresa`, {
              qr: qr.qr,
              id: qr.id,
              id_empresa: qr.id_empresa
          }, {
              responseType: 'blob' // Especifica que la respuesta es un blob (archivo binario)
          });
  
          if (response.status === 200) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const a = document.createElement('a');
              a.href = url;
              a.download = qr.qr; // Nombre del archivo
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
          } else {
              console.error('Error:', response.status);
          }
      } catch (error) {
          console.error('Error al enviar la solicitud:', error);
      }
  };

  const handleEstadoChange = async (userId, isChecked) => {
    const newStatus = isChecked ? 'ACTIVO' : 'INACTIVO';

    try {
        const response = await axios.get(`${backendURL}/api/estadosuscripcion/${userId}/${newStatus}`, {
            headers: {
                Authorization: `Bearer ${Token}`
            }
        });
       
    } catch (error) {
        console.error('Error al actualizar el estado:', error.response ? error.response.data : error.message);
    }
};
    return(
      <Container maxWidth='lg'>
        <Box sx={StyleBox}>
          <div className="ContieneTituloEmpresaQRAdmi">
           
            <h2>Información General Codigos Qr </h2>
          </div>
          <section className='footeraSucursalempresa'>
                 <div className="contienenqrbotones">
                    
                  <button onClick={handleOpen} className="BotonQrGenerar">
                      <img src={QrBlanco} alt="smart memorial" title="smart memorial" width={35}/>
                      <h3>Generar QR {Sucursal.nombre_sucursal}
                    </h3>
                  </button>
               
              </div>
                </section>
       
          {/* section qr generar y verificar */}
          <Box sx={StyleBoxBlanco}>
            <section className='CabeceraSucursalempresa'>
            <div className="SessionsucursarTitulo">
              <h5>Codigos QR</h5>
             </div>
            </section>
            <div className="buscadorlupaqr">
            <input
              className="inputregistroqrempresa"
              type="text"
              placeholder="Buscar código QR"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
              <div className="lupa">
                <IoSearch /> 
              </div>
            </div>
            <div style={{ height: 400, width: '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
              checkboxSelection
              disableSelectionOnClick
              sortModel={[
                {
                  field: 'id', // Reemplaza 'id' con el campo de tu columna que deseas usar para ordenar
                  sort: 'desc', // Orden descendente
                },
              ]}
              sx={{

                marginTop: '50px',
                fontFamily: 'Poppins',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
                borderRadius: '8px', // Borde redondeado (si lo deseas)
                '& .MuiDataGrid-columnHeaders': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
                  fontWeight: '600', // Estilo de fuente de la cabecera
                  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
                  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
                },
                '& .MuiDataGrid-cell': {
                  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
                  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
                },
                '& .MuiCheckbox-root': {
                  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
                },
                '& .MuiTablePagination-selectLabel':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiTablePagination-displayedRows' : {
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiInputBase-input':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .Mui-disabled':{
                    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                },
                '& .MuiSelect-icon':{
                   color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                    fontFamily:'Poppins'
                }
              }}
            />
          )}
        </div>
              
            {/* Modal que contiene el componente GeneradorQREmpresa */}
            <Modal open={open} onClose={handleClose}>
              <Box sx={modalStyle}>
                <Box sx={modalStyleClose}>
                    <button onClick={handleClose}><IoIosClose /></button>
                </Box>
                
                <GeneradorQRSucursal 
                  sucursal={Sucursal}
                  user={Usuario} 
                  QR={qrData}
                  Token={Token}
                  refreshQrData={refreshQrData}
                  close = {handleClose}
                />
              
              </Box>
            </Modal>
        </Box>
      </Box>
      </Container>
    )
}


/* estilo material UI */
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px'
  };

  const modalStyleClose = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end'
  }

  
const StyleBox ={
  width:'100%',
  
  marginTop:'30px',
  borderRadius:'10px',
  p:2,

 
}
 
const StyleBoxBlanco ={
  width:'100%',
  border:'1px solid #FFFF',
  margin:'0px',
  borderRadius:'10px',
  p:2,
  background:'#FFFF',
  margin:'10px 0px'
}
const StyleGridQR = {
  display:'flex',
  alignItems:'center',
  justifyContent:'center',

}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));