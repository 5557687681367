import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { BsPeople, BsChatSquareHeart } from "react-icons/bs";

const StepTipoReporte = ({ selectedOption, handleOptionSelect }) => {
  
  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        Selecciona el tipo de reporte a descargar
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <button
            className={`botonseleccion ${selectedOption === 'Clientes' ? 'selected' : ''}`}
            onClick={() => handleOptionSelect('Clientes')}
          >
            <BsPeople />
            Clientes
          </button>
        </Grid>
       
        <Grid item>
          <button
            className={`botonseleccion ${selectedOption === 'Comentarios' ? 'selected' : ''}`}
            onClick={() => handleOptionSelect('Comentarios')}
          >
            <BsChatSquareHeart />
            Comentarios
          </button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StepTipoReporte;


const tituloStyledescripcion = {
  fontFamily:'Poppins',
  color:'#585857',
  fontSize:'1rem',
  fontWeight:'500',
  marginBottom:'30px',
  marginTop:'30px',
  display:'flex'
}