import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../../../Style/AdministradorSuperEmpresa/LoginEmpresas.css"
import { useUserEmpresa } from "../../../context/UsuarioEmpresa";

/* Material UI */
import { Container,  Box, Grid, CircularProgress,  Typography, TextField,  InputLabel, IconButton } from '@mui/material';
import { styled } from "@mui/system";


/* Iconos React */
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';

/* imagenes */
import Logo from "../../../../Image/AdministradorSuperEmpresa/Login/logo-blanco-smart-memorial.png"
 import Portada from"../../../../Image/video/memorial-intro.gif" 

export const LoginPageEmpresas = () =>{
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAdminUserEmpresaToken, setUserInformationMarca,setUserInformationSucursal, setUserInformationUsuario, setUserInformationEmpresa} = useUserEmpresa();
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');
  
    if (!validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      setLoading(false);
      return;
    } else {
      setEmailError('');
    }
  
    try {
      const response = await fetch(`${backendURL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });
  
      if (!response.ok) {
        setPasswordError('El correo o contraseña están incorrectos');
        setLoading(false);
        return;
      }
  
      const data = await response.json();
      const { token, user, marca_empresa, empresa, sucursal } = data; // Desestructurar para obtener el token y el usuario
      
      console.log("data", data)
     
      // Guardar el token y el usuario en sessionStorage y contexto
      sessionStorage.setItem('Token', token);
      sessionStorage.setItem('User', user);
      setUserInformationMarca(marca_empresa); 
      setAdminUserEmpresaToken(token, user);
      setUserInformationUsuario(user);
      setUserInformationEmpresa(empresa);
      setUserInformationSucursal(sucursal);
     
  
      // Navegar según el rol
      if (user.id_rol == 3) {
        navigate(`/Inicio-Empresa/${user.id}`);
      } else if (user.id_rol == 4) {
        navigate(`/Inicio-Sucursal/${user.id}`);
      }
  
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("userToken");
    if (token) {
      const tokenParts = token.split('.');
      if (tokenParts.length == 3) {
        try {
          const user = JSON.parse(atob(tokenParts[1])); // Intenta decodificar el payload
          // Verifica que el usuario tenga la propiedad 'id_rol'
          if (user.id_rol) {
            if (user.id_rol == 3) {
              navigate(`/Inicio-Empresa/${user.id}`);
            } else if (user.id_rol == 4) {
              navigate(`/Inicio-Sucursal/${user.id}`);
            } else {
              navigate("/Inicio-Seccion-Empresas"); // Redirigir si no es un rol reconocido
            }
          } else {
            console.error('El token no contiene el rol del usuario.');
           
            navigate("/Inicio-Seccion-Empresas");
          }
        } catch (error) {
          console.error('Error al decodificar el token:', error);
          navigate("/Inicio-Seccion-Empresas");
        }
      } else {
        console.error('Token no es válido');
        navigate("/Inicio-Seccion-Empresas");
      }
    }
  }, [navigate]);


  const validateEmail = (email) => {
    // Expresión regular para validar correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

    return(
        <>
         <Container maxWidth="xl" sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Grid container>
                <Grid item xs={6} sx={styleGrid}> 
                   <Box sx={styleBox}>
                        <section className="LogoLoginEmpresa">
                            <img src={Logo} alt="smart memorial" title="smart memorial" />
                        </section>
                        <section className="TituloPaginaLoginEmpresa">
                            <h1 className="Bienvenido">Bienvenido a</h1>
                            <h1 className="TituloDosEmpresa"> Smart Memorial Empresa</h1>
                        </section>
                     
                        <Box
                            sx={{
                                marginTop:'30px',
                                marginBottom:'100px',
                                width: '55%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            data-aos="fade-down"
                            >

                            <Typography component="h1" variant="h5" sx={{ fontSize: '1.2rem', fontWeight: '600', color: '#D26999', fontFamily:"Poppins", marginBottom:"-10px" }}>
                                Iniciar Sesión
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                                <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '25px', width: '100%' }}>
                                <InputLabel className="label-login" htmlFor="email" sx={{fontFamily:"Poppins", fontWeight:'500', position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                                    Correo electrónico
                                </InputLabel>
                                    <StyledTextField
                                        margin="normal"
                                        required
                                        id="email"
                                        name="email"
                                        autoComplete="email"
                                        className={`input-login ${emailError && 'input-error'}`}
                                        InputProps={{
                                        sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color: "#694B81" },
                                        style: { border: emailError ? '1px solid #D26999' : '3px solid ##F0EDF3', }
                                        }}
                                        sx={{ marginBottom: '10px' }}
                                    />

                            </Box>

                            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '10px', width: '100%' }}>
                                <InputLabel className="labellogin" htmlFor="password" sx={{fontFamily:"Poppins", fontWeight:'500', position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                                    Contraseña
                                </InputLabel>
                                <StyledTextFields
                                    className={`input-login ${passwordError && 'input-error'}`}
                                    margin="normal"
                                    required
                                    name="password"
                                    id="password"
                                    autoComplete="current-password"
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                    sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color: "#694B81" },
                                    endAdornment: (
                                        <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                        className="password-toggle-btn"
                                        >
                                        {showPassword ? <FaRegEyeSlash className="eye-icon" /> : <FaRegEye className="eye-icon" />}
                                        </IconButton>
                                    )
                                    }}

                                />

                            </Box>
                            <div className="contiene-error-mensaje">  {passwordError && <span className="errormessagelogin"><FiAlertCircle className='icon-error' />{passwordError}</span>} </div>

                            <div className="contienenbotons-login">
                                <button
                                    type="submit"

                                    variant="contained"
                                    sx={{ mt: 3, mb: 2, background: '#694B81', borderRadius: '40px', padding: '10px 60px', fontWeight: '600', marginBottom: '30px' }}
                                >
                                    {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Iniciar Sesión'}
                                </button>

                                <Grid container>
                                    <Grid item xs>
                                        <Link to="/" className="link" >
                                            <h5 className="Olvidar">He olvidado mi contraseña</h5>
                                        </Link>
                                    </Grid>

                                </Grid>
                         </div>
                        </Box>
                        </Box> 
                    </Box> 
                </Grid>
                <Grid item xs={6}  sx={styleGridDos}>
                     <Box sx={styleBox}> 
                       
                            <img src={Portada}className="ImagenPortadaLoginEmpresa" alt="smart memorial" title="smart memorial"/>
                         
                     </Box>
                </Grid>
            </Grid>
           
         </Container>
        
        
        </>
    )
}

/* estilos material UI */
const styleGrid = {
    maxWidth:'100%',
    borderTopLeftRadius: '10px',  // Radio en la esquina superior izquierda
    borderBottomLeftRadius: '10px', 
    boxShadow: 24,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    margin:'10px 0px',
};
const styleGridDos = {
    display:'flex',
    alignItems:'center',
    justifyContent:'center', 
    borderRadius:'10px',

};

const styleBox = {
    width: '100%',    // O el porcentaje o píxeles que prefieras
    height: '700px',  // Fija un alto específico para el contenedor
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection:'column',
    borderRadius:'10px'
};


const StyledTextField = styled(TextField)({
    width: '100%',
  
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
    '& .MuiInputBase-root': {
      transition: 'all 0.3s ease',
      color: "#694B81",
      fontFamily: "Poppins",
      fontSize: '0.9rem',
      fontWeight: "500",
    },
    '& .MuiInputBase-root.Mui-focused': {
      border: ' 1px solid #585857', // Cambia esto al color que quieras
  
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: ' 1px solid #585857',  // Cambia esto al color que quieras
    },
  });
  
  const StyledTextFields = styled(TextField)({
    width: '100%',
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
  
    '& .MuiInputBase-root': {
      transition: 'all 0.3s ease',
      color: "#694B81",
      fontFamily: "Poppins",
      fontSize: '0.9rem',
      fontWeight: "500",
    },
    '& .MuiInputBase-root.Mui-focused': {
      bborder: ' 1px solid #585857', // Cambia esto al color que quieras
      color: "#694B81",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: ' 1px solid #585857',  // Cambia esto al color que quieras
      color: "#694B81",
    },
  });