import React, { useEffect, useState } from "react";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout";
import { Container, Grid, Box, CircularProgress, Modal,  Card, CardContent, Typography} from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { LineChart } from '@mui/x-charts/LineChart';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { DataGrid } from '@mui/x-data-grid'; 
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from "axios";
import { AgregarSucursalAdmin } from "./../../ComponenteEstructura/AgregarAdminSucursal";
import { useNavigate} from 'react-router-dom';
/* react icons */
import { IoIosClose } from "react-icons/io";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import StoreIcon from '@mui/icons-material/Store';

const gaugeSettings = {
    width: 100,
    height: 100,
    cornerRadius: "50%",
    startAngle: 0,
    endAngle: 360,
  };

export const PaginaTodasSucursales = ({setShowNavbarFooter}) => {
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [sucursales, setSucursales] = useState([]);
    const [gruposFamiliaresCount, setGruposFamiliaresCount] = useState(0);
    const [gruposFamiliaresCountAsignados, setGruposFamiliaresCountAsignados] = useState(0);
    const [gruposFamiliaresCountDisponibles, setGruposFamiliaresCountDisponibles] = useState(0);
    const [sucursalesCount, setSucursalesCount] = useState(0);
    const [openCreateAdmin, setOpenCreateAdmin] = useState(false); 
    const [gruposFamiliaresCountConIdUsuario, setGruposFamiliaresCountConIdUsuario] = useState([]);
    const [loadings, setLoadings] = useState(true);
    const navigate = useNavigate()
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [selectedSucursal, setSelectedSucursal] = useState(null);
    const theme = useTheme();
   
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

     // Traer las sucursales cuando la empresa y el token estén disponibles
  const fetchSucursales = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true);
    try {
      const response = await axios.get(`${backendURL}/api/Sucursales/empresa/${Empresa.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setSucursales(response.data.sucursales); // Establece las sucursales obtenidas
    } catch (error) {
      console.error("Error fetching sucursales:", error);
    } finally {
      setLoadings(false);
    }
  };

  // Llamar a fetchSucursales cuando los datos de la empresa y el token cambien
  useEffect(() => {
    if (Empresa && Token) {
      fetchSucursales();
    }
  }, [Empresa, Token]);

  useEffect(() => {
    if (sucursales.length > 0) {
      const totalGruposFamiliares = sucursales.reduce((acc, sucursal) => {
        return acc + sucursal.grupos_familiares.length; // Sumar la longitud de grupos_familiares de cada sucursal
      }, 0);
      setGruposFamiliaresCount(totalGruposFamiliares); // Establecer el conteo total
    }
  }, [sucursales]);
  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar el número total de grupos familiares
      const totalGruposFamiliares = sucursales.reduce((acc, sucursal) => {
        return acc + sucursal.grupos_familiares.length;
      }, 0);
      setGruposFamiliaresCount(totalGruposFamiliares); // Establecer el conteo total de grupos familiares
    }
  }, [sucursales]);

  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar el número total de grupos familiares
    
      // Contar solo los grupos familiares con id_usuario
      const gruposConIdUsuario = sucursales.reduce((acc, sucursal) => {
        const gruposConId = sucursal.grupos_familiares.filter(grupo => grupo.id_usuario).length;
        return acc + gruposConId;
      }, 0);

      // Establecer el estado con los resultados
     setGruposFamiliaresCountAsignados(gruposConIdUsuario)
    }
  }, [sucursales]);

  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar los grupos familiares con id_usuario vacío
      const gruposConIdUsuarioVacio = sucursales.reduce((acc, sucursal) => {
        const gruposSinId = sucursal.grupos_familiares.filter(grupo => !grupo.id_usuario).length;
        return acc + gruposSinId;
      }, 0);

      // Establecer el estado con los resultados
      setGruposFamiliaresCountDisponibles(gruposConIdUsuarioVacio);  // Número de grupos familiares sin id_usuario
     
    }
  }, [sucursales]);
  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar los grupos familiares con id_usuario asignado y mostrar el nombre de la sucursal
      const gruposConIdUsuario = sucursales.reduce((acc, sucursal) => {
        // Filtrar los grupos familiares con id_usuario
        const gruposConId = sucursal.grupos_familiares.filter(grupo => grupo.id_usuario).length;
        // Si hay grupos con id_usuario, agregar el nombre de la sucursal y el conteo
        if (gruposConId > 0) {
          acc.push({
            nombreSucursal: sucursal.nombre_sucursal,
            gruposConIdUsuario: gruposConId,
            createdAt: sucursal.created_at
          });
        }
        return acc;
      }, []);

      // Establecer el estado con los resultados
      setGruposFamiliaresCountConIdUsuario(gruposConIdUsuario);
    }
  }, [sucursales]);

  
  const maxValues = {
    sucursales: 20, // Máximo de sucursales creadas
    qrGenerados: 2000, // Máximo de QR generados
    qrAsignados: 2000, // Máximo de QR asignados
    qrDisponibles: 2000, // Máximo de QR disponibles
  };
  
  // Cálculo del porcentaje
  const calculatePercentage = (current, max) => Math.min((current / max) * 100, 100);
  const data = [
   
    {
      title: "QR Generados por Sucursales",
      value: gruposFamiliaresCount,
      gaugeValue: calculatePercentage(gruposFamiliaresCount, maxValues.qrGenerados),
    },
    {
      title: "QR Asignados por Sucursales",
      value: gruposFamiliaresCountAsignados,
      gaugeValue: calculatePercentage(gruposFamiliaresCountAsignados, maxValues.qrAsignados),
    },
    {
      title: "QR Disponibles",
      value: gruposFamiliaresCountDisponibles,
      gaugeValue: calculatePercentage(gruposFamiliaresCountDisponibles, maxValues.qrDisponibles),
    },
  ];
    

      const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'Sucursal', headerName: 'Sucursal', width: 200, editable: true },
        { field: 'QR', headerName: 'QR asignados', width: 150, editable: true },
        {
          field: 'Estado',
          headerName: 'Estado',
          width: 150,
          renderCell: (params) => {
            const estado = params.row.Estado;
      
            return (
              <Button
                variant="contained"
                color={estado === 'A' ? 'success' : 'error'}
                startIcon={estado === 'A' ? <CheckIcon /> : <CancelIcon />}
                sx={{
                  textTransform: 'none',
                  color:'#ffff',
                  backgroundColor: estado === 'A' ? 'green' : 'red',
                  '&:hover': {
                    backgroundColor: estado === 'A' ? 'darkgreen' : 'darkred',
                  },
                }}
              >
                {estado === 'A' ? 'Activo' : 'Inactivo'}
              </Button>
            );
          },
        },
        { field: 'Direccion', headerName: 'Dirección', width: 150, editable: true },
        { field: 'Pais', headerName: 'País', width: 150, editable: true },
        { field: 'Region', headerName: 'Región', width: 150, editable: true },
        { field: 'Comuna', headerName: 'Comuna', width: 150, editable: true },
        { field: 'Telefono', headerName: 'Telefono', width: 150, editable: true },
        { field: 'Correo', headerName: 'Correo', width: 150, editable: true },
        {
          field: 'Accion Sucursal',
          headerName: 'Accion Sucursal',
          width: 200,
          editable: true,
          renderCell: (params) => {
            const Sucursal = params.row.Sucursal;
      
            return (
              <div className="botonesSucursales">
                <button className="BotonMemorial" onClick={() => handleVerSucursal(params.row)}>
                  Ver Sucursal
                </button>
              </div>
            );
          },
        },
        {
          field: 'Accion Sucursal Administrador',
          headerName: 'Accion Sucursal Administrador',
          width: 300,
          editable: true,
          renderCell: (params) => {
            const Sucursal = params.row.Sucursal;
      
            return (
              <div className="botonesSucursales">
                <button className="BotonMemorialadmin" onClick={() => formOpenAdminModal(params.row)}>
                  Agregar Administrador
                </button>
              </div>
            );
          },
        },
      ];
      
      
      // Ajustar los datos de sucursales con el conteo de QR asignados
      const rows = sucursales.map((sucursal) => {
        // Encuentra los datos de gruposConIdUsuario por el nombre de la sucursal
        const grupo = gruposFamiliaresCountConIdUsuario.find(
          (grupo) => grupo.nombreSucursal === sucursal.nombre_sucursal
        );
      
        return {
          id: sucursal.id,
          Sucursal: sucursal.nombre_sucursal,
          QR : grupo ? grupo.gruposConIdUsuario : 0, // Si no se encuentra, asigna 0
          Estado: sucursal.estado,
          Direccion: sucursal.direccion,
          Pais: sucursal.pais,
          Region: sucursal.region,
          Comuna: sucursal.comuna,
          Telefono: sucursal.telefono,
          Correo: sucursal.correo, 
          'Accion Sucursal': 'Acción',
          'Accion Sucursal Administrador': 'Acción Admin',
        };
      });
      /* botones */
        /* navegacion */
        const handleNavigateCrearSucursal = () => {
            navigate(`/empresa/${Usuario.id}/crear-sucursal`, {
                state: { Usuario, Empresa, Token},
            });
        };
    
        const handleVerSucursal = (sucursales) => {
          // Navegamos a la ruta deseada y pasamos la información
          navigate(`/sucursales/${Usuario.id}/empresa`, { state: { sucursales, Usuario, Empresa, Token } });
        };

        const formOpenAdminModal = (sucursal) => {
            setSelectedSucursal(sucursal);  // Guardar los datos en el estado
            setOpenCreateAdmin(true);       // Abrir el modal
          };
          
          const formCloseAdminModal =()   => setOpenCreateAdmin(false);
    
    
  
    return(
        <EmpresaLayout>
        <Container maxWidth="lg">
        <div style={{
          padding:'0px 100px',
          display:"flex",
          alignItems:'center',
          justifyContent:'center',flexDirection:'column'
        }} >
                <Box sx={{ marginTop:'50px' }}>
                <Typography variant="h5" gutterBottom sx={{ fontFamily:'Poppins', fontWeight:600, fontSize:'1rem', marginBottom:'10px', color:'#585857' }}>
                Sucursales {Empresa?.nombre_social}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} >
                  <Card sx={{
                    
                    
                    boxShadow: 3, // Nivel de sombra (puede ser un número de 0 a 24)
                    '&:hover': {
                        boxShadow: 6, // Aumenta la sombra al hacer hover sobre la tarjeta
                        }
                    }}
                  
                    
                    >
                        <CardContent>
                        <Grid container alignItems="center"
                         sx={{
                          marginTop:'15px',
                                  
                           }}>
                          
                            <Grid item xs={12} sm={4}>
                              <div style={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                               
                              }}>
                                 <IconButton>
                              <StoreIcon style={{
                                fontSize:'4.5rem',
                                color: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                              }} />
                            </IconButton>
                            </div>
                            </Grid>
                            {/* Texto en el lado derecho */}
                            <Grid item xs={12} sm={8} sx={{ paddingLeft:'10px'}}>
                            <Typography variant="h6"sx={{ fontFamily:'Poppins', fontSize:'1rem', lineHeight:'1', }} >Sucursales Creadas</Typography>
                            <Typography variant="h6" sx={{ fontFamily:'Poppins', marginTop:'5px', fontSize:'1.3rem', }}>{sucursalesCount}</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                  </Grid>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6}  key={index}>
                    <Card sx={{
                   
                    boxShadow: 3, // Nivel de sombra (puede ser un número de 0 a 24)
                    '&:hover': {
                        boxShadow: 6, // Aumenta la sombra al hacer hover sobre la tarjeta
                        }
                    }}
                  
                    
                    >
                        <CardContent>
                        <Grid container alignItems="center">
                            {/* Gauge en el lado izquierdo */}
                            <Grid item xs={12} sm={4}>
                            <Gauge
  {...gaugeSettings}
  value={item.gaugeValue}
  sx={(theme) => ({
    '& .e2oxsm-MuiGauge-container': {
      color: '#FF0000', // Cambia el color
      backgroundColor: '#F0F0F0', // Cambia el fondo
    },
    '.MuiTypography-root': {
      fontFamily: 'Poppins',
      color: '#585857',
    },
    [`& .${gaugeClasses.valueText}`]: {
      fontSize: 16,
      fontFamily: 'Poppins',
      color: '#585857',
    },
    [`& .${gaugeClasses.valueArc}`]: {
      fill: "#52b202", // Color del área llena
      fontFamily: 'Poppins',
    },
    [`& .${gaugeClasses.referenceArc}`]: {
      fill: theme.palette.text.disabled, // Color de referencia
      fontFamily: 'Poppins',
    },
    [`& .${gaugeClasses.track}`]: {
      fill: "#585857", // Cambia este color por el que desees para el área vacía
      fontFamily: 'Poppins',
    },
  })}
/>
                            </Grid>
                            {/* Texto en el lado derecho */}
                            <Grid item xs={12} sm={8} sx={{ paddingLeft:'10px'}}>
                            <Typography variant="h6"sx={{ fontFamily:'Poppins', fontSize:'1rem', lineHeight:'1',}} >{item.title}</Typography>
                            <Typography variant="h6" sx={{ fontFamily:'Poppins', marginTop:'5px', fontSize:'1.3rem', }}>{item.value}</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    </Grid>
                ))}
                </Grid>
            </Box>
        </div>
        <Box sx={{
            margin:'50px 0px',
            width:'80%',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
        }}> 
            <Grid container sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
        }}>
                <Grid item xs={12} sm={6}  sx={{
            display:'flex',
            alignItems:'flex-end',
            justifyContent:'flex-end',
            paddingRight:' 50px'
        }}>
                   
                        <div className="cardsucursalcrear" onClick={handleNavigateCrearSucursal}>
                            <AddBusinessIcon sx={{ color:'#ffff', fontFamily:'2rem'}}/>
                            <h5>Crear Sucursal</h5>
                        </div>
                    
                </Grid>
                <Grid item xs={12} sm={6}  sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
        }}>
                    <h4 className="textocrearsucursal">Aquí puedes crear una nueva sucursal para expandir tu empresa de manera eficiente. 
                        Registra la información clave como nombre, ubicación y estado de la sucursal. 
                        Controla y administra fácilmente todas tus sucursales desde un solo lugar.
                    </h4>
                </Grid>
            </Grid>
        
        </Box>
        <div style={{
            display:'flex',
            alignItems:'center',
             justifyContent:"center" 
        }} >
        <Box sx={{ height: 400, width: '900px' }}>     
          <DataGrid rows={rows} columns={columns} pageSizeOptions={[5, 10]} checkboxSelection  sx={{

marginTop: '50px',
fontFamily: 'Poppins',
boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
borderRadius: '8px', // Borde redondeado (si lo deseas)
'& .MuiDataGrid-columnHeaders': {
  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
  fontWeight: '600', // Estilo de fuente de la cabecera
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
  borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
},
'& .MuiDataGrid-cell': {
  color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
},
'& .MuiCheckbox-root': {
  color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
},
'& .MuiTablePagination-selectLabel':{
    color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
    fontFamily:'Poppins'
}
}} />
    </Box>
        </div>
        <Modal open={openCreateAdmin} onClose={formCloseAdminModal}>
            <Box sx={modalStyle}>
              <Box sx={modalStyleClose}>
              <div className="editusuariosucursal">
                      <IoIosClose style={{ fontSize: '2.3rem' }} onClick={formCloseAdminModal} />
                  </div>
               
              </Box>
              <AgregarSucursalAdmin sucursal={selectedSucursal} token={Token} formCloseAdminModal={formCloseAdminModal} />
            </Box>
          </Modal>
        </Container>
        </EmpresaLayout>
    )
}

/* estilo material UI */
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius:'10px',
    height:'650px',
    overflowY:'auto'
  };

  const modalStyleClose = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end'
  }