import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../../Style/Registro/validacion.css";
import '../../../Style/generales/button-retroceso.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiAlertCircle } from "react-icons/fi";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useUsers } from "../../context/userscontexts";
import useMediaQuery from '@mui/material/useMediaQuery';


export const Validacioncorreo = () => {
  const { ClientToken, setUserToken } = useUsers();  
  const location = useLocation();
  const { email } = location.state || {};
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const [isCodigoValido, setIsCodigoValido] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  const handleLogout = () => {
    setUserToken(null);
  
 sessionStorage.removeItem('ClientToken');
    navigate("/");
  };

  const handleChangeCodigo = (event) => {
    const codigoIngresado = event.target.value;
    setCodigo(codigoIngresado);
    setError("");
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${backendURL}/api/activeUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          codigo,
          email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al activar el usuario');
      }
  
      const data = await response.json();
      setIsCodigoValido(true);
      setOpenModal(true);
      setTimeout(async () => {
        handleCloseModal();
      }, 3000);
      handleLogout();
    } catch (error) {
      console.error(error);
      setError('Error al activar el usuario');
      setIsCodigoValido(false);
      handleLogout();
    }
  };
 

  const handleResendCode = async () => {
    handleOpenAlert();
    try {
      const response = await fetch(`${backendURL}/api/resendActivationCode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al reenviar el código');
      }
  
      const data = await response.json();

    } catch (error) {
      console.error(error);
      setError('Error al reenviar el código');
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    handleLogout();
    navigate('/' );
  };


  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  useEffect(() => {
    if (openAlert) {
      // Establecer un temporizador para cerrar el alert después de dos segundos
      const timer = setTimeout(() => {
        handleCloseAlert();
      }, 2000); // 2000 milisegundos = 2 segundos
  
      // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
      return () => clearTimeout(timer);
    }
  }, [openAlert]);

  const isSmallScreen = useMediaQuery('(max-width: 400px)');

  // Establecer el valor de maxWidth en función del tamaño de la pantalla
  const maxWidth = isSmallScreen ? 200 : 400;

  return (
    <>
      <section className="registro-cuerpo-inicio">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            position: "relative",
            marginTop:"40px",
            padding:"0px 10px"
          }}
        >
          <section className="titulo-validacion">
            <h1>Solicita reenviar</h1>
            <h1>código de confirmación</h1>
            <h1>al correo electrónico</h1>
            <h1>si no lo recibiste la primera vez</h1>
            <hr style={{ border: '0px', padding: '10px' }} />
            <h1><strong>{email}</strong></h1>
          </section>

          <section className="validar-codigo">
            <h1>Ingrese el código</h1>
            <input
              className={`validacion-input ${error ? 'input-error' : ''}`}
              value={codigo}
              onChange={handleChangeCodigo}
            />
            {error && <span className="error-message"><FiAlertCircle className='icon-error' />{error}</span>}
            <span>Solicita un nuevo código</span>
            <button style={{background:'white', border:'0px'}} onClick={handleResendCode}>
              <p>Reenviar correo</p>
            </button>
          </section>

          <Modal
            open={openAlert}
            onClose={handleCloseAlert}
            aria-labelledby="alert-modal-title"
            aria-describedby="alert-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                width: '200px',
                maxWidth: maxWidth,
                bgcolor: "#fff",
                border: "0px",
                p: 4,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
               <Typography id="modal-title" variant="h6" component="h4" sx={{color:'#694B81', textAlign:'center', display:'flex', alignItems:'center', justifyContent:"center", fontSize:'1rem'}}>
              Código enviado correctamente
              </Typography>
            </Box>
          </Modal>

          <div className="boton-registro">
            <button onClick={handleSubmit}>Confirmar</button>
          </div>
        </Box>
      </section>

      <Modal
        open={openModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '200px',
            bgcolor: 'background.paper',
            border: '1px solid #000',   
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h4" sx={{color:'#694B81', textAlign:'center',  display:'flex', alignItems:'center', justifyContent:"center", fontSize:'1rem'}}>
            Código verificado correctamente
          </Typography>
        </Box>
      </Modal>
    </>
  );
};