 export const datadireccion = {
    pais: {
        Chile: {
          region: {
            "Arica y Parinacota": {
              comuna: ["Arica", "Camarones", "Putre", "General Lagos"],
            },
            Tarapacá: {
              comuna: ["Iquique", "Alto Hospicio", "Pozo Almonte", "Pica", "Huara", "Camiña", "Colchane"],
            },
            Antofagasta: {
              comuna: ["Antofagasta", "Calama", "Tocopilla", "Mejillones", "Taltal", "Sierra Gorda", "María Elena"],
            },
            Atacama: {
              comuna: ["Copiapó", "Caldera", "Tierra Amarilla", "Chañaral", "Diego de Almagro", "Vallenar", "Huasco", "Freirina"],
            },
            Coquimbo: {
              comuna: ["La Serena", "Coquimbo", "Andacollo", "La Higuera", "Vicuña", "Illapel", "Los Vilos", "Salamanca", "Ovalle", "Monte Patria", "Punitaqui", "Combarbalá"],
            },
            "Valparaíso": {
              comuna: ["Valparaíso", "Viña del Mar", "Concón", "Quintero", "Puchuncaví", "Quillota", "La Calera", "Hijuelas", "Nogales", "Olmué", "San Antonio", "Cartagena", "El Quisco", "El Tabo", "Santo Domingo", "Los Andes", "San Felipe", "Putaendo", "Santa María"],
            },
            "Región Metropolitana": {
              comuna: ["Santiago",
                "Indenpendencia",
                "Recoleta",
                "Providencia",               
                "Ñuñoa",
                "Las Condes",
                "La Florida",
                "Peñalolén",
                "Macul",
                "La Granja",
                "La Cisterna",
                "San Miguel",
                "San Joaquín",
                "San Carlos de Apoquindo",
                "San Ramón",
                "El Bosque",
                "Huechuraba",
                "Conchalí",
                "Quilicura",
                "Pudahuel",
                "Cerrillos",
                "Cerro Navia",
                "Maipú",
                "Renca",
                "Estación Central",
                "Santiago Centro",
                "La Pintana",
                "Puente Alto",
                "San Bernardo",
                "San Vicente",
                "Buin",
                "Paine",
                "Melipilla",
                "Curacaví",
                "Lampa",
                "Colina",
                "Tiltil",
                "Lo Barnechea",
                "Las Condes",
                "Vitacura",
                "La Reina",
                "Lo Prado",
                "Peñalolén",
                "Ñuñoa",
                "San Isidro",
                "San José de Maipo",
                "San Pedro",
                "San Francisco de Mostazal",
                "San Sebastián",
                "San Gregorio",
                "San Miguel de Allende",
                "San Rafael",
                "San Juan de la Costa",
            ]},
            "O'Higgins": {
              comuna: ["Rancagua", "Machalí", "Graneros", "Requínoa", "Mostazal", "San Vicente", "Rengo", "Peumo", "Pichidegua", "Las Cabras", "San Fernando", "Santa Cruz", "Chépica", "Nancagua", "Pichilemu", "Marchigüe", "Navidad", "Litueche", "Paredones"],
            },
            Maule: {
              comuna: ["Talca", "Curicó", "Linares", "Maule", "Constitución", "San Clemente", "Cauquenes", "Parral", "Teno", "San Javier", "Longaví", "Romeral", "Villa Alegre", "Molina", "Río Claro", "Sagrada Familia", "Yerbas Buenas"],
            },
            Ñuble: {
              comuna: ["Chillán", "Chillán Viejo", "Quirihue", "Bulnes", "Coihueco", "El Carmen", "Ninhue", "Pemuco", "San Carlos", "San Fabián", "San Ignacio", "Yungay", "Pinto"],
            },
            Biobío: {
              comuna: ["Concepción", "Coronel", "Lota", "San Pedro de la Paz", "Talcahuano", "Hualpén", "Penco", "Tomé", "Chiguayante", "Florida", "Hualqui", "Laja", "Los Ángeles", "Nacimiento", "Negrete", "Quilaco", "Santa Bárbara", "Tucapel", "Yumbel"],
            },
            "La Araucanía": {
              comuna: ["Temuco", "Villarrica", "Pucón", "Angol", "Victoria", "Nueva Imperial", "Carahue", "Cunco", "Curacautín", "Ercilla", "Freire", "Galvarino", "Gorbea", "Lautaro", "Loncoche", "Lonquimay", "Los Sauces", "Melipeuco", "Padre Las Casas", "Perquenco", "Pitrufquén", "Renaico", "Saavedra", "Teodoro Schmidt", "Tolten", "Traiguén", "Vilcún", "Curarrehue", "Cholchol"],
            },
            "Los Ríos": {
              comuna: ["Valdivia", "La Unión", "Río Bueno", "Futrono", "Lago Ranco", "Panguipulli", "Paillaco", "Corral", "Los Lagos", "Mariquina", "Lanco"],
            },
            "Los Lagos": {
              comuna: ["Puerto Montt", "Puerto Varas", "Osorno", "Castro", "Ancud", "Chaitén", "Quellón", "Dalcahue", "Quinchao", "Hualaihué", "Fresia", "Frutillar", "Llanquihue", "Palena", "Maullín", "Puqueldón"],
            },
            Aysén: {
              comuna: ["Coyhaique", "Puerto Aysén", "Cisnes", "Cochrane", "Chile Chico", "Río Ibáñez", "Lago Verde", "O'Higgins", "Tortel"],
            },
            Magallanes: {
              comuna: ["Punta Arenas", "Puerto Natales", "Porvenir", "Primavera", "Torres del Paine", "Cabo de Hornos", "Antártica", "Timaukel", "Laguna Blanca", "Río Verde", "San Gregorio"],
            }
          }
        }
      }
    }