import React from "react";
import "../../../../../../Style/usuario/crear-familiar.css";
import "../../../../../../Style/usuario/cometarioyfondo.css";
import { useLocation, useNavigate, useParams} from "react-router-dom";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { useState, useEffect } from 'react'
import { Container, Modal } from "@mui/material";
import axios from 'axios';
import Box from "@mui/material/Box";
import { IoIosMail } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { BsTrash3Fill } from "react-icons/bs";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FiPlusCircle } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { CircularProgress } from '@mui/material';
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { styled } from '@mui/system';
import 'react-quill/dist/quill.snow.css';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { IoPlayCircleOutline } from 'react-icons/io5';


const StyledMenuItem = styled(MenuItem)(({ theme, error }) => ({
  color: "#585857",
  fontFamily: "Poppins",
  fontSize: "0.8rem",
  '& .MuiTypography-root': {
    fontSize: "0.8rem",
  }

}))


export const MensajeDemoEmpresaMemorial =({ memorialId, empresa, user, grupoFamiliar, Token, onNextStep }) =>{
    
    const navigate = useNavigate();
    const {UserDataMarca} = useUserEmpresa();
    const { id } = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [comentarios, setComentarios] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModals, setOpenModals] = useState(false);
    const [commentToDelete, setCommentToDelete] = useState(null);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [showAlertcrear, setShowAlertcrear] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [formData, setFormData] = useState({
      nombre: '',
      titulo: '',
      descripcion: '',
      imagen: null
    });

    /* marca */
    const [marcas, setMarcas] = useState([]);
    const [error, setError] = useState(null);
    const [hayColores, setHayColores] = useState(false);
    const [colores, setColores] = useState({ // Estado para almacenar los colores
      color_1: '',
      color_2: '',
      color_3: '',
      color_4: '',
    });
    
    useEffect(() => {
        if (grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length === 1) {
          setShowAlertcrear(true);
        } else {
          setShowAlertcrear(false);
        }
      }, [grupoFamiliar]);
    
      const handleVideoModalOpenvideo = (e) => {
        e.preventDefault();
        setOpenVideoModal(true);
      };
    
      const handleVideoModalClose = () => {
        setOpenVideoModal(false);
      };
    
      const handleVideoURLChange = (e) => {
        setVideoURL(e.target.value);
      };
    
      const handleSaveVideoURL = () => {
        if (videoURL) {
          const videoLink = `<a href="${videoURL}" target="_blank"> <IoPlayCircleOutline /> Ver Video</a>`;
          const updatedDescription = formData.descripcion + videoLink;
          setFormData(prevFormData => ({
            ...prevFormData,
            descripcion: updatedDescription
          }));
          setVideoURL('');
          setOpenVideoModal(false);
        }
      };
    
      const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        updateMemorial(selectedValue);
      };
    
      const togglePopup = () => {
        setShowPopup(!showPopup);
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleImageChange = (e) => {
        setFormData({
          ...formData,
          imagen: e.target.files[0]
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const { nombre, titulo, descripcion, imagen } = formData;
    
        const formDataToSend = new FormData();
        formDataToSend.append('titulo', titulo);
        formDataToSend.append('creador', nombre);
        formDataToSend.append('mensaje', descripcion);
        formDataToSend.append('imagen', imagen);
        formDataToSend.append('id_memorial', memorialId);
    
        try {
          setLoading(true)
          setenviandoAlert(true);
          const response = await axios.post(`${backendURL}/api/comentarioStoredemo`, formDataToSend, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${Token}`
            }
          });
    
          await axios.post(`${backendURL}/api/comentarioStatedemo`, {
            id: response.data.id, visible: 1
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${Token}`
            }
          });
          setLoading(false);
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Su mensaje fue creado con éxito.')
          setComentarios([...comentarios, response.data]);
          setFormData({
            nombre: '',
            titulo: '',
            descripcion: '',
            imagen: null
          });
    
          togglePopup();
    
    
        } catch (error) {
          setErrorAlertOpen(true);
          setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
        }
        finally {
          setenviandoAlert(false);
        }
    
        // Simulación de tiempo de carga
        setTimeout(() => {
          handleCloseModal();
        }, 3000);
        // Simulación de tiempo de carga
        setTimeout(() => {
          setSuccessAlertOpen(false);
          setErrorAlertOpen(false);
        }, 2000);
    
      };
    
      const handleOpenModalsnew = (comentario) => {
        setCommentToDelete({ id: comentario.id, nombre: comentario.creador });
        setOpenModals(true);
      };
    
      const handleOpenModal = () => {
    
        setOpenModal(true);
      };
    
      const handleCloseModals = () => {
        setOpenModals(false);
        setCommentToDelete(null);
      };
    
      const handleDeleteComment = async (id) => {
        try {
          setenviandoAlert(true);
          await axios.post(
            `${backendURL}/api/comentarioDeletedemo`,
            { id: id },
            {
              headers: {
                'Authorization': `Bearer ${Token}`
              }
            }
          );
          setenviandoAlert(false);
          setSuccessAlertOpen(true);
          setAlertMessage('Su mensaje fue eliminado con éxito.')
          setLoading(false);
          handleCloseModals();
          const updatedComentarios = comentarios.filter(comentario => comentario.id !== id);
          setComentarios(updatedComentarios);
    
          setFormData({
            nombre: '',
            titulo: '',
            descripcion: '',
            imagen: null
          });
    
    
        } catch (error) {
          setErrorAlertOpen(true);
          setAlertMessage('Error al eliminar el mensaje, vuelva a intentar.');
        }
        finally {
          setenviandoAlert(false);
        }
    
        // Simulación de tiempo de carga
        setTimeout(() => {
          handleCloseModal();
        }, 3000);
        // Simulación de tiempo de carga
        setTimeout(() => {
          setSuccessAlertOpen(false);
          setErrorAlertOpen(false);
        }, 2000);
      };
    
      const handleCloseModal = () => {
        setOpenModal(false);
        setenviandoAlert(false);
      };
    
      const updateMemorial = async (selectedOption) => {
        try {
          const response = await axios.post(`${backendURL}/api/updateMemorialdemofondo/${memorialId}`,
            { fondo: selectedOption },
            {
              headers: {
                'Authorization': `Bearer ${Token}`
              }
            }
          );
    
    
        } catch (error) {
          console.error('Error al editar el memorial:', error);
        }
      };
    
      useEffect(() => {
        const fetchComentarios = async () => {
          try {
            const response = await axios.get(`${backendURL}/api/getMemorialIddemo/${memorialId}`, {
              headers: {
                'Authorization': `Bearer ${Token}`
              }
            });
    
            // Verificar si response.data.comentarios es un array antes de establecer el estado
            if (Array.isArray(response.data.comentarios)) {
              setComentarios(response.data.comentarios);
            } else {
              console.error('La respuesta no contiene comentarios válidos:', response.data);
            }
    
            // Establecer el valor predeterminado si response.data.fondo es null o undefined
            const defaultFondo = response.data.fondo || 'clasicoSmartMemorial';
            setSelectedOption(defaultFondo);
          } catch (error) {
            console.error('Error al obtener comentarios:', error);
          }
        };
    
        fetchComentarios();
      }, [Token, memorialId]);

      const handleClickfinal = () => {
        navigate(`/ver/demo/memorial/empresa/${id}`, {
          state: { user, empresa, grupoFamiliar },
        });
      };
    
      
      const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      /* marca  */
    const fetchMarcas = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/marcaempresa/${empresa.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Token}`,
          },
        });
        const fetchedMarca = response.data.data[0];
        setMarcas(fetchedMarca);
        if (fetchedMarca) {
          // Comprueba si los colores son 'undefined' y los establece
          const nuevosColores = {
            color_1: fetchedMarca.color_1 || '',
            color_2: fetchedMarca.color_2 || '',
            color_3: fetchedMarca.color_3 || '',
            color_4: fetchedMarca.color_4 || '',
          };
    
          // Verificar si hay colores definidos
          setHayColores(!!nuevosColores.color_1 || !!nuevosColores.color_2 || !!nuevosColores.color_3 || !!nuevosColores.color_4);
          
          // Si deseas también almacenar los nuevos colores en el estado, puedes hacerlo aquí
          setColores(nuevosColores);
        }
      } catch (err) {
        setError(err.response.data.message || 'Error al cargar marcas');
      }
    };

  useEffect(() => {
      if (empresa.id) {
          fetchMarcas();
      }
  }, [empresa.id]);
    
    return(
        <>
        <Container component="main" maxWidth="xs">
        

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >

          <div className="pasosmemorial">
            <div className="titulopasos"><h1>Paso Mensajes:</h1>
              <span>Agregue mensajes para su memorial</span>
            </div>
          </div>
          {showAlertcrear && (
            <div className="alertaeditarmemorial">
              <BiErrorCircle />
              <div className="infoalertamemorial">
                <h4>Si usted no posee la información necesaria en este</h4>
                <h4>momento, puede editar el memorial más tarde</h4>
              </div>
            </div>
          )}
          <section className="subtituloinfocrearmensaje ">
            <div className="titulomensajecrear"><IoIosMail /> <h2 style={{ fontSize: '1rem' }} id="modal-title"> Mensajes</h2></div>
            <span>Comparte un recuerdo incluyendo un enlace de video en tu mensaje. Simplemente haz clic en el botón "Agregar video", ingresa la URL del video que deseas compartir y selecciona "Guardar". El enlace aparecerá en tu mensaje como "Ver Video", permitiendo a otros ver tu video mientras leen tu mensaje. </span></section>
          <div className="conitenentodoimagencomt">
            {Array.isArray(comentarios) && comentarios.map((comentario, index) => (
              <div className="cardmensajeaparicion" key={index}>
                <div className="basuracomt">
                  <button onClick={() => {
                    handleOpenModalsnew(comentario)
                  }} >
                    Eliminar <BsTrash3Fill /></button>
                </div>
                <div className="toocomt">
                  <div className="titulogeneradocoment">
                    <h3>{capitalizeFirstLetter(comentario.titulo)}</h3>
                  </div>
                  <div className="comentariogeneradocomt">
                    <p> {comentario.mensaje ? (
                      <DangerouslySetHTMLContent html={capitalizeFirstLetter(comentario.mensaje)} />
                    ) : (
                      <span></span>
                    )}</p>
                  </div>
                  <div className="quientlorealizohora">
                    <h3>{comentario.creador}</h3>  •
                    <h3>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</h3>
                  </div>
                  <div className="imagencomentario">
                    {comentario.imagen && (
                      <img
                        src={`${backendURL}/${comentario.imagen}`}
                        alt="smart memorial"
                        className="imagencomentariofinal"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <section className="campocomentariosingresarcliente">
            <div className="card-comentario">
              <button className="agregarcomentario" onClick={togglePopup}>
                <FiPlusCircle /> <p>Agregar un mensaje</p>
              </button>
            </div>
            <Modal
              open={showPopup}
              onClose={togglePopup}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "350px", height: '90vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius: '10px', overflowY: 'auto', ' @media (max-width: 500px)': {
                  width: "90%"
                }
              }}>
                <section className="closemodal" onClick={togglePopup}> <IoClose /></section>
                <section className="contienentodomesjeneviar">
                  <section className="titulodentromodalmensaje">  <IoIosMail /> <h2 style={{ fontSize: '1rem' }} id="modal-title">Dejar un mensaje</h2> </section>
                  <section className="subtituloinfomensaje"><span>Si deseas compartir un recuerdo en video, puedes incluir un enlace de video en tu mensaje. Simplemente haz clic en el botón "Agregar video", ingresa la URL del video que deseas compartir y selecciona "Guardar". El enlace aparecerá en tu mensaje como "Ver Video", permitiendo a otros ver tu video mientras leen tu mensaje. </span></section>
                </section>
                <form>
                  <div className="formulariocoment">
                    <label>Ingrese su nombre</label>
                    <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} className="desinput" />

                    <label>Título</label>
                    <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} className="desinput" />
                    <label>Descripción</label>
                    <textarea
                      className="inputmesnajeneviar"
                      value={formData.descripcion}
                      onChange={handleChange}
                      name="descripcion"
                      id="descripcion"
                    />
                    <button className="videodescripcionmensaje" onClick={handleVideoModalOpenvideo}>    <IoPlayCircleOutline /> Agregar video </button>


                    <label>Agregar imágen (opcional)</label>
                    <div className="cardimagencom">
                      <div className="todocardimganecomt">
                        <input type="file" accept="image/*" onChange={handleImageChange} className="desimagen" />
                        <div className="iconoagregarcomimagen">
                          <FaArrowUpFromBracket className="imgicono" />
                          <span>Subir Foto</span>
                        </div>
                      </div>
                      {formData.imagen && (
                        <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa de la imagen" className="imagenselecion" />

                      )}
                    </div>
                    <button className="botonguardarcomt" onClick={handleSubmit}>
                      Enviar mensaje
                    </button>
                  </div>
                </form>
                <section className="scroolmensaje">
                  <div className="scrolldown" >
                    <div className="chevrons">
                      <div className="chevrondown"></div>
                      <div className="chevrondown"></div>
                    </div>
                  </div>
                </section>
              </Box>
            </Modal>

          </section>
          <section className="fondomemorialdemo">
            <div className="titulofondomemorial">
              <h1>Fondo</h1>
            </div>
          </section>
          <section className="selectdemo" >
            <Select
              className="seleciondefondofinal"
              value={selectedOption}
              onChange={handleSelectChange}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                    ? UserDataMarca.color_1 
                                    : '#694B81',
                width: '100%',
                borderRadius: '50px',
                display: 'flex',
                fontSize: "0.8rem",
                alignItems: 'flex-start',
                color: '#585857',
                fontFamily: 'Poppins',
                '& .MuiTypography-root': { // Estilos para MuiTypography-root
                  fontSize: '0.8rem', // Aquí estableces el tamaño de fuente deseado
                }
              }}
            >
              <StyledMenuItem value="atardecerVerano">
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ListItemIcon>
                    <div style={{ backgroundColor: '#E28939', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                  </ListItemIcon>
                  <StyledListItemText primary="Atardecer en verano" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                </div>
              </StyledMenuItem>
              <StyledMenuItem value="naturalezaAustral">
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <ListItemIcon>
                    <div style={{ backgroundColor: '#68A354', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
                  </ListItemIcon>
                  <StyledListItemText primary="Naturaleza austral" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem", '& .MuiTypography-root': { fontSize: "0.8rem", fontFamily: "Poppins" }, '@media (max-width: 310px)': { fontSize: '0.7rem' } }} />
                </div>
              </StyledMenuItem>
              {hayColores ? (
      <StyledMenuItem value="coloresEmpresa">
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
          <ListItemIcon>
            <div style={{ backgroundColor: colores.color_2, borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
          </ListItemIcon>
          <StyledListItemText primary="Colores Empresa" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem" }} />
        </div>
      </StyledMenuItem>
    ) : (
      <StyledMenuItem value="clasicoSmartMemorial">
        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
          <ListItemIcon>
            <div style={{ backgroundColor: '#694B81', borderRadius: '50%', width: 20, height: 20, marginTop: '5px' }} />
          </ListItemIcon>
          <StyledListItemText primary="Clásico Smart Memorial" x={{ width: '200px', fontFamily: "Poppins", fontSize: "0.7rem" }} />
        </div>
      </StyledMenuItem>
    )}
            </Select>
          </section>
          <section className="guardarbotones" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <button className="botonguardarmensaje" onClick={handleClickfinal} >Crear Memorial </button>
          </section>

        </Box>
      </Container>
      <Modal
        open={openModals}
        onClose={handleCloseModals}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '400px', bgcolor: '#fff', boxShadow: 24, p: 4, borderRadius: "10px", '@media (max-width: 500px)': {
            width: '80%', p: 2
          }
        }}>
          <div className="tituloeliminarmensaje">
            <h2 id="modal-title">¿Desea eliminar este mensaje de {capitalizeFirstLetter(commentToDelete?.nombre)}?</h2></div>
          <div className="contienetodoslosbotonesediteliminar"> <button className="noeli" onClick={handleCloseModals}>No eliminar</button>
            <button className="sieli" onClick={() => handleDeleteComment(commentToDelete?.id)} >{loading ? (<CircularProgress color="inherit" />) : ("Si eliminar")}</button></div>
        </Box>
      </Modal>
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>

      <Modal
        open={openVideoModal}
        onClose={handleVideoModalClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "300px", bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '10px' }}>
          <h2 id="video-modal-title" className="titlevideourl">Agregar link del Video</h2>
          <input
            className="desinput"
            type="text"
            value={videoURL}
            onChange={handleVideoURLChange}
            placeholder="Ingrese la URL del video"
            style={{ width: '100%', marginBottom: '10px', padding: '8px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button className="crrarlinkvideo" onClick={handleVideoModalClose}>Cerrar</button>
            <button className="guardarlinkvideo" onClick={handleSaveVideoURL}>Guardar</button>
          </div>
        </Box>
      </Modal>

</>
    )
}


const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  fontFamily: 'Poppins',
  fontSize: '0.7rem',
  '& .MuiTypography-root': {
    fontSize: '0.8rem',
    fontFamily: 'Poppins', // Aplica el fontFamily a la raíz de la tipografía
  },
}));