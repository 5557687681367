import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import EmpresaLayout from "../../../../../../EmpresaLayout";
/* componentes */

import { CrearDemoQr } from "../../FuncionesEmpresaAdmi/CrearDemo";

/* libreria UI */
import { Container, Grid, Box, CircularProgress, Modal, Button} from '@mui/material';
import { styled } from '@mui/material/styles'; 

/* react */
import { BsQrCode } from "react-icons/bs";

export const DemoQr = ({setShowNavbarFooter}) => {
    const { id } = useParams(); // Aquí obtienes el id de la empresa o usuario
    const location = useLocation();
    const { Empresa, Usuario } = location.state || {};
    

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

  

    return(
        <EmpresaLayout>
        <Container maxWidth='lg'>
          
            <Box display="flex" justifyContent="center" alignItems="center" >
                    <CrearDemoQr user={Usuario} empresa={Empresa}/>          
            </Box>

        </Container>
        </EmpresaLayout>
    )
}

