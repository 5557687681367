import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
/* libreria UI */
import { Container, Grid, Box, CircularProgress, Modal,Snackbar, Alert, Button } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";

    export const EditarPerfilEmpresa = () => {
        const { Token, UserDataMarca, Empresa } = useUserEmpresa();
        const [isLoading, setIsLoading] = useState(false);
        const [snackbar, setSnackbar] = useState({ open: false, type: '', message: '' });
        const backendURL = process.env.REACT_APP_BACKEND_URL;
        const handleFileChange = (e) => {
            setLogo(e.target.files[0]);
        };
        const [logo, setLogo] = useState(null); 
        const [formData, setFormData] = useState({
            nombre_empresa: Empresa.nombre_empresa,
            nombre_social: Empresa.nombre_social,
            web: Empresa.web,
            telefono: Empresa.telefono,
        
        });

        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };

        const handleSubmit = async (e) => {
            e.preventDefault();
            setIsLoading(true); // Inicia la carga
    
            const data = new FormData();
            for (const key in formData) {
                data.append(key, formData[key]);
            }
            if (logo) {
                data.append('logo', logo);
            }
    
            try {
                await axios.post(`${backendURL}/api/empresa/${Empresa.id}`, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${Token}`,
                    },
                });
    
                setSnackbar({ open: true, type: 'success', message: 'Empresa actualizada exitosamente' });
            } catch (error) {
                setSnackbar({ open: true, type: 'error', message: 'Error al actualizar la empresa' });
            } finally {
                setIsLoading(false); // Finaliza la carga
            }
        };
    
        const handleCloseSnackbar = () => {
            setSnackbar({ ...snackbar, open: false });
        };

        return(
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: '20px' }}>
            <form  onSubmit={handleSubmit} style={{
                width: '60%',
                position: 'relative'
            }}>
                <Grid container >
                <Grid item xs={12}>
                {Empresa.logo &&(
                  <section className="contiene-input-imagen-perfil">
                <label htmlFor="file-input" className="input-subir">
                  <img src={`${backendURL}/${Empresa.logo}`} alt="Vista previa de la imagen" />
                </label>
            
              </section>
  )}
                    <div className="inputcrearperfil">
                        <label  className="titulocrearperfillabel"
                         htmlFor="logo"
                         >
                            Logo de la empresa:
                        </label>
                        <input
                            className="crearinputperfil"
                            type="file"
                            id="logo"
                            name="logo"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{
                                width: '100%',
                                color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                                    ? UserDataMarca.color_1
                                    : '#694B81'
                            }}
                        />
                        </div>
                    </Grid>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label
                            className="titulocrearperfillabel"
                            htmlFor=""
                            >Nombre empresa</label>
                        <input
                            className="crearinputperfil"
                            type="text"
                            name="nombre_empresa"
                            value={formData.nombre_empresa}
                            onChange={handleChange}
                            style={{
                                width: '100%',
                                color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                                    ? UserDataMarca.color_1
                                    : '#694B81'
                            }} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel"  htmlFor="">Nombre Social</label>
                        <input className="crearinputperfil"
                         type="text"
                         name="nombre_social"
                            value={formData.nombre_social}
                            onChange={handleChange} 
                            style={{
                                width: '100%',
                                color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                                    ? UserDataMarca.color_1
                                    : '#694B81'
                            }} />
                    </div>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="editfecha"> Pagína web</label>
                        <div className="contienencalendarioempresaperfil">
                            <input type="text"
                             className="crearinputempresaperfil" 
                             name="web"
                             value={formData.web}
                             onChange={handleChange}
                                 style={{
                                    width: '100%',
                                    color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                                        ? UserDataMarca.color_1
                                        : '#694B81'
                                }} />
                            
                        </div>

                    </div>
                </Grid>
                
                <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <div className="inputcrearperfil">
                        <label className="titulocrearperfillabel" htmlFor="">Teléfono</label>
                        <input className="crearinputperfil" type="text"
                         name="telefono"
                        value={formData.telefono}
                        onChange={handleChange} 
                            style={{
                                width: '100%',
                                color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                                    ? UserDataMarca.color_1
                                    : '#694B81'
                            }}
                            />
                    </div>
                </Grid>
            </Grid>

            <section className="botonguardarperfilempresacreado">
                <button type="submit" disabled={isLoading}  style={{
                    background: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined')
                        ? UserDataMarca.color_1
                        : '#694B81'
                }}>
                    {isLoading ?
                        <CircularProgress size={24} /> : "Editar empresa"}
                </button>
            </section>
        </form>

        
               <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.type}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
           
    </Box>
    )
}