import React, { useEffect } from "react";
import { LoginPageEmpresas } from "../Components/Users/AdministradoresSuperEmpresas/LoginPage/LoginPageEmpresa";


export const LoginEmpresas = ({ setShowNavbarFooter }) => {
    useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
    setShowNavbarFooter(true);
    };
    }, [setShowNavbarFooter]);

    return(
        <>
         <LoginPageEmpresas/>
        </>
    )
}