import React from "react";
import { useEffect, useState } from 'react'
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useNavigate, useParams, Link } from "react-router-dom";
import { Container, Box, CircularProgress, Grid  } from "@mui/material";
import { Navbar } from "../nav-fotter/navbar";
import { Iconos, Iconosnaranjos, Iconosverdes } from "../../data/dataicono";
import "../../Style/paginavista/obituariopublico.css"

/* icono */
import { IoIosClose } from "react-icons/io";

/* imagenes data previsualizacion  verde*/

import v25 from "../../Image/obituario/verde/v25.png"
import v26 from "../../Image/obituario/verde/v26.png"
import v27 from "../../Image/obituario/verde/v27.png"


/* imagenes data previsualizacion  naranjo*/
import n22 from "../../Image/obituario/naranjo/n22.png"
import n23 from "../../Image/obituario/naranjo/n23.png"
import n24 from "../../Image/obituario/naranjo/n24.png"


/* imagenes data previsualizacion  morado*/
import icono24 from "../../Image/obituario/m24.png";
import icono25 from "../../Image/obituario/m25.png";
import icono26 from "../../Image/obituario/m26.png";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 100 });

export const Obituariopagina = ({setShowNavbarFooter}) => {
    
  const [grupoFamiliar, setGrupoFamiliar] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    const[obituario, setObituario] = useState(null)
    const[memorialData, setmemorialData] = useState(null)
    const [link, setLink] = useState(null); 
    const [logoUrl, setLogoUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [edad, setEdad] = useState(null);
    const [marcas, setMarcas] = useState(null)
 
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleClickvista = (memorialId) => {
      const state = { familia: grupoFamiliar, memorial: memorialId }; 
      navigate(`/familiar/memorial/${memorialId}`, { state });
  };

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
        setShowNavbarFooter(true);
    };
}, [setShowNavbarFooter]);

const fetchObituarios = async () => {
  const response = await http.get(`${backendURL}/api/obtenerObituarioid/${id}`);
  setObituario(response.data.obituario);
  return response.data.obituario.id_memorial;
};

const fetchMemorialData = async (idMemorial) => {
  const response = await http.get(`${backendURL}/api/getMemorialIdGeneral/${idMemorial}`);
  setmemorialData(response.data);
  
  const fechaNacimiento = new Date(response.data.fecha_nacimiento);
  const fechaDefuncion = new Date(response.data.fecha_defuncion);
  setEdad(calcularEdad(fechaNacimiento, fechaDefuncion));

  return response.data.id_grupo_familiar;
};

const fetchfamiliarData = async (idGrupoFamiliar) => {
  const response = await http.get(`${backendURL}/api/get/GrupoFamiliar/public/${idGrupoFamiliar}`);
  setGrupoFamiliar(response.data);

  if (response.data?.id_empresa) {
      const idEmpresa = response.data.id_empresa;
      const empresaResponse = await http.get(`${backendURL}/api/marcaempresa/especifica/${idEmpresa}`, {
          headers: { 'Content-Type': 'application/json' }
      });
      setMarcas(empresaResponse.data.data[0]);
  }
};

useEffect(() => {
  const fetchData = async () => {
      try {
          setLoading(true);

          // Primero obtenemos el `idMemorial` y `idGrupoFamiliar`
          const idMemorial = await fetchObituarios();
          const idGrupoFamiliar = await fetchMemorialData(idMemorial);

          // Ejecuta las tres solicitudes en paralelo
          await Promise.all([
              fetchObituarios(),
              fetchfamiliarData(idGrupoFamiliar),
              fetchMemorialData(idMemorial)
              
          ]);

      } catch (error) {
          console.error('Error fetching data:', error);
      } finally {
          setLoading(false);
      }
  };

  fetchData();
}, [backendURL, id]);
  
  
  

  useEffect(() => {
      const fetchFuneraria = async () => {
          try {
              if (grupoFamiliar && grupoFamiliar.id_funeraria) {
                  const response = await http.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
                  const funerariaData = response.data;
                  
                  setLink(funerariaData.web);setLogoUrl(funerariaData.logo);
                  if (funerariaData.nombre === 'smart memorial') {
                      setLogoUrl(null); // No mostrar logo
                  } else if (funerariaData.logo) {
                      
                  } else {
                      setLogoUrl(null); // No hay logo disponible
                  } 
              }
          } catch (error) {
              console.error('Error fetching funeraria data:', error);
          }
      };

      fetchFuneraria();
  }, [grupoFamiliar]);

  const calcularEdad = (fechaNacimiento, fechaDefuncion) => {
    let edad = fechaDefuncion.getFullYear() - fechaNacimiento.getFullYear();
    let meses = fechaDefuncion.getMonth() - fechaNacimiento.getMonth();
    let dias = fechaDefuncion.getDate() - fechaNacimiento.getDate();

    // Ajustar los días si la diferencia es negativa
    if (dias < 0) {
        meses--;  // Reducimos un mes
        const ultimoDiaMesAnterior = new Date(
            fechaDefuncion.getFullYear(),
            fechaDefuncion.getMonth(),
            0
        ).getDate();
        dias += ultimoDiaMesAnterior;  // Sumamos los días restantes del mes anterior
    }

    // Ajustar los meses si la diferencia es negativa
    if (meses < 0) {
        edad--;  // Reducimos un año
        meses += 12;  // Sumamos 12 meses para ajustar
    }

    // Mostrar solo el valor más relevante
    if (edad > 0) {
        return `${edad} ${edad === 1 ? 'año' : 'años'}`;
    } else if (meses > 0) {
        return `${meses} ${meses === 1 ? 'mes' : 'meses'}`;
    } else {
        return `${dias} ${dias === 1 ? 'día' : 'días'}`;
    }
};

  const styles = {
    titleStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
        }
      })(),
    },
    fondomensajeStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
        }
      })(),
    },
    fondosStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#F0EDF3';
        }
      })(),
    },
    titulooscuroStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#D26999';
        }
      })(),
    },
    texto: {
      color: "#fff"
    },
    selectedIconStyle: {
  
      cursor: "pointer",
      width: '40px',
      height: '40px',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
        }
      })(),
    },
    iconStyle: {
      cursor: "pointer",
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'transparent',  // Aquí está el error corregido
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
        }
      })(),
    },
    
  };
  
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const getImageSrc = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return v25;
      case 'atardecerVerano':
        return n22;
      default:
        return icono24
    }
  };
  
  const getImageizquierdo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return v26;
      case 'atardecerVerano':
        return n23;
      default:
        return icono25
    }
  };
  const getImagederecho = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return v27;
      case 'atardecerVerano':
        return n24;
      default:
        return icono26
    }
  };
  
  const getIconoSeleccionado = (fondo, value) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return Iconosverdes.find(icono => icono.value == value);
      case 'atardecerVerano':
        return Iconosnaranjos.find(icono => icono.value == value);
      default:
        return Iconos.find(icono => icono.value == value);
    }
  };
  
  const iconoSeleccionado = getIconoSeleccionado(memorialData?.fondo, obituario?.icono);
  const imageSrc = getImageSrc(memorialData?.fondo);
  const imageizquierdo = getImageizquierdo(memorialData?.fondo);
  const imagederecho = getImagederecho(memorialData?.fondo);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
};

const getDayOfWeek = (dateString) => {
  const date = new Date(dateString + 'T00:00:00'); 
  const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  return days[date.getDay()];
};

let parentescoArray;
try {
  parentescoArray = JSON.parse(obituario.parentesco);
} catch (e) {
  parentescoArray = []; // Manejar el error si no es un JSON válido
}

// Ahora puedes usar join si se convierte correctamente
const parentescoString = parentescoArray.join(', ');

const StyleFondo={
  backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
    backgroundSize: 'cover',           // Asegurar que cubra toda la sección
    backgroundPosition: 'center',      // Centrar la imagen
    width: '100%',
    height: 'auto',                   // Ajustar la altura de la sección
    
}

    return(<> {loading ? (
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Pantalla completa
        flexDirection: 'column',
      }}
    >
      <CircularProgress
        size={100}
        thickness={4}
        sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
      />
    </Box>
  ) : (
        <section className="contienenpaginainiciousuario" style={StyleFondo}>
            {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
             {logoUrl && (
        <Link to={link.web} className="logofunerariacrearmemorial" target="black">

          <img
            src={`${backendURL}/${logoUrl}`}
            alt="Logo de la funeraria"
            className="logofun"

          />
        </Link>
      )}
        <Container component="main" maxWidth="xs" sx={{ paddingLeft:"0px", paddingRight:"0px", ' @media (max-width: 700px)': {
            marginTop: '0px'
          }}}>
            <Box sx={{
             backgroundColor: (() => {
              switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                  return '#F3F8F1';
                case 'atardecerVerano':
                  return '#FDF5EF';
                default:
                  return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#F0EDF3';
              }
            })(), 
            
            display: "flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection: "column",
           paddingTop:2
            
        }}>
  <Box sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      }}> 
        <IoIosClose  
          className="botoncloseobituario" 
          onClick={() => handleClickvista(memorialData.id)}
           style={styles.titleStyle} 
        />
      </Box>
      {iconoSeleccionado && (
    <Box sx={{ 
      width: "100%", 
      textAlign: 'center', 
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column" 
    }}>
       <img
        className="iconoselecionobform"
        key={iconoSeleccionado.value}
        src={iconoSeleccionado.src}
        alt="smart memorial" 
        title="smart memorial"
      />
      
      <div className="tituloobituario">
        <h4 style={styles.titleStyle}>Obituario</h4>
      </div>
      
      <img 
        src={imageSrc} 
        className="subrayadoobuno" 
        alt="smart memorial" 
        title="smart memorial"
      />
    </Box>
  )}
  <Box sx={{
      width:"100%",
      display:"flex",
      alignItems:"center"
      ,justifyContent:"center"
      ,flexDirection:"column",
      marginTop:"20px",
      marginBottom:"30px"
    
    }}>
      <span className="informacionfallecimientoobparteuno"> {obituario.edad === '1' ? (
    <>
      A la edad de <p style={styles.titleStyle}>{edad}</p>
    </>
  ) : null} 
    el día <p style={styles.titleStyle}>{formatDate(memorialData?.fecha_defuncion)}</p> </span>
      <span  className="informacionfallecimientoobparteuno">Lamentamos informar  el fallecimiento de:</span>
    </Box>
    <Box sx={{
      width:"100%",
      display:"flex",
      alignItems:"center"
      ,justifyContent:"center"
      ,flexDirection:"column",
      position:"relative"
    }}>

    <div className="imagenobituario"  style={{
  borderColor: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
                  return '#F3F8F1';
                case 'atardecerVerano':
                  return '#FDF5EF';
                default:
                  return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#F0EDF3';
    }
  })(),}} >
                {memorialData && (

                  <img src={`${backendURL}/imagenes/memoriales/${memorialData?.carpeta_perfil}/${memorialData?.imagen_perfil}`} alt="smart memorial" />

                )}
              </div>
    <div className="cuadradoobituario" style={{ backgroundColor: (() => {
    switch (memorialData?.fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#E1DBE6';
    }
  })() }}>

      <div className="titulonombreob"> <h3 style={styles.titulooscuroStyle}  >{memorialData?.nombre}</h3></div>
      <div className="fechaobipersona" style={styles.titleStyle}> {formatDate(memorialData?.fecha_nacimiento)} • {formatDate(memorialData?.fecha_defuncion)} </div>
   
   <div className="descripcionob"> <span>{capitalizeFirstLetter(obituario.descripcion)}</span> </div>

   <div className="parentescoovit"><span> Fue {parentescoString}, quien despediremos en compañía de su familia y amigos.</span></div>
    </div>

    </Box>
    <Box sx={{
      marginTop:"-50px"
    }}>
                <section className="infovelarotioob">
                <div className="tituloinfoobt">
                   <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial"/> 
                   <h2 style={styles.titleStyle}>Velatorio</h2>
                    <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial"/> </div>
                <div className="estructurafechaob"> 
                  <div className="titulofechaob" style={styles.titulooscuroStyle}>Fecha</div>
                  <div className="infodosfechaob" > <div className="contienefechao"> <span> {getDayOfWeek(obituario.fecha_desde)}</span> <p>{formatDate(obituario.fecha_desde)}</p></div> • <div className="contienefechaprevo" ><span>{getDayOfWeek(obituario.fecha_hasta)}</span> <p>{formatDate(obituario.fecha_hasta)}</p></div></div>
                </div>
                <div className="estructurafechaob"> 
                  <div className="titulofechaob" style={styles.titulooscuroStyle}>Dirección</div>
                  <div className="infodosob">{obituario.direccion}</div>
                </div>

                </section>
                <section className="infovelarotioob">
                <div className="tituloinfoobt">
                   <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial"/>
                    <h2 style={styles.titleStyle}>Cortejo fúnebre</h2>
                     <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial"/>
                      </div>
                      <div className="estructurafechaob"> 
                  <div className="titulofechaob" style={styles.titulooscuroStyle}>Hora</div>
                  <div className="infodosfechaob" > {obituario.hora_salida} hrs.</div>
                </div>
                </section>
                <section className="infovelarotioob">
                <div className="tituloinfoobt">
                   <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial"/>
                    <h2 style={styles.titleStyle}>Funeral</h2>
                     <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial"/>
                      </div>
                      <div className="estructurafechaob"> 
                      <Grid Container sx={{ display:"flex", width:"100%", ' @media (max-width: 271px)': {
            flexDirection:"column", alignItems:"center", justifyContent:"center"
          }}}>
                        <Grid item xs={6} sx={{ display:"flex", width:"100%", alignItems:"center", justifyContent:"center", position:"relative", left:"-37px", ' @media (max-width: 271px)': {left:"0px"}}}>
                          <div className="estructurafechaob"> 
                  <div className="titulofechaob" style={styles.titulooscuroStyle}>Fecha</div>
                  <div className="infodosfechaob" >  <div className="contienefechao"> <span> {getDayOfWeek(obituario.fecha_funeral)}</span> <p>{formatDate(obituario.fecha_funeral)}</p></div> </div>
                </div></Grid>
                        <Grid item xs={6} sx={{ display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}}>
                          <div className="estructurafechaob"> 
                  <div className="titulofechaob" style={styles.titulooscuroStyle}>Hora</div>
                  <div className="infodosfechaob" > {obituario.hora_funeral} hrs.</div>
                </div></Grid>
                      </Grid>
                     </div>
                     <div className="estructurafechaob">
                     <div className="titulofechaob" style={styles.titulooscuroStyle}>Dirección</div>
                     <div className="infodosob" >{memorialData?.cementerio} </div>


                     </div>
                </section>
                </Box>
            </Box>
        </Container>
     
        </section>
          )}
          </>
    )
}