import React, { useState, useEffect, useRef} from "react";
import axios from "axios";
import { useAuth } from "../../../context/usecontext";
import { useNavigate} from "react-router-dom"; 
import "../../../../Style/admi/adminuser.css";


/* libreria UI */
import { Container,  Box, Grid, CircularProgress,  Select, MenuItem, FormControl, InputLabel, TextField, Modal, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from "@mui/system";

/* imagenes */
import logoinicio from "../../../../Image/administrador/logoadmi.png";

/* import icons */
import { MdOutlineAddTask } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { FaHouseMedical } from "react-icons/fa6";


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '0px',
    boxShadow: 24,
    p: 4,
    display:"flex",
    alignItems:'center',
    flexDirection:'column',
    borderRadius:'20px',
    maxHeight: '90vh', // Limitar la altura máxima al 90% de la altura de la pantalla
    overflowY: 'auto' 
};

export const TareasEmpresas = ()=>{
    const { adminToken, setAdminAuthToken } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [tareas, setTareas] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    

    const handleOpenDeleteModal = (task) => {
        setSelectedTask(task);
        setOpenDeleteModal(true);
    };
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);
    const handleOpenEditModal = (task) => {
        setSelectedTask(task);
        setOpenEditModal(true);
    };
    
    const handleCloseEditModal = () => {
      setOpenEditModal(false);
  };
    const handlempresa = (task) => {
      setSelectedTask(task);
      setOpenCreateModal(true);
  };
  const handleCloseModalempresas = () => {
    setOpenCreateModal(false); // Asegúrate de que el estado se esté actualizando correctamente
};
    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
        fetchTareas();
    }, [setAdminAuthToken, navigate, adminToken]);

    const fetchTareas = async () => {
      setLoading(true); 
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/tareas`, {
          headers: {
            'Authorization': `Bearer ${adminToken}`
          }
        });
        setTareas(response.data.tareas); // Suponiendo que los datos vienen en el formato correcto
      } catch (error) {
        console.error('Error fetching tareas:', error);
      }
      finally {
        setLoading(false); // Finaliza la carga
      }
    };

    const handleDeleteTask = async () => {
        if (!selectedTask) return;
        
        try {
            await axios.delete(`${backendURL}/api/tareas/${selectedTask.id}`, {
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                }
            });
            setTareas(tareas.filter(t => t.id !== selectedTask.id));
            handleCloseDeleteModal();
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    return(
        <>
           <Container component="main" maxWidth="lg" sx={{ position: "relative" }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "50px"
                    }}
                >
                   <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "150px"
        }}>

          <img
            src={logoinicio}
            alt="smart memorial"
            title="smart memorial"
            width="100%"
          />

        </Box>
        <section className="titulocodigoadmi"><h1>Bienvenido a la sesión de creación tareas para empresas</h1></section>

        <section className="contienesectorbotoncreartarea"> 
        <button  onClick={handleOpenModal} className="botoncreartarea">
                            <MdOutlineAddTask /> Crear Nueva Tarea  
                        </button>

        </section>
        <section className="tabla-tareas-admi-smart">
                {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{color:'#D26999'}} />
            </div>
          ) : (
            <table className="encabezado-tabla">
              <thead>
                <tr>
                  <th>Imagen</th>
                  <th>Nombre Tarea</th>
                  <th>Estado</th>
                  <th>Descripción Función</th>
                  <th>UF</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody className="tareas-table">
                {tareas.map(tarea => (
                  <tr key={tarea.id}>
                    <td>
                      <div className="logotareacrear">
                        {tarea.logo && (
                          <img src={`${backendURL}/imagenes/tareas/${tarea.logo}`} alt="smart memorial" title="smart memorial" />
                        )}
                      </div>       
                    </td>
                    <td>{tarea.nombre}</td>
                    <td>{tarea.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                    <td>{tarea.funcion}</td>
                    <td>UF {tarea.valor_uf}</td>
                    <td style={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}> 
                      <button onClick={() => handleOpenEditModal(tarea)} className="botonedittarea">
                        <FaEdit />
                      </button>
                      <p className="titulobotonaccion">Editar</p>
                      <button onClick={() => handleOpenDeleteModal(tarea)} className="botoneliminartarea">
                        <FaTrashAlt />
                      </button>
                      <p className="titulobotonaccion">Eliminar</p>
                      <button onClick={()=> handlempresa(tarea)} className="empresalistadelatarea">
                        <FaHouseMedical />
                      </button>
                      <p className="titulobotonaccion" >Empresa</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
                       </Box>
                        {/* Modal para crear nueva tarea */}
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={modalStyle}>
                            <Box sx={{
                                width:"100%",
                                display:"flex",
                                alignItems:"flex-end",
                                justifyContent:"flex-end"
                            }}>
                                <button onClick={handleCloseModal} className="closetareamodal" sx={{ mt: 2 }}>
                                <IoClose />
                                </button>    
                            </Box>
                            <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "150px"
                            }}>

                                <img
                                    src={logoinicio}
                                    alt="smart memorial"
                                    title="smart memorial"
                                    width="100%"
                                />
                            </Box>
                            <Typography id="modal-title" variant="h6" component="h2" sx={{ color:'#694B81', fontWeight:'600', fontSize:'1.3rem'}}>
                                Crear Nueva Tarea
                            </Typography>
                            <Box sx={{ width:"80%"}}>
                                <Creartarea Close={handleCloseModal} fetchTareas={fetchTareas} />
                            </Box>
                            
                        </Box>
                    </Modal>

                    <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
                    <Box sx={modalStyle}>
                        <Typography variant="h6" style={{ color:'#694B81'}}>¿Estás seguro de que deseas eliminar esta tarea?</Typography>
                        <div className="contienetodoslosbotonesediteliminar">
                            <button className="sieli" onClick={handleDeleteTask}>Eliminar</button>
                        <button className="noeli" onClick={handleCloseDeleteModal}>Cancelar</button>
                        </div>
                    </Box>
                </Modal>

                {/* Modal para Editar Tarea */}
                <Modal open={openEditModal} onClose={handleCloseEditModal}>
                    <Box sx={modalStyle}>
                    <Box sx={{
                                width:"100%",
                                display:"flex",
                                alignItems:"flex-end",
                                justifyContent:"flex-end"
                            }}>
                                <button onClick={handleCloseEditModal} className="closetareamodal">
                            <IoClose />
                        </button>
                        </Box>
                        
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "150px"
                            }}>

                                <img
                                    src={logoinicio}
                                    alt="smart memorial"
                                    title="smart memorial"
                                    width="100%"
                                />
                            </Box>
                            <Typography id="modal-title" variant="h6" component="h2" sx={{ color:'#694B81', fontWeight:'600', fontSize:'1.3rem'}}>
                                Editar Tarea
                            </Typography>
                        <Editartarea id={selectedTask} Close={handleCloseEditModal} fetchTareas={fetchTareas}/> {/* Asegúrate de modificar Creartarea para manejar la tarea */}
                    </Box>
                </Modal>

                <Modal
                        open={openCreateModal}
                         onClose={handleCloseModalempresas}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box sx={modalStyle}>
                            <Box sx={{
                                width:"100%",
                                display:"flex",
                                alignItems:"flex-end",
                                justifyContent:"flex-end"
                            }}>
                                <button  onClick={handleCloseModalempresas} className="closetareamodal" sx={{ mt: 2 }}>
                                <IoClose />
                                </button>    
                            </Box>
                            <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "150px"
                            }}>

                                <img
                                    src={logoinicio}
                                    alt="smart memorial"
                                    title="smart memorial"
                                    width="100%"
                                />
                            </Box>
                            <Typography id="modal-title" variant="h6" component="h2" sx={{ color:'#694B81', fontWeight:'600', fontSize:'1.3rem'}}>
                                Empresas asociadas a la tarea
                            </Typography>
                            <Box sx={{ width:"80%"}}>
                                < TareasEmpresa Close={handleCloseModalempresas} id={selectedTask}  />
                            </Box>
                            
                        </Box>
                    </Modal>
            </Container>
        </>
    )
}

export const Creartarea = ({Close,fetchTareas}) =>{
    const { adminToken } = useAuth();
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [emailExistsError, setEmailExistsError] = useState(false);
    /* procesadores */
    const [errors, setErrors] = useState({});
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [formData, setFormData] = useState({
        nombre: '',
        funcion: '',
        valor_uf: '',
        estado:'A',
        logo:'',
      });
    const handleImageChange = (e) => {
        if (e.target.files.length) {
          setSelectedImage(URL.createObjectURL(e.target.files[0]));
         setFormData(prevData => ({
            ...prevData,
            logo: e.target.files[0]
          })); 
        }
      };
      const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setenviandoAlert(true);
        
        const data = new FormData();
        data.append('nombre', formData.nombre);
        data.append('funcion', formData.funcion);
        data.append('valor_uf', formData.valor_uf);
        data.append('estado', formData.estado); // Añadido este campo también
        if (formData.logo) {
          data.append('logo', formData.logo); // Adjuntar la imagen
        }
    
        try {
            const response = await axios.post(
                `${backendURL}/api/tarea`,
                data,
                {
                  headers: {
                    'Authorization': `Bearer ${adminToken}`
                    // No necesitas añadir Content-Type, axios lo gestiona automáticamente.
                  }
                }
              );
    
              setAlertMessage('Tarea creada exitosamente');
              setSuccessAlertOpen(true);
              setenviandoAlert(false);
              fetchTareas();
              setTimeout(() => {
                setSuccessAlertOpen(false);
                Close()
              }, 4000);
            } catch (error) {
              setAlertMessage('Error al crear la tarea');
              setErrorAlertOpen(true);
              setenviandoAlert(false);
              if (error.response && error.response.data.errors) {
                setErrors(error.response.data.errors);
              }
            }
          };
    
    return(
        <>
        <form onSubmit={handleSubmit}>
            <div className="input-crearadminsmartempresa">
                <label htmlFor='nombre'>Nombre Tarea</label>
                <div className='contieneinputempresaregister'>
                  <input
                  className="input-crear-empresa-adminsmart" 
                  name="nombre"
                  id="nombre"
                  value={formData.nombre}
                  onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="input-crearadminsmartempresa">
                <label htmlFor='nombre'>Descripcion Tarea</label>
                <div className='contieneinputempresaregister'>
                  <input 
                   className="input-crear-empresa-adminsmart" 
                   name="funcion"
                   id="funcion"
                   value={formData.funcion}
                   onChange={handleInputChange}
                   />
                </div>
              </div>
              <div className="input-crearadminsmartempresa">
                    <label htmlFor="valor_uf">UF</label>
                    <div className="contieneinputempresaregister" style={{ position: 'relative' }}>
                        <span 
                        style={{
                            position: 'absolute', 
                            left: '10px', 
                            top: '50%', 
                            transform: 'translateY(-50%)', 
                            color: '#666',
                            zIndex:'1000000'
                        }}
                        >
                        UF
                        </span>
                        <input  
                        className="input-crear-empresa-adminsmart"
                        name="valor_uf"
                        id="valor_uf"
                        type="number"
                        value={formData.valor_uf}
                        onChange={handleInputChange}
                        style={{ paddingLeft: '30px' }} // Espacio para el prefijo "UF"
                        />
                    </div>
                    </div>
              <div className="input-crearadminsmartempresa">
                <label htmlFor="logo">Logo</label>
                <div className="contieneinputempresaregister">
                  {/* Ocultar el input original */}
                  <input
                    className="input-crear-empresa-adminsmart"
                    type="file"
                    id="logo"
                    name="logo"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef} // Referencia para disparar el clic
                  />

                  {/* Botón personalizado para seleccionar archivo */}
                  <div
                    className="custom-file-upload"
                    onClick={() => fileInputRef.current.click()} // Dispara el clic del input
                  >
                    Seleccionar archivo
                  </div>
                </div>

                {/* Vista previa de la imagen seleccionada */}
                {selectedImage && (
                  <div className="image-preview-select-logo-empresa">
                    <img
                      src={selectedImage}
                      alt="Selected"
                      className="imagenlogoselect"
                    />
                  </div>
                )}
              </div>

              <div className="buttonsiguientecrearempresa">
                <button type="submit">Crear Empresa</button>
              </div>
        
        </form>
        
        <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </>
    )
}


export const Editartarea = ({Close, id,  fetchTareas}) =>{
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { adminToken } = useAuth();
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const [formData, setFormData] = useState({
    nombre: '',
    funcion: '',
    valor_uf: '',
    logo: null,
  });
  /* procesadores */
    const [errors, setErrors] = useState({});
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setEnviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

   
    useEffect(() => {
      if (id) {
        setFormData({
          nombre: id.nombre || '',
          funcion: id.funcion || '',
          valor_uf: id.valor_uf || '',
          logo: id.logo || null, // Mantener el logo actual si existe
        });
      }
    }, [id]);
  
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleImageChange = (e) => {
      if (e.target.files.length) {
        setSelectedImage(URL.createObjectURL(e.target.files[0]));
        setFormData((prevData) => ({
          ...prevData,
          logo: e.target.files[0],
        }));
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setEnviandoAlert(true);
    
      const data = new FormData();
      data.append('nombre', formData.nombre);
      data.append('funcion', formData.funcion);
      data.append('valor_uf', formData.valor_uf);
    

      // Solo añade el logo si es un archivo
      if (formData.logo instanceof File) {
        data.append('logo', formData.logo);
      }

    
      try {
        const response = await axios.post(`${backendURL}/api/tareaeditar/${id.id}`, data, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
    
        setEnviandoAlert(false);
        setSuccessAlertOpen(true);
        setAlertMessage('Tarea actualizada exitosamente');
        fetchTareas();
        setTimeout(() => {
          setSuccessAlertOpen(false);
          Close()
        }, 4000);
      } catch (error) {
        setEnviandoAlert(false);
        setErrorAlertOpen(true);
        setAlertMessage('Error al actualizar la tarea');
        console.error('Error al actualizar la tarea:', error.response?.data);
      }
    };

  return(
    <>
    <form onSubmit={handleSubmit}>
        <div className="input-crearadminsmartempresa">
          <label htmlFor="nombre">Nombre Tarea</label>
          <div className="contieneinputempresaregister">
            <input
              className="input-crear-empresa-adminsmart"
              name="nombre"
              id="nombre"
              value={formData.nombre}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="input-crearadminsmartempresa">
          <label htmlFor="funcion">Descripción Tarea</label>
          <div className="contieneinputempresaregister">
            <input
              className="input-crear-empresa-adminsmart"
              name="funcion"
              id="funcion"
              value={formData.funcion}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
        <div className="input-crearadminsmartempresa">
          <label htmlFor="valor_uf">UF</label>
          <div className="contieneinputempresaregister" style={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: '#666',
              }}
            >
              UF
            </span>
            <input
              className="input-crear-empresa-adminsmart"
              name="valor_uf"
              id="valor_uf"
              type="number"
              value={formData.valor_uf}
              onChange={handleInputChange}
              style={{ paddingLeft: '30px' }}
              required
            />
          </div>
        </div>
        <div className="input-crearadminsmartempresa">
          <label htmlFor="logo">Logo</label>
          <div className="contieneinputempresaregister">
            <input
              className="input-crear-empresa-adminsmart"
              type="file"
              id="logo"
              name="logo"
              accept="image/*"
              
              onChange={handleImageChange}
              ref={fileInputRef}
            />
            <div className="custom-file-upload" onClick={() => fileInputRef.current.click()}>
              Seleccionar archivo
            </div>
          </div>
          {selectedImage ? (
  <div className="image-preview-select-logo-empresa">
    <img src={selectedImage} alt="Selected" className="imagenlogoselect" />
  </div>
) : id.logo ? (
  <div className="image-preview-select-logo-empresa">
    <img src={`${backendURL}/imagenes/tareas/${formData.logo}`} alt="Logo actual" className="imagenlogoselect" />
  </div>
) : null}
        </div>
        <div className="buttonsiguientecrearempresa">
          <button type="submit">Actualizar Tarea</button>
        </div>
      </form>

    <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        borderRadius: '10px',
        '@media (max-width: 500px)': {
          width: '90%',
        },
      }}
    >
      <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
        <IoClose />
      </section>
      <section style={{ textAlign: 'center' }}>
        <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
        <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
          {alertMessage}
        </p>
      </section>
    </Box>
  </Modal>

  {/* Popup de error */}
  <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        borderRadius: '10px',
        '@media (max-width: 500px)': {
          width: '90%',
        },
      }}
    >
      <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
        <IoClose />
      </section>
      <section style={{ textAlign: 'center' }}>
        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
          {alertMessage}
        </p>
      </section>
    </Box>
  </Modal>

  {/* Popup de carga */}
  <Modal open={enviandoAlert} onClose={() =>setEnviandoAlert(false)}>
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '350px',
        height: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 1,
        borderRadius: '10px',
        '@media (max-width: 500px)': {
          width: '90%',
        },
      }}
    >
      <section style={{ textAlign: 'center', padding: '1rem' }}>
        <CircularProgress style={{ color: '#694B81' }} />
        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
          Procesando solicitud...
        </p>
      </section>
    </Box>
  </Modal>
    </>
  )
}

export const TareasEmpresa =({Close, id}) =>{
  const [empresas, setEmpresas] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [updating, setUpdating] = useState(false);
  const { adminToken } = useAuth();

  useEffect(() => {
    const fetchEmpresas = async () => {
        if (id) {
            setLoading(true);
            try {
                const response = await axios.get(`${backendURL}/api/empresas-por-tarea/${id.id}`, {
                    headers: {
                        'Authorization': `Bearer ${adminToken}`
                    }
                });
                
                setEmpresas(response.data.empresas); // Ajusta según el formato de tu respuesta
            } catch (error) {
                console.error('Error fetching empresas:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    fetchEmpresas();
}, [id, backendURL, adminToken]);

const toggleEstadoTarea = async (tareaId) => {
  setUpdating(true);
  try {
      const response = await axios.post(`${backendURL}/api/tarea/${id.id}/toggle-estado/${tareaId}`, {}, {
          headers: {
              Authorization: `Bearer ${adminToken}`,
          },
      });

      // Actualiza el estado de la empresa en el estado local
      setEmpresas((prevEmpresas) =>
          prevEmpresas.map((empresa) =>
              empresa.id == tareaId
                  ? { ...empresa, estado: response.data.tarea.estado } // Asegúrate que la respuesta tiene el estado
                  : empresa
          )
      );

  } catch (error) {
      console.error("Error al actualizar el estado de la tarea:", error);
      alert("Error al actualizar el estado de la tarea");
  } finally {
      setUpdating(false);
  }
};


  return(
    <section style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
           {loading ? (
                <CircularProgress />
            ) : (
                <>
                    {empresas.length == 0 ? (
                        <p className="no-disponible">Actualmente no hay empresas asociadas.</p>
                    ) : (
                        <table className="encabezado-tabla">
                <thead>
                  <tr>
                    <th>Imagen</th>
                    <th>Nombre Tarea</th>
                    <th>Estado</th>
                    <th>Descripción Función</th>
                    <th>UF</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody className="tareas-table">
                {empresas.length > 0 ? (
                    empresas.map((empresa) => (
                        <tr key={empresa.id}>
                            <td>
                                <div className="logotareacrear">
                                    {empresa.logo && (
                                        <img
                                            src={`${backendURL}/${empresa.logo}`}
                                            alt="smart memorial"
                                            title="smart memorial"
                                        />
                                    )}
                                </div>
                            </td>
                            <td>{empresa.nombre_empresa}</td>
                            <td>{empresa.estado === 'A' ? 'Activo' : 'Inactivo'}</td>
                            <td>{id.funcion}</td>
                            <td>UF {id.valor_uf}</td>
                            <td>
                                <IOSSwitch
                                checked={empresa.estado === 'A'}
                                onChange={() => toggleEstadoTarea(empresa.id)} 
                                 disabled={updating} />
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="6">No hay empresas disponibles.</td>
                    </tr>
                )}
                   </tbody>
               </table>
           )}
       </>
   )}
    </section>
  )
}


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
  
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
  },
}));
