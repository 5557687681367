import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress, Table, TableBody, TableCell,   InputAdornment, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, TablePagination, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TbFaceIdError } from "react-icons/tb";
import "../../../Style/admi/codigos.css";
import jsPDF from 'jspdf';
import { FaTrashCan } from "react-icons/fa6";
import { Modal } from '@mui/material';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import SearchIcon from '@mui/icons-material/Search';
import fondo from "../../../Image/generales/carta.png";

export const Codigos = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const adminToken = location.state.user;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [codigos, setCodigos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [estadoFiltro, setEstadoFiltro] = useState('');
    const [suscripcionFiltro, setSuscripcionFiltro] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [selectedCodigoId, setSelectedCodigoId] = useState(null); // Estado para el Autocomplete
   
   

    const fetchCodigos = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/getallcodigos`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });
            setCodigos(response.data);
        } catch (error) {
            console.error("Error al obtener los códigos:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCodigos();
    }, [backendURL, adminToken]);

    const handleEstadoFiltroChange = (event) => {
        setEstadoFiltro(event.target.value);
    };

    const handleSuscripcionFiltroChange = (event) => {
        setSuscripcionFiltro(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleCodigoIdChange = (event, value) => {
        setSelectedCodigoId(value ? value.id : null);
    };

    const filteredCodigos = codigos.filter((codigo) => {
        return (
            (estadoFiltro === '' || codigo.estado === estadoFiltro) &&
            (suscripcionFiltro === '' || codigo.id_suscripcion.toString() === suscripcionFiltro) &&
            (!selectedCodigoId || codigo.id === selectedCodigoId)
        );
    });

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredCodigos.length - page * rowsPerPage);

    /* descarga */
    const handleDownloadPDF = (codigoId) => {
        // Obtener el código correspondiente
        const codigo = codigos.find(c => c.id === codigoId);
        if (!codigo) return;
        
        // Crear un nuevo documento PDF
        const doc = new jsPDF();

        // Agregar la imagen como fondo
        doc.addImage(fondo, 'png', 0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight());

        // Calcular la posición central del PDF
        const textWidth = doc.getStringUnitWidth(codigo.codigo) * doc.internal.getFontSize() / doc.internal.scaleFactor;
        const textHeight = doc.internal.getLineHeight() / doc.internal.scaleFactor;
        const centerY = (doc.internal.pageSize.getHeight() - textHeight) / 2 + 8; // Mover 30 pixeles hacia abajo

        // Agregar contenido al PDF
        doc.text(codigo.codigo, (doc.internal.pageSize.getWidth() - textWidth) / 2, centerY);

        // Descargar el PDF con un nombre único basado en el código
        doc.save(`codigo_${codigo.codigo}.pdf`);
    };

    /* eliminar */
    const handleDeleteUserSuscripcion = async (id) => {
        try {
            setenviandoAlert(true);
            const response = await axios.delete(`${backendURL}/api/deleteUserSuscripcion/${id}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`
                }
            });

            if (response.status === 200) {
                // Eliminar la suscripción del estado local
                setCodigos(codigos.filter(codigo => codigo.id !== id));
                setenviandoAlert(false);
                setSuccessAlertOpen(true);
                setAlertMessage('Codigo de pack memorial se elimino con éxito.');
                await fetchCodigos();
            } else {
                setErrorAlertOpen(true);
                setAlertMessage('Error al eliminar el codigo de suscripcion', response.status);
            }
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al eliminar el codigo, vuelva a intentar.', error);
        } finally {
            setenviandoAlert(false);
        }

        // Simulación de tiempo de carga
        setTimeout(() => {
            setenviandoAlert(false);
        }, 3000);
        // Simulación de tiempo de carga
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }, 5000);
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{  width:"100%", display: 'flex', alignItems: 'center', flexDirection: "column", marginTop: '30px' }}>
                <section className="titulocodigoadmi"><h1>Códigos</h1></section>
                <section className="informacioncodigos"><p>Sección para visualizar los códigos generados para la seguridad de la compra de servicio smart memorial.</p></section>

                <Box sx={{ display: 'flex', width: '100%', marginBottom: '20px', '@media (max-width: 400px)': {
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexDirection:"column",
                marginBottom:'0px'
                
              }, }}>
                    <FormControl sx={{ minWidth: 120, '@media (max-width: 400px)': {width:"100%", fontSize:'0,8rem'} }}>
                        <InputLabel id="estado-filtro-label">Estado</InputLabel>
                        <Select
                            labelId="estado-filtro-label"
                            id="estado-filtro"
                            value={estadoFiltro}
                            label="Estado"
                            onChange={handleEstadoFiltroChange}
                        >
                            <MenuItem value=""><em>Todos</em></MenuItem>
                            <MenuItem value="DISPONIBLE">Disponible</MenuItem>
                            <MenuItem value="ACTIVO">Activo</MenuItem>
                            <MenuItem value="INACTIVO">Inactivo</MenuItem>
                            {/* Añade más estados si es necesario */}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 180, '@media (max-width: 400px)': {width:"100%", fontSize:'0,8rem'} }}>
                        <InputLabel id="suscripcion-filtro-label">Suscripción</InputLabel>
                        <Select
                            labelId="suscripcion-filtro-label"
                            id="suscripcion-filtro"
                            value={suscripcionFiltro}
                            label="Suscripción"
                            onChange={handleSuscripcionFiltroChange}
                        >
                            <MenuItem value=""><em>Todos</em></MenuItem>
                            <MenuItem value="1">Smart Memorial Full</MenuItem>
                            <MenuItem value="2">Smart Memorial Basico</MenuItem>
                            {/* Añade más tipos de suscripción si es necesario */}
                        </Select>
                    </FormControl>
                

               </Box>
                <Box sx={{width:"100%"}}>
                    <Autocomplete
                    options={codigos}
                    getOptionLabel={(option) => `ID: ${option.id_grupo_familiar} - Código: ${option.codigo}`}
                    onChange={handleCodigoIdChange}
                    sx={{
                        '.MuiAutocomplete-hasPopupIcon .MuiOutlinedInput-root': {
                            paddingRight: '5px',
                        },
                        '.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root': {
                            paddingRight: '5px',
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Buscar Código QR"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            sx={{
                                '& .MuiOutlinedInput-root':{
                                    paddingRight:'5px'
                                }
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon sx={{color:"#694B81", left:"20px"}}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                /> 
                </Box>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredCodigos.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Usuario cliente</TableCell>
                                        <TableCell>Código Qr</TableCell>
                                        <TableCell>Suscripción</TableCell>
                                        <TableCell>Código</TableCell>
                                        <TableCell>Estado</TableCell>
                                        <TableCell>Descargar</TableCell>
                                        <TableCell>Eliminar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredCodigos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((codigo) => (
                                        <TableRow key={codigo.id}>
                                            <TableCell component="th" scope="row" sx={{textAlign:"center" }}>
                                                {codigo.id_user}
                                            </TableCell>
                                            <TableCell component="th" scope="row" sx={{textAlign:"center" }}>
                                                {codigo.id_grupo_familiar}
                                            </TableCell>
                                            <TableCell sx={{textAlign:"center" }}>{codigo.id_suscripcion == 1 ? 'Smart Memorial Full' : 'Smart Memorial Basico'}</TableCell>
                                            <TableCell sx={{textAlign:"center"  }}>{codigo.codigo}</TableCell>
                                            <TableCell sx={{textAlign:"center"  }}>{codigo.estado}</TableCell>
                                            <TableCell sx={{textAlign:"center"  }}> 
                                                <button className="Btn"  onClick={() => handleDownloadPDF(codigo.id)}>
                                                <svg className="svgIcon" viewBox="0 0 384 512" height="1em" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                                                <span className="icon2"></span>
                                            </button>
                                            </TableCell>
                                            <TableCell sx={{textAlign:"center"}}>  {codigo.estado === 'DISPONIBLE' && (
        <FaTrashCan className="eliminarcodigo" onClick={() => handleDeleteUserSuscripcion(codigo.id)} />
    )}</TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={7} align="center">
                                                <div className="contienenerrorcodigo">
                                                    <TbFaceIdError className="iconoerrorcodigo" />
                                                    No existen códigos
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
                )}
            </Box>
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </Container>
    );
};