import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { Box, Modal, CircularProgress, Grid, Container, Typography, List, ListItem } from '@mui/material';
import { FiAlertCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FaRegCircleCheck } from "react-icons/fa6";
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});


export const EditSucursalEMpresa = ({ usuario, onUserUpdated,onclose }) => {
    const { Token } = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const [usuarioData, setUsuarioData] = useState([]);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [error, setError] = useState("");

    const [password, setPassword] = useState({
        old_password: "",
        password: "",
        new_password: "",
    });



    const fetchEmpresaData = async () => {
        if (!Token || !usuario.id) return; // Asegúrate de que Token e id estén disponibles
        setLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/usuarioEmpresa/${usuario.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Token}`
                }
            });

            if (response.status === 200) {
                setUsuarioData(response.data.user);
            } else {
                console.error("Error al obtener datos de la empresa");
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchEmpresaData(); // Llamar a la función al montar el componente
    }, [Token, usuario.id, backendURL]);



    const handleUpdatePerfil = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento predeterminado del formulario

        try {
            setIsLoading(true);
            setenviandoAlert(true);
            const requestBody = { ...usuarioData };



            await axios.post(
                `${backendURL}/api/updateUserSucursal/${usuarioData.id}`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${Token}`
                    }
                }
            );

            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Se ha editado correctamente su información.');
            onUserUpdated()
            onclose()

            // Simulación de tiempo de carga para cerrar la alerta


        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al editar su información, vuelva a intentar.');
        } finally {
            setIsLoading(false);
            setenviandoAlert(false);
        }
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }, 3000);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            setenviandoAlert(true);
            const response = await axios.post(
                `${backendURL}/api/updatePassworSucursalByEmpresa`,
                {
                    user_id: usuarioData.id, // Asegúrate de que haya un usuario disponible
                    password: password.new_password,
                    confirm_password: password.confirm_new_password
                },
                {
                    headers: {
                        Authorization: `Bearer ${Token}`
                    }
                }
            );
            setIsLoading(false);
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Se ha actualizado correctamente su contraseña.');
            
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al actualizar su contraseña, vuelva a intentar.');
            setIsLoading(false);
        } finally {
            setIsLoading(false);
            setenviandoAlert(false);
        }
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
            onclose()
        }, 3000);
    };
    const handleChangeContraseña = (event) => {
        const { value } = event.target;
        setPassword({ ...password, new_password: value });
        if (value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    };

    const handleChangeRepetirContraseña = (event) => {
        const { value } = event.target;
        setPassword({ ...password, confirm_new_password: value });
        if (value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else if (value !== password.new_password) {
            setPasswordError('Las contraseñas no coinciden');
        } else {
            setPasswordError('');
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordcorrectVisibility = () => {
        setShowPasswordcorrect(!showPasswordcorrect);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsuarioData({ ...usuarioData, [name]: value });
    };

    const handleUserInputChange = (e) => {
        const { name, value } = e.target;
        setUsuarioData({ ...usuarioData, [name]: value });
    };

    return (
        <Box style={{
            display: 'flex',
            alignItem: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '30px'
        }}>


            <Box >
                <Box >
                    <h2 className="tituloeditarsucempresa">Editar Administrador Sucursal {usuario.name} {usuario.apellido}</h2>
                </Box>
                {loading ? ( // Condicional para mostrar el loader
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />

                    </Box> // Cambia esto por tu componente de loader
                ) : (

                    <form
                        onSubmit={handleUpdatePerfil}
                        style={{
                            width: '100%',
                            position: 'relative'
                        }}>

                        <Grid container >
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Nombre Administrador</label>
                                    <input className="crearinputperfil" type="text" name="name" onChange={handleUserInputChange} value={usuarioData.name}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Apellido</label>
                                    <input className="crearinputperfil" type="text" name="apellido" onChange={handleUserInputChange} value={usuarioData.apellido}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Teléfono</label>
                                    <input className="crearinputperfil" type="text" name="telefono" onChange={handleUserInputChange} value={usuarioData.telefono}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                                <div className="inputcrearperfil">
                                    <label className="titulocrearperfillabel" htmlFor="">Correo Electronico</label>
                                    <input className="crearinputperfil" type="mail" name="email" onChange={handleUserInputChange} value={usuarioData.email}
                                    />
                                </div>
                            </Grid>


                        </Grid>
                        <section className="botonguardarcontraseñanuevacreado">
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? <CircularProgress size={24} /> : 'Editar Administrador'}
                            </button>
                        </section>
                    </form>
                )}

            </Box>

            <Box sx={{
                width: '95%',
                marginTop: '50px'
            }} >

                <h1 className="titulocontraseñacorrecta">Cambiar contraseña</h1>
                {loading ? ( // Condicional para mostrar el loader
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>

                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="80%" />


                    </Box>
                ) : (<form
                    onSubmit={handleSubmit}
                    style={{
                        width: '100%',
                        position: 'relative'
                    }}>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                            <div className="inputcrearcontraseñanueva">
                                <label className="titulocontraseñalabel" htmlFor="new_password">Contraseña nueva</label>
                                <div className="contienentodocontraseñanueva">
                                    <input  style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }}  className={`crearinputcontraseña ${error.password && 'input-error'} ${passwordError && 'input-error'}`} type={showPassword ? 'text' : 'password'} name="new_password" onChange={(e) => { handleChange(e); handleChangeContraseña(e); handleChangeContraseña(e) }} value={password.new_password} />
                                    <span className="togglenuevoempresapassword" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {error.password && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{error.password}</span>}
                                {passwordError && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                            </div>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                            <div className="inputcrearcontraseñanueva">
                                <label className="titulocontraseñalabel" htmlFor="confirm_new_password">Repetir contraseña</label>
                                <div className="contienentodocontraseñanueva">
                                    <input   style={{
                      background: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                    }} className={`crearinputcontraseña ${error.password && 'input-error'} ${passwordError && 'input-error'}`} type={showPasswordcorrect ? 'text' : 'password'} name="confirm_new_password" onChange={(e) => { handleChange(e); handleChangeRepetirContraseña(e); }} value={password.confirm_new_password} />
                                    <span className="togglenuevoempresapassword" onClick={togglePasswordcorrectVisibility}>
                                        {showPasswordcorrect ? <FaRegEyeSlash /> : <FaRegEye />}
                                    </span>
                                </div>
                            </div>
                            <div>
                                {error.password && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{error.password}</span>}
                                {passwordError && <span className="errorcontraseñamensaje"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                            </div>
                        </Grid>
                    </Grid>
                    <section className="botonguardarcontraseñanuevacreado">
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? <CircularProgress size={24} /> : 'Guardar nueva contraseña'}
                        </button>
                    </section>
                </form>
                )}
            </Box>

            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop: "-5px" }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Popup de error */}
            <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                            {alertMessage}
                        </p>
                    </section>
                </Box>
            </Modal>

            {/* Popup de carga */}
            <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
            </Modal>

        </Box>
    );
};