import React, { useState, useEffect} from "react";
import axios from "axios";
import { useAuth } from "../../../context/usecontext";
import { useNavigate, Link} from "react-router-dom";
import "../../../../Style/admi/adminuser.css";

/* libreria UI */
import { Container,  Box, Grid, CircularProgress,  Select, MenuItem, FormControl, InputLabel, TextField } from '@mui/material';
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* imagenes */
import bordeuno from "../../../../Image/administrador/borde1.png";
import bordedos from "../../../../Image/administrador/borde2.png";


export const AllEmpresas = ()=>{
    const { adminToken, setAdminAuthToken } = useAuth();
    const navigate = useNavigate();
    const [empresas, setEmpresas] = useState([]);
    const [filteredEmpresas, setFilteredEmpresas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState(""); // Estado para el filtro
    const [search, setSearch] = useState(""); // Estado para la búsqueda
    const [nombreEmpresas, setNombreEmpresas] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
    });

    useEffect(() => {
        const fetchEmpresasData = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/empresas`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                setEmpresas(response.data.empresas);
                setFilteredEmpresas(response.data.empresas);
                const nombres = response.data.empresas.map(empresa => ({ id: empresa.id, nombre: empresa.nombre_empresa }));
                setNombreEmpresas(nombres);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener los datos de empresas:", error);
            }
        };

        if (adminToken) {
            fetchEmpresasData();
        }
    }, [adminToken, backendURL]);

    useEffect(() => {
        // Filtrar empresas basado en el filtro y la búsqueda
        const filterEmpresas = () => {
            let filtered = empresas;

            if (filter) {
                filtered = filtered.filter(empresa => empresa.estado === filter);
            }

            if (search) {
                filtered = filtered.filter(empresa =>
                    empresa.nombre_empresa.toLowerCase().includes(search.toLowerCase())
                );
            }

            setFilteredEmpresas(filtered);
        };

        filterEmpresas();
    }, [filter, search, empresas]);

    return(
        <>
            <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "50px"

                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        marginBottom:"50px"
                    }}> 
                        <section className="titulotodaslasempresas">
                            <div className="imagenbordetodosempresasuno"> 
                                <img src={bordeuno} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                            </div>
                            <div className="tituloallempresas">
                                <h2>Bienvenido a las empresas associadas con</h2>
                                <h2> Smart Memorial</h2>
                            </div>
                            <div className="imagenbordetodosempresasdos">
                                <img src={bordedos} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                            </div>
                            
                        </section>
                        <section className="infoallempresas"> 
                            <h4>Selecciona la empresa para revisar su información </h4> 
                            <h4>y modificar las funciones asignadas disponibles.</h4>
                         </section>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}> 
                        <section className="filtro-all-empresa">
                        <FormControl sx={{ minWidth: 800 }}>
                            <InputLabel id="empresa-filtro-label" sx={{ paddingLeft: "5px", fontFamily: "Poppins", color: "#694B81", fontWeight: "500", fontSize:"1rem" }}>Buscar empresa</InputLabel>
                            <StyledSelect
                                labelId="empresa-filtro-label"
                                id="empresa-filtro"
                                label="Empresa"
                                value={search} // Usa el estado de búsqueda para el valor
                                onChange={(e) => setSearch(e.target.value)}
                                IconComponent={KeyboardArrowDownIcon}
                            >
                                <MenuItem value=""><em>Todos</em></MenuItem>
                                {nombreEmpresas.map(empresa => (
                                    <MenuItem key={empresa.id} value={empresa.nombre}>{empresa.nombre}</MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                        <StyledTextField
                            label="Buscar por nombre"
                            variant="outlined"
                            sx={{ marginTop: 2, minWidth: 800 }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </section>
                        <section className="card-allempresa">
                       <Grid container sx={{ display:"flex", alignItems:'center', justifyContent:"center"}}> 
                            {loading ? (
                                        <CircularProgress />
                                    ) : filteredEmpresas.length > 0 ? (
                                        filteredEmpresas.map((empresa) => (
                                            <Grid item xs={3} key={empresa.id} sx={{padding:'10px'}}>
                                                <Link to={`/empresa-admin-smart/${empresa.id}`}>
                                                <button className="card-id-empresa" key={empresa.id}>
                                                    <div className="imagen-card-idempresa">
                                                        {empresa.logo && (
                                                            <img src={`${backendURL}/${empresa.logo}`} alt="smart memorial" title="smart memorial" />
                                                        )}
                                                    </div>
                                                </button>
                                                </Link>
                                            </Grid>
                                        ))
                                    ) : (
                                        <p>No hay empresas disponibles.</p>
                                    )}
                        </Grid>
                            
                            
                       
                        </section>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '5px 13px',
    color: '#694B81',
    position: 'relative',
    left: '0.5%',
    fontFamily: "Poppins",
    width: '100%',
    height: '55px',
    fontSize: '0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#B4A5C0', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B4A5C0', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#B4A5C0', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize: '0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
  
  }));

  const StyledTextField = styled(TextField)(({ theme, error }) => ({
  
    '& .MuiInputBase-root': {
        // Aquí puedes agregar estilos específicos para la clase .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input
        padding: '5px 13px',
        color: '#694B81',
        position: 'relative',
        left: '0.5%',
        fontFamily: "Poppins",
        width: '100%',
        height: '55px',
        fontSize: '0.8rem',
        borderRadius: '50px',
        border: '3px solid #F0EDF3',// Ajustar el padding si es necesario
      },
    // Estilos para el label del campo de entrada
  '& .MuiFormLabel-root': {
    color: '#694B81',
    fontFamily: 'Poppins',
    fontSize: '1rem',
    fontWeight: '500',
  },
  // Estilos para la entrada de texto
  '& .MuiOutlinedInput-input': {
    color: '#694B81',
    fontSize: '1rem',
    padding: '0 8px', // Ajustar el padding si es necesario
  },
  // Estilos para el estado enfocado
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B4A5C0', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  }))