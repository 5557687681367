import React, { useState, useEffect, useCallback } from "react"
import axios from "axios";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import rateLimit from 'axios-rate-limit';
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
/* libreria UI */
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { Grid, Box, CircularProgress, Button, Typography } from "@mui/material";
import icono from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/moradoluto.png"
import { useTheme } from '@mui/material/styles';
import { BsFillBookmarkHeartFill } from "react-icons/bs";
// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});



export const CalendarioMemorial = ({ user, empresa, Token }) => {
    const [memoriales, setMemoriales] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredMemoriales, setFilteredMemoriales] = useState([]);
    const [memorialDates, setMemorialDates] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const { UserDataMarca } = useUserEmpresa();
    const theme = useTheme();
    useEffect(() => {
        fetchMemoriales();
    }, [empresa]);

    const fetchMemoriales = async () => {
        if (!empresa?.id || !Token) return;
        setLoading(true);
        try {
            const response = await https.get(`${backendURL}/api/getMemorialesPorEmpresa/${empresa.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Token}`,
                },
            });
            setMemoriales(response.data);
        } catch (error) {
            console.error("Error al obtener los memoriales", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Procesar las fechas de los memoriales a UTC
        const processedDates = memoriales.map((memorial) => {
            const memorialDate = new Date(memorial.created_at);
            const utcDate = new Date(
                memorialDate.getUTCFullYear(),
                memorialDate.getUTCMonth(),
                memorialDate.getUTCDate()
            );
          
            return utcDate.toDateString();
        });
        setMemorialDates(processedDates);
    }, [memoriales]);

    const filterMemorialesByDate = useCallback((date) => {
        const filtered = memoriales.filter((memorial) => {
            const memorialDate = new Date(memorial.created_at);
            return (
                memorialDate.getUTCFullYear() === date.getUTCFullYear() &&
                memorialDate.getUTCMonth() === date.getUTCMonth() &&
                memorialDate.getUTCDate() === date.getUTCDate()
            );
        });
        setFilteredMemoriales(filtered);
    }, [memoriales]);

    useEffect(() => {
        filterMemorialesByDate(selectedDate);
    }, [selectedDate, memoriales, filterMemorialesByDate]);

    const isMemorialDay = useCallback(
        (date) => {
            const utcDate = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
            );
            const isDay = memorialDates.includes(utcDate.toDateString());
            if (isDay) {
              
            }
            return isDay;
        },
        [memorialDates]
    );

    return (
        <Box
            sx={{
                width: '100%',
                marginTop: "50px",
                marginBottom: '100px',
                textAlign: "center",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    marginBottom: "0px",
                    fontFamily: 'Poppins',
                    fontSize: '1rem',
                    fontWeight: '600',
                    color: '#585857',
                }}
            >
                Calendario de Memoriales {empresa?.nombre_empresa}
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    marginBottom: "20px",
                    fontFamily: 'Poppins',
                    fontSize: '0.8rem',
                    fontWeight: '500',
                    color: '#585857',
                }}
            >
               Haz clic en los recuadros verdes para explorar los memoriales creados.
            </Typography>
            <Calendar
                onChange={setSelectedDate}
                value={selectedDate}
                tileClassName={({ date }) => (isMemorialDay(date) ? 'memorial-day' : null)}
                style={{
                    background:(UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined') 
                    ? UserDataMarca.color_3 
                    : '#ffff',
                }}
            />

            {loading ? (
                <CircularProgress />
            ) : (
                <Box
                    sx={{
                        marginTop: "20px",
                        border: '1px solid',
                        borderColor: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                        width: '90%',
                        borderRadius: '10px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderBottom: '1px solid',
                            borderColor: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                            ? UserDataMarca.color_1 
                            : '#694B81',
                            
                        }}
                    >
                        <Typography
                            style={{
                                color: '#694B81',
                                fontSize: '1rem',
                                fontWeight: '600',
                                margin: '10px',
                                color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                            }}
                        >
                            Memoriales Creados
                        </Typography>
                    </Box>
                    {filteredMemoriales.length > 0 ? (
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                marginTop: "10px",
                                marginBottom: '10px',
                                height: '150px',
                                overflowY: 'auto',
                            }}
                        >
                            {filteredMemoriales.map((memorial) => (
                                <Grid item xs={12} key={memorial.id}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={7}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-start',
                                    
                                            }}
                                        >
                                            <div className="iconomemoempressa" >
                                                <BsFillBookmarkHeartFill style={{
                                                    fontSize:'2rem', color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                                    ? UserDataMarca.color_1 
                                                    : '#694B81',
                                                }} />
                                            </div>
                                            <span className="nombrememorialempresa">{memorial.nombre}</span>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <a
                                                href={`/familiar/memorial/${memorial.id}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <button className="butonvermemorial" style={{
                                                    background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                                    ? UserDataMarca.color_1 
                                                    : '#694B81',
                                                }}>Ver Memorial</button>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <div className="errorinfomemoempresa">
                            <div className="iconomemoempressa">
                                <Diversity2Icon   sx={{
                                   
                                   fontSize: '2rem',
                                   
                                   margin: '0px',
                                   color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                       ? UserDataMarca.color_1 
                       : '#694B81',
                               }} />
                            </div>
                            <Typography
                                sx={{
                                   
                                    fontSize: '0.8rem',
                                    fontWeight: '500',
                                    margin: '0px',
                                    color: (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                        ? UserDataMarca.color_1 
                        : '#694B81',
                                }}
                            >
                                No se encontraron memoriales para esta fecha.
                            </Typography>
                        </div>
                    )}
                </Box>
            )}
        </Box>
    );
};