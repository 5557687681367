import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip, Drawer, List, ListItem, ListItemButton, ListItemText, Divider, Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import "../../Style/navbar/navbar.css"
import LogoutIcon from '@mui/icons-material/Logout';
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";
import PropTypes from 'prop-types';
import { BsTwitterX } from "react-icons/bs";
import { IoLogoLinkedin } from "react-icons/io5";



/* imagenes */
import logo from "../../Image/navbar/movil-smart-memorial-header.png"
import { Grid3x3Sharp } from "@mui/icons-material";



const AnchorTemporaryDrawer = ({ open, onClose,  color1, logomarca, marcas}) => {
  
 

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': { // Selecciona el Paper dentro del Drawer
    background: color1 || '#431E62', // Cambia el color de fondo
  },
      }}
    >
      <Box
        sx={{ width:400, padding: '50px 0px',  background: color1 || '#431E62', color: '#fff', '@media (max-width: 400px)': {
          width: '100%',
        }, }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        id="menunavbaradmi"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop:'-20px' }}>  
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon className="iconoclosenav" />
          </IconButton>
        </Box>
        <List sx={{marginTop:"50px"}}>
        <ListItem disablePadding sx={{marginBottom:"10px"}}>
        
          </ListItem>
          <ListItem disablePadding sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-15%', marginBottom:'5%'}} >
          <Link to={marcas?.web || '/'} style={{ width: '50px' }}>
        <img
            src={logomarca || logo} 
            alt='Smart Memorial'
            width='100%'
        />
    </Link>
          </ListItem>
          {!marcas || marcas.length === 0 ? (
          <Link to ='/'>
          <ListItem disablePadding >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'0.9rem', color:'#fff'}}>
            Acceder a mi cuenta
            </StyledListItemButton>
          </ListItem>
          </Link>
          ) : <Link to ={`/empresa/${marcas.id_empresa}/login/usuario`}>
          <ListItem disablePadding >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'0.9rem', color:'#fff'}}>
            Acceder a mi cuenta
            </StyledListItemButton>
          </ListItem>
          </Link>}
          <Link to ='/Inicio-Seccion-Empresas'>
          <ListItem disablePadding >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center', fontSize:'0.9rem', color:'#fff'}}>
            Acceder a mi cuenta empresa
            </StyledListItemButton>
          </ListItem>
          </Link>
          {!marcas || marcas.length === 0 ? (
          <Link to='/solicitarmemorial'>
          <ListItem disablePadding  >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
            Solicitar memorial
            </StyledListItemButton>
          </ListItem>
           </Link>
           ) : null}
         {!marcas || marcas.length === 0 ? ( 
           <Link to="/smartmemorial-terminos-y-condiciones">    
    <ListItem disablePadding>
        <StyledListItemButton sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '0.9rem', color: '#fff' }}>
            Términos y condiciones
        </StyledListItemButton>
    </ListItem>
         </Link>
        ) : null}
        </List>
        <Divider sx={{ background: "#fff", margin:"10px 10px" }} />
        <List>
          <ListItem disablePadding>
            <StyledListItemButtonfinal >
            <div className="informacionnav">
  <span>Si necesita más información,</span>
  {marcas?.web ? (
    <>
      <span>visítanos a nuestra página web:</span>
      <a href={marcas.web} target="_blank" rel="noopener noreferrer">
        <button>{marcas.web}</button>
      </a>
    </>
  ) : (
    <>
      <span>escríbanos un correo a:</span>
      <a href="mailto:info@smartmemorial.cl">
        <button>info@smartmemorial.cl</button>
      </a>
    </>
  )}
</div>
            
            </StyledListItemButtonfinal>
            </ListItem>
            <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="redessociales">
              <div className="tituloredesnav">
                <h3>Síguenos en:</h3>
              </div>
              <Grid container sx={{ width: "90%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {marcas ? (
          marcas.facebook && marcas.facebook !== 'undefined' && marcas.facebook !== null && marcas.facebook !== "null" && marcas.facebook !== "" ? (
            <Grid item xs={2} sx={{ width: "50%" }}>
              <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.facebook}>
                <div className="rednav"><FaFacebook /></div>
              </a>
            </Grid>
          ) : null // No mostrar nada si la marca existe pero no tiene Facebook válido
        ) : (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/smartmemorial">
              <div className="rednav"><FaFacebook /></div>
            </a>
          </Grid>
        )}

        {/* Instagram */}
        {marcas ? (
          marcas.instagram && marcas.instagram !== 'undefined'  && marcas.instagram !== null && marcas.instagram !== "" && marcas.instagram !== "null" ? (
            <Grid item xs={2} sx={{ width: "50%" }}>
              <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.instagram}>
                <div className="redimstanav"><GrInstagram /></div>
              </a>
            </Grid>
          ) : null // No mostrar nada si la marca existe pero no tiene Instagram válido
        ) : (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/smart.memorial/">
              <div className="redimstanav"><GrInstagram /></div>
            </a>
          </Grid>
        )}
                  {marcas ? (
    marcas.tiktok && marcas.tiktok !== 'undefined' && marcas.tiktok !== null && marcas.tiktok !== "" && marcas.tiktok !== "null" ? (
      <Grid item xs={2} sx={{ width: "50%" }}>
        <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.tiktok}>
          <div className="rednav"><FaTiktok /></div>
        </a>
      </Grid>
    ) : null // Si la marca existe pero no tiene TikTok válido, no se muestra nada
  ) : (
    <Grid item xs={2} sx={{ width: "50%" }}>
      <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@smart.memorial">
        <div className="rednav"><FaTiktok /></div>
      </a>
    </Grid>
  )}
                  {marcas ? (
    marcas.youtube && marcas.youtube !== 'undefined' && marcas.youtube !== null && marcas.youtube !== "" && marcas.youtube !== "null" ? (
      <Grid item xs={2} sx={{ width: "50%" }}>
        <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.youtube}>
          <div className="rednav"><FaYoutube /></div>
        </a>
      </Grid>
    ) : null // No mostrar nada si la marca existe pero no tiene YouTube válido
  ) : (
    <Grid item xs={2} sx={{ width: "50%" }}>
      <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@SmartMemorial">
        <div className="rednav"><FaYoutube /></div>
      </a>
    </Grid>
  )}
                  {/* Mostrar LinkedIn solo si existe */}
                  {marcas?.linkedin && marcas.linkedin !== 'undefined' && marcas.linkedin !== 'null' && (
                      <Grid item xs={2} sx={{ width: "50%" }}>
                          <a className="nulla" target="_blank" href={marcas.linkedin}>
                              <div className="rednav"><IoLogoLinkedin /></div>
                          </a>
                      </Grid>
                  )}
                  {/* Mostrar Twitter solo si existe */}
                  {marcas?.twiter_x && marcas.twiter_x !== 'undefined' && marcas.twiter_x !== 'null' && (
                      <Grid item xs={2} sx={{ width: "50%" }}>
                          <a className="nulla" target="_blank" href={marcas.twiter_x}>
                              <div className="rednav"><BsTwitterX /></div>
                          </a>
                      </Grid>
                  )}
              </Grid>
              </div>
            </StyledListItemButtonfinal>
          </ListItem>
        </List>

        
      </Box>
    </StyledDrawer>
  );
};


export const Navbar =({ color1, logomarca, marcas }) => {
    const {id} = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
      setDrawerOpen(open);
    };
  
 

  return (
    <StyledAppBar position="static" className='nav' sx={{ background: color1 || '#431E62' }}>
     
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, padding:'0px 20px' }}>
          <Link to={marcas?.web || '/'} style={{ width: '50px' }}>
        <img
            src={logomarca || logo} 
            alt='Smart Memorial'
            width='100%'
        />
    </Link>
        
          </Box>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           
         
          </Box>

          <Box sx={{ flexGrow: 0, paddingRight:'20px' }}>
            <Tooltip title="Open settings">
            <IconButton
            sx={{ fontSize:'1.8rem'}}
                size="large"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
              <MenuIcon />
            </IconButton>
             
            </Tooltip>
            <AnchorTemporaryDrawer color1={color1} logomarca={logomarca} marcas={marcas} open={drawerOpen} onClose={toggleDrawer(false)}/>
          </Box>
        </Toolbar>
      
    </StyledAppBar>
  );
}


const StyledAppBar = styled(AppBar)({
  boxShadow: "none",
});


const StyledDrawer = styled(Drawer)({
  
});

const StyledListItemButton = styled(ListItemButton)({
  fontSize:"1.1rem",
  marginBottom:"10px"
});

const StyledListItemButtonfinal = styled(ListItemButton)({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
  marginBottom:"10px"
});

const StyledGrid  = styled(Grid )({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
   flexDirection:"row,"
});
