import React from "react";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Box, Grid, CircularProgress, Modal, Typography, Button } from "@mui/material";
import "../../../../Style/usuario/evento.css"
import { IoIosArrowBack } from "react-icons/io";
import { RxCalendar } from "react-icons/rx";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { LuClock2 } from "react-icons/lu";
import { useUsers } from "../../../context/userscontexts";

export const Crearevento = () => {
    const location = useLocation();
    const {userData} = useUsers();
    const ClientToken = location.state.user;
    const grupoFamiliar = location.state.familia;
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [link, setLink] = useState(null);
    const [logoUrl, setLogoUrl] = useState('');
    const [evento, setEvento] = useState({
        titulo: "",
        fecha: "",
        hora: "",
        url:" ",
        descripcion: "",
        id_memorial: ""
    });

     

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEvento({ ...evento, [name]: value });
    }; 

    const handleClick = () => {
        const state = { user: ClientToken };
        navigate('/evento', { state });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formattedDate = evento.fecha.split('-').join('-');

       
        try {
            setenviandoAlert(true);
            const response = await axios.post(
                `${backendURL}/api/store/eventos`,
                { ...evento, fecha: formattedDate },
                {
                    headers: {
                        Authorization: `Bearer ${ClientToken.token}`
                    }
                }
            );
            setIsLoading(false);
            setShowModal(true);
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Su evento fue creado con éxito.')
           
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al crear el evento, vuelva a intentar.', error);
        }
        finally{
            setenviandoAlert(false);
       }
      
       // Simulación de tiempo de carga
       setTimeout(() => {
           handleCloseModals();
       }, 3000);
       // Simulación de tiempo de carga
       setTimeout(() => {
           setSuccessAlertOpen(false);
           setErrorAlertOpen(false);
       }, 2000);
        

    };

    useEffect(() => {
      const fetchFuneraria = async () => {
        try {
          if (grupoFamiliar && grupoFamiliar.id_funeraria) {
            const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
            const funerariaData = response.data;
            setLink(funerariaData);
            if (funerariaData.nombre === 'smart memorial') {
              setLogoUrl(null); // No mostrar logo
            } else if (funerariaData.logo) {
            setLogoUrl(funerariaData.logo);
            } else {
              setLogoUrl(null); // No hay logo disponible
            }
        
          } else {
            console.error('Group family or funerary ID is missing');
          }
        } catch (error) {
          console.error('Error fetching funeraria data:', error);
        }
      };
    
      fetchFuneraria();
    }, [grupoFamiliar]); 

    const handleCloseModals = () => {
        setenviandoAlert(false);
        navigate('/evento', { state: { user: ClientToken } });
      };
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/evento', { state: { user: ClientToken } });
    };
    return (
      <section className="contienenpaginainiciousuario">
      {logoUrl && (
            <Link to={link.web} className="logofunerariamemorial" target="black"> 
          
           <img
             src={`${backendURL}/${logoUrl}`}
             alt="Logo de la funeraria"
             className="logofun"
  
           />
         </Link>
          )}
            <Container className="contieneregistro" component="main" maxWidth="xs">
                <Box sx={{

                    display: 'flex',
                    justifyContent: 'flex-star',
                    alignItems: 'center',
                    padding: '0px',
                    marginTop:"10px",
                   
                }}>
                    <IoIosArrowBack className="flecharetrocederevento" onClick={handleClick}  style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}/>
                </Box>
                <Box sx={{
                    padding: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <section className="titulocrearevento">
                        <h1 style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>Nuevo evento</h1>
                        <span>Conmemore fechas y momentos importantes, agregue videos para compartir momentos especiales.</span>
                       
                    </section>
                </Box>
                <Box sx={{
                    padding: '0px',
                    display: 'flex',
                    alignContent: 'flex-start',
                    flexDirection: 'column'
                }}>
                     <form onSubmit={handleSubmit}>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }} >
                            <div className="inputcrearevento">
                                <label className="titulocreareventolabel" htmlFor="">Título
                                </label>
                                <input 
                                style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                                className="crearinputevento" type="text" name="titulo" value={evento.titulo} 
        onChange={handleChange} />
                            </div>
                        </Grid>
                        <Grid container sx={{ width: '100%' }}>
                            <Grid item xs={8} sx={{ width: '100%', marginBottom: '10px'}}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="editfecha">Fecha</label>
                                    <div className="contienencalendariocrearevento">
                                        <input
                                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                                            type="date"
                                            className="inputeventofecha"
                                            name='fecha'
                                            value={evento.fecha} 
                                            onChange={handleChange} 
                                        />
                                        <div className="relojicono">
                                        <RxCalendar className="iconoeventocalendario"
                                        style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} />
                                   </div> </div>

                                </div>
                            </Grid>
                            <Grid item xs={4} sx={{ width: '100%', marginBottom: '10px', marginTop:'1px', position:'relative',right:'0px'  }}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="">Hora
                                    </label>
                                    <div  className="contienenrelojcrearevento">
                                    <input className="crearinputevento" 
                                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                                    type="time" 
                                    name="hora" 
                                    value={evento.hora} 
                                     onChange={handleChange} />
                                     <div className="relojicono">
                                     <LuClock2 className="iconoeventocalendario"
                                     style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}/>
                                     </div>
                                </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="id_memorial">Selección Memorial</label>
                                    <select className="crearmeminputevento"
                                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}} name="id_memorial" value={evento.id_memorial} onChange={handleChange}>
                                    <option value="" disabled>Selecciona el memorial</option>
                                        {grupoFamiliar.memorial.map((memorial) => (
                                            <option key={memorial.id} value={memorial.id}>
                                                {memorial.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Grid>
                            <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}>
                                <div className="inputcrearevento">
                                    <label className="titulocreareventolabel" htmlFor="url">Agregar link de video</label>
                                    <input
                                    style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                                     className="crearinputevento" name="url" value={evento.url} onChange={handleChange}/>
                                   
                                </div>
                            </Grid>
                        <Grid item xs={12} sx={{ width: '100%', marginBottom: '10px' }}> <div className="inputcrearevento">
                            <label className="titulocreareventolabel" type="text" htmlFor="">Descripción del evento
                            </label>
                            <textarea
                            style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}
                             className="creardebinputevento" type="text"  name="descripcion" value={evento.descripcion} 
        onChange={handleChange} />
                        </div>
                        </Grid>

                    </Grid>

                    <section className="botonguardareventocreado">
                            <button type="submit" disabled={isLoading} style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :'#694B81'}}>
                                {isLoading ? <CircularProgress size={24} /> : "Guardar evento"}
                            </button>
                        </section>
                    </form>
                </Box>
            </Container>

           


            <Modal open={successAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>

        </section>
    )
}