import React from "react";
import axios from 'axios';
import { useLocation, Link } from "react-router-dom";
import { useState, useEffect } from 'react'
import "../../../../Style/usuario/inicio-usuario.css"
import Box from "@mui/material/Box";
import { FiAlertCircle } from "react-icons/fi";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Modal from '@mui/material/Modal';
import { IoClose } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import { useUsers, userData } from "../../../context/userscontexts";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { MdGroups } from "react-icons/md";




export const InicioUsuario = () => {
  const location = useLocation();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const [comentarios, setComentarios] = useState([]);
  const [grupoUser, setGrupoUser] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [openModal, setOpenModal] = useState(false);
  const [memorialName, setMemorialName] = useState('');
  const [selectedComentario, setSelectedComentario] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [link, setLink] = useState(null); 
  const {  userData } = useUsers(); 

 

  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariomemoriales', { state });
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  /* funeraria Logo traer */
  useEffect(() => {

    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData);
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };




    fetchFuneraria();

    
  }, [grupoFamiliar]);


  /* mensaje */
  const obtenerComentarios = async () => {
    try {
      const response = await axios.get(`${backendURL}/api/comentarioGetGroup`, {
        headers: {
          Authorization: `Bearer ${ClientToken.token}`
        },
        params: { id_grupo_familiar: grupoFamiliar.id }
      });
      const comentariosFiltrados = response.data.filter(comentario => comentario.visible === '0');
      setComentarios(comentariosFiltrados);
    } catch (error) {
      console.error('Error al obtener los comentarios:', error);
    }
  };

  useEffect(() => {
    obtenerComentarios();
  }, [grupoFamiliar, ClientToken]);

  const handleOpenPopup = (message) => {
    setPopupMessage(message);
    setShowPopup(true);

    setTimeout(() => {
      setShowPopup(false);
      setPopupMessage('');
    }, 2000); 
  };

  useEffect(() => {
    const fetchGrupoUser = async () => {
      try {
        const response = await axios.get(`${backendURL}/api/getGrupoUser/${ClientToken.userId}`, {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        });
       
        const data = response.data;
  
        if (data && data.length > 0) {
          const userData = data[0].user[0];
          setGrupoUser(userData);
  
          // Verificar si el estado del usuario es "0"
          if (userData.estado === "0") {
            // Redirigir al usuario a la página de inactividad con el estado necesario
            const state = { user: ClientToken, familia: grupoFamiliar };
            navigate('/usuarioinactivo', { state });
          }
        } else {
          setGrupoUser(null);
        }
        setLoading(false); // Marcar el estado como no cargando después de obtener los datos
        
      } catch (error) {
        console.error('Error fetching grupo familiar:', error);
        setLoading(false); // También necesitas marcar el estado como no cargando en caso de error
      }
    };
  
    if (ClientToken) {
      fetchGrupoUser(); // Llamar a fetchGrupoUser solo si ClientToken está definido
    }
  }, [ClientToken, backendURL, navigate, grupoFamiliar]);

if (loading) {
    return <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop:"30px" }}><CircularProgress sx={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} /></Box>;
}


  const handleClosePopup = () => {
    setShowPopup(false);
    setPopupMessage('');
    clearTimeout();
  };

  const handleOpenModal = (comentario) => {
    setSelectedComentario(comentario);
    setOpenModal(true);
    // Buscar el nombre del memorial asociado al comentario seleccionado
    const memorialnombre = grupoFamiliar.memorial.find(memorial => memorial.id === parseInt(comentario.id_memorial));

 if (memorialnombre) {
  setMemorialName(memorialnombre.nombre);
} else {
  console.error("Memorial no encontrado para el comentario:", comentario);
}
  };
  


  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedComentario(null);
    setMemorialName('');
  };

  const handleAceptar = async () => {
    setLoading(false);
    try {
      setLoading(false);
      const response = await axios.post(
        `${backendURL}/api/comentarioState`,
        {
          id: selectedComentario.id,
          visible: 1
        },
        {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`
          }
        }
      );

      // Si la solicitud fue exitosa
      if (response.status === 200) {
        // Actualiza el comentario con el estado actualizado
        setComentarios(prevComentarios =>
          prevComentarios.map(comentario =>
            comentario.id === selectedComentario.id ? { ...comentario, visible: response.data.visible } : comentario
          )
        );
        handleOpenPopup('Comentario aceptado con éxito.');
        // Cierra el modal
        handleCloseModal();

        // Vuelve a obtener los comentarios actualizados
        obtenerComentarios();
      }
    } catch (error) {
      console.error('Error al cambiar el estado del comentario:', error);
      handleOpenPopup('Error al aceptar el comentario, vuelva a intentar.');
    }
    finally {
      setLoading(false);
      
    }
  };

  const handleDelete = async () => {
    setLoading(false);
    try {
      setLoading(false);
      await axios.post(
        `${backendURL}/api/comentarioDelete`,
        { id: selectedComentario.id },
        {
          headers: {
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );
      handleOpenPopup('Comentario Eliminado con éxito.');
      // Filtra los comentarios excluyendo el comentario que se va a eliminar
      const updatedComentarios = comentarios.filter(comentario => comentario.id !== selectedComentario.id);
      setComentarios(updatedComentarios);
 
      // Cierra el modal
      handleCloseModal();
      
      // Vuelve a obtener los comentarios actualizados
      obtenerComentarios();
    } catch (error) {
      console.error('Error al eliminar el comentario:', error);
      handleOpenPopup('Error al eliminar el comentario, vuelva a intentar.');
     
    }
    finally{
      setLoading(false);
    }
  };

 



  

  
  return (
    <section className="contienenpaginainiciousuario" >

{logoUrl && (
          <Link to={link.web} className="logofunerariamemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
      <Container className="contieneregistro" component="main" maxWidth="xs">


        <Box sx={{
          padding: '40px 0px 20px 0px',
          '@media (max-width: 745px)': {
            padding: '0px 0px 20px 0px',
          }

        }}>

          <div className="titulobienvenidausuario">
            <h1 className="titulo-bienvenida-usuario" style={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>{grupoUser.genero === 'Femenino' ? 'Bienvenida' : grupoUser.genero === 'Masculino' ? 'Bienvenido' : 'Bienvenides'},</h1>
            <h1 className="titulo-bienvenidanombre" style={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>{grupoUser.name}</h1>
            <h1 className="titulo-bienvenidanombre" style={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>{grupoUser.apellido}</h1>
          </div>
        </Box>
        <Box sx={{
          cursor: 'pointer',
          padding: '25px 0px',
          background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
          marginTop: '40px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '30px 0px', // Margen por defecto
          '@media (max-width: 450px)': {
            margin: '30px 0px'
          },
          '@media (max-width: 259px)': {
            margin: '20px 0px',
            padding: '10px 0px',
          }
          
        }}
        onClick={handleClick}
        >
          <div className="contienentitulofamilia">
            <div className="titulofamilia">

              <h3 style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>
                Grupo Familiar
              </h3>
             
                <h1 style={{color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>{grupoFamiliar.nombre_familia}</h1>
            
                <MdGroups style={{fontSize:'3.5rem',color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999'}} />
            </div>
            <div className="buttonfamilia">

              <button className="flechasiguiente" style={{ cursor: 'pointer', color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }} onClick={handleClick}><IoIosArrowForward  style={{  color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}/></button>

            </div>
          </div>

        </Box>
        <Box>
          <div className="titulomensajesfamiliageneral">
            <h1 style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}>Solicitudes de mensajes</h1>
            {comentarios.length > 0 && <span className="cantidaddemensajesusuario">({comentarios.length})</span>}
          </div>
          <div className="muestrademensajesfamiliageneral">
            {comentarios.length === 0 ? (
              <div className="noexistenmensajes">
                <FiAlertCircle />
                <h1 
                style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}>No hay nuevos mensajes</h1>
              </div>
            ) : (
              <div className="conitenentodoimagengroupcomt">
                {comentarios.map(comentario => (
                  <div className="cardgroupmensajeaparicion" style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }} key={comentario.id}>
                    <div className="toogroupcomt">
                      <div className="titulogroupgeneradocoment">
                        <h3>{capitalizeFirstLetter(comentario.titulo)}</h3>
                      </div>

                      <div className="quiengrouptlorealizohora">
                        <h3>{comentario.creador}</h3>  •
                        <h3>{new Date(comentario.fecha).toLocaleDateString('es-ES')}</h3>
                      </div>
                      <section className="butonmensajever">
                        <button className="botonvermasmensaje" onClick={() => handleOpenModal(comentario)}> Ver mensaje</button>
                      </section>
                    </div>
                  </div>

                ))}
              </div>
            )}
          </div>
          <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box sx={{
              position: 'absolute',
              top: '50%', 
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400, bgcolor:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' ,
              boxShadow: 24,
              p: 2,
              borderRadius: "10px",
              '@media (max-width: 500px)': {
                width: '85%',

              },
              '@media (max-width: 325px)': {
                width: '85%',
                p: 1,

              }
            }}>
              <section className="botondecierremodalmensajeusuario" onClick={handleCloseModal}> <IoClose style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}/></section>
            <div className="contienentodoelmensaje">
              <div className="titulogroupgeneradocoment">
                <h3>{capitalizeFirstLetter(selectedComentario?.titulo)}</h3>
              </div>
              <div className="comentariogroupgeneradocomt" style={{ maxHeight: '200px', overflowX: 'auto', WebkitOverflowScrolling: 'touch', marginTop: '10px' }}>
                <p>{selectedComentario?.mensaje ? (
    <DangerouslySetHTMLContent html={capitalizeFirstLetter(selectedComentario?.mensaje)} />
  ) : (
    <span></span>
  )}</p>
              </div>
              <div className="quientgrupolorealizohora">
                <h3>{selectedComentario?.creador}</h3>  •
                <h3>{new Date(selectedComentario?.fecha).toLocaleDateString('es-ES')}</h3>
              </div>
             
             
                {selectedComentario?.imagen && (
                  <img
                    src={`${backendURL}/${selectedComentario?.imagen}`}
                    alt="smart memorial"
                    className="imagencomentarioiniciofinal"
                  />
                )}
             
              
               </div>
              
              <section className="contienenbasemensaje">
              <div className="alertadelmensajecreado"> <FiAlertCircle /> <div className="textoalertamensajeusuario" style={{ color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }}> <p>Este mensaje se mostrará en el Memorial de</p> <strong><p>{memorialName}</p></strong> </div></div>
              <section className="botonesmodalmensajeusuariosino">
                <button className="rechazar" onClick={handleDelete}>Rechazar</button>
                <button className="aceptar" onClick={handleAceptar}>Aceptar</button>
                </section>
                </section>
            </Box>
          </Modal>
          {showPopup && (
          <div className="popupmodal">
            <div className="popupmensaje">
            <CircularProgress sx={{color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
              <p style={{ fontFamily:"Poppins", color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize:"0.8rem", fontWeight:"600", marginTop: '20px'}}>Espere un momento</p>
              <p className="mensajefinalpop">{popupMessage}</p>
              
            </div>
          </div>
        )}
         
        </Box>
      </Container>



    </section>

  )
}