import React, { createContext, useContext, useState, useEffect } from "react";

const UsuarioEmpresaContext = createContext();

export const AuthEmpresasProvider = ({ children }) => {
  const [Token, setToken] = useState(null);
  const [User, setUser] = useState(null); // Estado para almacenar el usuario
  const [UserDataMarca, setUserDataMarca] = useState(null);
  const [Usuario, setUsuario] = useState(null);
  const [Empresa, setEmpresa] = useState(null);
  const [Sucursal, setSucursal] = useState(null);


  // Recuperar el token y el usuario de sessionStorage al cargar la aplicación
  useEffect(() => {
    const storedToken = sessionStorage.getItem('Token');
    const storedUser = sessionStorage.getItem('User');
    const storedUserDataMarca = sessionStorage.getItem('UserDataMarca');
    const storedUsuario = sessionStorage.getItem('Usuario');
    const storedEmpresa = sessionStorage.getItem('Empresa');
    const storedSucursal= sessionStorage.getItem('Sucursal');

    if (storedToken) {
      setToken(storedToken); // Establece el token almacenado en el estado
    }
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Establece el usuario almacenado en el estado
    }
    if (storedUserDataMarca) {
      setUserDataMarca(JSON.parse(storedUserDataMarca));
    }
    if (storedUsuario) {
      setUsuario(JSON.parse(storedUsuario));
    }
    if (storedEmpresa) {
      setEmpresa(JSON.parse(storedEmpresa));
    }
    if (storedSucursal) {
      setSucursal(JSON.parse(storedSucursal));
    }
  }, []); // Solo ejecuta al montar el componente

  // Función para actualizar tanto el estado como sessionStorage
  const setAdminUserEmpresaToken = (token, User) => {
    setToken(token); // Actualiza el estado del token
    setUser(User); // Actualiza el estado del usuario
    if (token) {
      sessionStorage.setItem('Token', token); // Guarda el token en sessionStorage
    } else {
      sessionStorage.removeItem('Token'); // Elimina el token de sessionStorage si es null
    }
    if (User) {
      sessionStorage.setItem('User', JSON.stringify(User)); // Guarda el usuario en sessionStorage
    } else {
      sessionStorage.removeItem('User'); // Elimina el usuario de sessionStorage si es null
    }
  };

  const setUserInformationMarca = (data) => {
    setUserDataMarca(data);
    if (data) {
      sessionStorage.setItem('UserDataMarca', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('UserDataMarca');
    }
  };

  const setUserInformationUsuario = (data) => {
    setUsuario(data);
    if (data) {
      sessionStorage.setItem('Usuario', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('Usuario');
    }
  };

  
  const setUserInformationEmpresa = (data) => {
    setEmpresa(data);
    if (data) {
      sessionStorage.setItem('Empresa', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('Empresa');
    }
  };
  const setUserInformationSucursal = (data) => {
    setSucursal(data);
    if (data) {
      sessionStorage.setItem('Sucursal', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('Sucursal');
    }
  };

  return (
    <UsuarioEmpresaContext.Provider value={{ Token, User, UserDataMarca, Usuario, Empresa, Sucursal, setUserInformationSucursal, setAdminUserEmpresaToken, setUserInformationMarca, setUserInformationUsuario, setUserInformationEmpresa  }}>
      {children}
    </UsuarioEmpresaContext.Provider>
  );
};

// Cambia el nombre del hook para que comience con "use"
export const useUserEmpresa = () => useContext(UsuarioEmpresaContext);