import React, { useState, useEffect } from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
    import axios from "axios";
    import { Grid,  WarningAmberIcon, Modal, Box, Table, Alert,Snackbar, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, FormControl, Select, MenuItem } from "@mui/material";
    import EmailIcon from '@mui/icons-material/Email';
    import WhatsAppIcon from '@mui/icons-material/WhatsApp';

    export const EnviarEncuesta = ({ encuestaId }) => {
        const { Token, UserDataMarca, Empresa } = useUserEmpresa();
        const [selectedYear, setSelectedYear] = useState("");
        const [selectedMonth, setSelectedMonth] = useState("");
        const [usuariosAgrupados, setUsuariosAgrupados] = useState({});
        const whatsappNumber = Empresa.telefono;
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [customMessage, setCustomMessage] = useState("");
        const [snackbarOpen, setSnackbarOpen] = useState(false);
        const [snackbarMessage, setSnackbarMessage] = useState("");
        const [snackbarSeverity, setSnackbarSeverity] = useState("info");
        const [status, setStatus] = useState({});
        const [selectedUser, setSelectedUser] = useState(null);
        const backendURL = process.env.REACT_APP_BACKEND_URL;
    
        // Obtener usuarios por empresa
        useEffect(() => {
        if (Empresa?.id && Token) {
            const fetchUsuarios = async () => {
            try {
                const { data } = await axios.get(
                `${backendURL}/api/usuariosgetempresatraer/${Empresa.id}`,
                {
                    headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Token}`,
                    },
                }
                );
                setUsuariosAgrupados(data.data);
            } catch (error) {
                console.error("Error al cargar los usuarios:", error);
            }
            };
            fetchUsuarios();
        }
        }, [Empresa?.id, Token]);
    
        const filteredUsuarios = usuariosAgrupados?.[selectedYear]?.[selectedMonth] || [];

     // Función para manejar el envío de WhatsApp a un usuario específico
     const handleEnviarwhatssap = async () => {
        if (!selectedYear || !selectedMonth) {
          showSnackbar("Debe seleccionar un año y un mes.", "warning");
          return;
        }
      
        if (filteredUsuarios.length === 0) {
          showSnackbar("No hay usuarios disponibles para este mes.", "info");
          return;
        }
      
        if (!selectedUser) {
          showSnackbar("No se ha seleccionado un usuario.", "warning");
          return;
        }
        
        const usuario = filteredUsuarios.find((u) => u.id === selectedUser);
        if (!usuario) {
          showSnackbar("Usuario no encontrado.", "error");
          return;
        }
      
        // Actualizar el estado a 'loading' para el usuario seleccionado
        setStatus((prev) => ({ ...prev, [usuario.id]: "loading" }));
      
        // Construir el mensaje y el enlace de WhatsApp
        const encuestaUrl = `${window.location.origin}/encuesta/${Empresa.id}/${encuestaId}/${usuario.id}`;
        const mensaje = `Hola ${usuario.name} ${usuario.apellido}, ${customMessage} ${encuestaUrl}`;
        const mensajeEncoded = encodeURIComponent(mensaje);
      
        const cleanedNumber = whatsappNumber.replace(/\D/g, ""); // Elimina caracteres no numéricos
        const whatsappLink = `https://wa.me/${cleanedNumber}?text=${mensajeEncoded}`;
        
        try {
          // Abrir el enlace de WhatsApp en una nueva pestaña
          window.open(whatsappLink, "_blank");
      
          // Simular el cambio de estado a 'sent' después de un breve retraso
          setTimeout(() => {
            setStatus((prev) => ({ ...prev, [usuario.id]: "sent" }));
            showSnackbar(`Encuesta enviada a ${usuario.name} ${usuario.apellido}.`, "success");
          }, 1000); // Ajusta el tiempo según tus necesidades
        } catch (error) {
          console.error("Error al enviar WhatsApp:", error);
          setStatus((prev) => ({ ...prev, [usuario.id]: "error" }));
          showSnackbar(`Error al enviar WhatsApp a ${usuario.name}.`, "error");
        }
      };
    
  const openModal = (usuarioId) => {
    setSelectedUser(usuarioId);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setCustomMessage("");
    setSelectedUser(null);
  };

    const years = [2024, 2025];
    const months = [
      { id: 1, name: "Enero" },
      { id: 2, name: "Febrero" },
      { id: 3, name: "Marzo" },
      { id: 4, name: "Abril" },
      { id: 5, name: "Mayo" },
      { id: 6, name: "Junio" },
      { id: 7, name: "Julio" },
      { id: 8, name: "Agosto" },
      { id: 9, name: "Septiembre" },
      { id: 10, name: "Octubre" },
      { id: 11, name: "Noviembre" },
      { id: 12, name: "Diciembre" },
    ];
  
    const showSnackbar = (message, severity = "info") => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      /* enviar por correo */
      const handleEnviarCorreo = async () => {
        if (!selectedYear || !selectedMonth) {
          setSnackbarMessage("Debe seleccionar un año y un mes.");
          setSnackbarSeverity("warning");
          setSnackbarOpen(true);
          return;
        }
      
        try {
          // Preparar los datos para el endpoint
          const payload = {
            fecha: `${selectedYear}-${String(selectedMonth).padStart(2, "0")}-01`, // Formato de fecha: YYYY-MM-DD
            id_empresa: Empresa.id,
            id_encuesta: encuestaId,
          };
      
          // Realizar la solicitud POST
          const { data } = await axios.post(`${backendURL}/api/envio/encuesta`, payload, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`, // Asegúrate de pasar el token si es necesario
            },
          });
      
          // Mostrar mensaje de éxito
          setSnackbarMessage(data.message || "Correos enviados exitosamente.");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
        } catch (error) {
          console.error("Error al enviar correos:", error);
          const errorMessage =
            error.response?.data?.message || "Ocurrió un error al enviar los correos.";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
    return (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={8}>
          {/* Selección de año y mes */}
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: "#585857" }}>
              Seleccione el año:
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                displayEmpty
                sx={{
                  color: "#585857",
                  border: "1px solid #585857",
                }}
              >
                <MenuItem value="" disabled>
                  Año
                </MenuItem>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
  
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ color: "#585857", mt: 2 }}
            >
              Seleccione el mes:
            </Typography>
            <FormControl fullWidth>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                displayEmpty
                sx={{
                  color: "#585857",
                  border: "1px solid #585857",
                }}
              >
                <MenuItem value="" disabled>
                  Mes
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.id} value={month.id}>
                    {month.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div> 
                <Typography variant="subtitle1" sx={{ color: "#585857", mt: 2 }}>
    Cantidad de usuarios del mes:{" "}
    <span style={{ fontWeight: "bold" }}>{filteredUsuarios.length}</span>
  </Typography>
  </div>

  <Box sx={{ textAlign: "center", mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEnviarCorreo}
              disabled={!selectedYear || !selectedMonth}
              sx={{
                textTransform: "capitalize",
                backgroundColor: "#0078D4",
                color:'#ffff', // Color azul
                '&:hover': { backgroundColor: '#005C8C' }, // Color azul más oscuro
                padding: "10px 20px",
                marginRight: "10px",
              }}
            >
              <EmailIcon sx={{ mr: 1 }} />
              Enviar por correo
            </Button>
            
          </Box>    
          </Grid>
  
          {/* Tabla de usuarios */}
          <Grid item xs={12} md={9}>
            <Typography variant="subtitle1" gutterBottom sx={{ color: "#585857" }}>
              Lista de Usuarios
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell sx={{ color: "#585857" }}>Whatssapp</TableCell>
                    <TableCell sx={{ color: "#585857" }}>Nombre</TableCell>
                    <TableCell sx={{ color: "#585857" }}>Correo Electrónico</TableCell>
                    <TableCell sx={{ color: "#585857" }}>Número Telefónico</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsuarios.length > 0 ? (
                    filteredUsuarios.map((usuario) => (
                      <TableRow key={usuario.id}>
                         <TableCell>
                         <Button
                            variant="contained"
                            color={
                                status[usuario.id] === "loading"
                                ? "warning"
                                : status[usuario.id] === "sent"
                                ? "success"
                                : "primary"
                            }
                            disabled={status[usuario.id] === "loading" || status[usuario.id] === "sent"}
                            onClick={() => {
                                openModal();
                                setSelectedUser(usuario.id); // Guardamos el usuario actual para manejar su estado
                            }}
                            sx={{
                                textTransform: "capitalize",
                                backgroundColor:
                                status[usuario.id] === "sent" ? "#4CAF50" : "#25D366", // Cambia el color cuando esté enviado
                                color: "#ffff",
                                "&:hover": {
                                backgroundColor:
                                    status[usuario.id] === "sent" ? "#388E3C" : "#128C7E", // Color al hacer hover en "Enviado"
                                   
                                },
                                '&.Mui-disabled':{
                                     color: '#68A354',
                                },
                            }}
                            >
                            <WhatsAppIcon sx={{ mr: 1 }} />
                            {status[usuario.id] === "loading"
                                ? "Cargando..."
                                : status[usuario.id] === "sent"
                                ? "Enviado"
                                : "WhatsApp"}
                            </Button>
                         </TableCell>
                        <TableCell sx={{ color: "#585857" }}>
                          {usuario.name}
                        </TableCell>
                        <TableCell sx={{ color: "#585857" }}>
                          {usuario.email}
                        </TableCell>
                        <TableCell sx={{ color: "#585857" }}>
                          {usuario.telefono}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        sx={{ color: "#585857" }}
                      >
                        No hay clientes disponibles para este mes.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
           
          </Grid>
        </Grid>
        <Modal open={isModalOpen} onClose={closeModal}>
  <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 600,
      bgcolor: "#ffff",
      border: "1px solid #000",
      boxShadow: 24,
      borderRadius:'10px',
      p: 4,
    }}
  >
    <div>
    <Typography variant="h6" component="h2" sx={{
       color: "#585857", fontSize:'1rem', display:'flex', alignItems:'center', justifyContent:'center', 
    }}>
      Escribe el mensaje personalizado para enviar 
    </Typography>
    <Typography variant="h6" component="h2" sx={{
       color: "#585857", fontSize:'1rem', display:'flex', alignItems:'center', justifyContent:'center', 
    }}>
      la encuesta por <WhatsAppIcon sx={{ mr: 1, ml:1 }} /> WhatsApp
    </Typography>
    </div>
    <textarea
    
      rows={4}
      value={customMessage}
      onChange={(e) => setCustomMessage(e.target.value)}
      style={{ width: "100%", marginTop: "10px", padding: "10px", fontSize: "16px", fontFamily:'Poppins',  color: "#585857", background:'#ffff' }}
      placeholder="Escribe tu mensaje aquí"
    />
    <Box sx={{ textAlign: "right", marginTop: "10px" }}>
      <Button
      sx={{
        textTransform: "capitalize",
        color:'#ffff'
      }}
        variant="contained"
        color="success"
        onClick={() => {
          handleEnviarwhatssap(customMessage);
          closeModal();
        }}
        disabled={!customMessage.trim()}
      >
        Enviar
      </Button>
    </Box>
  </Box>
</Modal>
<Snackbar
  open={snackbarOpen}
  autoHideDuration={4000} // Duración en milisegundos
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
>
  <Alert
    onClose={() => setSnackbarOpen(false)}
    severity={snackbarSeverity}
    sx={{ width: "100%" }}
  >
    {snackbarMessage}
  </Alert>
</Snackbar>

      </Box>
    );
  };