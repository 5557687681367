import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ReporteFuncion } from "../../FuncionesEmpresaAdmi/Tareas/Reportefuncion";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import EmpresaLayout from "../../../../../../EmpresaLayout"
/* MATERIAL UI */
import { Container, Box, Modal, Grid, Select, MenuItem, CircularProgress} from '@mui/material';

export const IndexTareaBaseDatos = ({setShowNavbarFooter}) => {
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
    return (
        <EmpresaLayout>
        <Container maxWidth="lg"> 
            <ReporteFuncion  empresa={Empresa}  Token={Token}  />
        </Container>
        </EmpresaLayout>

    )
}