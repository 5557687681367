import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Box, Typography, Grid, Button,  Checkbox, FormControlLabel  } from '@mui/material';
import { useUserEmpresa } from '../../../../../../context/UsuarioEmpresa';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

 const ComentarioSucursalReporte = ({ selectedOption, selectedOptioncliente, setselectedOptioncliente, setSelectedOptionsucursal,  selectedDate, setSelectedDate, sucursal, id}) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { Token, Sucursal, Usuario } = useUserEmpresa();
  const [comentarios, setComentarios] = useState([]);
  const [comentariosClientes, setComentariosClientes] = useState([]);
  const [mostrarFechas, setMostrarFechas] = useState(false); // Estado para mostrar todas las fechas
  const [mostrarFechascliente, setMostrarFechascliente] = useState(false);

  // Fetch para comentarios generales
  useEffect(() => {
    if (!Sucursal?.id) {
      console.warn("Sucursal no está definida o no tiene ID.");
      return;
    }

    const fetchComentariosGeneral = async () => {
      try {
        
        const response = await Axios.get(
          `${backendURL}/api/usuarios/comentarios/general/sucursal/${Sucursal.id}`,
          {
            headers: { Authorization: `Bearer ${Token}` },
          }
        );
      
        const fechas = response.data.fechas.map((fecha) => ({
          dia: fecha,
          mes: fecha.slice(0, 7),
        }));

        setComentarios(fechas);
        setMostrarFechas(true);
      } catch (error) {
        console.error("Error en fetchComentariosGeneral:", error.message);
      }
    };

    fetchComentariosGeneral();
  }, [Sucursal, backendURL, Token]);

  // Fetch para comentarios de administrador
  useEffect(() => {
    if (!Usuario) {
      console.warn("ID no está definido.");
      return;
    }

    const fetchComentarios = async () => {
      try {
       
        const response = await Axios.get(
          `${backendURL}/api/usuarios/comentarios/sucursal/admin/${Usuario.id}`,
          {
            headers: { Authorization: `Bearer ${Token}` },
          }
        );
    
        const fechas = response.data.fechas.map((fecha) => ({
          dia: fecha,
          mes: fecha.slice(0, 7),
        }));

        setComentariosClientes(fechas);
        setMostrarFechascliente(true);
      } catch (error) {
        console.error("Error en fetchComentarios:", error.message);
      }
    };

    fetchComentarios();
  }, [id, backendURL, Token]);

  const cambiarVistaCliente = (vista) => {
    setMostrarFechascliente(vista);
  };
 

  const handlecomentarioSelect = (option) => {
    if (selectedOptioncliente === option) {
      setselectedOptioncliente(null);
      setMostrarFechas(false); // Oculta la tabla si se deselecciona la opción
    } else {
      setselectedOptioncliente(option);
      setSelectedOptionsucursal(null);
      setSelectedDate(null);
    }
  };


  const handleCheckboxChangeMonth = (newSelection) => {
    // Actualiza selectedDate para que solo contenga el nuevo valor (día o mes)
    setSelectedDate(newSelection);
  };


  return (
    <Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px' }}>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOption}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '90px' }}>
      <Grid item>
          <button
            className={`botonseleccion ${selectedOptioncliente === 'Clientes Comentarios' ? 'selected' : ''}`}
            onClick={() => handlecomentarioSelect('Clientes Comentarios')}
          >
            Comentarios memoriales clientes
          </button>
        </Grid>
        <Grid item>
          <button
            className={`botonseleccion ${selectedOptioncliente === 'Empresa Comentarios' ? 'selected' : ''}`}
            onClick={() => handlecomentarioSelect('Empresa Comentarios')} // Elimina el espacio extra aquí
          >
            Comentarios memoriales empresa
          </button>
        </Grid>
      </Grid>
      {selectedOptioncliente === 'Empresa Comentarios' && (
        <>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        Selecciona la fecha para el reporte "Mes y Año"
      </Typography>
      <hr style={Linea} />

<Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px',display:'flex', alignItems:'center', justifyContent:'center' }}>
{mostrarFechas && (
              <TableContainer component={Paper} elevation={3} sx={{ width: '50%', background:'#ffff', borderRadius: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                        <IconButton color="secondary" aria-label="Fecha">
                          <CalendarTodayIcon fontSize="small" />
                        </IconButton>
                        Fecha
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {comentarios.length > 0 ? (
                      comentarios.map((comentario, index) => {
                        const [year, month] = comentario.mes.split('-').map(Number);
                        // Crear la fecha usando el año y mes (restando 1 al mes)
                        const parsedDate = new Date(year, month - 1);
                        // Formatear la fecha en el formato deseado
                        const mesFormateado = parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' });
                        
                        // Verifica si el mes actual está seleccionado
                        const isSelected = selectedDate?.mes === comentario.mes;
                        
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <Checkbox
                                checked={isSelected}
                                onChange={() => handleCheckboxChangeMonth({ mes: comentario.mes })} // Actualizar selectedDate con el mes
                              />
                            </TableCell>
                            <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{mesFormateado}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center" sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                          No hay comentarios registrados en este mes.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
      )}

{selectedOptioncliente === 'Clientes Comentarios' && (
        <>
            <Typography variant="h6" sx={tituloStyledescripcion}>
        Selecciona la fecha para el reporte "Mes y Año"
      </Typography>
      <hr style={Linea} />
      <Box display="flex" justifyContent="center" mb={2} sx={{ marginTop: '30px' }}>
          {['Día', 'Mes'].map((periodo) => (
            <Button
              key={periodo}
              variant="contained"
              onClick={() => cambiarVistaCliente(periodo.toLowerCase())}
              sx={{
                marginRight: 1,
                width: '100px',
                backgroundColor: mostrarFechascliente === periodo.toLowerCase() ? '#694B81' : 'transparent',
                color: mostrarFechascliente === periodo.toLowerCase() ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              {periodo}
            </Button>
          ))}
        </Box>

<Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px',display:'flex', alignItems:'center', justifyContent:'center' }}>
{mostrarFechascliente && (
              <TableContainer component={Paper} elevation={3} sx={{ width: '50%', background:'#ffff', borderRadius: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                      <TableCell  sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                        <IconButton color="secondary" >
                          <CalendarTodayIcon fontSize="small" />
                        </IconButton>
                        Fecha
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {comentariosClientes.length > 0 ? (
  comentariosClientes.map((comentario, index) => {
    const isSelected =
    mostrarFechascliente === "día"
      ? selectedDate?.dia === comentario.dia
      : selectedDate?.mes === comentario.mes;

  // Si el formato es de día, convertir el string en una fecha
  const [year, month, day] = comentario.dia.split("-");
  const formattedDay = `${day}-${month}-${year}`; // Formato "DD-MM-YYYY"

  // Si es un mes, mostrar solo el nombre del mes y año
  const [yearMes, mes] = comentario.mes.split("-");
  const formattedMonth = new Date(yearMes, mes - 1).toLocaleDateString("es-ES", {
    year: "numeric",
    month: "long",
  });
    
    return (
      <TableRow key={index}>
        <TableCell>
          <Checkbox
            checked={isSelected}
            onChange={() => handleCheckboxChangeMonth(mostrarFechascliente === 'día' ? { dia: comentario.dia } : { mes: comentario.mes })} // Selecciona solo el día o el mes
          />
        </TableCell>
        <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
        {mostrarFechascliente === "día" ? formattedDay : formattedMonth}
        </TableCell>
      </TableRow>
    );
  })
) : (
  <TableRow>
    <TableCell colSpan={2} align="center" sx={{ fontFamily: 'Poppins', color: '#585857' }}>
      No hay comentarios registrados en este mes.
    </TableCell>
  </TableRow>
)}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
)}
    </Box>
  );
};

export default ComentarioSucursalReporte;

const tituloStyledescripcion = {
  fontFamily:'Poppins',
  color:'#585857',
  fontSize:'0.8rem',
  fontWeight:'500',
  marginBottom:'0px',
  marginTop:'50px',
  display:'flex'
}

const Linea = {
  color:'#a8a6a6',
  background:'#a8a6a6',
  Border: '1px solid #a8a6a6',
  marginBottom:'50px'
}