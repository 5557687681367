import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate} from "react-router-dom";
import '../../../../../Style/AdministradorSuperEmpresa/InicioEmpresaAdmi.css'

/* libreria UI */
import { Box, CircularProgress, Modal,  Select, MenuItem, FormControl, InputLabel, TextField, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
/* react icons */
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";



/* imagenes */
import QrMorado from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrmorado.png"


export const GeneradorQRSucursal = ({ user, sucursal , QR, Token,  refreshQrData, close }) => {
    const [cantidad, setCantidad] = useState(1);  // Estado para la cantidad
    const [loading, setLoading] = useState(false); // Estado para la carga
    const [tipoSuscripcion, setTipoSuscripcion] = useState("");
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setEnviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const theme = useTheme();
 
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setEnviandoAlert(true);
            setProgress(0);
    
            // Simular progreso
            const totalSteps = cantidad; 
            const stepProgress = 100 / totalSteps; 
            
            for (let i = 0; i < totalSteps; i++) {
                await new Promise(resolve => setTimeout(resolve, 800)); // Simula la creación del QR
                setProgress((prev) => Math.min(prev + stepProgress, 100));
            }
    
            // Realizar la solicitud POST
            const response = await axios.post(`${backendURL}/api/qr/sucursal/store`, {
                cantidad: cantidad,
                id_empresa: sucursal.id_empresa,
                id_sucursal: sucursal.id,
                id_admin_sucursal: user.id,
                tipo_suscripcion: tipoSuscripcion,
            }, {
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
    
            if (response.status === 200) {
                setSuccessAlertOpen(true);
                setAlertMessage('Se ha generado con éxito los códigos QR.');
                
                // Llamar a refreshQrData para actualizar la lista de QR
                await refreshQrData();
    
                setTimeout(() => {
                    close(); // Cierra el modal después de 3 segundos
                }, 3000);
            } else {
                setErrorAlertOpen(true);
                setAlertMessage('Error al crear código QR.');
            }
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al crear código QR.');
        } finally {
            setEnviandoAlert(false);
            setTimeout(() => {
                setSuccessAlertOpen(false);
                setErrorAlertOpen(false);
               
            }, 3000);
        }
    };

    return (
        <Box sx={StyleBox}>
            <Box sx={StyleImagenBox}>
                <img src={QrMorado} alt="smart memorial" title="smart memorial" width={'60px'} />
                <div className="ContieneInfoModalMemoriaCrear">
                    <h3 className="ModalTituloMemorialCrear">Generar Codigo QR</h3>
                    <h3 className="ModalTituloMemorialCrear">{sucursal.nombre_sucursal}</h3>
                </div>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "100%",
                marginBottom: "100px"
            }}>
                <form className="cantidadenviar" onSubmit={handleSubmit}>
                    <TextField
                    sx={{
                            
                        background:
                       theme.palette.mode === 'light' ? '#ffff' : '#694B81'
                    }}
                        className="inputcantidadqr"
                        label="Cantidad"
                        type="number"
                        value={cantidad}
                        onChange={(e) => setCantidad(e.target.value)}
                        fullWidth
                        required
                    />
                    <FormControl fullWidth required margin="normal">
                        <InputLabel>Tipo de Suscripción</InputLabel>
                        <Select
                        sx={{
                            
                            background:
                           theme.palette.mode === 'light' ? '#ffff' : '#694B81'
                        }}
                            value={tipoSuscripcion}
                            onChange={(e) => setTipoSuscripcion(e.target.value)}
                            label="Tipo de Suscripción"
                        >
                            <MenuItem value={1}>QR placas</MenuItem>
                            <MenuItem value={2}>QR digital</MenuItem>
                        </Select>
                    </FormControl>
                    <button className="enviarcantidadqr" type="submit" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Generar QR'}
                    </button>
                </form>
            </Box>

            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
                <Box sx={modalStyle}>
                    <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ color: '#694B81' }}>{alertMessage}</p>
                    </section>
                </Box>
            </Modal>

            {/* Modal de error */}
            <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
                <Box sx={modalStyle}>
                    <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
                        <p style={{ color: '#694B81' }}>{alertMessage}</p>
                    </section>
                </Box>
            </Modal>

            {/* Modal de progreso */}
            <Modal open={enviandoAlert} onClose={() => setEnviandoAlert(false)}>
                <Box sx={modalStyle}>
                <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
                        <IoClose />
                    </section>
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
                        <p style={{ color: '#694B81' }}>Procesando solicitud...</p>
                    </section>
                </Box>
            </Modal>

           
        </Box>
    );
};

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const StyleImagenBox = {
    width:'100%',
    Height:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',

}

const StyleBox = {
    width:'100%',
    Height:'100%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column'
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px'
  };
