import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import "../../../Style/Registro/validacion.css";
import '../../../Style/generales/button-retroceso.css';
import { useNavigate } from 'react-router-dom';
import { FiAlertCircle } from "react-icons/fi";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

export const Nuevacontraseña = ({ email }) => { 
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [codigo, setCodigo] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [openAlert, setOpenAlert] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const isSmallScreen = useMediaQuery('(max-width: 400px)');
  const maxWidth = isSmallScreen ? 200 : 400;
  const handleChangeCodigo = (event) => {
    setCodigo(event.target.value);
    setError("");
  };
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleResendCode = async () => {
    handleOpenAlert();
    try {
      setenviandoAlert(true);
      const response = await fetch(`${backendURL}/api/renviodecodigonuevo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Error al reenviar el código');
      }
  
      const data = await response.json();
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Se a renviado el codigo  con exito.')
      
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.message 
    ? error.response.data.message 
    : 'Error al renviar codigo.';

  setErrorAlertOpen(true);
  setAlertMessage(errorMessage);
    }
    finally {
      setenviandoAlert(false);
    }
  
  // Simulación de tiempo de carga
  setTimeout(() => {
   setSuccessAlertOpen(false);
    setErrorAlertOpen(false);
  }, 2000);
  };
  

  useEffect(() => {
    if (openAlert) {
      // Establecer un temporizador para cerrar el modal después de dos segundos
      const timer = setTimeout(() => {
        handleCloseAlert();
      }, 2000); // 2000 milisegundos = 2 segundos
  
      // Limpiar el temporizador al desmontar el componente o al actualizar openAlert
      return () => clearTimeout(timer);
    }
  }, [openAlert]);
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  

  const handleVerifyCode = async () => {
    try {
        setenviandoAlert(true);
        const response = await axios.post(`${backendURL}/api/verifyResetCode`, {
            email,
            codigo_reset: codigo,
        });
        
        if (response.status === 200) {
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Código correcto.');
            setTimeout(() => {
                navigate('/nueva-contraseña', { state: { email, codigo } });
            }, 2000); 
        } else {
            setErrorAlertOpen(true);
            setAlertMessage("Código incorrecto");
            setError("Código incorrecto");
        }
    } catch (error) {
        const errorMessage = error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : 'Código incorrecto.';
        setErrorAlertOpen(true);
        setAlertMessage(errorMessage);
    } finally {
        setLoading(false);
        setenviandoAlert(false);
    }

    // Simulación de tiempo de carga
    setTimeout(() => {
        setSuccessAlertOpen(false);
        setErrorAlertOpen(false);
    }, 2000);
};

    return(
        <>
         <section className="registro-cuerpo-inicio">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#ffff",
            textAlign: "center",
            position: "relative"
          }}
        >
         

          <section className="titulo-validacion">
            <h1>Hemos enviado un</h1>
            <h1>código de confirmación</h1>
            <h1>al correo electrónico</h1>
            <hr style={{ border: '0px', padding: '10px' }} />
            <h1><strong>{email}</strong></h1>
          </section>

          <section className="validar-codigo">
            <h1>Ingrese el código</h1>
            <input className={`validacion-input ${error && 'input-error'}`}  value={codigo} onChange={handleChangeCodigo} />
           <div className="contienenerrordecodigo">  {error && <span className="errorverificarmessage"> <FiAlertCircle className='iconserror' />{error}</span>}</div>
            <span>¿No recibió el código?</span>
           <button style={{background:'white', border:'0px'}} onClick={handleResendCode}> <p  >Reenviar correo</p></button>
          </section>
          <Modal
  open={openAlert}
  onClose={handleCloseAlert}
  aria-labelledby="alert-modal-title"
  aria-describedby="alert-modal-description"
>
<Box
              sx={{
                position: "absolute",
                width: '100%',
                maxWidth: maxWidth,
                bgcolor: "transparent",
                border: "0px",
                p: 4,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Alert severity="success" variant="filled" sx={{ backgroundColor: '#431E62', width:'100%' }}>
                Código enviado correctamente
              </Alert>
            </Box>
</Modal>

          <div className="botonverificarregistro">
            <button onClick={handleVerifyCode}>Confirmar</button>
          </div>

          <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        </Box>
      </section>
      <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>
      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        
        </>
    )
}