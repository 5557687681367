import React from "react";
import "../../Style/generales/louding.css"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box'
import { useUsers, useData } from "../context/userscontexts";

/* imagenes */
import logo from "../../Image/generales/movil-smart-memorial-header.png"

export const Louding = () => {
    const {  userData } = useUsers(); 

    return(
        <section  className="loudingsmartmemorial">
        <div className="smartmemorial">

      <CircularProgress  size={100}
          thickness={4} sx={{marginBottom:'50px', color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}/>
    </div>
        </section>
        
    )
}