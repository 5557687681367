import React, { useState } from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { keyframes } from "@emotion/react";

/* Animaciones */
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const CrearEncuestaIndex = ({close, onEncuestaCreada}) => {
  const { Token, UserDataMarca, Empresa } = useUserEmpresa();
  const [preguntas, setPreguntas] = useState([{ id: 1, texto: "" }]);
  const [contador, setContador] = useState(2);
  const [isAdding, setIsAdding] = useState(false);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [loading, setLoading] = useState(false);
  const [alerta, setAlerta] = useState({ open: false, message: "", severity: "" });
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const getNextId = () => {
    const idsExistentes = preguntas.map((p) => p.id).sort((a, b) => a - b);
    for (let i = 1; i <= 10; i++) {
      if (!idsExistentes.includes(i)) return i;
    }
    return null;
  };

  const agregarPregunta = () => {
    setPreguntas((prev) => [...prev, { id: contador, texto: "" }]);
    setContador((prev) => prev + 1); // Incrementa el contador global
  };

  const eliminarPregunta = (id) => {
    setPreguntas((prev) => prev.filter((p) => p.id !== id));
  };

  const handleChange = (id, texto) => {
    setPreguntas((prev) =>
      prev.map((p) => (p.id === id ? { ...p, texto } : p))
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!titulo || !descripcion) {
      setAlerta({
        open: true,
        message: "El título y la descripción no pueden estar vacíos.",
        severity: "warning",
      });
      return;
    }

    setLoading(true);

    const data = {
      nombre_encuesta: titulo,
      descripcion: descripcion,
      ...preguntas.reduce((acc, pregunta) => {
        acc[`pregunta_${pregunta.id}`] = pregunta.texto || null;
        return acc;
      }, {}),
    };

    try {
      const response = await fetch(`${backendURL}/api/store/encuesta/${Empresa.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) throw new Error("Error al guardar la encuesta.");

      const result = await response.json();
      setLoading(false);
      setAlerta({
        open: true,
        message: "Encuesta creada exitosamente.",
        severity: "success",
      });
        // Llamar el callback para actualizar la tabla
      if (onEncuestaCreada) {
        onEncuestaCreada(); // Actualiza el listado de encuestas
      }

      // Cerrar el modal
      if (close) {
        close(); // Llama a la función para cerrar el modal
      }
    } catch (error) {
      setLoading(false);
      setAlerta({
        open: true,
        message: "Error al guardar la encuesta.",
        severity: "error",
      });
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "start", flexDirection: "column" }}>
      <Typography
        variant="body2"
        sx={{ marginBottom: "40px", color: "#585857" }}
      >
        Sigue estos pasos para configurar tus preguntas:
        <br />
        1. Puedes agregar hasta un máximo de 10 preguntas en total.
        <br />
        2. Las preguntas del 1 al 9 deben ser del tipo "Sí o No".
        <br />
        3. La pregunta número 10 será de tipo "abierta".
        <br />
      </Typography>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <TextField
          fullWidth
          placeholder="Título de la encuesta"
          variant="filled"
          value={titulo}
          onChange={(e) => setTitulo(e.target.value)}
          sx={{
            marginBottom: "10px",
            fontFamily: "Poppins",
            background: UserDataMarca?.color_3 && UserDataMarca?.color_3 !== "undefined"
              ? UserDataMarca?.color_3
              : "#3f51b5",
            "& .MuiFilledInput-input": {
              color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                ? UserDataMarca?.color_2
                : "#585857",
            },
          }}
        />
        <TextField
          fullWidth
          placeholder="Descripción de la encuesta"
          variant="filled"
          value={descripcion}
          onChange={(e) => setDescripcion(e.target.value)}
          sx={{
            marginBottom: "10px",
            fontFamily: "Poppins",
            background: UserDataMarca?.color_3 && UserDataMarca?.color_3 !== "undefined"
              ? UserDataMarca?.color_3
              : "#3f51b5",
            "& .MuiFilledInput-input": {
              color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                ? UserDataMarca?.color_2
                : "#585857",
            },
          }}
        />

        {preguntas.map((pregunta) => (
          <Grid container alignItems="center" key={pregunta.id} sx={{ animation: `${fadeIn} 0.3s` }}>
            <Grid item xs={11}>
              <TextField
                fullWidth
                placeholder={`Pregunta ${pregunta.id}`}
                variant="filled"
                value={pregunta.texto}
                onChange={(e) => handleChange(pregunta.id, e.target.value)}
                sx={{
                  marginBottom: "10px",
                  fontFamily: "Poppins",
                  background: UserDataMarca?.color_3 && UserDataMarca?.color_3 !== "undefined"
                    ? UserDataMarca?.color_3
                    : "#3f51b5",
                  "& .MuiFilledInput-input": {
                    color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                      ? UserDataMarca?.color_2
                      : "#585857",
                  },
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="text"
                color="error"
                onClick={() => eliminarPregunta(pregunta.id)}
              >
                <RemoveCircleOutlineIcon />
              </Button>
            </Grid>
          </Grid>
        ))}

        {preguntas.length < 10 && (
          <Button variant="contained"  sx={{
            marginTop: "10px",
            fontFamily:'Poppins',
            textTransform:'capitalize',
            color:'#ffff'
          }} onClick={agregarPregunta} startIcon={<AddCircleOutlineIcon />}>
            Agregar Pregunta
          </Button>
        )}

        <Button type="submit" variant="contained"  sx={{
              marginTop: "40px",
              fontFamily:'Poppins',
               textTransform:'capitalize',
               color:'#ffff',
               background: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                    ? UserDataMarca?.color_1
                    : "#3f51b5"
            }}>
          Guardar Encuesta
        </Button>
      </form>

      <Modal open={loading}>
        <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '350px',
                        height: 'auto',
                        bgcolor: '#ffff',
                        boxShadow: 24,
                        p: 1,
                        borderRadius: '10px',
                        '@media (max-width: 500px)': {
                            width: '90%',
                        },
                    }}
                >
                    <section style={{ textAlign: 'center', padding: '1rem' }}>
                        <CircularProgress style={{ color: '#694B81' }} />
                        <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                            Procesando solicitud...
                        </p>
                    </section>
                </Box>
      </Modal>
      <Modal open={alerta.open} >
        <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: '#fff',
                      boxShadow: 24,
                      p: 0, // Sin padding adicional
                      borderRadius: '10px',
                      display: 'flex',
                      width:'100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                >
      <Snackbar
        open={alerta.open}
        autoHideDuration={4000}
        onClose={() => setAlerta({ ...alerta, open: false })}
      >
        <Alert severity={alerta.severity}>{alerta.message}</Alert>
      </Snackbar>
    </Box>
    </Modal>

    </Box>
  );
};

const subtituloStyle = {
  fontFamily: "Poppins",
  color: "#585857",
  fontSize: "0.8rem",
  fontWeight: "500",
  textAlign: "center",
};
const subtituloinputStyle = {
    fontFamily: "Poppins",
    color: "#585857",
    fontSize: "0.8rem",
    fontWeight: "500",
    textAlign: "start",
  };