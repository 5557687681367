import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const LogoutProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    ClientToken: null,
  });

  const setUserToken = (token) => {
    setAuthState((prevState) => ({ ...prevState, ClientToken: token }));
  };

  const handleLogout = () => {
    setUserToken(null);
    sessionStorage.removeItem('ClientToken');
  };

  return (
    <AuthContext.Provider value={{ ...authState, setUserToken, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};