import React, { createContext, useContext, useState, useEffect } from 'react';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [ClientToken, setClientToken] = useState(null);
  const [userData, setUserData] = useState(null); // Nuevo estado para la información del usuario

  // Recuperar el token y la información del usuario desde sessionStorage cuando el componente se monta
  useEffect(() => {
    const storedToken = sessionStorage.getItem('ClientToken');
    const storedUserData = sessionStorage.getItem('UserData');
    if (storedToken) {
      setClientToken(JSON.parse(storedToken));
    }
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const setUserToken = (token) => {
    setClientToken(token);
    if (token) {
      sessionStorage.setItem('ClientToken', JSON.stringify(token));
    } else {
      sessionStorage.removeItem('ClientToken');
    }
  };

  const setUserInformation = (data) => {
    setUserData(data);
    if (data) {
      sessionStorage.setItem('UserData', JSON.stringify(data));
    } else {
      sessionStorage.removeItem('UserData');
    }
  };

  return (
    <UsersContext.Provider value={{ ClientToken, setUserToken, userData, setUserInformation }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => useContext(UsersContext);