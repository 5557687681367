import React, { useEffect } from "react";

/* importar componenetes */
import { Registro } from "../Components/Log/Registrar/registro";

export const RegistroPrincipal = ({ setShowNavbarFooter }) => {
useEffect(() => {
setShowNavbarFooter(false);
return () => {
setShowNavbarFooter(true);
};
}, [setShowNavbarFooter]);
return(
<>
  <Registro />

</>
)
}