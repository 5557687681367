import React, { useState, useEffect } from "react";
import { useAuth } from "../../../context/usecontext";
import { useNavigate} from "react-router-dom";
import axios from "axios";
import "../../../../Style/admi/adminuser.css";

/* libreria UI */
import { Container, Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, TablePagination, TextField } from '@mui/material';
import { styled } from "@mui/system";

/* imagenes */
import logoinicio from "../../../../Image/administrador/logoadmi.png";
import buscar from "../../../../Image/administrador/buscar.png";


export const Userdelmessmart = () => {
    const { adminToken, setAdminAuthToken } = useAuth();
    const navigate = useNavigate();
    const [qrData, setQrData] = useState([]);
    const [users, setUsers] = useState([]);
    const [empresas, setEmpresa] = useState([]);
    const [servicio, setServicios] = useState([]);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [mes, setMes] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedQr, setSelectedQr] = useState('');
    const [selectedMonth, setSelectedMonth] = useState("");
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedEmpresa, setSelectedEmpresa] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
        // Obtener la fecha actual
        const fechaActual = new Date();
        const mes = fechaActual.getMonth(); // Mes (de 0 a 11)
        const año = fechaActual.getFullYear(); // Año

        // Arreglo con los nombres de los meses
        const meses = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];

        const fechames = ` ${meses[mes]} ${año}`;

        // Establecer la fecha en el estado
        setMes(fechames);


    }, [setAdminAuthToken, navigate]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/users`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`
                    }
                });
                setUsers(response.data);
            } catch (error) {
                console.error("Error al obtener los usuarios:", error);
            }
        };

        const fetchQrData = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getAllGruposFamiliares`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                setQrData(response.data);
                extractYears(response.data);
                extractMonths(response.data);
            } catch (error) {
                console.error("Error al obtener los datos de QR:", error);
            }
        };

        const fetchEmpresa = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/empresas`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                const data = response.data;
                if (Array.isArray(data)) {
                    setEmpresa(data);
                } else {
                    console.error('La respuesta no es un array:', data);
                }
               
            } catch (error) {
                console.error("Error al obtener los datos de empresas:", error);
            }
        };

        const fetchServicios = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/funerariasGet`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
               
                    setServicios(response.data);
                
            } catch (error) {
                console.error("Error al obtener los datos de funerarias:", error);
            }
        };
        

        if (adminToken) {
            Promise.all([fetchQrData(), fetchUsers(), fetchEmpresa(), fetchServicios()])
                .then(() => setLoading(false))
                .catch(error => {
                    console.error("Error en la obtención de datos:", error);
                    setLoading(false);
                });
        } else {
            navigate("/");
        }
    }, [backendURL, adminToken, navigate, setAdminAuthToken]);


    /* filtros */
    const empresasEnQrData = [...new Set(qrData.map(row => row.id_empresa)).values()].filter(id => id !== null);
    const funerariasEnQrData = [...new Set(qrData.map(row => row.id_funeraria)).values()].filter(id => id !== null);

    const empresasFiltradas = (empresas || []).filter(emp => empresasEnQrData.includes(emp.id));
    const funerariasFiltradas = (servicio || []).filter(serv => funerariasEnQrData.includes(serv.id));



    // Filtrar los datos para mostrar solo los del mes actual
    const filteredQrData = qrData.filter((row) => {
        const fechaCreacion = new Date(row.created_at);
        const mes = fechaCreacion.getMonth() + 1;
        const year = fechaCreacion.getFullYear();
    
        let isEmpresaSelected = true;
        let isFunerariaSelected = true;
    
        if (selectedEmpresa && typeof selectedEmpresa === 'string') {
            const [type, id] = selectedEmpresa.split('-');
            const idInt = parseInt(id, 10); // Convertir id a número
    
           
    
            if (type === 'empresa') {
                isEmpresaSelected = (row.id_empresa == idInt || row.id_empresa == null);
                isFunerariaSelected = row.id_funeraria == null; // Permitir todas las funerarias si se selecciona empresa
            } else if (type === 'funeraria') {
                isFunerariaSelected = (row.id_funeraria == idInt || row.id_funeraria == null);
                isEmpresaSelected = row.id_empresa == null; // Permitir todas las empresas si se selecciona funeraria
            }
        } else {
            // Si no se ha seleccionado empresa o funeraria, permitir ambos
            isEmpresaSelected = true;
            isFunerariaSelected = true;
        }
    
        const result = (
            (selectedQr ? row.id === parseInt(selectedQr, 10) : true) &&
            isEmpresaSelected &&
            isFunerariaSelected &&
            (selectedMonth ? mes === parseInt(selectedMonth, 10) : true) &&
            (selectedYear ? year === parseInt(selectedYear, 10) : true)
        );
    
        
        return result;
    });


    /* Manejadores de cambio para los filtros */
    const handleChange = (event) => {
        const { name, value } = event.target;
    
    
        if (name === "qr") {
            setSelectedQr(value);
        } else if (name === "empresa") {
            setSelectedEmpresa(value);
        } else if (name === "month") {
            setSelectedMonth(value);
        } else if (name === "year") {
            setSelectedYear(value);
        }
    };

    /* Generar nombres de opciones */
    const getEmpresaNombre = (idEmpresa) => {
        const empresa = empresas.find((e) => e.id == idEmpresa);
        return empresa ? empresa.nombre_empresa : "No Disponible";
    };

    const getServiciosNombre = (idFuneraria) => {
        const funeraria = servicio.find((f) => f.id === idFuneraria);
        return funeraria ? funeraria.nombre : "No Disponible";
    };

    const getUsuarioNombre = (idUsuario) => {
        const usuario = users.find((u) => u.id == idUsuario);
        return usuario ? `${usuario.name} ${usuario.apellido}` : "No Disponible";
    };

    // Crear opciones de select para empresas o funerarias
    const options = empresas.length > 0
    ? empresas.map(emp => (
        <MenuItem key={emp.id} value={`empresa-${emp.id}`}>{emp.nombre_empresa}</MenuItem>
    ))
    : servicio.map(serv => (
        <MenuItem key={serv.id} value={`funeraria-${serv.id}`}>{serv.nombre}</MenuItem>
    ));

    /* filtro por años */
    const extractYears = (qrData) => {
        // Extraer años únicos de las fechas `created_at`
        const years = qrData.map(qr => new Date(qr.created_at).getFullYear());
        const uniqueYears = [...new Set(years)]; // Eliminar años duplicados
        setYears(uniqueYears);
    };
    const extractMonths = (qrData) => {
        // Extraer meses únicos de las fechas `created_at`
        const months = qrData.map(qr => new Date(qr.created_at).getMonth() + 1); // Los meses en JS son de 0-11
        const uniqueMonths = [...new Set(months)]; // Eliminar meses duplicados
        setMonths(uniqueMonths);
    };
    const monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];
    

    /* tabla */
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredQrData.length - page * rowsPerPage);
   

    return(
        <>
         <Container component="main" maxWidth="xl">
            <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection:"column",
                    marginTop:"50px"
                  
                }}>  
                <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "150px"
                    }}>

                        <img
                            src={logoinicio}
                            alt="smart memorial"
                            title="smart memorial"
                            width="100%"
                        />

                    </Box>
                    <Box>
                    <section className="titulocodigoadmi">
                        <h1>Usuarios del mes {mes} </h1>
                    </section>
                    <section className="informacionq">
                        <p>Sección vista de todos los usuarios que estan ahora con smart memorial en el mes.</p>
                    </section>
                    
                    </Box>

                    <Box sx={{
                        marginBottom:"50px"
                    }}>
                        <div className="contienentitulobuscadorusuariossmart">
                            <div className="imagenbuscador">
                                <img src={buscar} className="buscar" alt="smart memorial" title="smart memorial"  width="100%"/> 
                            </div> 
                            <h2>Explorar usuarios</h2>
                        </div>
                        <div className="contienenfiltradousuarios">
                        <FormControl sx={{ minWidth: 300 }}>
                            <InputLabel id="estado-filtro-label">Código QR</InputLabel>
                            <StyledSelect
                                labelId="estado-filtro-label"
                                id="estado-filtro"
                                value={selectedQr}
                                name="qr" 
                                label="Código QR"
                                onChange={handleChange}
                            >
                                <MenuItem value=""><em>Todos</em></MenuItem>
                                {qrData.map((qr) => (
                                    <MenuItem key={qr.id} value={qr.id}>
                                        {qr.id} 
                                    </MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                        <FormControl sx={{ minWidth: 300 }}>
                            <InputLabel id="empresa-select-label">Seleccionar Servicio</InputLabel>
                            <StyledSelect
                                labelId="empresa-select-label"
                                id="empresa-select"
                                label="Seleccionar Empresa o Funeraria"
                                name="empresa"
                                value={selectedEmpresa}
                                onChange={handleChange}
                            >
                                <MenuItem value=""><em>Todos</em></MenuItem>
                                {empresas.length > 0 && empresas.map(emp => (
                                    <MenuItem key={emp.id} value={`empresa-${emp.id}`}>{emp.nombre_empresa}</MenuItem>
                                ))}
                                {servicio.length > 0 && servicio.map(serv => (
                                    <MenuItem key={serv.id} value={`funeraria-${serv.id}`}>{serv.nombre}</MenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                        <FormControl sx={{ minWidth: 220 }}>
                    <InputLabel id="month-select-label">QR meses anteriores</InputLabel>
                    <StyledSelect
                        labelId="month-select-label"
                        id="month-select"
                        label="QR meses anteriores"
                        name="month" 
                        value={selectedMonth}
                        onChange={handleChange}
                    >
                        <MenuItem value=""><em>Todos</em></MenuItem>
                        <MenuItem value={new Date().getMonth() + 1}><em>Mes actual</em></MenuItem>
                        {months.map((month) => (
                            <MenuItem key={month} value={month}>
                                {monthNames[month - 1]} {/* Mostrar el nombre del mes */}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
                        <FormControl sx={{ minWidth: 150 }}>
                    <InputLabel id="year-select-label">Año</InputLabel>
                    <StyledSelect
                        labelId="year-select-label"
                        id="year-select"
                        label="Año"
                        name="year" 
                        value={selectedYear}
                        onChange={handleChange}
                    >
                        <MenuItem value=""><em>Todos</em></MenuItem>
                        {years.map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FormControl>
                        </div>
                    </Box>
                    <Box sx={{
                        marginBottom:"100px"
                    }}>
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',border:"0px" }}>
                 <CircularProgress sx={{
            color: "#D26999",border:"0px"
        }} />
                </div>
            ) : (   <>
                        <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredQrData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Nombre</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>QR</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Usuario</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Fecha Creación</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Fecha Inicio</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Fecha Final</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Estado</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Empresa</TableCell>
                                    <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857",
                                                textAlign:"center"
                                            }}>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {filteredQrData
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Aplicar paginación
                                .map((row, index) => {
                                    const nombreEmpresaOFuneraria = row.id_empresa
                                        ? getEmpresaNombre(row.id_empresa)  // Mostrar nombre de la empresa
                                        : getServiciosNombre(row.id_funeraria); 

                                    return (
                                        <StyledTableRow key={row.id} index={index}>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"1rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{row.nombre}</TableCell>
                                            <TableCell>{row.qr && (
                                            <img
                                                src={`${backendURL}/imagenes/qr/${row.qr}`}
                                                alt="smart memorial"
                                                style={{ maxWidth: '100px', maxHeight: '100%', width: 'auto', height: 'auto' }}
                                            />
                                        )}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{getUsuarioNombre(row.id_usuario)}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{new Date(row.created_at).toLocaleDateString()}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{row.fecha_inicio}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{row.fecha_final}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{row.estado == 1 ? "Activo" : "Inactivo"}</TableCell>
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}>{nombreEmpresaOFuneraria || "No Disponible"}</TableCell> {/* Usa la lógica aquí */}
                                            <TableCell sx={{
                                                fontFamily:"Poppins",
                                                fontSize:"0.8rem",
                                                fontWeight:"500",
                                                color:"#585857"
                                            }}><button className="botonvermemorialadmi">Ver memorial</button></TableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </>
            )}
                    </Paper>
                    </Box>
  
        </Box>
        </Container>
        </>
    )
}

// Estilo personalizado para las celdas de la tabla

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',

    fontFamily: "Poppins",
    width:'100%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '20px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
    
  }));

  const StyledTableRow = styled(TableRow)(({ theme, index }) => ({
    border: "2px solid",
    backgroundColor: index % 2 == 0 ? "#F0EDF3" : "#fff", // Alterna colores para filas pares e impares
    cursor:"pointer",
     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Efecto de sombra al pasar el mouse

}));
