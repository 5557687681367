import React, { useEffect, useState } from "react";
import axios from "axios";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import "../../../../../../Style/AdministradorSuperEmpresa/diseñoempresaadmi.css"
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EmpresaLayout from "../../../../../../EmpresaLayout"
/* MATERIAL UI */
import { Container, Box, Grid, Typography, CircularProgress, Modal, Select, MenuItem,  FormControl, InputLabel } from '@mui/material';
import { fontFamily, styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';
/* imagenes */
import logomemorialdos from "../../../../../../Image/generales/grupo-familia-2.png";
import perfil from "../../../../../../Image/generales/foto-predeterminada-memorial.jpg"
import crear from "../../../../../../Image/generales/crear-memorial.png";


/* react icons */
import { BiHomeHeart } from "react-icons/bi";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { PiScrollFill } from "react-icons/pi";
import { RxCalendar } from "react-icons/rx";
import { ImSearch } from "react-icons/im";
import { BsPostcardHeart } from "react-icons/bs";


export const MemorialNuevoCrearEmpresa = ({ setShowNavbarFooter }) => {
  const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
  const location = useLocation();
  const { QR} = location.state || {};
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false)
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [nombreFallecido, setNombreFallecido] = useState('');
  const [rut, setRut] = useState('');
  const [tipo_servicio, setTiposervicio] = useState([]);
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [fechaFallecimiento, setFechaFallecimiento] = useState('');
  const [cementerio, setCementerio] = useState((Empresa? `${Empresa.direccion}, ${Empresa.comuna}, ${Empresa.region}, ${Empresa.pais}`: ''));
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [historia, setHistoria] = useState('');
  const fechaNacimientoFormateada = fechaNacimiento ? dayjs(fechaNacimiento).format('YYYY/MM/DD') : '';
  const fechaFallecimientoFormateada = fechaFallecimiento ? dayjs(fechaFallecimiento).format('YYYY/MM/DD') : '';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorAlertOpens, setErrorAlertOpens] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imagenPredeterminadaBlob, setImagenPredeterminadaBlob] = useState(null);
  const [showCreateObituary, setShowCreateObituary] = useState(false);
  const [marcas, setMarcas] = useState([]);

  useEffect(() => {
    setShowNavbarFooter(false);
    return () => {
      setShowNavbarFooter(true);
    };
  }, [setShowNavbarFooter]);

  useEffect(() => {
    if (Empresa) {
      setCementerio(
        `${Empresa.direccion}, ${Empresa.comuna}, ${Empresa.region}, ${Empresa.pais}`
      );
    }
  }, [Empresa]);

  useEffect(() => {
    if (fechaFallecimiento) {
      const fechaActual = dayjs();
      const fechaFallecimientoDate = dayjs(fechaFallecimiento);
      const diferenciaDias = fechaActual.diff(fechaFallecimientoDate, 'day');

      if (diferenciaDias <= 3) {
     
        setShowCreateObituary(true);

      }
    }
  }, [fechaFallecimiento]);

 
  const fetchMarcas = async () => {
    try {
      const response = await axios.get(`${backendURL}/api/marcaempresa/${Empresa.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`,
        },
      });
  
      const fetchedMarca = response.data.data[0];
  
      // Asegúrate de que servicios no sea nulo antes de parsearlo
      if (fetchedMarca.servicios) {
        const serviciosArray = JSON.parse(fetchedMarca.servicios); // Parsear la cadena JSON
        fetchedMarca.servicios = serviciosArray; // Reemplaza la cadena por el array
      } else {
        fetchedMarca.servicios = []; // Si no hay servicios, inicializa como un array vacío
      }
  
      setMarcas(fetchedMarca);
    
      
    } catch (err) {
      console.error("Error al obtener marcas:", err);
    }
  };
   // useEffect para llamar a fetchMarcas cuando empresa.id cambia
   useEffect(() => {
    if (Empresa?.id) {
        fetchMarcas();
    }
  }, [Empresa?.id]);
  


  /* crear memorial */
  const Submit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Usar imagen predeterminada si no se seleccionó ninguna imagen
    if (!imagenPerfil && !imagenPredeterminadaBlob) {
      setErrorAlertOpens(true);
      setAlertMessage('Debe subir una imagen de perfil.');
      setIsSubmitting(false);
      return; // Detener la ejecución si no hay imagen
    }

    const formData = new FormData();
    formData.append('nombre', nombreFallecido);
    formData.append('cementerio', cementerio);
    formData.append('rut', rut);
    formData.append('tipo_servicio', tipo_servicio);
    formData.append('fecha_nacimiento', fechaNacimientoFormateada);
    formData.append('fecha_defuncion', fechaFallecimientoFormateada);
    formData.append('id_empresa', Empresa.id );
    formData.append('id_usuario',  QR.idUsuario);
    // Solo añadir la imagen si ha sido seleccionada
    if (imagenPerfil) {
      formData.append('imagen_perfil', imagenPerfil);
    } else if (imagenPredeterminadaBlob) {
      formData.append('imagen_perfil', imagenPredeterminadaBlob, 'foto-predeterminada-memorial.jpg');
    }
    formData.append('historia', historia);

    try {
      setenviandoAlert(true);
      const response = await axios.post(`${backendURL}/api/memorialCrearEmpresa/${QR.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${Token}` // Asegúrate de tener el token de autenticación disponible
        }
      });


      // Actualizar el estado del componente con el ID del memorial creado
      if (response.status === 201) {
        const memorialId = response.data.id;
  
        // Actualizar el estado del componente con el ID del memorial creado
        setSuccessAlertOpen(true);
        setAlertMessage('El memorial se ha creado con éxito.');
  
        
        // Aquí puedes manejar la respuesta como desees
  
      }

      // Aquí puedes manejar la respuesta como desees
    } catch (error) {
      setErrorAlertOpen(true);

    }
    finally {
      setIsSubmitting(false); // Ocultar el CircularProgress al finalizar el envío del formulario
      setenviandoAlert(false);
    }
    // Simulación de tiempo de carga
    setTimeout(() => {
      handleCloseModal();
    }, 3000);

    // Simulación de tiempo de carga
    setTimeout(() => {
      setErrorAlertOpen(false);
    }, 2000);
  };


  /* historia */
  const handleChange = (value) => {
    setHistoria(value);
  };

  /* imagen perfil */
 
  useEffect(() => {
    // Cargar la imagen predeterminada como Blob
    fetch(perfil)
      .then(response => response.blob())
      .then(blob => setImagenPredeterminadaBlob(blob));
  }, []);

  const handleImagenSeleccionada = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagenPreview(reader.result);
      };
      reader.readAsDataURL(file);
      setImagenPerfil(file);
    }
  };
  const handleCloseModal = () => {
    setenviandoAlert(false);
  };
  const handleAccept = () => {
    setSuccessAlertOpen(false);
    const state = { QR: QR,  Empresa, Usuario, Token  };
    navigate(`/empresa/${Usuario.id}/qr/memorial/${QR.id}`, { state });
  };

  const handleChanges = (event) => {
    const {
        target: { value },
    } = event;
    // Actualizar el estado con los valores seleccionados
    setTiposervicio(typeof value === 'string' ? value.split(',') : value);
};


  return (
    <EmpresaLayout>
      <Container maxWidth='lg' sx={StyleContainer}>
      
          <Box sx={StyleBoxdos}>
            <section className="portada-familia-crear">


              <section className="titulo-crear-familia">
                <h1 className="titulofamiliafinal">Grupo Familiar</h1>
                <div className="apellido-crear">
                  <h1>Familia {QR?.nombreFamilia}</h1>

                  <img
                    src={logomemorialdos}
                    className="imagen-seccion2"
                    alt="smart memorial"
                  />
                </div>
              </section>
            </section>
            <section className="contienetodoempresacrearmemorial">

              <div className="titulopartedos">
                <img src={crear} alt="smart memorial" />
                <h1 className="titulo-memoria">Crear Memorial</h1>
              </div>


              <form className="formcrearempresamemorial" onSubmit={Submit} >
                <div className="inputregistormemorialempresaform">
                  <label>Nombre del fallecido</label>
                  <div className="contieneinputempresamemorial">
                    <input value={nombreFallecido} className={`crearinputmemorialempresa ${error && 'input-error'}`} name="nombres"
                      id="name" onChange={(e) => setNombreFallecido(e.target.value)} />
                  </div>           {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
                </div>
                <div className="inputregistormemorialempresaform">
                  <label>Rut del fallecido</label>
                  <div className="contieneinputempresamemorial">
                    <input value={rut} className={`crearinputmemorialempresa ${error && 'input-error'}`} name="rut"
                      id="rut" onChange={(e) => setRut(e.target.value)} />
                  </div>       
                      {error && 
                      <div className="errorinput">
                        <FiAlertCircle />
                        {error}
                      </div>}
                </div>  
                <div className="input-registorformempresa">
                  <label htmlFor='tipo_servicio'>Servicio</label>
                  <div className='contieneinputregister'>
                    <FormControl fullWidth error={error.tipo_servicio}>
                      <InputLabel id='tipo_servicio-label'>Selecciona los servicios adjudicados al memorial...</InputLabel>
                      <StyledSelect
                        labelId='tipo_servicio-label'
                        name='tipo_servicio'
                        id='tipo_servicio'
                        multiple
                        value={tipo_servicio} // Este debe ser un array de servicios seleccionados
                        onChange={handleChanges} // Maneja los cambios al seleccionar un servicio
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={KeyboardArrowDownIcon}
                      >
                        <MenuItem value="">
                          
                          </MenuItem>
                          {(() => {
                            // Parsear el string JSON de servicios a un array
                            let serviciosArray = [];
                            try {
                              serviciosArray = JSON.parse(UserDataMarca?.servicios || "[]");
                            } catch (e) {
                              console.error("Error al parsear los servicios: ", e);
                            }

                            // Mapear y renderizar los servicios como opciones
                            return serviciosArray.map((servicio, index) => (
                              <MenuItem
                            key={index} 
                           value={servicio}
                           sx={{
                             '&.MuiPopover-paper':{
                               backgroundColor: '#ffff',
                             },
                             '&.MuiMenuItem-root': {
                               backgroundColor: '#ffff',
                             },
                             // Estilos para el contenedor generado del `Popover` o `Menu`
                             '& .MuiPaper-root': {
                               backgroundColor: 'white', // Cambia el color de fondo del `Popover`
                             },
                             fontFamily:'Poppins',color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                             ? UserDataMarca.color_1 
                             : '#694B81',
                            
                             '&.Mui-selected': {
                               backgroundColor: '#68A354', // Color de fondo del elemento seleccionado
                               color: 'white',          // Color del texto del elemento seleccionado
                             },
                             '&.Mui-selected:hover': {
                               backgroundColor: '#E1EDDD', // Color al pasar el mouse sobre el elemento seleccionado
                               color:'#68A354'
                             },
                           }} >
                             {servicio}
                            
                        
                            </MenuItem>
                            ));
                          })()}
                        </StyledSelect>
                        {error.tipo_servicio && (
                          <span className="error-message">
                            <FiAlertCircle className="icon-error" /> {error.tipo_servicio}
                          </span>
                        )}
                      </FormControl>
                  </div>
                </div>
                <div className="inputregistormemorialempresaform">
                  <label htmlFor="editfecha">Fecha de Nacimiento</label>
                  <div className="inputcreacioncrearmemorialempresacontainer">
                    <input
                      type="date"
                      placeholder=""
                      className={`crearinputmemorialempresa ${error && 'input-error'}`}
                      name='fechaNacimiento'
                      value={fechaNacimiento}
                      onChange={(e) => setFechaNacimiento(e.target.value)}
                    />
                    <div className="calendariocreacionicono">
                      <RxCalendar className="iconocrearmemorialcalendario" />
                    </div>
                  </div>
                  {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
                </div>
              
                <div className="inputregistormemorialempresaform">
                  <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                  <div className="inputcreacioncrearmemorialempresacontainer">
                    <input
                      type="date"
                      className={`crearinputmemorialempresa ${error && 'input-error'}`}
                      name='fechaNacimiento'
                      onChange={(e) => setFechaFallecimiento(e.target.value)}
                      value={fechaFallecimiento}
                    />
                    <div className="calendariocreacionicono">
                      <RxCalendar className="iconocrearmemorialcalendario" />
                    </div>
                  </div>
                  {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
                </div>
                <div className="inputregistormemorialempresaform">
                  <label>Cementerio (opcional)</label>
                  <div className="input-empresamemorialcementerio">

                    <input
                      type='text'
                      className="crearinputmemorialempresa"
                      name="cementerio"
                      id="cementerio"
                      placeholder="Indicar nombre del cementerio"
                      value={cementerio} onChange={(e) => setCementerio(e.target.value)}
                    />
                    <span className="buscadorempresamemorial">
                      <ImSearch />
                    </span>
                  </div>
                </div>
                <section className="contiene-input-imagen-perfil">
                  {showCreateObituary ? (<label htmlFor="file-input" className="input-subir">
                    <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
                    <div className="contiene-icono">
                      <FaArrowUpFromBracket className="imgicono" />
                      <span>Subir Foto</span>
                    </div>
                    <img src={imagenPreview || perfil} alt="Vista previa de la imagen" />

                  </label>
                  ) : (
                    <label htmlFor="file-input" className="input-subir">
                      <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
                      <div className="contiene-icono">
                        <FaArrowUpFromBracket className="imgicono" />
                        <span>Subir Foto</span>
                      </div>
                      {imagenPreview && <img src={imagenPreview} alt="Vista previa de la imagen" />}

                    </label>)}
                  <div >  {showCreateObituary ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "70px", marginTop: "-40px" }}>

                    <label className="buttonsubirfotomemorial">
                      <input input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
                      <FaArrowUpFromBracket />
                      <span>Subir una foto</span>
                    </label>
                  </div>

                  ) : (<></>)}</div>
                </section>
                <section className="historia-crear">
                  <div className="titulohitoriacrear">
                    <PiScrollFill />
                    <h1>Su historia</h1>
                  </div>
                  <ReactQuill
                    className="input-historia"
                    placeholder="Escriba hechos importantes en la vida de su ser querido..."
                    value={historia}
                    onChange={handleChange}
                    modules={{
                      toolbar: [
                        [],
                        [],
                        ['link'],

                      ],
                    }}
                    formats={[
                      'header', 'font', 'size',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'image', 'video'
                    ]}
                  />
                </section>
                <div className="botonregistro">
                  <button type="submit">{isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Crear'}</button>
                </div>

              </form>
            </section>
          </Box>
    

        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ width: '400px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', padding: '20px', borderRadius: '10px', margin: 'auto', marginTop: '15%' }}>
            <FaRegCircleCheck color="green" size={50} />
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:'center', fontFamily:'Poppins'}}>
              ¡Nombre de grupo familiar creado exitosamente!
            </Typography>

          </Box>
        </Modal>
        <Modal open={successAlertOpen} onClose={() => handleAccept}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 2,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section style={{ textAlign: 'center' }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <FaRegCircleCheck
                  style={{
                    color: 'green',
                    fontSize: '2rem',
                    marginBottom: '1rem',
                  }}
                />
                <p
                  style={{
                    fontFamily: 'Poppins',
                    color: '#585857',
                    fontSize: '1rem',
                    fontWeight: '600',
                    marginTop: '-5px',
                  }}
                >
                  {alertMessage}
                </p>
                <p
                  style={{
                    fontFamily: 'Poppins',
                    color: '#585857',
                    fontSize: '1rem',
                    fontWeight: '600',
                    marginTop: '-5px',
                  }}
                >
                  
                </p>
                <div className="contienebotonaceptar">
                  <button className="botonaceptar" onClick={handleAccept}>
                    Aceptar
                  </button>
                </div>
              </div>
            </section>
          </Box>
        </Modal>
        {/* Popup de error */}
        <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>

        <Modal open={errorAlertOpens} onClose={() => setErrorAlertOpens(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section className="closemodal" onClick={() => setErrorAlertOpens(false)}>
              <IoClose />
            </section>
            <section style={{ textAlign: 'center' }}>
              <BiErrorCircle style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
                {alertMessage}
              </p>
            </section>
          </Box>
        </Modal>

        {/* Popup de carga */}
        <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '350px',
              height: 'auto',
              bgcolor: '#ffff',
              boxShadow: 24,
              p: 1,
              borderRadius: '10px',
              '@media (max-width: 500px)': {
                width: '90%',
              },
            }}
          >
            <section style={{ textAlign: 'center', padding: '1rem' }}>
              <CircularProgress style={{ color: '#694B81' }} />
              <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
                Procesando solicitud...
              </p>
            </section>
          </Box>
        </Modal>
      </Container>
    </EmpresaLayout>
  )
}

const StyleContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '100%',
  fontFamily:'Poppins'
}

const StyleBoxdos = {
  marginTop: '0px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%'
}


const StyledSelect = styled(Select)(({ theme, error }) => ({
  padding: '1px 13px',
  color:'#694B81',
  position:'relative',
  left:'0.5%',
  fontFamily: "Poppins",
  width:'100%',
  height:'55px',
  fontSize:'0.8rem',
  borderRadius: '50px',
  border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F0EDF3', // Color del borde por defecto
    },
    '&:hover fieldset': {
      borderColor: '#585857', // Color del borde al pasar el mouse
    },
    '&.Mui-focused fieldset': {
      borderColor: '#585857', // Color del borde en foco
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#585857', // Color del borde en foco
    borderWidth: '2px', // Ancho del borde en foco
  },
  '@media (max-width: 310px)': {
    fontSize:'0.7rem',
    width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
    maxWidth: '96%',
  },
  
}));