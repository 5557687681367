import React, { useEffect, useState } from "react";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";

import { Container, Grid, Box, CircularProgress, Modal,  Card, CardContent, Typography} from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import StoreIcon from '@mui/icons-material/Store';
import axios from "axios";
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

/* react icons */

import { PiHouseLineBold } from "react-icons/pi";

const gaugeSettings = {
    width: 100,
    height: 100,
    cornerRadius: "50%",
    startAngle: 0,
    endAngle: 360,
  };

export const InformacionSucursales = () => {
    const { Usuario, Token, Empresa, UserDataMarca } = useUserEmpresa();
    const [sucursales, setSucursales] = useState([]);
    const [gruposFamiliaresCount, setGruposFamiliaresCount] = useState(0);
    const [gruposFamiliaresCountAsignados, setGruposFamiliaresCountAsignados] = useState(0);
    const [gruposFamiliaresCountDisponibles, setGruposFamiliaresCountDisponibles] = useState(0);
    const [sucursalesCount, setSucursalesCount] = useState(0);
    const [gruposFamiliaresCountConIdUsuario, setGruposFamiliaresCountConIdUsuario] = useState([]);
    const [loadings, setLoadings] = useState(true); 
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const theme = useTheme();
  



     // Traer las sucursales cuando la empresa y el token estén disponibles
  const fetchSucursales = async () => {
    if (!Empresa || !Token) return;
    setLoadings(true);
    try {
      const response = await axios.get(`${backendURL}/api/Sucursales/empresa/${Empresa.id}`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setSucursales(response.data.sucursales); // Establece las sucursales obtenidas
    } catch (error) {
      console.error("Error fetching sucursales:", error);
    } finally {
      setLoadings(false);
    }
  };

  // Llamar a fetchSucursales cuando los datos de la empresa y el token cambien
  useEffect(() => {
    if (Empresa && Token) {
      fetchSucursales();
    }
  }, [Empresa, Token]);

  useEffect(() => {
    if (sucursales.length > 0) {
      const totalGruposFamiliares = sucursales.reduce((acc, sucursal) => {
        return acc + sucursal.grupos_familiares.length; // Sumar la longitud de grupos_familiares de cada sucursal
      }, 0);
      setGruposFamiliaresCount(totalGruposFamiliares); // Establecer el conteo total
    }
  }, [sucursales]);
  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar el número total de grupos familiares
      const totalGruposFamiliares = sucursales.reduce((acc, sucursal) => {
        return acc + sucursal.grupos_familiares.length;
      }, 0);
      setGruposFamiliaresCount(totalGruposFamiliares); // Establecer el conteo total de grupos familiares
    }
  }, [sucursales]);

  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar el número total de grupos familiares
    
      // Contar solo los grupos familiares con id_usuario
      const gruposConIdUsuario = sucursales.reduce((acc, sucursal) => {
        const gruposConId = sucursal.grupos_familiares.filter(grupo => grupo.id_usuario).length;
        return acc + gruposConId;
      }, 0);

      // Establecer el estado con los resultados
     setGruposFamiliaresCountAsignados(gruposConIdUsuario)
    }
  }, [sucursales]);

  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar los grupos familiares con id_usuario vacío
      const gruposConIdUsuarioVacio = sucursales.reduce((acc, sucursal) => {
        const gruposSinId = sucursal.grupos_familiares.filter(grupo => !grupo.id_usuario).length;
        return acc + gruposSinId;
      }, 0);

      // Establecer el estado con los resultados
      setGruposFamiliaresCountDisponibles(gruposConIdUsuarioVacio);  // Número de grupos familiares sin id_usuario
     
    }
  }, [sucursales]);
  useEffect(() => {
    if (sucursales.length > 0) {
      // Contar el número total de sucursales
      setSucursalesCount(sucursales.length);

      // Contar los grupos familiares con id_usuario asignado y mostrar el nombre de la sucursal
      const gruposConIdUsuario = sucursales.reduce((acc, sucursal) => {
        // Filtrar los grupos familiares con id_usuario
        const gruposConId = sucursal.grupos_familiares.filter(grupo => grupo.id_usuario).length;
        // Si hay grupos con id_usuario, agregar el nombre de la sucursal y el conteo
        if (gruposConId > 0) {
          acc.push({
            nombreSucursal: sucursal.nombre_sucursal,
            gruposConIdUsuario: gruposConId,
            createdAt: sucursal.created_at
          });
        }
        return acc;
      }, []);

      // Establecer el estado con los resultados
      setGruposFamiliaresCountConIdUsuario(gruposConIdUsuario);
    }
  }, [sucursales]);

  
  const maxValues = {
    sucursales: 20, // Máximo de sucursales creadas
    qrGenerados: 2000, // Máximo de QR generados
    qrAsignados: 2000, // Máximo de QR asignados
    qrDisponibles: 2000, // Máximo de QR disponibles
  };
  
  // Cálculo del porcentaje
  const calculatePercentage = (current, max) => Math.min((current / max) * 100, 100);
  const data = [
  
    {
      title: "QR Generados por Sucursales",
      value: gruposFamiliaresCount,
      gaugeValue: calculatePercentage(gruposFamiliaresCount, maxValues.qrGenerados),
    },
    {
      title: "QR Asignados por Sucursales",
      value: gruposFamiliaresCountAsignados,
      gaugeValue: calculatePercentage(gruposFamiliaresCountAsignados, maxValues.qrAsignados),
    },
    {
      title: "QR Disponibles",
      value: gruposFamiliaresCountDisponibles,
      gaugeValue: calculatePercentage(gruposFamiliaresCountDisponibles, maxValues.qrDisponibles),
    },
  ];
    

    
  
    return(
     
        <Container maxWidth="xxl">
            <div  >
                <Box sx={{ marginTop:'50px' }}>
                <Typography variant="h5" gutterBottom sx={{ fontFamily:'Poppins', fontWeight:600, fontSize:'1rem', marginBottom:'10px', color:'#585857' }}>
                Sucursales {Empresa?.nombre_social}
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} >
                  <Card sx={{
                    
                    
                    boxShadow: 3, // Nivel de sombra (puede ser un número de 0 a 24)
                    '&:hover': {
                        boxShadow: 6, // Aumenta la sombra al hacer hover sobre la tarjeta
                        }
                    }}
                  
                    
                    >
                        <CardContent>
                        <Grid container alignItems="center"
                         sx={{
                          marginTop:'15px',
                                  
                           }}>
                          
                            <Grid item xs={12} sm={4}>
                              <div style={{
                                display:'flex',
                                alignItems:'center',
                                justifyContent:'center',
                               
                              }}>
                                 <IconButton>
                              <StoreIcon style={{
                                fontSize:'4.5rem',
                                color: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',
                              }} />
                            </IconButton>
                            </div>
                            </Grid>
                            {/* Texto en el lado derecho */}
                            <Grid item xs={12} sm={8} sx={{ paddingLeft:'10px'}}>
                            <Typography variant="h6"sx={{ fontFamily:'Poppins', fontSize:'1rem', lineHeight:'1', }} >Sucursales Creadas</Typography>
                            <Typography variant="h6" sx={{ fontFamily:'Poppins', marginTop:'5px', fontSize:'1.3rem', }}>{sucursalesCount}</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                  </Grid>
                {data.map((item, index) => (
                    <Grid item xs={12} sm={6}  key={index}>
                    <Card sx={{
                   
                    boxShadow: 3, // Nivel de sombra (puede ser un número de 0 a 24)
                    '&:hover': {
                        boxShadow: 6, // Aumenta la sombra al hacer hover sobre la tarjeta
                        }
                    }}
                  
                    
                    >
                        <CardContent>
                        <Grid container alignItems="center">
                            {/* Gauge en el lado izquierdo */}
                            <Grid item xs={12} sm={4}>
                            <Gauge
  {...gaugeSettings}
  value={item.gaugeValue}
  sx={(theme) => ({
    '& .e2oxsm-MuiGauge-container': {
      color: '#FF0000', // Cambia el color
      backgroundColor: '#F0F0F0', // Cambia el fondo
    },
    '.MuiTypography-root': {
      fontFamily: 'Poppins',
      color: '#585857',
    },
    [`& .${gaugeClasses.valueText}`]: {
      fontSize: 16,
      fontFamily: 'Poppins',
      color: '#585857',
    },
    [`& .${gaugeClasses.valueArc}`]: {
      fill: "#52b202", // Color del área llena
      fontFamily: 'Poppins',
    },
    [`& .${gaugeClasses.referenceArc}`]: {
      fill: theme.palette.text.disabled, // Color de referencia
      fontFamily: 'Poppins',
    },
    [`& .${gaugeClasses.track}`]: {
      fill: "#585857", // Cambia este color por el que desees para el área vacía
      fontFamily: 'Poppins',
    },
  })}
/>
                            </Grid>
                            {/* Texto en el lado derecho */}
                            <Grid item xs={12} sm={8} sx={{ paddingLeft:'10px'}}>
                            <Typography variant="h6"sx={{ fontFamily:'Poppins', fontSize:'1rem', lineHeight:'1',}} >{item.title}</Typography>
                            <Typography variant="h6" sx={{ fontFamily:'Poppins', marginTop:'5px', fontSize:'1.3rem', }}>{item.value}</Typography>
                            </Grid>
                        </Grid>
                        </CardContent>
                    </Card>
                    </Grid>
                ))}
                </Grid>
            </Box>
        </div>
       
       
        </Container>
       
    )
}

/* estilo material UI */
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#ffff',
    border: '0px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius:'10px',
    height:'650px',
    overflowY:'auto'
  };

  const modalStyleClose = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end'
  }