import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../../../context/usecontext";
import { useNavigate, useParams, Link } from "react-router-dom";
import "../../../../Style/admi/adminuser.css";
import { datadireccion } from "../../../../data/api direccion/datadireccion";

/* libreria UI */
import {
    Container, Box, Grid, CircularProgress, Modal, Typography,
    TextField, Button, Select, MenuItem, InputLabel, FormControl, Alert, AlertTitle
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from "@mui/system";
import LinearProgress from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



/* react iconos */
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { TbUserX } from "react-icons/tb";


/* imagenes */
import bordeuno from "../../../../Image/administrador/borde1.png";
import bordedos from "../../../../Image/administrador/borde2.png";
import luto from "../../../../Image/administrador/moradoluto.png";
import casa from "../../../../Image/administrador/casasucursal.png";


export const EmpresasSelecion = () => {
    const [qrAllEmpresa, setQrAllEmpresa] = useState([]);
    const [tiposEmpresas, setTiposEmpresas] = useState([]);
    const [tipoEmpresaSeleccionado, setTipoEmpresaSeleccionado] = useState('');
    const [cantidad, setCantidad] = useState('');
    const [uf, setUf] = useState('');
    const [iva, setIva] = useState('');
    const { adminToken, setAdminAuthToken } = useAuth();
    const { id } = useParams();
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [empresa, setEmpresa] = useState(null);
    const [tareasEmpresas, setTareasEmpresas] = useState([])
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tareas, setTareas] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [open, setOpen] = useState(false);
    const [sucursal, setSucursal] = useState([]);
    const [openQr, setOpenQr] = useState(false);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [selectedTarea, setSelectedTarea] = useState(null);
    const [tareasAdjudicadas, setTareasAdjudicadas] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ estado: null, id_tarea: null });
    const [showAlert, setShowAlert] = useState(false);
    const [progress, setProgress] = useState(100);
    const [openEdit, setOpenEdit] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [modalOpeneliminar, setModalOpeneliminar] = useState(false);



    /* intercepcion axios */
    axios.interceptors.response.use(
        response => response, // Retorna la respuesta si no hay error
        error => {
            if (error.response && error.response.status === 404) {
                // No mostrar el error 404 en la consola
                return Promise.reject(error); // O simplemente retornar un valor específico
            }
            // Para otros errores, se registran normalmente
            console.error("Error en la solicitud:", error.message);
            return Promise.reject(error);
        }
    );

    /* botonesmodal */
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedTarea(null); // Resetear la tarea seleccionada
        setModalOpen(false); // Cerrar también el modal de estado si está abierto
    };



    const handleOpenAddQr = () => setOpenQr(true);
    const handleCloseAddQr = () => {
        setOpenQr(false);
        setSelectedTarea(null); // Resetear la tarea seleccionada
        setModalOpen(false); // Cerrar también el modal de estado si está abierto
    };

    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => setOpenEdit(false);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
    }, [setAdminAuthToken, navigate]);


    useEffect(() => {
        fetchQrCodes();
    }, [id, adminToken]);

    useEffect(() => {
        const fetchTiposEmpresas = async () => {
            try {
                const response = await fetch(`${backendURL}/api/get/tipo/empresas`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error('Error en la red');
                }

                const data = await response.json();

                if (data.tipo_empresa && Array.isArray(data.tipo_empresa)) {
                    setTiposEmpresas(data.tipo_empresa);
                } else {
                    console.error('La respuesta no contiene un array de tipo_empresa:', data);
                }
            } catch (error) {
                console.error('Error al obtener los tipos de empresa:', error);
            }
        };

        fetchTiposEmpresas();
    }, [adminToken]);
    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
    }, [setAdminAuthToken, navigate]);

    useEffect(() => {
        if (!adminToken) return;
        setLoading(true);
        // Función para obtener la empresa por ID
        const fetchEmpresa = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/empresa/${id}`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                setEmpresa(response.data.empresa);
                const fetchTareas = async () => {
                    try {
                        const tareasResponse = await axios.get(`${backendURL}/api/tareas`, {
                            headers: {
                                Authorization: `Bearer ${adminToken}`,
                            },
                        });
                        // Filtrar tareas según el ID de la empresa
                        setTareas(tareasResponse.data.tareas);
                    } catch (error) {
                        console.error("Error al obtener las tareas:", error);
                    }
                };


                fetchTareas();




                const fetchEmpresastareasSuscripcion = async () => {
                    try {
                        const response = await axios.get(`${backendURL}/api/empresasalltareasuscripcion/${id}`, {
                            headers: {
                                'Authorization': `Bearer ${adminToken}`
                            }
                        });


                        setTareasEmpresas(response.data.tareas)
                        const adjudicadas = response.data.tareas.map(empresa => empresa.id_tarea);
                        setTareasAdjudicadas(adjudicadas);

                    } catch (error) {
                        console.error('Error fetching empresas:', error);
                    }
                };

                fetchEmpresastareasSuscripcion();

                const fetchSucursal = async () => {
                    setLoading(true);
                    setError(null);

                    try {
                        const response = await axios.get(`${backendURL}/api/sucursal/${id}`, {
                            headers: {
                                Authorization: `Bearer ${adminToken}`,
                            },
                        });

                        if (response.data.sucursales) {
                            setSucursal(response.data.sucursales);
                        } else {
                            setSucursal([]);
                            setError("No hay sucursal afiliada a la empresa");
                        }
                    } catch (error) {
                        if (error.response && error.response.status === 404) {
                            setError("No hay sucursal afiliada a la empresa");
                            setSucursal([]);
                        } else {
                            console.error("Error al obtener la sucursal:", error.message);
                            setError("Ocurrió un error al obtener los datos de la sucursal");
                        }
                    } finally {
                        setLoading(false);
                    }
                };
                fetchSucursal();




                // Después de obtener la empresa, buscar los usuarios por id_empresa
                const fetchUsers = async () => {
                    try {
                        const usersResponse = await axios.get(`${backendURL}/api/users`, {
                            headers: {
                                Authorization: `Bearer ${adminToken}`,
                            },
                        });

                        const filteredUsers = usersResponse.data.filter(user => user.id_empresa == parseInt(id));
                        setUsuarios(filteredUsers);
                    } catch (error) {
                        console.error("Error al obtener los usuarios:", error);
                    }
                };

                fetchUsers();
            } catch (err) {
                setError("Error al obtener la empresa");
                console.error("Error al obtener la empresa:", err);
            } finally {
                setLoading(false);
            }
        };


        if (id) {
            fetchEmpresa();
        }
    }, [id, backendURL]);

    const fetchEmpresa = async () => {
        if (!adminToken) return;
        setLoading(true);

        try {
            const response = await axios.get(`${backendURL}/api/empresa/${id}`, {
                headers: { Authorization: `Bearer ${adminToken}` },
            });
            setEmpresa(response.data.empresa);
            // Aquí también puedes llamar a tus funciones para obtener tareas, usuarios, etc.
        } catch (err) {
            setError("Error al obtener la empresa");
            console.error("Error al obtener la empresa:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchEmpresa();
        }
    }, [id, backendURL, adminToken]);

    const refreshData = () => {
        fetchEmpresa();
    };
    /* traer las tareas para aguregar a la empresa */
    const fetchEmpresastareasSuscripcion = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/empresasalltareasuscripcion/${id}`, {
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                }
            });

            // Actualiza el estado de tareasEmpresas con la respuesta
            setTareasEmpresas(response.data.tareas);
        } catch (error) {
            console.error('Error fetching empresas:', error);
        }
    };



    /* apertura de la vista de la contraseña */

    const handleConfirmationClose = () => setConfirmationOpen(false);

    /* agregar tarea */
    const agregarTarea = async (tareaId) => {
        const data = {
            id_empresa: id,
            id_tarea: tareaId,
            estado: 'A', // O el estado que desees por defecto
        };

        try {
            const response = await axios.post(`${backendURL}/api/tarea-empresa`, data, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
            setConfirmationOpen(true); // Abre el modal de confirmación
            handleClose(); // Cierra el modal de selección de tareas
        } catch (error) {
            console.error("Error al agregar la tarea:", error);
            alert("Error al agregar la tarea");
        }
    };

    /* cambio de estado de las tareas empresa */
    const toggleEstadoTarea = async (tareaId) => {
        setUpdating(true);
        try {
            const response = await axios.post(`${backendURL}/api/tarea/${tareaId}/toggle-estado/${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });

            // Actualizar el estado de la tarea en el estado local
            setTareas(tareas.map(tarea =>
                tarea.id == tareaId ? { ...tarea, estado: response.data.tarea.estado } : tarea
            ));

            if (response.data && response.data.tarea) {
                setModalData({
                    estado: response.data.tarea.estado,
                    id_tarea: response.data.tarea.id_tarea
                });
                setModalOpen(true);
                await fetchEmpresastareasSuscripcion();

            } else {
                console.error("La respuesta no contiene los datos esperados.");
            }

        } catch (error) {
            console.error("Error al actualizar el estado de la tarea:", error);
            alert("Error al actualizar el estado de la tarea");
        } finally {
            setUpdating(false);
        }
    };

    /* agregar cantidad de qr */
    const handleSubmitAddQr = async (event) => {
        event.preventDefault();
        const data = {
            cantidad,
            uf,
            iva,
            fecha: new Date().toISOString().split('T')[0], // Fecha actual
            id_tipo_empresa: tipoEmpresaSeleccionado, // ID de la empresa seleccionada
            id_empresa: id,
        };

        try {
            const response = await axios.post(`${backendURL}/api/add/qr/empresa`, data, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setConfirmationOpen(true); // Abre el modal de confirmación
            handleCloseAddQr();

            // Aquí puedes cerrar el modal o realizar otras acciones
        } catch (error) {
            console.error("Error al asignar la cantidad de QR:", error);
            alert("Error al asignar la cantidad de QR");
        }
    };

    const handleSubmitEditQr = async (event) => {
        event.preventDefault();
        const data = {
            cantidad,
            uf,
            iva,
            fecha: new Date().toISOString().split('T')[0], // Fecha actual
            id_tipo_empresa: tipoEmpresaSeleccionado, // ID de la empresa seleccionada
            id_empresa: id,
        };

        try {
            const response = await axios.post(`${backendURL}/api/qr/edit/empresa/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                },
            });

            setConfirmationOpen(true); // Abre el modal de confirmación
            fetchQrCodes();



        } catch (error) {
            console.error("Error al editar la cantidad de QR:", error);
            alert("Error al editar la cantidad de QR");
        }
    };

    const fetchQrCodes = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/qr-empresa/${id}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                },
            });
            setQrAllEmpresa(response.data.data);
           
        } catch (err) {
            setError(err.response?.data?.message || 'Error fetching QR codes');
        }
    };


    /* funciones de botones de navegacion */
    const handleAddSucursal = () => {
        navigate('/crear-sucursal', { state: { empresaId: id } });
    };
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    /* cierres de alertas */
    useEffect(() => {
        if (error) {
            setShowAlert(true);
            setProgress(100); // Reinicia el progreso

            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 5000);

            const progressInterval = setInterval(() => {
                setProgress((prev) => {
                    if (prev <= 0) {
                        clearInterval(progressInterval); // Detiene el intervalo
                        return 0;
                    }
                    return prev - (100 / 50); // Reduce el progreso en un valor específico
                });
            }, 100); // Ajusta el intervalo de tiempo

            return () => {
                clearTimeout(timer);
                clearInterval(progressInterval); // Limpia el intervalo al desmontar
            };
        }
    }, [error]);

    const handleDeleteempresa = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const response = await axios.delete(`${backendURL}/api/eliminarempresa/${id}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });

            // Manejar la respuesta exitosa
            setSuccessMessage(response.data.message);
            navigate('/todas-empresas');
            // Aquí puedes hacer algo más, como actualizar la lista de sucursales

        } catch (err) {
            console.error("Error al eliminar la sucursal:", err);
            setError("Ocurrió un error al eliminar la sucursal.");
        } finally {
            setLoading(false);
            setModalOpen(false); // Cerrar el modal después de eliminar
        }
    };
    if (loading) {
        return (
            <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                    <CircularProgress sx={{ color: "D26999" }} />
                </Box>
            </Container>
        );
    }
   

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        borderRadius: '10px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };


    return (
        <>
            <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "50px"
                }}>
                    <section className="sectionempresaespecifica">
                        <div className="logoempresaespecifico">
                            {empresa.logo && (
                                <img src={`${backendURL}/${empresa.logo}`} alt="smart memorial" title="smart memorial" />
                            )}
                        </div>
                        <div className="nombreespecificoempresa">
                            <div className="imagenbordetodosempresasuno">
                                <img src={bordeuno} className="bordetodaslasempresas" alt="smart memorial"
                                    title="smart memorial" />
                            </div>
                            <div className="tituloallempresas">
                                <h2> {empresa.id_tipo_empresa == 1 ? 'Cementerio' : empresa.id_tipo_empresa == 2 ? 'Funeraria' :
                                    'Otro'} {empresa.nombre_empresa}</h2>

                            </div>
                            <div className="imagenbordetodosempresasdos">
                                <img src={bordedos} className="bordetodaslasempresas" alt="smart memorial"
                                    title="smart memorial" />
                            </div>
                        </div>
                    </section>

                    <section className="informacionemmpresaespecifica">
                        <div className="card-infoempresaespecifica">
                            <div className="titulocard-infoespecifico">
                                <div className="herramienta-info-especifico-empresa">
                                    <div className="immagenluto">
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                    </div>
                                    <h3>Información empresa</h3>
                                </div>
                                <div>
                                </div>
                                <div className="editarempresa">
                                    <FaEdit />
                                </div>
                            </div>
                            <div className="cardblancoinfo">
                                <Grid container>
                                    <Grid item xs={4}>

                                        {usuarios.length > 0 ? (
                                            <>
                                                {usuarios.map((user) => (
                                                    <div className="iteminfoempresa" key={user.id}>
                                                        <h3>Nombre</h3>

                                                        <h4>{user.name} {user.apellido}</h4>
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p>No se encontraron usuarios para esta empresa.</p>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Pagína web</h3>
                                            <h5>{empresa.web}</h5>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Correo electrónico</h3>
                                            <h5>{empresa.correo}</h5>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Nombre Empresa</h3>
                                            <h4> {empresa.id_tipo_empresa == 1 ? 'Cementerio' : empresa.id_tipo_empresa == 2 ? 'Funeraria' :
                                    'Otro'} {empresa.nombre_empresa}</h4>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Categoria Empresa</h3>
                                            <h4> {empresa.id_tipo_empresa == 1 ? 'Cementerio' : empresa.id_tipo_empresa == 2 ? 'Funeraria' :
                                    'Otro'} </h4>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {usuarios.length > 0 ? (
                                            <>
                                                {usuarios.map((user) => (
                                                    <div className="iteminfoempresa">
                                                        <button>
                                                            <h3> Reiniciar Contraseña </h3>
                                                        </button>

                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <p>No se encontraron usuarios para esta empresa.</p>
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Dirección</h3>
                                            <h5> {empresa.pais}, {empresa.region}, {empresa.comuna}, {empresa.direccion}</h5>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div className="iteminfoempresa">
                                            <h3>Teléfono</h3>
                                            <h4> {empresa.telefono}</h4>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                    </section>

                    <section className="informacionemmpresaespecifica">
                        <div className="card-infoempresaespecifica">
                            <div className="titulocard-infoespecifico">
                                <div className="herramienta-info-especifico-empresa">
                                    <div className="immagenluto">
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                    </div>
                                    <h3>Tareas empresa</h3>
                                </div>
                                <div>
                                </div>
                                <div className="editarempresa" onClick={handleOpen}>

                                    <MdOutlineAddToPhotos />

                                    <p>Agregar tarea</p>

                                </div>
                            </div>
                            <div className="cardblancoinfo">
                                <Grid container>

                                    {loading ? (
                                        <Box
                                            sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                            <CircularProgress sx={{ color: "D26999" }} />
                                        </Box>
                                    ) : tareasEmpresas.length === 0 ? (
                                        <Grid item xs={12} sx={{ padding: '20px' }}>
                                            <div className="editarempresa" onClick={handleOpen}>
                                                <MdOutlineAddToPhotos />
                                                <p>Agregar tarea</p>
                                            </div>
                                        </Grid>
                                    ) : (
                                        tareasEmpresas.map(tareaEmpresa => {
                                            // Extraer la tarea
                                            const { tarea, } = tareaEmpresa;

                                            return (
                                                <Grid item xs={4} key={tarea.id}>
                                                    <div className="cardtarea">
                                                        <div className="contieneimagentitulotareaempresasmartmemorial">
                                                            <div className="contienenimagentareaadmismarttarea">
                                                                <img src={`${backendURL}/imagenes/tareas/${tarea.logo}`} alt={tarea.nombre}
                                                                    title={tarea.nombre} />
                                                            </div>
                                                            <h4>{tarea.nombre}</h4>
                                                        </div>
                                                        <div className="switch">
                                                            <IOSSwitch checked={tarea.estado === 'A'} // Verifica el estado de la tarea
                                                                onChange={() => toggleEstadoTarea(tarea.id)}
                                                                disabled={updating}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            );
                                        })
                                    )}
                                </Grid>
                            </div>
                        </div>


                    </section>
                    <section className="informacionemmpresaespecifica">
                        {/* agregarqr */}
                        <div className="card-infoempresaespecifica">
                            <div className="titulocard-infoespecifico">
                                <div className="herramienta-info-especifico-empresa">
                                    <div className="immagenluto">
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                    </div>
                                    <h3>Codigos QR Asignado</h3>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="cardblancoinfo">
                                <Grid item xs={12} sx={{ padding: '20px' }}>
                                    <div className="editarempresa" onClick={handleOpenAddQr}>
                                        <MdOutlineAddToPhotos />
                                        <p>Asignar cantidad QR</p>
                                    </div>
                                </Grid>

                                <Container maxWidth="lg">
                                    <Grid container spacing={2}>
                                        {qrAllEmpresa.map((item) => (
                                            <form onSubmit={handleSubmitEditQr}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel id="tipo-empresa-label">Cantidad de QR (actual: {item.cantidad})</InputLabel>
                                                        <TextField variant="outlined" type="number" value={cantidad}
                                                            onChange={(e) => setCantidad(e.target.value)}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel id="tipo-empresa-label">UF(actual: {item.uf})</InputLabel>
                                                        <TextField variant="outlined" fullWidth value={uf} onChange={(e) =>
                                                            setUf(e.target.value)}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <InputLabel id="tipo-empresa-label">IVA(actual: {item.iva})</InputLabel>
                                                        <TextField variant="outlined" fullWidth value={iva} onChange={(e) =>
                                                            setIva(e.target.value)}
                                                            required
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>

                                                        <InputLabel id="tipo-empresa-label">
                                                            Tipo de Empresa (actual: {item.id_tipo_empresa === 1 ? 'Cementerio' : item.id_tipo_empresa === 2 ? 'Funeraria' : 'Desconocido'})
                                                        </InputLabel>
                                                        <FormControl fullWidth required>
                                                            <Select labelId="tipo-empresa-label" value={tipoEmpresaSeleccionado} onChange={(e) =>
                                                                setTipoEmpresaSeleccionado(e.target.value)}
                                                            >
                                                                {tiposEmpresas.map((tipo) => (
                                                                    <MenuItem key={tipo.id} value={tipo.id}>
                                                                        {tipo.nombre} {/* Mostrar el nombre de la empresa */}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button type="submit" variant="contained" color="primary">
                                                            Editar Suscripcion QR
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <div className="estadoempresaadmismart">
                                                            <div className="contienenestadoempresaadmi">
                                                                <div class="heartadmi-container" title="Like">
                                                                    <input type="checkbox" className="checkbox" id="Give-It-An-Id" defaultChecked />
                                                                    <div className="svg-container">
                                                                        <svg viewBox="0 0 24 24" className="svg-outline" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                                                                            </path>
                                                                        </svg>
                                                                        <svg viewBox="0 0 24 24" className="svg-filled" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                                                                            </path>
                                                                        </svg>
                                                                        <svg className="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                                                                            <polygon points="10,10 20,20"></polygon>
                                                                            <polygon points="10,50 20,50"></polygon>
                                                                            <polygon points="20,80 30,70"></polygon>
                                                                            <polygon points="90,10 80,20"></polygon>
                                                                            <polygon points="90,50 80,50"></polygon>
                                                                            <polygon points="80,80 70,70"></polygon>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <p>Estado Suscripción</p>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </form>

                                        ))}
                                    </Grid>
                                </Container>


                            </div>
                        </div>
                    </section>

                    <section className="informacionemmpresaespecifica">
                        <div className="nombreespecificoempresa">
                            <div className="imagenbordetodosempresasuno">
                                <img src={bordeuno} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial" />
                            </div>
                            <div className="tituloallempresas">
                                <h2> Sucursales</h2>
                            </div>

                            <div className="imagenbordetodosempresasdos">
                                <img src={bordedos} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial" />
                            </div>
                        </div>
                        <div className="contienensucursalesasignadasempresa">
                            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {sucursal.length > 0 ? (
                                    <>
                                        {sucursal.map((sucursalItem) => (
                                            <Grid item xs={4} key={sucursalItem.id} sx={{ maxWidth: '100%', padding: '10px' }}>
                                                <Link to={`/sucursal-admin-smart/${sucursalItem.id}`} >
                                                    <button className="cardsucursaldesdeempresa" style={{ width: '100%' }}>
                                                        <div className="imagensucursalvistaempresaadmismart">
                                                            <img src={casa} width={'100%'} alt="smart memorial" title="smart memorial" />
                                                        </div>
                                                        <p>{sucursalItem.nombre_sucursal}</p>
                                                    </button>
                                                </Link>
                                            </Grid>
                                        ))}
                                        {/* Botón "Agregar sucursal" al lado de las sucursales */}
                                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <button className="cardsucursaldesdeempresa" onClick={handleAddSucursal}>
                                                <div className="imagensucursalvista-empresaadmismart">
                                                    <MdOutlineAddToPhotos />
                                                </div>
                                                <p>Agregar sucursal</p>
                                            </button>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <button className="cardsucursaldesdeempresa" onClick={handleAddSucursal}>
                                            <div className="imagensucursalvista-empresaadmismart">
                                                <MdOutlineAddToPhotos />
                                            </div>
                                            <p>Agregar sucursal</p>
                                        </button>
                                    </Grid>
                                )}
                            </Grid>
                        </div>


                    </section>
                    <section className="informacionemmpresaespecifica">
                        {/* agregarqr */}
                        <div className="card-infoempresaespecifica">
                            <div className="titulocard-infoespecifico">
                                <div className="herramienta-info-especifico-empresa">
                                    <div className="immagenluto">
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                    </div>
                                    <h3>Suscripción Smart Memorial</h3>
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className="cardblancoinfo" >
                                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: '15px 0px' }}>
                                    <Grid item xs={3} sx={{ padding: '20px', borderRight: '2px solid #431E62', }}>
                                        <div className="contendormontocontableadmismart" > <p>Costo Total del uso del administrador mes </p></div>
                                    </Grid>
                                    <Grid item xs={6} sx={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', borderRight: '2px solid #431E62' }}>
                                        <div className="contendormontocontableadmismart" >  <p> Total</p> <h3> </h3></div>
                                        <div className="contendormontocontableadmismart" >  <p> Interes Total</p> <h3> </h3></div>
                                    </Grid>
                                    <Grid item xs={3} sx={{ padding: '20px' }}>
                                        <div className="contendormontocontableadmismart" > <p> Total UF Smart</p> <h3> </h3></div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </section>
                    <section className="informacionemmpresaespecifica">
                        <button className="buttoneliminarsucursal" onClick={() => setModalOpeneliminar(true)}>
                            <TbUserX />
                            <p> Eliminar Empresa {empresa.nombre_empresa}</p>
                        </button>
                    </section>

                </Box>
            </Container>


            {/* modal eliminar */}
            <Modal
                open={modalOpeneliminar}
                onClose={() => setModalOpeneliminar(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center',
                    }}
                >
                    <h2 id="modal-modal-title">Confirmar Eliminación</h2>
                    <p id="modal-modal-description">
                        ¿Estás seguro de que deseas eliminar esta empresa?
                    </p>
                    <div className="contienetodoslosbotonesediteliminar">
                        <button
                            onClick={handleDeleteempresa}
                            className="sieli"
                        >
                            Eliminar
                        </button>
                        <button
                            onClick={() => setModalOpen(false)}
                            className="noeli"
                        >
                            Cancelar
                        </button>
                    </div>
                </Box>
            </Modal>

            {/* Modal asignar qr empresa */}
            <Modal open={openQr} onClose={handleCloseAddQr}>
                <Box sx={style}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <button onClick={handleCloseAddQr} className="closetareamodal">
                            <IoClose />
                        </button>
                    </Box>

                    <h2 className="titulotareaselecion">Asignar cantidad QR</h2>
                    <form onSubmit={handleSubmitAddQr}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Cantidad de QR" variant="outlined" type="number" value={cantidad}
                                    onChange={(e) => setCantidad(e.target.value)}
                                    requiredfullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="UF" variant="outlined" fullWidth value={uf} onChange={(e) =>
                                    setUf(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="IVA" variant="outlined" fullWidth value={iva} onChange={(e) =>
                                    setIva(e.target.value)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth required>
                                    <InputLabel id="tipo-empresa-label">Tipo de Empresa</InputLabel>
                                    <Select labelId="tipo-empresa-label" value={tipoEmpresaSeleccionado} onChange={(e) =>
                                        setTipoEmpresaSeleccionado(e.target.value)}
                                    >
                                        {tiposEmpresas.map((tipo) => (
                                            <MenuItem key={tipo.id} value={tipo.id}>
                                                {tipo.nombre} {/* Mostrar el nombre de la empresa */}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary">
                                    Asignar QR
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
            {/* Modal para mostrar todas las tareas */}
            <Modal open={open} onClose={handleClose}>
                <Box sx={style}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>
                        <button onClick={handleClose} className="closetareamodal">
                            <IoClose />
                        </button>
                    </Box>
                    <h2 className="titulotareaselecion">Todas las Tareas</h2>
                    <Grid container>
                        {tareas.length > 0 ? (
                            <table className="encabezado-tabla">
                                <thead>
                                    <tr>
                                        <th>Imagen</th>
                                        <th>Nombre Tarea</th>
                                        <th>Descripción Función</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                                <tbody className="tareas-table">
                                    {tareas.map(tarea => (
                                        <tr key={tarea.id}>
                                            <td>
                                                <div className="logotareacrear">
                                                    {tarea.logo && (
                                                        <img src={`${backendURL}/imagenes/tareas/${tarea.logo}`} alt="smart memorial"
                                                            title="smart memorial" />
                                                    )}
                                                </div>
                                            </td>
                                            <td>{tarea.nombre}</td>
                                            <td>{tarea.funcion}</td>
                                            <td
                                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '300px', marginTop: '30px' }}>

                                                {tareasAdjudicadas.includes(tarea.id) ? (
                                                    <button className="agregado">Agregado</button> // Cambia el botón por un mensaje
                                                ) : (
                                                    <div className="buttontareaagregarempresa">
                                                        <button onClick={() => agregarTarea(tarea.id)}>Agregar Tarea</button>
                                                    </div>
                                                )}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No hay tareas disponibles.</p>
                        )}
                    </Grid>
                </Box>
            </Modal>

            {/* Modal de confirmación de tarea agregada */}
            <Modal open={confirmationOpen} onClose={handleConfirmationClose}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    borderRadius: '10px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    zIndex: '111111111111111111111'
                }}>
                    < FaRegCircleCheck style={{ color: 'green', fontSize: '1.8rem' }} />
                    <h2 style={{ margin: '0px', fontWeight: '500', color: '#585857', paddingBottom: '10px', fontSize: '1rem' }}>Tarea
                        agregada con éxito</h2>
                    <div className="buttontareaagregarempresa">
                        <button onClick={handleConfirmationClose}>
                            Cerrar
                        </button>
                    </div>
                </Box>
            </Modal>

            <Modal open={modalOpen} onClose={handleClose}>
                <Box sx={style}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end"
                    }}>
                        <button onClick={handleClose} className="closetareamodal">
                            <IoClose />
                        </button>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}>

                        <Typography variant="h6" sx={{
                            fontFamily: 'Poppins', fontWeight: '500', color: '#585857'
                        }}>
                            {modalData.estado == 'A' ? 'Tarea Activada' : 'Tarea Desactivada'}
                        </Typography>
                        {tareas
                            .filter(tarea => tarea.id == modalData.id_tarea)
                            .map(tarea => (
                                <div key={tarea.id}
                                    style={{ display: 'flex', alignItems: 'center', marginTop: '20px', flexDirection: 'column' }}>
                                    <div className="logotareacrear">
                                        {tarea.logo && (
                                            <img src={`${backendURL}/imagenes/tareas/${tarea.logo}`} alt="smart memorial"
                                                title="smart memorial" />
                                        )}
                                    </div>
                                    <Typography variant="body1" sx={{
                                        fontFamily: 'Poppins', fontWeight: '500', color: '#585857'
                                    }}>
                                        {tarea.nombre}
                                    </Typography>

                                </div>
                            ))}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}


export const Editarempresa = ({ close, empresa, refreshData }) => {
    const { adminToken } = useAuth(); // Asegúrate de que tienes acceso al token de autenticación
    const [empresaData, setEmpresaData] = useState({});
    const [usuarioData, setUsuarioData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [showAlert, setShowAlert] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertMessage, setAlertMessage] = useState("");
    const [loadingModalOpen, setLoadingModalOpen] = useState(false);



    // Obtener información de la empresa y del usuario al cargar el componente
    useEffect(() => {
        const fetchEmpresaData = async () => {
            try {
                const empresaResponse = await axios.get(`${backendURL}/api/empresa/${empresa.id}`, {
                    headers: { Authorization: `Bearer ${adminToken}` },
                });
                setEmpresaData(empresaResponse.data.empresa);


            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        if (adminToken) {
            fetchEmpresaData();
        }
    }, [adminToken, empresa.id]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersResponse = await axios.get(`${backendURL}/api/users`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });

                // Filtrar el usuario por id_empresa
                const filteredUsers = usersResponse.data.filter(user => user.id_empresa == empresa.id);
                if (filteredUsers.length > 0) {
                    setUsuarioData(filteredUsers[0]); // Si solo es uno, toma el primer usuario
                }
            } catch (error) {
                setError("Error al obtener los usuarios");
                console.error("Error al obtener los usuarios:", error);
            } finally {
                setLoading(false);
            }
        };

        if (adminToken && empresa?.id) {
            fetchUsers();
        }
    }, [adminToken, empresa]);

    const handleEmpresaDataChange = (e) => {
        const { name, value } = e.target;
        let newData = { ...empresaData, [name]: value };

        // Sincroniza 'correo' y 'email' si uno de ellos cambia
        if (name === 'correo') {
            newData.email = value;  // Actualiza también el email
        } else if (name === 'email') {
            newData.correo = value;  // Si cambias el email, también se actualiza el correo
        }

        setEmpresaData(newData);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingModalOpen(true); // Abre el modal de carga

        try {
            // Actualizar empresa
            await axios.put(`${backendURL}/api/empresa/${empresa.id}`, empresaData, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                },
            });

            // Actualizar usuario
            await axios.put(`${backendURL}/api/user/${usuarioData.id}`, usuarioData, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                    'Content-Type': 'application/json',
                },
            });

            // Mostrar alerta de éxito
            setAlertSeverity("success");
            setAlertMessage("Información actualizada correctamente.");
            setShowAlert(true);

            // Cierra el modal después de 3 segundos
            setTimeout(() => {
                setLoadingModalOpen(false);
                refreshData();
                close(); // Cierra el modal de edición
            }, 3000);
        } catch (error) {
            console.error("Error al actualizar la información:", error);
            setAlertSeverity("error");
            setAlertMessage("Hubo un error al actualizar la información.");
            setShowAlert(true);

            // Cierra el modal después de 3 segundos
            setTimeout(() => {
                setLoadingModalOpen(false);
            }, 3000);
        } finally {
            // Cierra la alerta después de 3 segundos
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    if (loading) return <div>Cargando...</div>;
    if (error) return <div>{error}</div>;

    return (
        <> {showAlert && (
            <Alert
                severity={alertSeverity}
                action={
                    <Button color="inherit" size="medium" onClick={handleCloseAlert}>
                        <IoClose />
                    </Button>
                }
                style={{ margin: '20px 0', position: 'fixed', top: '0', right: '0' }}
            >
                <AlertTitle>{alertSeverity === "success" ? "Éxito" : "Error"}</AlertTitle>
                <Typography variant="h6" sx={{
                    fontFamily: 'Poppins', fontWeight: '500', fontSize: '0.8rem', margin: '0', paddingRight: '5px'
                }}>
                    {alertMessage}
                </Typography>
                {alertSeverity === "success" && <LinearProgress />}
            </Alert>
        )}
            <form className="formulariocrearempresa" onSubmit={handleSubmit}>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombre'>Nombre empresa</label>
                    <div className='contieneinputempresaregister'>
                        <input className="input-crear-empresa-adminsmart"
                            value={empresaData.nombre_empresa || ''}
                            onChange={(e) => setEmpresaData({ ...empresaData, nombre_empresa: e.target.value })}
                            name="nombre_empresa"
                            id="nombre_empresa" />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombre'>Nombre social</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="nombre_social"
                            id="nombre_social"
                            value={empresaData.nombre_social || ''}
                            onChange={(e) => setEmpresaData({ ...empresaData, nombre_social: e.target.value })}
                        />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombre'>Nombre usuario</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="name"
                            id="name"
                            value={usuarioData.name || ''}
                            onChange={(e) => setUsuarioData({ ...usuarioData, name: e.target.value })}
                        />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombre'>Apellido usuario</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="apellido"
                            id="apellido"
                            value={usuarioData.apellido || ''}
                            onChange={(e) => setUsuarioData({ ...usuarioData, apellido: e.target.value })}
                        />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombre'>web</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="web"
                            id="web"
                            value={empresaData.web || ''}
                            onChange={(e) => setEmpresaData({ ...empresaData, web: e.target.value })}
                        />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='telefono'>Teléfono</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="telefono"
                            id="telefono"
                            value={empresaData.telefono || ''}
                            onChange={(e) => setEmpresaData({ ...empresaData, telefono: e.target.value })}
                        />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='email'>Correo electrónico</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            type="email"
                            className='input-crear-empresa-adminsmart'
                            name="correo"
                            id="correo"
                            value={empresaData.correo || ''}
                            onChange={handleEmpresaDataChange} />
                    </div>
                </div>
                <div className="input-crearadminsmartempresa">
                    <label htmlFor='nombreEmpresa'>Dirección</label>
                    <div className='contieneinputempresaregister'>
                        <input
                            className="input-crear-empresa-adminsmart"
                            name="direccion"
                            id="direccion"
                            value={empresaData.direccion || ''}
                            onChange={(e) => setEmpresaData({ ...empresaData, direccion: e.target.value })}
                        />
                    </div>
                </div>


                <div className="buttonsiguientecrearempresa">
                    <button type="submit">Editar empresa</button>
                </div>
            </form>
            <Modal open={loadingModalOpen} onClose={() => setLoadingModalOpen(false)}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    borderRadius: '10px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',  // Fijar la altura máxima al 80% de la ventana
                    bgcolor: 'background.paper',
                    overflowY: 'auto',
                }}>
                    <LinearProgress />
                    <Typography variant="h6" sx={{ marginLeft: 2 }}>Cargando solicitud...</Typography>
                </Box>
            </Modal>
        </>
    )
}


const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 100,
    height: 45,
    padding: 0,
    borderRadius: '50px',

    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(58px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#694B81',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },

        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 40,
        height: 40,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 6,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
    },
}));


const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color: '#694B81',
    position: 'relative',
    left: '0.5%',
    fontFamily: "Poppins",
    width: '100%',
    height: '55px',
    fontSize: '0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#F0EDF3', // Color del borde por defecto
        },
        '&:hover fieldset': {
            borderColor: '#585857', // Color del borde al pasar el mouse
        },
        '&.Mui-focused fieldset': {
            borderColor: '#585857', // Color del borde en foco
        },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#585857', // Color del borde en foco
        borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
        fontSize: '0.7rem',
        width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
        maxWidth: '96%',
    },

}));