import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../../context/usecontext";
import "../../../../Style/admi/admininicio.css";
import moment from "moment";
/* grafico */
import { Doughnut } from "react-chartjs-2";

import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

/* libreria UI */
import { Box, Grid, CircularProgress } from '@mui/material';

/* imagenes */
import logoinicio from "../../../../Image/administrador/logoadmi.png";
import bordeuno from "../../../../Image/administrador/borde1.png";
import bordedos from "../../../../Image/administrador/borde2.png";
import qrblanco from "../../../../Image/administrador/qrblanco.png";
import qrmorado from "../../../../Image/administrador/qrmorado.png";
import luto from "../../../../Image/administrador/moradoluto.png";
import user from "../../../../Image/administrador/user.png";
import lutoblanco from"../../../../Image/administrador/lazoblanco.png";
import people from "../../../../Image/administrador/peopleqr.png"
import blamnco from "../../../../Image/administrador/blanco.png"

/* Registrar los componentes necesarios de Chart.js */
Chart.register(ArcElement, Tooltip, Legend);

export const Adminhome = () => {
    const { adminToken, setAdminAuthToken } = useAuth();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [fechaIngreso, setFechaIngreso] = useState("");
    const [mes, setMes] = useState("");
    const [qrData, setQrData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalQr, setTotalQr] = useState(0);
    const [qrEnUso, setQrEnUso] = useState(0);
    const [qrDisponibles, setQrDisponibles] = useState(0);
    const [empresas, setEmpresas] = useState([]);
    const [empresaConMasGrupos, setEmpresaConMasGrupos] = useState('');
    const [totalQrMes, setTotalQrMes] = useState(0);
    const [totalQrActivos, setTotalQrActivos] = useState(0);
    const [totalQrInactivos, setTotalQrInactivos] = useState(0);
    
    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }

        // Obtener la fecha actual
        const fechaActual = new Date();
        const dia = fechaActual.getDate(); // Día
        const mes = fechaActual.getMonth(); // Mes (de 0 a 11)
        const año = fechaActual.getFullYear(); // Año

        // Arreglo con los nombres de los meses
        const meses = [
            "enero", "febrero", "marzo", "abril", "mayo", "junio",
            "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
        ];

        // Formatear la fecha como '4 septiembre 2024'
        const fechaFormateada = `${dia} ${meses[mes]} ${año}`;
        const fechames = ` ${meses[mes]}`;

        // Establecer la fecha en el estado
        setFechaIngreso(fechaFormateada);
        setMes(fechames);


    }, [setAdminAuthToken, navigate]);

    useEffect(() => {
        // Función para obtener los datos de QR desde el backend
        const fetchQrData = async () => {
            try {
                // Hacer la solicitud con el token de autorización
                const response = await axios.get(`${backendURL}/api/getAllGruposFamiliares`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
    
                const data = response.data;
                setQrData(data);
    
                // Calcular el total de QR
                setTotalQr(data.length);
    
                // Calcular los QR en uso (estado 1)
                const enUso = data.filter(qr => qr.estado == 1).length;
                setQrEnUso(enUso);
    
                // Calcular los QR disponibles (estado 0 y sin id_usuario)
                const disponibles = data.filter(qr => qr.estado == 0 && !qr.id_usuario).length;
                setQrDisponibles(disponibles);
               
                // Definir el rango de fechas para el mes actual
                const fechaInicio = new Date();
                fechaInicio.setDate(1); // Inicio del mes
                fechaInicio.setHours(0, 0, 0, 0);
    
                const fechaFin = new Date();
                fechaFin.setMonth(fechaFin.getMonth() + 1);
                fechaFin.setDate(0); // Fin del mes
                fechaFin.setHours(23, 59, 59, 999);
    
                // Filtrar los QR creados en el mes actual y que tengan un id_empresa asociado
                const dataDelMes = data.filter(qr => {
                    const createdAt = new Date(qr.created_at);
                    return createdAt >= fechaInicio && createdAt <= fechaFin && qr.id_empresa; // Solo QR con id_empresa
                });
    
                // Calcular el total de QR generados en el mes por id_empresa
                const totalPorEmpresa = dataDelMes.reduce((acc, qr) => {
                    acc[qr.id_empresa] = (acc[qr.id_empresa] || 0) + 1;
                    return acc;
                }, {});
              
    
                const totalQrMes = Object.values(totalPorEmpresa).reduce((a, b) => a + b, 0);
                setTotalQrMes(totalQrMes);
    
                // Calcular el total de QR activos por id_empresa en el mes actual
                const activosPorEmpresa = dataDelMes.filter(qr => qr.estado == 1).reduce((acc, qr) => {
                    acc[qr.id_empresa] = (acc[qr.id_empresa] || 0) + 1;
                    return acc;
                }, {});
                setTotalQrActivos(Object.values(activosPorEmpresa).reduce((a, b) => a + b, 0));
    
                // Calcular el total de QR inactivos por id_empresa en el mes actual
                const inactivosPorEmpresa = dataDelMes.filter(qr => qr.estado == 0).reduce((acc, qr) => {
                    acc[qr.id_empresa] = (acc[qr.id_empresa] || 0) + 1;
                    return acc;
                }, {});
                setTotalQrInactivos(Object.values(inactivosPorEmpresa).reduce((a, b) => a + b, 0));
   
    
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener los datos de QR:", error);
                setLoading(false);
            }
        };

       // Función para obtener los nombres de las empresas
       const fetchEmpresasData = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/empresas`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });
            setEmpresas(response.data);
        } catch (error) {
            console.error("Error al obtener los datos de empresas:", error);
        }
    };

    if (adminToken) {
        fetchQrData();
        fetchEmpresasData();
        setLoading(false);
    }
}, [adminToken, backendURL]);

useEffect(() => {
    if (qrData.length > 0 && empresas.length > 0) {
        // Obtener la fecha actual
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth() + 1; // Enero es 0, sumamos 1
        const añoActual = fechaActual.getFullYear();

        // Filtrar los grupos familiares creados en el mes actual
        const gruposFamiliaresDelMes = qrData.filter(qr => {
            const fechaCreacion = new Date(qr.created_at); // Suponiendo que hay un campo 'fecha_creacion'
            return (
                fechaCreacion.getMonth() + 1 === mesActual &&
                fechaCreacion.getFullYear() === añoActual
            );
        });

        // Contar cuántos grupos familiares tiene cada empresa
        const conteoPorEmpresa = gruposFamiliaresDelMes.reduce((acc, qr) => {
            const empresaId = qr.id_empresa;
            if (empresaId) {
                acc[empresaId] = (acc[empresaId] || 0) + 1;
            }
            return acc;
        }, {});

        // Encontrar la empresa con más grupos familiares
        const empresaConMasGruposId = Object.keys(conteoPorEmpresa).reduce((a, b) =>
            conteoPorEmpresa[a] > conteoPorEmpresa[b] ? a : b
        );

        // Buscar el nombre de la empresa con más grupos familiares
        const empresaConMasGrupos = empresas.find(empresa => empresa.id === parseInt(empresaConMasGruposId));

        // Si la empresa existe, tomar su nombre; si no, usar "Desconocida"
        const empresaConMasGruposNombre = empresaConMasGrupos ? empresaConMasGrupos.nombre_empresa : 'Desconocida';
        
        // Guardar el nombre de la empresa en el estado
        setEmpresaConMasGrupos(empresaConMasGruposNombre); 
    }
}, [qrData, empresas]);

    if (loading) {
        return <p> <CircularProgress sx={{
            color: "#D26999"
        }} />
        </p>;
    }


    return (

        <section className="contiene-inicio-home-admin">

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "50px",
            }}>

                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "150px"
                }}>

                    <img
                        src={logoinicio}
                        alt="smart memorial"
                        title="smart memorial"
                        width="100%"
                    />

                </Box>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingLeft: "20px"
                }}>
                    <h1 className="titulo-inicio-admin-home">Bienvenido al administrador de Smart Memorial</h1>
                    <span className="Fecha-inicio-admin-home">  {fechaIngreso}</span>
                </Box>

            </Box>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "50px",
                borderRadius: "10px",
                background: "#F0EDF3",
                width: "100%",
                padding: 3
            }}>
                <div className="titulo-inicio-admin-home">
                    <div className="imagenbordeadmihomesmart">
                        <img
                            src={bordeuno}
                            alt="smart memorial"
                            title="smart memorial"
                            width="100%"
                        />
                    </div>
                    <div className="subtitulohomesmartinicio">
                        <h1>Información  general de Smart Memorial </h1>
                    </div>
                    <div className="imagenbordeadmihomesmart">
                        <img
                            src={bordedos}
                            alt="smart memorial"
                            title="smart memorial"
                            width="100%"
                        />
                    </div>
                </div>
                <div className="contienegraficaadmismarthome">
                    <Grid container sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",

                    }}>
                        <Grid item xs={6} sx={{
                            background: "#fff",
                            borderRadius: "10px",
                            padding: 2,
                            position: "relative",
                            left: "-5px",
                            maxWidth: "80%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column"

                        }}>
                            <div className="parte-superior">
                                <div className="contiene-titulo">
                                    <h3>Total codigos QR Smart Memorial</h3>
                                </div>
                                <div>
                                    <GraficoQRtotalsmart />
                                </div>
                            </div>
                            <div>
                                <Grid container sx={{
                                    marginTop: "50px",
                                    marginBottom: "50px"
                                }}>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                            <h4>{totalQr}</h4>
                                            <h6>Total Smart Memorial</h6>
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                            <h4>{qrDisponibles}</h4>
                                            <h6>En uso Smart Memorial</h6>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                            <h4>{qrEnUso}</h4>
                                            <h6>Disponible Smart Memorial</h6>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="contiene-qr-vista-meses">
                               <Link to="/usuarios-mes">
                                    <button>
                                        <img src={qrblanco} alt="smart memorial" title="smart memorial" width="100%" />
                                        <span>Ver QR en meses</span>
                                    </button>
                                </Link>
                            </div>


                        </Grid>
                        <Grid item xs={6} sx={{
                            background: "#fff",
                            borderRadius: "10px",
                            padding: 2,
                            position: "relative",
                            right: "-5px",
                            maxWidth: "80%",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}>
                            <div className="parte-superior">
                                <div className="contiene-titulo">
                                    <h3>Total codigos QR Smart Empresas  {mes}</h3>
                                </div>
                                <div className="contienenempresagraficohomesmart">
                                    <GraficoQRtotalempresasmart />
                                </div>
                            </div>
                            <div className="destacadoempresahomesmartadmi">
                                <div className="contienenimgandestacado">
                                    <img src={luto} className="destacado" alt="smart memorial" title="smart memorial" />
                                </div>
                                <h3>Empresa con mayor uso de codigo QR</h3>
                                <h2> {empresaConMasGrupos ? empresaConMasGrupos : '-'}</h2>

                            </div>
                            <div>
                                <Grid container sx={{
                                    marginTop: "50px",
                                    marginBottom: "50px"
                                }}>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                           <h6>QR generado en el mes de {mes}</h6> 
                                           <h4>{totalQrMes}</h4>
                                            
                                        </div>

                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                            <h6>QR en uso mes {mes}</h6>
                                            <h4>{totalQrActivos}</h4>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        flexDirection: "column"
                                    }} >
                                        <div className="contien-imagen-info-smartadmi">
                                            <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" />
                                        </div>
                                        <div className="infosmartadmihomeprogress">
                                            <h6>QR disponibles {mes}</h6>
                                             <h4>{totalQrInactivos}</h4>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                        </Grid>
                    </Grid>

                </div>



            </Box>

            <Box  sx={{
                        maxWidth:"100%",
                        width:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"50px",
                        marginBottom:"50px"
                    }}>
                <Grid container sx={{
                    width:"100%",
                        maxWidth:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}>
                    <Grid item xs={3} sx={{
                        p:1,
                        width:"100%",
                        maxWidth:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}>
                        <Link to="/usuariossuscritos" style={{ width:"100%"}}>
                            <button className="cardbutonsmartmhomeadmin">
                                <div className="imagenuserhome">
                                    <img src={user} alt="smart memorial" title="smart memorial" className="userhomesmart"/>
                                </div>
                                <h3>Nuestros</h3>
                                <h3>Usuarios Smart</h3>
                            </button>
                        </Link>
                    </Grid>
                    <Grid item xs={3} sx={{
                        p:1, 
                        width:"100%",
                        maxWidth:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}>
                         <Link to="/generar-qr-smart-memorial" style={{ width:"100%"}}>
                    <button className="cardbutonsmartmhomeadmin">
                            <div className="imagenuserhome">
                                <img src={blamnco} alt="smart memorial" title="smart memorial" className="userhomesmart"/>
                            </div>
                            <h3>Generar QR </h3>
                            <h3>a Smart Memorial</h3>
                        </button></Link>
                    </Grid>
                    <Grid item xs={3} sx={{
                        p:1,
                        width:"100%",
                        maxWidth:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}>
                        <Link to="/crear-empresas" style={{ width:"100%"}}>
                            <button className="cardbutonsmartmhomeadmin">
                                    <div className="imagenuserhome">
                                        <img src={lutoblanco} alt="smart memorial" title="smart memorial" className="userhomesmart"/>
                                    </div>
                                    <h3>Agregar Nueva Empresa</h3>
                                    <h3>a Smart Memorial</h3>
                            </button>
                        </Link>
                    </Grid>
                    <Grid item xs={3} sx={{
                        p:1,
                        width:"100%",
                        maxWidth:"100%",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center"
                    }}>
                        <Link to="/todas-empresas" style={{ width:"100%"}}>
                            <button className="cardbutonsmartmhomeadmin">
                                    <div className="imagenuserhome">
                                        <img src={people} alt="smart memorial" title="smart memorial" className="userhomesmart"/>
                                    </div>
                                    <h3>Nuestras</h3>
                                    <h3>empresas</h3>
                            </button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>




        </section>
    )
}



/* qr pogreso grafica smart memorial general */

export const GraficoQRtotalsmart = () => {
    const [qrData, setQrData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { adminToken } = useAuth();
    const chartRef = useRef(null);
    // Configurar la URL de tu backend
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        // Función para obtener los datos de QR desde el backend
        const fetchQrData = async () => {
            try {
                // Hacer la solicitud con el token de autorización
                const response = await axios.get(`${backendURL}/api/getAllGruposFamiliares`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`, // Incluir el token en la cabecera
                    },
                });
                setQrData(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error al obtener los datos de QR:", error);
                setLoading(false);
            }
        };

        if (adminToken) {
            fetchQrData(); // Llamar a la función solo si el token está disponible
        }
    }, [adminToken, backendURL]);

    // Configurar los datos para el gráfico
    // Agrupar por mes y contar las ocurrencias
    const qrCountByMonth = qrData.reduce((acc, item) => {
        const month = moment(item.created_at).format('MMMM YYYY'); // Formato de mes y año
        acc[month] = (acc[month] || 0) + 1;
        return acc;
    }, {});

    // Calcular el total de QR creados
    const totalQr = qrData.length;

    // Crear los datos para el gráfico
    const labels = Object.keys(qrCountByMonth); // Meses
    const dataValues = Object.values(qrCountByMonth); // Cantidades por mes

    // Calcular los porcentajes
    const percentages = dataValues.map(count => ((count / totalQr) * 100).toFixed(2));


    const data = {
        labels: labels,
        datasets: [
            {
                label: '',
                data: percentages,
                backgroundColor: [
                    '#B4A5C0',
                    '#694B81',
                    '#D26999',
                    '#F0EDF3',
                    '#A04424',
                    '#E28939',
                    '#F9E7D7',
                    '#27632D',
                    '#68A354',
                    '#E1EDDD',
                    '#F3F8F1',

                ],
                hoverBackgroundColor: [
                    '#B4A5C0',
                    '#694B81',
                    '#D26999',
                    '#F0EDF3',
                    '#A04424',
                    '#E28939',
                    '#F9E7D7',
                    '#27632D',
                    '#68A354',
                    '#E1EDDD',
                    '#F3F8F1',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,  // Ocultar leyenda
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        return `${tooltipItem.label}: ${tooltipItem.raw}%`;  // Personalizar el tooltip
                    }
                }
            },
        },
        onHover: (event, chartElement) => {
            if (chartElement.length) {
                const chart = chartRef.current;
                chart.setActiveElements(chartElement);  // Resaltar el sector sobre el que se pasa el cursor
                chart.tooltip.setActiveElements(chartElement);  // Mostrar tooltip
                chart.update();
            }
        },
    };

    // Resaltar un sector al cargar (el primero, por ejemplo)
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.setActiveElements([{ datasetIndex: 0, index: 0 }]); // Resalta el primer sector
            chartRef.current.update();
        }
    }, [loading]);

    if (loading) {
        return <p><CircularProgress sx={{ color: "#D26999" }} /></p>;
    }

    return (
        <div>
            <Doughnut ref={chartRef} data={data} options={options} />

        </div>
    );
};

/* qr progreso grafica empresas */

export const GraficoQRtotalempresasmart = () => {
    const [qrData, setQrData] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [totalEmpresas, setTotalEmpresas] = useState(0); // Nuevo estado para total de empresas
    const [totalSucursales, setTotalSucursales] = useState(0);
    const [totalQRsEmpresa, setTotalQRsEmpresa] = useState(0);
    const [totalQRs, setTotalQRs] = useState(0); 
    const [loading, setLoading] = useState(true);
    const { adminToken } = useAuth();
    const chartRef = useRef(null);
    // Configurar la URL de tu backend
    const backendURL = process.env.REACT_APP_BACKEND_URL;
   

    useEffect(() => {
        // Función para obtener los datos de QR desde el backend
        const fetchQrData = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getAllGruposFamiliares`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                setQrData(response.data);
            } catch (error) {
                console.error("Error al obtener los datos de QR:", error);
            }
        };

        // Función para obtener los nombres de las empresas
        const fetchEmpresasData = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/empresas`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
                setEmpresas(response.data.empresas);
                setTotalEmpresas(response.data.empresas.length); 
            } catch (error) {
                console.error("Error al obtener los datos de empresas:", error);
            }
        };

        if (adminToken) {
            fetchQrData();
            fetchEmpresasData();
            setLoading(false);
        }
    }, [adminToken, backendURL]);

 

    useEffect(() => {
        if (qrData.length > 0) {
            // Calcular el total de sucursales únicas (id_sucursal)
            const sucursalesUnicas = new Set(qrData.map(item => item.id_sucursal).filter(Boolean));
            setTotalSucursales(sucursalesUnicas.size);

            // Calcular el total de códigos QR por empresa
            const qrCountByEmpresa = qrData.reduce((acc, item) => {
                const empresaId = item.id_empresa || 'Sin empresa'; // Si no hay id_empresa, usar 'Sin empresa'
                acc[empresaId] = (acc[empresaId] || 0) + 1;
                return acc;
            }, {});

            // Calcular el total de códigos QR sumando los valores
            const totalQRsCount = Object.values(qrCountByEmpresa).reduce((sum, count) => sum + count, 0);
            setTotalQRs(totalQRsCount);
        }
    }, [qrData]);

    // Si no hay datos, mostramos un mensaje o valor alternativo
    if (qrData.length === 0 || !qrData) {
        return (
            <p className="contieneavisosmart">
                <img src={luto} className="iconoluto" alt="smart memorial" title="smart memorial" />
                No hay empresas disponibles actualmente.
            </p>
        );
    }

    // Calcular el conteo de QR por empresa
    const qrCountByEmpresa = qrData.reduce((acc, item) => {
        const empresaId = item.id_empresa || 'Sin empresa'; // Si no hay id_empresa, usar 'Sin empresa'
        acc[empresaId] = (acc[empresaId] || 0) + 1;
        return acc;
    }, {});

    const labels = Object.keys(qrCountByEmpresa).map(empresaId => {
        // Buscar el nombre de la empresa en la lista de empresas por su id_empresa
        const empresa = empresas.find(e => e.id == parseInt(empresaId));
        return empresa ? empresa.nombre_empresa : 'Sin empresa'; // Si no se encuentra, usar 'Sin empresa'
    });
    const dataValues = Object.values(qrCountByEmpresa); // Los valores del gráfico

    // Configurar los datos del gráfico
    const data = {
        labels: labels.length > 0 ? labels : ['Sin empresa'], // Si no hay etiquetas, mostrar 'Sin empresa'
        datasets: [
            {
                label: 'Cantidad de QR por Empresa',
                data: dataValues.length > 0 ? dataValues : [0], // Si no hay valores, mostrar 0
                backgroundColor: [
                    '#B4A5C0', '#694B81', '#D26999', '#F0EDF3', '#A04424', '#E28939',
                    '#F9E7D7', '#27632D', '#68A354', '#E1EDDD', '#F3F8F1',
                ],
                hoverBackgroundColor: [
                    '#B4A5C0', '#694B81', '#D26999', '#F0EDF3', '#A04424', '#E28939',
                    '#F9E7D7', '#27632D', '#68A354', '#E1EDDD', '#F3F8F1',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`,
                },
            },
        },
        onHover: (event, chartElement) => {
            if (chartElement.length && chartRef.current) {
                chartRef.current.setActiveElements(chartElement);
                chartRef.current.update();
            }
        },
    };

    // Si está cargando, mostrar un spinner
    if (loading) {
        return <p><CircularProgress sx={{ color: "#D26999" }} /></p>;
    }

    
    return (
        <>
            <Grid container>
                <Grid item xs={6}>
                    <ul className="listado-informacion-empresa">
                        <li className="itemcardhomeinfo">
                            <div className="card-infoempresahomesmart">
                                <div className="coniteneimagen-cardempresainfosmartmhome">
                                    <div>
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" className="luto" />
                                    </div>
                                    <span>Total Empresas</span>
                                </div>
                                <div className="cardinfo-homesmart">
                                    <h6>{totalEmpresas}</h6>
                                </div>
                            </div>
                        </li>
                        <li className="itemcardhomeinfo">
                            <div className="card-infoempresahomesmart">
                                <div className="coniteneimagen-cardempresainfosmartmhome">
                                    <div>
                                        <img src={luto} alt="smart memorial" title="smart memorial" width="100%" className="luto" />
                                    </div>
                                    <span>Total Sucursales</span>
                                </div>
                                <div className="cardinfo-homesmart">
                                    <h6>{totalSucursales}</h6>
                                </div>
                            </div>
                        </li>
                        <li className="itemcardhomeinfo">
                            <div className="card-infoempresahomesmart">
                                <div className="coniteneimagen-cardempresainfosmartmhome">
                                    <div>
                                        <img src={qrmorado} alt="smart memorial" title="smart memorial" width="100%" className="qr" />
                                    </div>
                                    <span>Total codigos QR para empresas </span>
                                </div>
                                <div className="cardinfo-homesmart">
                                    <h6>{totalQRsEmpresa > 0 ? totalQRsEmpresa : '-'}</h6>
                                </div>
                            </div>

                        </li>


                    </ul>
                </Grid>
                <Grid item xs={6}>
                <Doughnut ref={chartRef} data={data} options={options} />
                </Grid>
            </Grid>
        </>
    )
}