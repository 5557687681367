import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container} from '@mui/material';

import { useAuth } from "../../context/usecontext";
import { Adminhome } from "./dashbord/admininicio";


export const PerfilAdmi = () => {
  
    const { adminToken, setAdminAuthToken } = useAuth(); // Obtener token y función para establecer el token
  
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
   

    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
          
        } else {
            navigate("/"); 
        }
    }, []);

 



   

    return (
        <>
        <Container component="main" fixed maxWidth="lg">
          <Adminhome/>
          
             </Container>
        </>
    );
};

