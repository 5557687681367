import React from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import "../../../Style/generales/recetcon.css";

export const Finalgenerarcontraseña = () => {
    return(
        <>
           <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:'center'
          }}
        >
            <section className="checkcambiocontraseña">
            <FaRegCircleCheck />
            </section>
            <section className="titulocontraseñacorrecta">
                <h1>Su contraseña ha sido</h1>
                <h1>cambiada con éxito</h1>
            </section>
            <div className="botonverificarregistro">
                <Link to="/">
              <button >
              Iniciar Sesión
              </button></Link>
            </div>

        </Box>
        </Container>
        
        </>
    )
}