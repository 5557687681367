import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
/* MATERIAL UI */
import { Container, Box, Modal, Grid, Select, MenuItem, CircularProgress} from '@mui/material';
import { Navbar } from "../nav-fotter/navbar";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { FiAlertCircle } from "react-icons/fi";
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import "../../Style/login/login.css"
import { useAuth } from "../context/usecontext";
import { useUsers } from "../context/userscontexts";
import { styled } from "@mui/system";


export const LoginUserMarca = ({setShowNavbarFooter, onLogin}) =>{
    const { id } = useParams();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [marcas, setMarcas] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setAdminAuthToken } = useAuth();
    const { setUserToken, setUserInformation } = useUsers(); 
    const [userData, setUserData] = useState(null);
    const [showPassword, setShowPassword] = React.useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

    useEffect(() => {
        // Ocultar el navbar y footer al montar el componente
        setShowNavbarFooter(false);
        return () => {
            // Mostrar el navbar y footer al desmontar el componente
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
    useEffect(() => {
        const fetchGrupoFamiliar = async () => {
            try {
                setLoading(true);
                const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setMarcas(empresaResponse.data.data[0]);
            } catch (error) {
                console.error('Error fetching grupo familiar:', error);
                navigate('/Registro');
            } finally {
                setLoading(false);
            }
        };

        fetchGrupoFamiliar();
    }, [id, backendURL, navigate]);

     const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email');
        const password = formData.get('password');
    
        if (!validateEmail(email)) {
          setEmailError('El correo electrónico no es válido');
          setLoading(false);
          return;
        } else {
          setEmailError('');
        }
    
        try {
          const response = await fetch(`${backendURL}/api/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password }),
          });
    
          if (!response.ok) {
            setPasswordError('El correo o contraseña está incorrecta');
            setLoading(false);
            return;
          }
    
          const data = await response.json();
          // Mostrar toda la información del usuario en la consola para verificar
           console.log(data)
          const { token, user, marca_empresa } = data;
          const userId = user.id;
          
          if (!data || !data.token || !data.user) {
            setPasswordError('El servidor no respondió como se esperaba.');
            setLoading(false);
            return;
          }
    
          if (user.id_rol == 1) {
            sessionStorage.setItem('adminToken', token);
            setAdminAuthToken(token);
            sessionStorage.setItem('onLogin', 1);
            onLogin(1);
            navigate('/Administrador');
          } else {
            const clientTokenData = { token, userId };
            sessionStorage.setItem('ClientToken', JSON.stringify(clientTokenData));
            setUserToken(clientTokenData);
            setUserInformation(marca_empresa); 
            sessionStorage.setItem('onLogin', 2);
            onLogin(2);
            navigate(`/usuario/${userId}`);
          }
        } catch (error) {
          console.error('Error al procesar la solicitud:', error);
        } finally {
          setLoading(false);
        }
      };
    
      useEffect(() => {
        // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
        const token = sessionStorage.getItem("adminToken");
        if (token) {
          navigate("/Administrador");
        }
        
      }, []);
    
      useEffect(() => {
        // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
        const token = sessionStorage.getItem("ClientToken");
        if (token && userData) { // Verificamos si hay un token y datos de usuario disponibles
          navigate(`/usuario/${userData.user.id}`);
        }
      }, [userData]);
    
      const validateEmail = (email) => {
        // Expresión regular para validar correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      }; 
    

    if (loading) {
        return  <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
        />
      </Box>;
      }

      const StyleFondo={
        backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
          backgroundSize: 'cover',           // Asegurar que cubra toda la sección
          backgroundPosition: 'center',      // Centrar la imagen
          width: '100%',
          height: 'auto',                   // Ajustar la altura de la sección
          
      }
return(
    <section className="contienenpaginainiciousuario" style={StyleFondo}>
        {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
           <Helmet>
       <title>Bienvenido a iniciar Sección empresa </title>
        <meta name="description" content="registro del usuario en empresa" />
        
      </Helmet>
      <Container component="main" maxWidth="xs" sx={{
        paddingLeft: '0px',
        paddingRight: '0px',
        display: 'flex',
        height:'95vh',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 600px)': {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      }}>

        <Box sx={{
          width:'100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: "130px",
          position:"relative",
          zIndex:"11"

        }}>


             {marcas && marcas.logo ? (
   <div style={{width:'150px', padding:'20px 0px'}}>
   <img
        src={`${backendURL}/${marcas.logo}`} 
        alt='Logo de Smart Memorial'
        style={{ width: '100%', height: 'auto' }} // Para mantener la relación de aspecto
    /></div>
) : (
    <></> // Mensaje alternativo en caso de que no exista
)}
<Box
          sx={{
            marginTop:'30px',
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          data-aos="fade-down"
        >

          <Typography component="h1" variant="h5" sx={{ fontSize: '1.5rem', fontWeight: '600', color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#D26999', fontFamily:"Poppins", marginBottom:"-10px" }}>
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '25px', width: '100%' }}>
              <InputLabel className="label-login" htmlFor="email" sx={{ position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                Correo electrónico
              </InputLabel>
              <StyledTextField
              
                margin="normal"
                required
                id="email"
                name="email"
                autoComplete="email"
                className={`input-login ${emailError && 'input-error'}`}
                InputProps={{
                  sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' },
                  style: { border: emailError ? '1px solid #D26999' : '3px solid ##F0EDF3', }
                }}
                sx={{ marginBottom: '10px' }}
              />

            </Box>

            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '10px', width: '100%' }}>
              <InputLabel className="labellogin" htmlFor="password" sx={{ position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                Contraseña
              </InputLabel>
              <StyledTextFields
                className={`input-login ${passwordError && 'input-error'}`}
                margin="normal"
                required
                name="password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : "#694B81" },
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className="password-toggle-btn"
                    >
                      {showPassword ? <FaRegEyeSlash className="eye-icon" style={{color:(marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :  '#B4A5C0'}} /> : <FaRegEye className="eye-icon" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} />}
                    </IconButton>
                  )
                }}

              />

            </Box>
            <div className="contiene-error-mensaje">  {passwordError && <span className="errormessagelogin"><FiAlertCircle className='icon-error' />{passwordError}</span>} </div>
            <Box sx={{ width: '80%', display: 'flex', alignItems: 'flex-start', position: 'relative', }}>
              <div className="aceptar-terminos">
                <FormControlLabel

                  control={
                    <div className="unoterminos">
                      <label className="container" htmlFor="checkbox">
                        <input type="checkbox" name="checkbox" id="checkbox" />
                        <svg viewBox="0 0 64 64" height="1.8em" width="1.8em">
                          <path
                            d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                            pathLength="575.0541381835938"
                            className="path"
                          ></path>
                        </svg>
                      </label>
                    </div>
                  }
                  label="Mantener sesión iniciada"
                  sx={{
                    width: '100%',
                    color: '#585857',
                    '& .MuiTypography-root': {
                      fontSize: '0.7rem',

                    }
                  }}
                />
              </div>
              </Box>
            <div className="contienenbotons-login">
              <button
                type="submit"
                style={{background:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
                variant="contained"
                sx={{ mt: 3, mb: 2, background: '#694B81', borderRadius: '40px', padding: '10px 60px', fontWeight: '600', marginBottom: '30px' }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Iniciar Sesión'}
              </button>

              
             </div>
          </Box>

        
          
          
        </Box>
        </Box>
        </Container>

    </section>
)
}

const StyledTextField = styled(TextField)({
    width: '100%',
  
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
    '& .MuiInputBase-root': {
      transition: 'all 0.3s ease',
      color: "#694B81",
      fontFamily: "Poppins",
      fontSize: '0.9rem',
      fontWeight: "500",
    },
    '& .MuiInputBase-root.Mui-focused': {
      border: ' 1px solid #585857', // Cambia esto al color que quieras
  
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: ' 1px solid #585857',  // Cambia esto al color que quieras
    },
  });
  
  const StyledTextFields = styled(TextField)({
    width: '100%',
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
  
    '& .MuiInputBase-root': {
      transition: 'all 0.3s ease',
      color: "#694B81",
      fontFamily: "Poppins",
      fontSize: '0.9rem',
      fontWeight: "500",
    },
    '& .MuiInputBase-root.Mui-focused': {
      bborder: ' 1px solid #585857', // Cambia esto al color que quieras
      color: "#694B81",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: ' 1px solid #585857',  // Cambia esto al color que quieras
      color: "#694B81",
    },
  });