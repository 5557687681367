import React from "react";
import { Container, Box } from "@mui/material";
import "../Style/generales/error.css";

import { useLocation } from 'react-router-dom';

import logo from "../Image/generales/inhabilitado.png"

export const Inactivopublico = () => {
  const location = useLocation();
  const { familia } = location.state || {};


    return (
        <Container 
        component="main"
         maxWidth="xs"
         sx={{
            
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            
          }}
          >
            
        <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding:"25px 30px",
                width:'100%',
                  background:"#F0EDF3",
                  borderRadius:"10px",
                  '@media (max-width: 430px)': {
                    padding:"30px 30px",
          },
          '@media (max-width: 308px)': {
            padding:"20px 20px",
  }
              }}
          > 
          <div className="titulounoprivadoinactivo">
            <h3>El grupo familiar </h3>
            <h2> {familia.nombre_familia || null} </h2>
            <h3>se encuentra inhabilitado</h3>
          </div>
          <div className="logoinabilitado">
            <img src={logo} alt="smart memorial"/>
          </div>
          <div className="titulodosprivadoinactivo"> <h3>Por favor intente más tarde</h3></div>
     
        
        </Box>
        </Container>
    )
}