import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import "../../../Style/generales/recetcon.css";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import useMediaQuery from '@mui/material/useMediaQuery';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { useLocation} from 'react-router-dom';

export const Generarnuevacontraseña = () => {
  const location = useLocation();
  const { email, codigo } = location.state || {}; 
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState('');
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const navigate = useNavigate();
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width: 400px)');
  const maxWidth = isSmallScreen ? 200 : 400;
    const backendURL = process.env.REACT_APP_BACKEND_URL;
   
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "password") {
          setPassword(value);
    
          // Validar la longitud mínima de caracteres
          if (value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
          } else {
            setPasswordError('');
          }
        } else if (name === "confirmPassword") {
          setConfirmPassword(value);
    
          // Validar si la contraseña repetida coincide con la contraseña principal
          if (value !== password) {
            setPasswordError('Las contraseñas no coinciden');
          } else {
            setPasswordError('');
          }
        }
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (password !== confirmPassword) {
          setError("Las contraseñas no coinciden");
          return;
        }
    
        if (password.length < 6) {
          setError("La contraseña debe tener al menos 6 caracteres");
          return;
        }
    
        try {
          setenviandoAlert(true);
          const response = await axios.post(`${backendURL}/api/updatePassword`, {
            email: email,
           codigo_reset: codigo,
           password: password,
          });
    
          if (response.status === 200) {
            setenviandoAlert(false);
            setSuccessAlertOpen(true);
            setAlertMessage('Se ha actualizado su contraseña con éxito.');
            setTimeout(() => {
              navigate('/contraseña-nueva-generada'); 
            }, 2000); 
          } else {
            setErrorAlertOpen(true);
            setAlertMessage("Error al actualizar la contraseña");
            setError("Error al actualizar la contraseña");
          }
        } catch (error) {
          const errorMessage = error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : 'Error al actualizar la contraseña.';
    
          setErrorAlertOpen(true);
          setAlertMessage(errorMessage);
        } finally {
          setenviandoAlert(false);
          setLoading(false);
        }
    
        // Simulación de tiempo de carga
        setTimeout(() => {
          setSuccessAlertOpen(false);
          setErrorAlertOpen(false);
        }, 2000);
      };
    
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
    
      const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
      };

      const handleOpenAlert = () => {
        setOpenAlert(true);
      };
    
      const handleCloseAlert = () => {
        setOpenAlert(false);
      };

    return(
       
        <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
          }}
        >
          <section className="titulorecetcont">
            <h1>Restablecer</h1>
            <h1>contraseña</h1>
          </section>
          <form className="formcontveri" onSubmit={handleSubmit}>
            <section style={{ width: '100%' }}>
            <div className="inputcontveriform">
  <label>Contraseña</label>
  <div className='inputvercontraseña'>
    <input
      type={showPassword ? 'text' : 'password'}
      className={`inputverificarcont ${error && 'input-error'} ${passwordError && 'input-error'}`}
      name="password"
      value={password}
      onChange={handleInputChange}
      placeholder="Contraseña"
    />
    <span className="iconoojocont" onClick={togglePasswordVisibility}>
      {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
    </span>
  </div>
  <div className="errorecontraseñaverificar">
    {error && <span className="errogenenradofinal"><FiAlertCircle className='iconoerrorcontraseña' />{error}</span>}
    {passwordError && <span className="errogenenradofinal"><FiAlertCircle className='iconoerrorcontraseña' />{passwordError}</span>}
  </div>
</div>
  
              <div className="inputcontveriform">
                <label>Repetir contraseña</label>
                <div className='inputvercontraseña'>
                  <input
                    onChange={handleInputChange}
                    type={showConfirmPassword ? 'text' : 'password'}
                    className={`inputverificarcont ${error && 'input-error'} ${passwordError && 'input-error'}`}
                    name="confirmPassword"
                    value={confirmPassword}
                    placeholder="Repetir contraseña"
                  />
                  <span className="iconoojocont" onClick={toggleConfirmPasswordVisibility}>
                    {showConfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                  </span>
                </div>
                <div className="errorecontraseñaverificar">
                  {error && <span className="errogenenradofinal"><FiAlertCircle className='iconoerrorcontraseña' />{error}</span>}
                  
                </div>
              </div>
            </section>
            <div className="botonverificarregistro">
              <button type="submit" disabled={loading}>
                Guardar Contraseña
              </button>
            </div>
          </form>
         
  
<Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>
      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </Box>
      </Container>
        
        
    )
}