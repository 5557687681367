import React, { useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import '../../../../Style/Registro/tipomemorialpagina.css';
import { Helmet } from 'react-helmet';
import { IoClose } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import full from "../../../../Image/generales/full.jpg"

import uno from "../../../../Image/generales/1.png"
import dos from "../../../../Image/generales/2.png"
import tres from "../../../../Image/generales/3.png"
import cuatro from "../../../../Image/generales/4.png"
import cinco from "../../../../Image/generales/5.png"
import seis from "../../../../Image/generales/6.png"
import siete from "../../../../Image/generales/7.png"
import ocho from "../../../../Image/generales/8.png"
import nueve from "../../../../Image/generales/moradoluto.png"

import { IoIosArrowDown } from "react-icons/io";

export const Memorialfull = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [formValues, setFormValues] = useState({
        nombres: '',
        apellidos: '',
        correo_electronico: '',
        telefono: '+569',
        direccion: ''
    });
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };


    const sendForm = () => {
        setenviandoAlert(true);
        axios.post(`${backendURL}/api/sendmemorialfull`, formValues)
            .then(response => {
                
                 setenviandoAlert(false);
                setSuccessAlertOpen(true);
                setAlertMessage('Su mensaje fue enviado con éxito, sera contactado a la brevedad  por el equipo smart memorial.');
                handleOpen();
                // Limpiar los campos del formulario
                setFormValues({
                    nombres: '',
                    apellidos: '',
                    correo_electronico: '',
                    telefono: '+569',
                    direccion: ''
                });
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 7000);
            })
            .catch(error => {
                setenviandoAlert(false);
                setErrorAlertOpen(true);
            setAlertMessage('Error al enviar el mensaje, vuelva a intentar.');
                handleOpen();
                // Limpiar los campos del formulario
                setFormValues({
                    nombres: '',
                    apellidos: '',
                    correo_electronico: '',
                    telefono: '+569',
                    direccion: ''
                });
                // Cerrar el modal después de 3 segundos
                setTimeout(() => {
                    handleClose();
                }, 7000);
            })
             
    };
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <Container component="main" maxWidth="xs" >
             <Helmet>
        <title> Pack memorial full  </title>
        <meta name="description" content=" Smart memorial pack memorial full" />
        
      </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',

                }}
            >
                <section>
                    <Link to="/solicitarmemorial">
                    <IoIosArrowBack  className="icononback"/>
                    </Link>
                </section>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulomemorialselecion"> <h1> Memorial Full</h1></section>
                    <section className="contieneniformacionmemorialselecion">
                        <span>Memorial digital más 2 placas métálicas ultra

resistentes para lápida o ánfora
                        </span>
                    </section>
                    <section className="contieneinformacionmemorialselecion">
                    <div className="recuadroinfomemorial">
                      <div style={{width:"100%", height:"100%", borderRadius:'10px'}}>
                                <img src={full} alt="smart memorial full" style={{width:"100%", height:"100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
                            </div>

                            <div className="contieneninfodetalleprecio" onClick={toggleExpand}>
                <span className='detallesmemorialcompra'>Ver detalles</span>
                <IoIosArrowDown className={`arrow-icon ${expanded ? 'expanded' : ''}`} />
            </div>

                            <section className={`listadosservicio ${expanded ? 'expanded' : ''}`}>
                <div className="tituloservicioincluido">
                    <h2>Servicio incluye</h2>
                </div>
                <div className="infolistaincluido">
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">2 Placas metálicas con QR para lápida o ánfora</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Edición del memorial cuando desee</span>
                    </div>
                    <div className="itemdosservicio">
                        <div className="contieneimagensmart">
                            <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%" />
                        </div>
                        <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                    </div>
                    <div className="itemdosservicio">
                    <div className="contieneimagensmart">
                      <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemdosservicio">Compartir memoriales con familiares y amigos</span>
                  </div>
                  <div className="iteminfodosfinalservicio">
                    <div className="contieneimagensmart">
                      <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemdosservicio">Creación de Obituario </span>
                  </div>
                </div>
            </section>
                                <div className="preciosmartmemorial">
                                  <span className='preciomemorial'>Precio</span>
                                <div className="unicoprecio"> <p>$39.990</p></div>
                                 
                                </div>
                                
                            </div>
                            <section> <span className='contieneninfomemoriacompra'>Complete sus datos y el equipo de Smart
                            Memorial se pondrá en contacto con usted</span></section>
                            <form style={{width:'100%', marginTop:'30px'}}  onSubmit={(e) => { e.preventDefault(); sendForm(); }}>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="nombres"> Nombres</label>
                                    <input type="text" id="nombres" className="inputgenerarcompramemorial"  name="nombres" value={formValues.nombres} onChange={handleInputChange}   />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="apellidos"> Apellidos</label>
                                    <input type="text"id="apellidos"  className="inputgenerarcompramemorial" name="apellidos" value={formValues.apellidos} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="correo_electronico"> Correo electrónico</label>
                                    <input type="text" id="correo_electronico" className="inputgenerarcompramemorial"  name="correo_electronico" value={formValues.correo_electronico} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="telefono">Número de celular</label>
                                    <input type="text" id="telefono" className="inputgenerarcompramemorial"  name="telefono" value={formValues.telefono} onChange={handleInputChange} />
                                </div>
                                <div className="inputformulariomemorialgenerarcompra">
                                    <label htmlFor="direccion">Dirección de despacho</label>
                                    <input type="text" id="direccion" className="inputgenerarcompramemorial"  name="direccion" value={formValues.direccion} onChange={handleInputChange} />
                                </div>

                                <div className='botonsolicitarmemorial'>
                                    <button type="submit"> Solicitar </button>
                                </div>
                                <div className='informcinocompramemeorial'>
                                    <span>El equipo de Smart Memorial </span>
                                    <span> se pondrá en contacto con usted</span>
                                </div>
                            </form>
                            </section>

                </Box>
            </Box>
           
          

            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
        </Container>
    )
}