import React from "react";
import { Container, Box } from "@mui/material";
import "../Style/generales/error.css";
import { Link } from "react-router-dom";
import { RiHomeHeartFill } from "react-icons/ri";


import fondo from "../Image/generales/fondo-memorial.jpg"

export const Paginaerror = () => {
    return (
        <section style={{
            height: "95vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${fondo})`,
            backgroundSize: "cover", // Ajusta la imagen para cubrir todo el contenedor
            backgroundPosition: "center", // Centra la imagen
            backgroundRepeat: "no-repeat", 
        }}>
        <Container 
        component="main"
         maxWidth="xs"
         sx={{
            paddingLeft: "0px",
            paddingRight: "0px",
             // Evita que la imagen se repita
            // Agrega un filtro si es necesario
            // filter: "brightness(0.8)", // Puedes ajustar el brillo o agregar un filtro
          }}
          >
            
        <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding:"20px 0px"
              }}
          > 
       
        <div className="error">
            <h1>404</h1>
        <h3>Se ha perdido</h3>
            <Link to="/">
        <button className="button404"> <RiHomeHeartFill /> Inicio</button>
       </Link> </div>
        
        </Box>
        </Container>
        </section>
    )

}