import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
/* MATERIAL UI */
import { Container, Box, Modal, Grid, Select, MenuItem, CircularProgress} from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
/* react icons*/
import { RxCalendar } from "react-icons/rx";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

export const UserEmpresaMarca =({marcas, codigo, suscripcion}) => {
    const [selectedPack, setSelectedPack] = useState(suscripcion.id_suscripcion);
    const navigate = useNavigate();
    const [qrid, setQrid] = useState(suscripcion.id_grupo_familiar)
    const [selected, setSelected] = useState('1');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailExistsError, setEmailExistsError] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    
    const [formData, setFormData] = useState({
        name: '',
        apellido: '',
        fecha_nacimiento: '',
        genero: '',
        email: '',
        telefono: '+56 ',
        password: '',
        rut: '',
        codigo: '',
        id_grupo_familiar: '',
        tipo_memorial: 1
    });


    useEffect(() => {
        // Actualiza formData solo al montar el componente
        setFormData((prevData) => ({
            ...prevData,
            codigo: suscripcion.codigo, // Establece el código obtenido
            id_grupo_familiar: suscripcion.id_grupo_familiar // Establece el ID del grupo familiar
        }));
    }, [suscripcion.codigo, suscripcion.id_grupo_familiar]); // Dependencias
    
     /* verficacion de correo */
     const handleBlur = async () => {
        try {
          const response = await fetch(`${backendURL}/api/checkemailexists`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: formData['email'] })
          });
      
          const data = await response.json();
      
          // Manejo del error basado en el código de estado
          if (response.status === 400) {
            setEmailExistsError(true);
           
          } else {
            setEmailExistsError(false);
           
          }
        } catch (error) {
          console.error('Error al verificar el correo electrónico:', error);
        }
      };
      const handleChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Limpiar el error del campo actual
        }));
    
        if (name === 'email') {
            setEmailExistsError(false);
        }
    };
    
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordcorrectVisibility = () => {
        setShowPasswordcorrect(!showPasswordcorrect);
    };

    const handleChangeInput = (event) => {
        const { name } = event.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleChangeFecha = (event) => {
        const { value } = event.target;
        const date = new Date(value);
    
        if (!isNaN(date)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                fecha_nacimiento: '' // Limpiar el error de fecha
            }));

            const fechaFormateada = date.toISOString().split('T')[0];
    
            setFormData({
                ...formData,
                fecha_nacimiento: fechaFormateada
            });
    
            handleChange({ target: { name: 'fecha_nacimiento', value: fechaFormateada } });
        } else {
            console.error("Invalid date value");
        }
    };

    const handleChangeContraseña = (event) => {
        setPassword(event.target.value);
        if (event.target.value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    };

    const handleChangeRepetirContraseña = (event) => {
        const confirmPassword = event.target.value;
    
        if (confirmPassword.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    
        if (confirmPassword !== password) {
            setPasswordError('Las contraseñas no coinciden');
        } else {
            setPasswordError('');
        }
    };


    /* envio de la informacion  */
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formDataFromEvent = new FormData(event.target);
        const formDataObject = {};
    
        // Recopila los datos del formulario en un objeto
        formDataFromEvent.forEach((value, key) => {
            formDataObject[key] = value;
        });
    
        // Formatea la fecha
        const formattedDate = dayjs(formDataObject.fecha_nacimiento, 'DD-MM-YYYY').format('YYYY-MM-DD');
        formDataObject.fecha_nacimiento = formattedDate;
    
        let formErrors = {};
        for (let [key, value] of formDataFromEvent.entries()) {
            if (value.trim() === '' || value.trim() === '+56') {
                formErrors[key] = 'Campo no completado';
            }
        }
    
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length > 0) {
            setErrorAlertOpen(true);
            setAlertMessage('Tienes campos vacíos que deben ser completados.');
            return;
        }
    
      
        try {
            setenviandoAlert(true);
    
            // Enviar la solicitud
            const response = await fetch(`${backendURL}/api/usuario-empresa/resgistro-usuario-qr`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Establece el tipo de contenido a JSON
                },
                body: JSON.stringify(formDataObject),
            });
    
            const data = await response.json();
    
            if (!response.ok || response.status !== 201) {
                throw new Error(data.error || 'Error al registrar usuario');
            }
    
            const emailExistsError = data.error === 'El correo electrónico ya está registrado';
    
            if (!emailExistsError) {
                setSuccessAlertOpen(true);
                setAlertMessage('Su registro ha sido exitoso.');
                setTimeout(() => {
                    setSuccessAlertOpen(false);
                    navigate(`/empresa/${marcas.id_empresa}/login/usuario`)
                }, 3000);
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    emailExistsError: true,
                }));
            }
            
            
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al crear su registro, vuelva a intentar.');
        } finally {
            setenviandoAlert(false);
        }
    
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }, 5000);
    };
    
    

  /*   if (loading) {
        return(
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px", marginBottom:'100px' }}>
              <CircularProgress sx={{ color: "D26999" }} />
          </Box>);
      }
 */
   
      const Styleboton = {
        background: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81',
        fontFamily:'Poppins',
        fontSize:'1rem',
        color:'#fff',
        padding:'10px 20px',
        borderRadius:'50px',
        cursor:'pointer'        // Ajustar la altura de la sección
          
      }
    return(
        <>
         <Box sx={StyleBox}>
         
         
         {marcas && marcas.logo ? (
   <div style={{width:'150px', padding:'20px 0px'}}>
   <img
        src={`${backendURL}/${marcas.logo}`} 
        alt='Logo de Smart Memorial'
        style={{ width: '100%', height: 'auto' }} // Para mantener la relación de aspecto
    /></div>
) : (
    <></> // Mensaje alternativo en caso de que no exista
)}
                           
                            <section className="TituloQRUser">
                                <h3 style={{ fontSize:'1rem', textAlign:'center',  color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Creación de Usuario para Qr {suscripcion.id_grupo_familiar}</h3>
                            </section>
                            
                            <form className="ContieneFormEMpresaUserQr" onSubmit={handleSubmit}>
                                <div className="input-registor-formempresa">
                                     <label htmlFor='name' style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Nombres</label>
                                        <div className='contieneinputregister'> <input
                                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleChangeInput(e);
                                            }} className={`input-registroempresa ${errors.name && 'input-error'}`} name="name" id="name"
                                            value={formData['name']} />
                                        </div>  {errors.name && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.name}</span>}
                                </div>
                                
                                <div className="input-registor-formempresa">
                                    <label htmlFor='apellido' style={{fontSize:'0.8rem', fontSize:'0.8rem', color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Apellidos</label>
                                    <div className='contieneinputregister'> <input 
                                    style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                    onChange={handleChange} className={`input-registroempresa ${errors.apellido && 'input-error'}`} name="apellido" id="apellido" value={formData['apellido']} />
                                    </div> {errors.apellido && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.apellido}</span>}
                                </div>

                                <div className="input-registor-formempresa">
                                     <label htmlFor='rut' style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Rut</label>
                                        <div className='contieneinputregister'> <input 
                                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleChangeInput(e);
                                            }} className={`input-registroempresa ${errors.rut && 'input-error'}`} name="rut" id="rut"
                                            value={formData['rut']} />
                                        </div>  {errors.rut && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.rut}</span>}
                                </div>
                                <div className="input-registor-formempresa">
                                    <label htmlFor="fecha_nacimiento" style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Fecha de Nacimiento</label>
                                    <div className='contieneinputregister'>
                                        <div className="inputregistrocontainerempresa">
                                            <input
                                            style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                                type="date"
                                                className={`inputregistrofecha ${errors.fecha_nacimiento && 'input-error'}`}
                                                name='fecha_nacimiento'
                                                id='fecha_nacimiento'
                                                onChange={handleChangeFecha} 
                                                error={errors.fecha_nacimiento}   
                                                value={formData.fecha_nacimiento} // Asegúrate de que el valor sea el correcto del estado
                                            />
                                            <div className="calendariocreacioniconoempresa">
                                                <RxCalendar className="iconocrearmemorialcalendario" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} />
                                                </div>
                                        
                                        </div>
                                    </div>
                                    {errors.fecha_nacimiento && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.fecha_nacimiento}</span>}
                                </div>
                    
                                <div className="input-registor-formempresa">
                                    <label htmlFor='genero' style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Género</label>
                                    <div className='contieneinputregister'> < StyledSelect
                                    name='genero'
                                    id='genero'
                                    sx={{textAlign:"left", color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                    className={`input-select-registro ${errors.genero && 'input-error'}`}
                                    value={formData.genero}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    error={errors.genero}
                                    >
                                    <MenuItem value="">
                                        <em>Seleccionar género...</em>
                                    </MenuItem>
                                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                                    <MenuItem value={"Femenino"}>Femenino</MenuItem>
                                    <MenuItem value={"Me identifico con otro género"}>Me identifico con otro género </MenuItem>
                                    </ StyledSelect>
                                </div>
                                      {errors.genero && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.genero}</span>}
                                </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='email'
                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Correo electrónico</label>
                        <div className='contieneinputregister'>
                        <input
                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                            type="email"
                            name="email"
                            value={formData['email']}
                            onChange={handleChange} // Manejador de cambios para actualizar formData
                            onBlur={handleBlur} // Se llama a handleBlur cuando se pierde el enfoque
                            className={`input-registroempresa ${emailExistsError && 'input-error'}`}
                            />
                        </div> 
                            {errors.email && (
                                <span className="error-message">
                                <FiAlertCircle className='icon-error' />
                                {errors.email}
                                </span>
                            )}
                            {emailExistsError && (
                                <span className="error-message">
                                <FiAlertCircle className='icon-error' />
                                El correo electrónico ya está registrado
                                </span>
                            )}
                        <div className='aletainformativacorreoempresa '>
                            <div className='svgaletainformativa'><IoAlertCircleOutline /></div>
                            <div className='infoaletainformativa'>
                            <span className='tituloaletainformativa'>
                                IMPORTANTE:
                            </span>
                            <span className='descripcionaletainformativa'>
                                El correo electrónico NO debe ser de carácter institucional
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='telefono' style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Número de celular</label>
                        
                        <div className='contieneinputregister'>
                            <input
                            style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                value={formData['telefono']}
                                onChange={(e) => {
                                handleChange(e);
                                handleChangeInput(e);
                                }}
                                className={`input-registroempresa ${errors.telefono && 'input-error'}`}
                                name="telefono"
                                id="telefono"
                            />
                        </div>
                    
                        {errors.telefono && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.telefono}</span>}
                    </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='pack' style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Pack Memorial contratado</label>
                        <div className='contieneinputregister'>
                            <StyledSelect
                                name='pack'
                                id='pack'
                                value={selectedPack}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled
                                className="input-select-registro"
                                sx={{ fontSize:'0.8rem',textAlign: "left", color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                            >
                                <MenuItem value="">
                                <em>Selecciona el tipo Pack Memorial...</em>
                                </MenuItem>
                                <MenuItem value={1}> Pack Smart Memorial Full</MenuItem>
                                <MenuItem value={2}> Pack SmartMemorial Básico</MenuItem>
                            </StyledSelect>
                        </div>                    
                    </div>
                    <div className="input-registor-formempresa">
                        <label style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Contraseña</label>
                        <div className='input-contraseña'>
                        <input
                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                            onChange={(e) => { handleChange(e); handleChangeInput(e); handleChangeContraseña(e) }}
                            type={showPassword ? 'text' : 'password'}
                            className={`input-registroempresa ${errors.password && 'input-error'} ${passwordError && 'input-error'}`}
                            name="password"
                            value={formData['password']}
                            placeholder="Contraseña"
                        />
                        <span className="togglepassword" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaRegEyeSlash  style={{color:(marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :  '#B4A5C0'}}/> : <FaRegEye style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} />}
                        </span></div>
                        {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                        {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                    </div>

                    <div className="input-registor-formempresa">
                        <label style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Repetir contraseña</label>
                        <div className='input-contraseña'>
                        <input
                        style={{fontSize:'0.8rem',color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                            onChange={(e) => { handleChangeInput(e); handleChangeRepetirContraseña(e); }}
                            type={showPasswordcorrect ? 'text' : 'password'}
                            className={`input-registroempresa ${errors.passwords && 'input-error'} ${passwordError && 'input-error'}`}
                            name="passwords"
                            placeholder="Contraseña"
                        />
                        <span className="togglepassword" onClick={togglePasswordcorrectVisibility}>
                            {showPasswordcorrect ? <FaRegEyeSlash style={{color:(marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :  '#B4A5C0'}} /> : <FaRegEye style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} />}
                        </span>
                        </div>
                        {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                        {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                    </div>
                    <input
                        type="text"
                        name="codigo"
                        value={formData.codigo}
                        placeholder="Ingrese el código"
                        onChange={handleChange} 
                      style={{ opacity: 0, position: 'absolute', zIndex: -1 }}  

                    />
                      <input
                        type="text"
                        name="id_grupo_familiar"
                        value={formData.id_grupo_familiar}
                        placeholder="grupo"
                        onChange={handleChange} 
                  style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 

                    />
                     <input
                        type="text"
                        name="tipo_memorial"
                        value={formData.tipo_memorial}
                        placeholder="grupo"
                        onChange={handleChange} 
                         style={{ opacity: 0, position: 'absolute', zIndex: -1 }}   

                    />
                    
                  

                     <div className="boton-registro">

                        <button type="submit" disabled={emailExistsError} style={Styleboton}> Registrar </button>

                        </div>
                            </form>
                        </Box>
                        <Modal open={successAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
        </>
    )
}

/* estilo mateiral UI */
const StyleContainer = {
    marginTop:'50px',
    display:'flex',
     alignItems: 'center',
    justifyContent:'center',
    flexDirection:'column',
     width:'100%'
}
const StyleGrid ={
    display:'flex',
    alignItems: 'center', 
    justifyContent:'center',
     width:'100%'
}
 
const StyleBox ={
    display:'flex',
    alignItems: 'center', 
    justifyContent:'center',
    flexDirection:'column',
    width:'100%'
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    left:'0.5%',
    fontFamily: "Poppins",
    width:'100%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
    
  }));