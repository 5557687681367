import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet';
import Grid from '@mui/material/Grid';
import { CircularProgress} from "@mui/material";
import { Navbar } from "../nav-fotter/navbar";
import { FiAlertCircle } from "react-icons/fi";
import { MdVerifiedUser } from "react-icons/md";
import { UserEmpresaMarca } from "./registropartdos";

export const RegistroUsuarioMarca =({setShowNavbarFooter}) => {
    const { id, id_marcas } = useParams();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [marcas, setMarcas] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [codigo, setCodigo] = useState('');
    const [suscripcion, setSuscripcion] = useState([]);
    const [error, setError] = useState(null);
    const [step, setStep] = useState(1);

    useEffect(() => {
        // Ocultar el navbar y footer al montar el componente
        setShowNavbarFooter(false);
        return () => {
            // Mostrar el navbar y footer al desmontar el componente
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        const fetchGrupoFamiliar = async () => {
            try {
                setLoading(true);
                const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setMarcas(empresaResponse.data.data[0]);
            } catch (error) {
                console.error('Error fetching grupo familiar:', error);
                navigate('/Registro');
            } finally {
                setLoading(false);
            }
        };

        fetchGrupoFamiliar();
    }, [id, backendURL, navigate]);
    const handleSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backendURL}/api/getCodigo`, {
                params: { codigo }
            });
            setSuscripcion(response.data)
            handleNextStep();
        } catch (error) {
            setError('código de verificacion incorrecto.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    const Styleboton = {
        background: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81',
        fontFamily:'Poppins',
        fontSize:'1rem',
        color:'#fff',
        padding:'10px 20px',
        borderRadius:'50px',
        cursor:'pointer'        // Ajustar la altura de la sección
          
      }
    if (loading) {
        return  <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
        />
      </Box>;
      }

      const StyleFondo={
        backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
          backgroundSize: 'cover',           // Asegurar que cubra toda la sección
          backgroundPosition: 'center',      // Centrar la imagen
          width: '100%',
          height: 'auto',                   // Ajustar la altura de la sección
          
      }

 
      
    return(
        <section className="contienenpaginainiciousuario" style={StyleFondo}>
        {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
           <Helmet>
       <title>Bienvenido a registro usuario  empresa </title>
        <meta name="description" content="registro del usuario en empresa" />
        
      </Helmet>
      <Container component="main" maxWidth="xs">
      {step === 1 && ( <Box sx={{
                    padding: '20px 40px',
                    '@media (max-width: 450px)': {
                        padding: '20px 10px' // Margen reducido para pantallas menores a 450px
                    }

                }}>
                    <section className="contienenverificacionpagina">
                        <div className="iconoseguridad"><MdVerifiedUser style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}} /></div>
                        <div className="tituloverificar"><h1 style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}>Verificación de registro</h1>
                        </div>
  <strong className="bienveindaverificacion"> ¡Bienvenido!</strong>
                        <div className="contieneninformacionverificar">

                             <span>
                             Para completar tu registro y comenzar a
                              disfrutar de todos los beneficios que ofrece
                               nuestro servicio, por favor, ingresa el código
                                que hemos enviado a tu correo electrónico.
                                 Este código es crucial para verificar tu identidad 
                                 y garantizar una experiencia segura y personalizada.
                                  </span>
                                  </div>

                                  <div className="inputverificacionregistro">
                            <label htmlFor="">Ingresa código</label>
                            <input
                            style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81'}}
                                type="text"
                                className="verificacionregistro"
                                value={codigo}
                                onChange={(e) => setCodigo(e.target.value)}
                            />
                        </div>
                        {error && <div className="errorsmessage"> <FiAlertCircle/>{error}</div>}
                        <div className="botondeenviarverificacion">
                            <button onClick={handleSubmit} disabled={loading} style={Styleboton}>
                                {loading ? <CircularProgress size={24} /> : "Enviar"}
                            </button>
                        </div>
                       
                    </section>


                </Box>
                 )}
                    {step === 2 && (
                        <> <UserEmpresaMarca marcas={marcas} codigo={codigo} suscripcion={suscripcion}/></>
                    )}
        </Container>
        </section>
    )
}