import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, Grid, Button } from '@mui/material';
import { useUserEmpresa } from '../../../../../../context/UsuarioEmpresa';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, IconButton } from '@mui/material';

const ClienteReporte = ({ selectedOption, handleOptionSelect, empresa, selectedOptioncliente, setselectedOptioncliente, selectedOptionsucursal, setSelectedOptionsucursal,  selectedDate, setSelectedDate }) => {
  const [sucursales, setSucursales] = useState([]);
  const [fechasRegistradas, setFechasRegistradas] = useState([]);
  const [fechasRegistradasClientes, setFechasRegistradasClientes] = useState([]);
  const [todasfechasRegistradas, setTodasFechasRegistradas] = useState([]);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [vistaFecha, setVistaFecha] = useState("");
  const [vistaFechaCliente, setVistaFechaCliente] = useState(""); // 'dia' o 'mes'
  const { Token } = useUserEmpresa();

   // Obtener sucursales de la API
   useEffect(() => {
    const fetchSucursales = async () => {
      if (!empresa) return; // Verificar que el id de empresa esté disponible

      try {
        const response = await axios.get(`${backendURL}/api/todos/sucursales/empresa/${empresa.id}`, {
          
          headers: {
            'Authorization': `Bearer ${Token}`,
          },
        });
       
        setSucursales(response.data.sucursales || []);
      } catch (error) {
        console.error("Error al obtener las sucursales:", error);
      }
    };

    fetchSucursales();
  }, [backendURL, Token, empresa]); 

  // Función que limpia la sucursal y la fecha cuando se selecciona un cliente
  const handleClienteSelect = (option) => {
    if (selectedOptioncliente === option) {
      // Si ya está seleccionado, no haces nada (o puedes limpiar todos los valores si deseas un "reset")
      setselectedOptioncliente(null);
    } else {
      // Si se selecciona una nueva opción, se limpia el estado de los demás filtros (sucursal y fecha)
      setselectedOptioncliente(option);
      setSelectedOptionsucursal(null);  // Limpia sucursal
      setSelectedDate(null);  // Limpia la fecha
      // Aquí también puedes agregar cualquier otro estado que debas resetear
    }
  };

  const handleSucursalSelecttodas = async (empresa) => {
    try {
      const response = await axios.get(`${backendURL}/api/usuarios/sucursal/empresaselecion/${empresa.id}`, {
        headers: { 'Authorization': `Bearer ${Token}` },
      });
  
      const usuarios = response.data.data.flatMap(sucursal => sucursal.usuarios);
      const usuariosConFecha = usuarios.map(usuario => ({
        dia: usuario.created_at.split('T')[0],
        mes: usuario.created_at.slice(0, 7)
      }));
  
      setTodasFechasRegistradas(usuariosConFecha);
      setSelectedOptionsucursal(empresa);
      setSelectedDate(null); // Restablece la fecha seleccionada
      setFechasRegistradas([]); // Limpia fechas específicas
    } catch (error) {
      console.error("Error al obtener usuarios de la sucursal:", error);
    }
  };
  
  const handleSucursalSelect = async (sucursal) => {
    try {
      const response = await axios.get(`${backendURL}/api/usuarios/sucursal/selecion/${sucursal.id}`, {
        headers: { 'Authorization': `Bearer ${Token}` },
      });
  
      const usuarios = response.data.data;
      const fechas = usuarios.map(usuario => ({
        dia: usuario.created_at.split('T')[0],
        mes: usuario.created_at.slice(0, 7)
      }));
     
      setFechasRegistradas(fechas);
      setSelectedOptionsucursal(sucursal);
      setSelectedDate(null); // Restablece la fecha seleccionada
    } catch (error) {
      console.error("Error al obtener usuarios de la sucursal:", error);
    }
  };
  




  const formatDate = (date, isMonth = false) => {
    // Validación de entrada
    if (!date || typeof date !== 'string') return { full: 'Fecha no válida' };
  
    try {
      if (isMonth) {
        const [year, month] = date.split('-');
        // Validación adicional para año y mes
        if (!year || !month || isNaN(year) || isNaN(month)) return { monthYear: 'Fecha no válida' }; 
        
        const parsedDate = new Date(year, month - 1); // Se asume formato 'YYYY-MM'
        if (isNaN(parsedDate)) return { monthYear: 'Fecha no válida' }; // Validar si la fecha es correcta
        return {
          monthYear: parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' })
        };
      } else {
        const parsedDate = new Date(date);  // Verifica que la fecha esté en formato correcto
        if (isNaN(parsedDate)) return { full: 'Fecha no válida' };  // Si la fecha no es válida
        return {
          full: parsedDate.toLocaleDateString('es-ES')
        };
      }
    } catch (error) {
      console.error("Error al formatear fecha:", error);
      return { full: 'Fecha no válida' };
    }
  };

  // Función para eliminar fechas duplicadas en la vista de "mes"
  function getUniqueMonths() {
    const allMonths = fechasRegistradas.map(fecha => fecha.mes); // Extrae todos los meses
    const uniqueMonths = [...new Set(allMonths)].sort(); // Elimina duplicados y ordena
    return uniqueMonths;
  }
  const handleCheckboxChangeMonth = (mes) => {
    const isMonthSelected = selectedDate?.mes === mes;
    setSelectedDate(isMonthSelected ? null : { mes });
  };
  
  const handleCheckboxChangeDay = (dia) => {
    const isDaySelected = selectedDate?.dia === dia;
    setSelectedDate(isDaySelected ? null : { dia });
  };
  // Cambiar vista (día o mes)
  const cambiarVista = (vista) => {
    setVistaFecha(vista);
  };

 function getAllUniqueMonths() {
  const allMonths = todasfechasRegistradas.map(fecha => fecha.mes);
  const uniqueMonths = [...new Set(allMonths)].sort((a, b) => new Date(`${a}-01`) - new Date(`${b}-01`));
  return uniqueMonths;
}
  
  /* clientes */
  const cambiarVistaCliente = (vista) => {
    setVistaFechaCliente(vista);
  };
  const formatDatescliente = (date, esMes = false) => {
      // Validación de entrada
      if (!date || typeof date !== 'string') return { full: 'Fecha no válida' };
    
      try {
        if (esMes) {
          const [year, month] = date.split('-');
          
          // Validación adicional para año y mes
          if (!year || !month || isNaN(year) || isNaN(month)) return { monthYear: 'Fecha no válida' };
    
          // Crear el objeto Date con año y mes, asumiendo el primer día del mes
          const parsedDate = new Date(year, month - 1); // El mes comienza desde 0 (enero)
          
          // Validar si la fecha es correcta
          if (isNaN(parsedDate)) return { monthYears: 'Fecha no válida' };
    
          // Retornar el mes y el año en formato largo (mes en español)
          return {
            monthYears: parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' })
          };
        } else {
          // Para el caso de fecha completa
          const parsedDate = new Date(date);  // Verifica que la fecha esté en formato correcto
    
          // Validar si la fecha no es válida
          if (isNaN(parsedDate)) return { full: 'Fecha no válida' };
    
          // Retornar la fecha completa en formato 'DD/MM/YYYY' en español
          return {
            full: parsedDate.toLocaleDateString('es-ES')
          };
        }
      } catch (error) {
        console.error("Error al formatear fecha:", error);
        return { full: 'Fecha no válida' };
      }
    };
  

 

  useEffect(() => {
    const fetchData = async () => {
      if (selectedOptioncliente) {
        try {
          const response = await axios.get(`${backendURL}/api/usuarios/empresaselecion/${empresa.id}`, {
            headers: { 'Authorization': `Bearer ${Token}` },
          });
          const usuarios = response.data.data;
          const fechas = usuarios.map(usuario => ({
            dia: usuario.created_at.split('T')[0], // "YYYY-MM-DD"
            mes: usuario.created_at.slice(0, 7)    // "YYYY-MM"
          }));
          setFechasRegistradasClientes(fechas);
        } catch (error) {
          console.error("Error al obtener usuarios de la sucursal:", error);
        }
      }
    };
    fetchData();
  }, [selectedOptioncliente, backendURL, Token]);

  const uniqueMeses = [...new Set(fechasRegistradasClientes.map(fecha => fecha.mes))];
  const uniqueDias = [...new Set(fechasRegistradasClientes.map(fecha => fecha.dia))];

  return (
    <Box sx={{ textAlign: 'center', padding: '20px' }}>
<Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOption}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '90px' }}>
        <Grid item>
          <button
            className={`botonseleccion ${ selectedOptioncliente === 'Clientes' ? 'selected' : ''}`}
            onClick={() =>  handleClienteSelect('Clientes')}
          >
           Clientes General 
          </button>
        </Grid>
        <Grid item>
          <button
            className={`botonseleccion ${ selectedOptioncliente === 'Sucursal' ? 'selected' : ''}`}
            onClick={() =>  handleClienteSelect('Sucursal')}
          >
            Clientes por Sucursal
          </button>
        </Grid>
      </Grid>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOptioncliente}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      {/* codigo en selecion cliente */}
      {selectedOptioncliente === 'Clientes' && (
        <>
          <Box display="flex" justifyContent="center" mb={2} sx={{ marginTop: '30px' }}>
            <Button
              variant="contained"
              onClick={() => cambiarVistaCliente('dias')}
              sx={{
                marginRight: 1,
                width: '100px',
                backgroundColor: vistaFechaCliente === 'dias' ? '#694B81' : 'transparent',
                color: vistaFechaCliente === 'dias' ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              Día
            </Button>
            <Button
              variant="contained"
              onClick={() => cambiarVistaCliente('mes')}
              sx={{
                width: '100px',
                backgroundColor: vistaFechaCliente === 'mes' ? '#694B81' : 'transparent',
                color: vistaFechaCliente === 'mes' ? '#FFFFFF' : '#694B81',
                border: '1px solid #694B81',
                fontFamily: 'Poppins',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#5A3C72',
                  color: '#FFFFFF',
                },
              }}
            >
              Mes
            </Button>
          </Box>

          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          <h3 className="titulosfechassucursalesselecionreporte">Fechas Seleccionadas</h3>
          <TableContainer component={Paper} elevation={3} sx={{ width: '50%', borderRadius: '20px', marginBottom: '100px', background:'#ffff'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                    <IconButton color="secondary" aria-label="Fecha" sx={{ marginRight: 0.5 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    Fecha
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vistaFechaCliente === 'mes' ? (
                  uniqueMeses.map((mes, index) => {
                    const { monthYears } = formatDatescliente(mes, true);
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedDate?.mes === mes}
                            onChange={() => handleCheckboxChangeMonth(mes)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{monthYears}</TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  uniqueDias.map((dia, index) => {
                    const { full } = formatDatescliente(dia);
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={selectedDate?.dia === dia}
                            onChange={() => handleCheckboxChangeDay(dia)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{full}</TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          </Box>
        </>
      )}
      {selectedOptioncliente === 'Sucursal' && (
  <>
    <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '30px' }}>
      <Grid item>
        <button
          className={`botonseleccion ${selectedOptionsucursal?.nombre_sucursal === 'Todas' ? 'selected' : ''}`}
          onClick={() => handleSucursalSelecttodas({ id: empresa.id, nombre_sucursal: 'Todas' })}
        >
          Todas las Sucursales
        </button>
      </Grid>
      {sucursales.map((sucursal) => (
        <Grid item key={sucursal.id}>
          <button
            className={`botonseleccion ${selectedOptionsucursal?.id === sucursal.id ? 'selected' : ''}`}
            onClick={() => handleSucursalSelect(sucursal)}
          >
            {sucursal.nombre_sucursal}
          </button>
        </Grid>
      ))}
    </Grid>
    
    <Typography variant="h6" sx={tituloStyledescripcion}>
      {`Reporte para la sucursal: ${selectedOptionsucursal?.nombre_sucursal || "No seleccionada"}`}
    </Typography>

    {/* Mostrar los botones de "Día" y "Mes" solo si hay una sucursal seleccionada */}
    {selectedOptionsucursal && (
      <>
        <Box display="flex" justifyContent="center" mb={2} sx={{ marginTop: '30px' }}>
          <Button
            variant="contained"
            onClick={() => cambiarVista('dias')}
            sx={{
              marginRight: 1,
              width: '100px',
              backgroundColor: vistaFecha === 'dias' ? '#694B81' : 'transparent',
              color: vistaFecha === 'dias' ? '#FFFFFF' : '#694B81',
              border: '1px solid #694B81',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#5A3C72',
                color: '#FFFFFF',
              },
            }}
          >
            Día
          </Button>
          <Button
            variant="contained"
            onClick={() => cambiarVista('mes')}
            sx={{
              marginRight: 1,
              width: '100px',
              backgroundColor: vistaFecha === 'mes' ? '#694B81' : 'transparent',
              color: vistaFecha === 'mes' ? '#FFFFFF' : '#694B81',
              border: '1px solid #694B81',
              fontFamily: 'Poppins',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#5A3C72',
                color: '#FFFFFF',
              },
            }}
          >
            Mes
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            marginTop: '30px',
          }}
        >
          <h3 className="titulosfechassucursalesselecionreporte">Fechas Seleccionadas</h3>
          <TableContainer component={Paper} elevation={3} sx={{ width: '50%', borderRadius: '20px', marginBottom: '100px', background:'#ffff' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                    <IconButton color="secondary" aria-label="Fecha" sx={{ marginRight: 0.5 }}>
                      <CalendarTodayIcon fontSize="small" />
                    </IconButton>
                    Fecha
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {vistaFecha === 'mes' ? (
        selectedOptionsucursal.nombre_sucursal == 'Todas'
          ? getAllUniqueMonths().length > 0  // Verifica si hay meses para 'Todas'
            ? getAllUniqueMonths().map((mes, index) => { // Si hay meses, muestra la lista
                const { monthYear } = formatDate(mes, true);
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedDate?.mes === mes}
                        onChange={() => handleCheckboxChangeMonth(mes)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{monthYear}</TableCell>
                  </TableRow>
                );
              })
            : (
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: 'center', fontFamily: 'Poppins', color: '#585857' }}>
                  No hay registro para esta sucursal
                </TableCell>
              </TableRow>
            )
          : getUniqueMonths().length > 0  // Verifica si hay meses para la sucursal seleccionada
            ? getUniqueMonths().map((mes, index) => { // Si hay meses, muestra la lista
                const { monthYear } = formatDate(mes, true);
                return (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedDate?.mes === mes}
                        onChange={() => handleCheckboxChangeMonth(mes)}
                      />
                    </TableCell>
                    <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{monthYear}</TableCell>
                  </TableRow>
                );
              })
            : (
              <TableRow>
                <TableCell colSpan={2} sx={{ textAlign: 'center', fontFamily: 'Poppins', color: '#585857' }}>
                  No hay registro para esta sucursal
                </TableCell>
              </TableRow>
            )
      ) : (

        (selectedOptionsucursal.nombre_sucursal === 'Todas'
          ? todasfechasRegistradas
          : fechasRegistradas
        ).length > 0  // Verifica si hay fechas para mostrar
          ? (selectedOptionsucursal.nombre_sucursal === 'Todas' ? todasfechasRegistradas : fechasRegistradas).map((fecha, index) => {
              const { full } = formatDate(fecha.dia);
              return (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedDate?.dia === fecha.dia}
                      onChange={() => handleCheckboxChangeDay(fecha.dia)}
                    />
                  </TableCell>
                  <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{full}</TableCell>
                </TableRow>
              );
            })
          : (
            <TableRow>
              <TableCell colSpan={2} sx={{ textAlign: 'center', fontFamily: 'Poppins', color: '#585857' }}>
                No hay registro para esta sucursal
              </TableCell>
            </TableRow>
          )
      )}
    </TableBody>
  </Table>
</TableContainer>
        </Box>
      </>
    )}
  </>
)}
    </Box>)}




    export default ClienteReporte;



    const tituloStyledescripcion = {
      fontFamily:'Poppins',
      color:'#585857',
      fontSize:'0.8rem',
      fontWeight:'500',
      marginBottom:'0px',
      marginTop:'50px',
      display:'flex'
    }
    
    const Linea = {
      color:'#a8a6a6',
      background:'#a8a6a6',
      Border: '1px solid #a8a6a6',
      marginBottom:'50px'
    }