import React from "react";
import "../../../../Style/usuario/crear-familiar.css";
import "../../../../Style/usuario/galeriayvideo.css";
import { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom";
/* elmentos */
import { FaRegPlayCircle } from "react-icons/fa";
import axios from 'axios';
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { RxCalendar } from "react-icons/rx";
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaPhotoVideo } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { FiAlertCircle } from "react-icons/fi";
import dayjs from 'dayjs';
import { RiFileEditFill } from "react-icons/ri";
import { Container, Modal } from "@mui/material";
import { FiPlusCircle } from "react-icons/fi";
import { FaInfoCircle } from "react-icons/fa";
import ReactPlayer from 'react-player';
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useUsers } from "../../../context/userscontexts";



/* imagenes */
import editar from "../../../../Image/generales/descripcion-foto.png";


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = useState(10);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}


export const Memorialcreardos = () => {
  const {  userData } = useUsers(); 
  const location = useLocation();
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showCreateOnlyImageConfirmation, setShowCreateOnlyImageConfirmation] = useState(false);
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const memorialId = location.state.memorial;
  const [logoUrl, setLogoUrl] = useState('');
  const [video, setVideo] = useState(null);
  const [file, setFile] = useState(null);
  const [showFileInput, setShowFileInput] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar la visibilidad del modal
  const [imagenesData, setImagenesData] = useState([{ titulo: '', descripcion: '', fecha: '', imagen: null }]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Estado para almacenar el índice de la imagen seleccionada
  const [loading, setLoading] = useState(false);
  const [showAlertcrear, setShowAlertcrear] = useState(false);
  const [showImageAlerts, setShowImageAlerts] = useState(false);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [showTooltip, setShowTooltip] = useState(false);
  const [memorialData, setMemorialData] = useState({});
  const [isImageSaving, setIsImageSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [link, setLink] = useState(null);
  const[enviandoAlertprogress, setenviandoAlertprogress] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (grupoFamiliar && grupoFamiliar.memorial && grupoFamiliar.memorial.length === 1) {
      setShowAlertcrear(true);
    } else {
      setShowAlertcrear(false);
    }
  }, [grupoFamiliar]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  /* memorial */
  const fetchMemorialData = async (id) => {
    try {
      const response = await axios.get(`${backendURL}/api/getMemorialId/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ClientToken.token}`,
        }
      });
      setMemorialData(response.data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemorialData(memorialId);
  }, []);

  /* galeria */

  const handleInputChange = (index, name, value) => {
    const list = [...imagenesData];
    list[index][name] = value;
    setImagenesData(list);
  };

  const handleDescriptionButtonClick = (index) => {
    setSelectedImageIndex(index);
    const selectedImageData = memorialData.galeria[index];
    setImagenesData([{
      titulo: selectedImageData.titulo || '',
      descripcion: selectedImageData.descripcion || '',
      fecha: selectedImageData.fecha || ''
    }]);
    setModalOpen(true);
  };

  const handleEditImage = async () => {
    setLoading(true);
    try {
      setenviandoAlert(true);
      // Obtiene el ID de la imagen a editar
      const imageId = memorialData.galeria[selectedImageIndex].id;

      // Construye el objeto con los datos actualizados
      const updatedImageData = {
        id: imageId,
        titulo: imagenesData[0]?.titulo || '',
        descripcion: imagenesData[0]?.descripcion || '',
        fecha: imagenesData[0]?.fecha || ''
      };
      setLoading(true);
      // Envía una solicitud POST al backend para actualizar la imagen
      await axios.post(
        `${backendURL}/api/galeriaimagenUpdate`,
        updatedImageData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );

      setLoading(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue editada con éxito.')
      handleModalClose();
      await fetchMemorialData(memorialId);
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al editar la imagen, vuelva a intentar.',error);
    }
    finally {
      setLoading(false); // Ocultar el CircularProgress al finalizar el guardado de la imagen
      setenviandoAlert(false);
    }
    // Simulación de tiempo de carga
 setTimeout(() => {
  handleCloseModal();
}, 3000);
// Simulación de tiempo de carga
setTimeout(() => {
  setSuccessAlertOpen(false);
  setErrorAlertOpen(false);
}, 2000);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;
    const imagenData = {
      titulo: '',
      descripcion: '',
      fecha: '',
      imagen: files[0]
    };
    setImagenesData([imagenData]);
    setSelectedImageIndex(0);
    setShowCreateOnlyImageConfirmation(true);
  };

  const handleAddImage = () => {
    setImagenesData([...imagenesData, { titulo: '', descripcion: '', fecha: '', imagen: null }]);
  };

  const handleRemoveImage = async (index) => {
    try {
      setenviandoAlert(true);
      // Obtiene el ID de la imagen a eliminar
      const imageId = memorialData.galeria[index].id;

      // Envía una solicitud POST al backend para eliminar la imagen
      await axios.post(
        `${backendURL}/api/galeriaDelete`,
        { id: imageId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ClientToken.token}`
          }
        }
      );
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue eliminada con éxito.')
      await fetchMemorialData(memorialId);
      // Elimina la imagen del estado imagenesData
      const list = [...imagenesData];
      list.splice(index, 1);
      setImagenesData(list);
     
    } catch (error) {
     
      setErrorAlertOpen(true);
      setAlertMessage('Error al eliminar la imagen, vuelva a intentar.',error);
    }
    finally{
      setenviandoAlert(false);
 }
  // Simulación de tiempo de carga
  setTimeout(() => {
      handleCloseModal();
  }, 3000);
  // Simulación de tiempo de carga
  setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
  }, 2000);
  };


  const handleCreateOnlyImageConfirmation = async () => {
    
    setLoading(true);

    const formData = new FormData();
    formData.append('id_memorial', memorialId);

    // Agregar imagen al formulario
    if (selectedImageIndex !== null) {
      const imagenData = imagenesData[selectedImageIndex];
      const formattedFecha = imagenData.fecha ? dayjs(imagenData.fecha, 'YYYY-MM-DD').format('YYYY/MM/DD') : '';
      formData.append('imagenes[0][imagen]', imagenData.imagen);
      formData.append('imagenes[0][titulo]', imagenData.titulo);
      formData.append('imagenes[0][descripcion]', imagenData.descripcion);
      formData.append('imagenes[0][fecha]', formattedFecha);
    }

    try {
      setenviandoAlert(true);
      setLoading(true);
      const responseGaleria = await axios.post(`${backendURL}/api/galeriaStore`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${ClientToken.token}`
        },
      });
      await fetchMemorialData(memorialId);
      setLoading(false);
      handleModalClose(true);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su imagen en galeria fue creado con éxito.')
     
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear la imagen para galeria, vuelva a intentar.');
      setLoading(false)
    }
    finally{
      setenviandoAlert(false);
      setLoading(false)
      
 }

 // Simulación de tiempo de carga
 setTimeout(() => {
     handleCloseModal();
 }, 3000);
 // Simulación de tiempo de carga
 setTimeout(() => {
     setSuccessAlertOpen(false);
     setErrorAlertOpen(false);
 }, 2000);
}

  const handleModalClose = () => {
    setModalOpen(false);
    setShowCreateOnlyImageConfirmation(false);
    setImagenesData([]);
    setSelectedImageIndex(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setenviandoAlert(false);
    setenviandoAlertprogress(false);
  };

   /* video */

  const enviarVideoAlServidor = async (formData) => {
    try {
      setenviandoAlertprogress(true);
      setenviandoAlert(true);
      setUploading(true);
      setProgress(0); // Inicializar el progreso a 0
  
      const simulateUploadProgress = () => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(progressInterval);
            return 100;
          }
          return prevProgress + 10;
        });
      };
  
      const progressInterval = setInterval(simulateUploadProgress, 800);
  
      const response = await axios.post(
        `${backendURL}/api/updateMemorial/${memorialData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${ClientToken.token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      await fetchMemorialData(memorialData.id);
      setShowSuccessMessage(true);
      setVideo(URL.createObjectURL(formData.get('video')));
      setUploading(false);
      setenviandoAlertprogress(false);
      setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('Su video fue creado con éxito.');
    } catch (error) {
      setErrorAlertOpen(true);
      setAlertMessage('Error al crear el video, vuelva a intentar.');
    } finally {
      setUploading(false);
      setenviandoAlert(false);
    }
    
    // Simulación de tiempo de carga
    setTimeout(() => {
      handleCloseModal();
    }, 3000);
    // Simulación de tiempo de carga
    setTimeout(() => {
      setSuccessAlertOpen(false);
      setErrorAlertOpen(false);
    }, 2000);
  };
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    setFile(file);
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
  
    video.onloadedmetadata = () => {
      if (video.duration > 30.1) {
        setShowAlert(true);
        URL.revokeObjectURL(video.src);
      } else {
        setVideo(URL.createObjectURL(file));
        setShowAlert(false);
  
        const formData = new FormData();
        formData.append('video', file);
        const currentFondo = memorialData.fondo || 'default_value'; // Usa 'default_value' o un valor por defecto si no hay fondo.
        formData.append('fondo', currentFondo);
  
        // Verificar si el video ya está cargado antes de enviarlo al servidor
        if (!videoLoaded) {
          // Llama a la función para enviar el video al servidor directamente
          enviarVideoAlServidor(formData);
          // Establece el estado para indicar que el video se ha cargado
          setVideoLoaded(true);
        }

       
      }
    };

  };

  const handleVideoClick = () => {
    if (video) {
      // Si hay un video, simplemente limpiamos la selección actual
      setVideo(null);
      setShowAlert(false);
      setShowSuccessMessage(false);
    } else {
      // Si no hay video, simulamos hacer clic en el input de tipo file
      setShowSuccessMessage(false);
      const fileInput = document.getElementById('filevideoinput');
      if (fileInput) {
        fileInput.click();
      } else {
        console.error('Elemento con ID filevideoinput no encontrado.');
      }
    }
  };
  
  useEffect(() => {
  if (memorialData.video) {
    if (!videoLoaded) {
    const uniqueUrl = `${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`;
    setVideo(uniqueUrl);
    }
  } else {
    setVideo(null);
  }
}, [memorialData, backendURL, videoLoaded]);


  const handleclickcontinuar  = () => {
    const state = { user: ClientToken, familia: grupoFamiliar, memorial: memorialId };
      navigate('/crearmemorial/mensajefondo', { state });
  }


  /* funeraria Logo traer */
  useEffect(() => {
    const fetchFuneraria = async () => {
      try {
        if (grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData);
          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        } else {
          console.error('Group family or funerary ID is missing');
        }
      } catch (error) {
        console.error('Error fetching funeraria data:', error);
      }
    };

    fetchFuneraria();
  }, [grupoFamiliar]);

  return (
   
    <section className="contienenpaginainiciousuario" >
    {logoUrl && (
          <Link to={link.web} className="logofunerariacrearmemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
   
        
            
              <Container className="contieneregistro" component="main" maxWidth="xs">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                   
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  
                  <div className="pasosmemorial">
                    <div className="titulopasos" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#E1DBE6'
  }}><h1>PASO 3:</h1>
                      <span>Agregue fotos y videos en su galería</span>
                    </div>
                  </div>
                  {showAlertcrear && (
        <div className="alertaeditarmemorial">
          <BiErrorCircle />
          <div className="infoalertamemorial" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>
            <h4>Si usted no posee la información necesaria en este</h4>
            <h4>momento, puede editar el memorial más tarde</h4>
          </div>
        </div>
      )}

<div className="titulogaleriavideofinal">
                <div className="titulohitoriacrear" >
                  <FaPhotoVideo style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                  <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Galería de recuerdos</h1>
                </div>
              </div>
              <section className="contienesubtitulofoto">
              <div className="titulofotocrear">
                <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Fotos</h1>
              </div>
              </section>

              <section className="galeria-crear">
                <Grid container >
                  {memorialData.galeria && memorialData.galeria.map((imagen, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto">
                        <div className="inputsubir">
                          {/* Muestra la imagen */}
                          <img src={`${backendURL}/${imagen.imagen}`} alt={`Imagen ${index + 1}`} className="imagen-seelcion-galeria" />
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                            <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir Foto</span>
                          </div>
                           <div className="contienebotoneliminarfoto">
                        <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                          <IoClose style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                        </button>
                        </div>
                        </div>
                       
                        <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)} style={{
    background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>
                        <RiFileEditFill/>  <p>Descripción</p> 
                        </button>
                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="contienecardgaleria">
                    <div className="cardfoto" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>
                      <div className="inputsubir" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>

                        <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />


                        <div className="contieneicono">
                          <FaArrowUpFromBracket className="imgicono"  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
                          <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir Foto</span>
                        </div>
                      </div>


                    </div>
                  </Grid>
                  {imagenesData.map((imagenData, index) => (
                    <Grid key={index} item xs={4} className="contienecardgaleria">
                      <div className="cardfoto" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>
                        <div className="inputsubir" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>

                          <input type="file" accept="image/*" className="cardinputgaleria" onChange={handleImageChange} />

                          {imagenData.imagen && (
                            <img src={URL.createObjectURL(imagenData.imagen)} alt="Smart Memorial" className='imagen-seelcion-galeria' />
                          )}
                          <div className="contieneicono">
                            <FaArrowUpFromBracket className="imgicono" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                            <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir Foto</span>
                          </div>
                        </div>
                        {imagenData.imagen && ( // Condición para mostrar los botones solo si hay una imagen seleccionada
                          <>
                           <div className="contienebotoneliminarfoto">
                            <button className="button-cambiar-close" type="button" onClick={() => handleRemoveImage(index)}>
                              <IoClose  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
                            </button></div>
                            <button type="button" className="editar-imagen" onClick={() => handleDescriptionButtonClick(index)} style={{
    background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>
                              <p>Descripción</p> <img src={editar} alt="Smart Memorial" />
                            </button>
                          </>
                        )}

                      </div>
                    </Grid>
                  ))}
                  <Grid item xs={4} className="botonagregar">
                    <div className="cardnuevafoto" 
                    style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>
                      <div className="inputnuevosubir" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>
                        <button type="button" className="cardnuevafoto" onClick={handleAddImage} style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}> <div className="agregaricono">
                          <FiPlusCircle style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                        </div>
                        </button>
                      </div>
                    </div></Grid>



                </Grid>
              </section>

              {modalOpen && selectedImageIndex !== null && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/></span>
                    <div className="titulo-galeriadescripcion"> < RiFileEditFill style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                      <h2 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Agregar Descripción</h2>
                    </div>
                    <section className="aviso"> <span> Si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                        style={{
                          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                        }}
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[0]?.titulo || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                          <label>Fecha de la foto</label>
                          
                        </div>
                        <div className="inputfotocontainer">
                          <input
                          style={{
                            color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                          }}
                            type="date"
                            className="inputefotofecha"
                            name='fecha'
                            value={imagenesData[0]?.fecha || ''}
                            onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                          />
                       <div className="calendariofotoicono">
        <RxCalendar className="iconofotolcalendario"  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
        </div>
                        </div>
                      </div>
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                        style={{
                          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                        }}
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[0]?.descripcion || ''}
                          onChange={(e) => handleInputChange(0, e.target.name, e.target.value)}
                        />
                      </div>
                    </section>
                    <section className="botonguardarimagen">
                    <button className="guardarimagen" style={{
   background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>    {loading ? (
    <CircularProgress size={24} color="inherit" /> // Mostrar el CircularProgress si se está guardando la imagen
  ) : (
    <div onClick={handleEditImage}>Continuar</div> // Renderizar el botón "Guardar" normalmente
  )}</button>
                      
                    </section>
                  </div>
                </div>
              )}

              {showCreateOnlyImageConfirmation && (
                <div className="modal">
                  <div className="modal-content">
                    <span className="close" onClick={handleModalClose}><IoClose style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/></span>

                    <div className="titulo-galeriadescripcion"> < RiFileEditFill style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                      <h2 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Agregar Descripción</h2></div>
                      <section className="aviso"> <span> Si no desea agregar descripción </span> <span>haga click en continuar.</span></section>
                    <section className="formdescripciongaleriaimagen">
                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Título</label>
                        <input
                        style={{
                          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                        }}
                          type="text"
                          name="titulo"
                          className="inputdescripimag"
                          value={imagenesData[selectedImageIndex]?.titulo || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'titulo', e.target.value)}
                        />
                      </div>

                      <div className="inputimagenespecifico">
                        <div className="inputlabelinfofecha" style={{ display: "flex", flexDirection: "row", alignItems: "center" }} ><label>Fecha de la foto</label> </div>
                        <div className="inputfotocontainer">
                          <input
                          style={{
                            color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                          }}
                            type="date"
                            className="inputefotofecha"
                            name='fechaNacimiento'
                            value={imagenesData[selectedImageIndex]?.fecha || ''}
                            onChange={(e) => handleInputChange(selectedImageIndex, 'fecha', e.target.value)}

                          />
<div className="calendariofotoicono">
        <RxCalendar className="iconofotolcalendario" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
        </div>
                        </div>
                      </div>

                      <div className="inputimagenespecifico">
                        <label className="tituloinputfecha">Descripción</label>
                        <textarea
                        style={{
                          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                        }}
                          name="descripcion"
                          className="inputdescripitodo"
                          value={imagenesData[selectedImageIndex]?.descripcion || ''}
                          onChange={(e) => handleInputChange(selectedImageIndex, 'descripcion', e.target.value)}
                        />
                      </div>

                      <section className="botonguardarimagen">
                      <div className="guardarimagen" style={{
   background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>
  {loading ? (
    <CircularProgress size={24} color="inherit" /> // Mostrar el CircularProgress si se está guardando la imagen
  ) : (
    <div onClick={handleCreateOnlyImageConfirmation}>Continuar</div> // Renderizar el botón "Guardar" normalmente
  )}
</div>
                      </section>
                    </section>
                  </div>
                </div>
              )}



<section className="videoeditargaleria">
                <div className="titulovideoedit">
                  <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Videos</h1>
                  <div className="avisodevideo">  <FiAlertCircle  /> <p>El video debe tener una duración maxima de 30 segundos.</p></div>
                </div>
                {showAlert && (
                  <div className={`alerta ${showAlert ? 'mostrar' : ''}`} onClick={() => setShowAlert(false)}>
                    <FiAlertCircle /> <p>El video superar los 30 segundos intente con otro video.</p>
                  </div>
                )}

                {showSuccessMessage && (
                  <div className={`successmessagecorrect ${showSuccessMessage ? 'show' : ''}`}>
                    <FaRegCircleCheck /><p>¡El video se ha subido correctamente!</p>
                  </div>
                )}
                 <Modal
        open={uploading}
        aria-labelledby="uploading-modal-title"
        aria-describedby="uploading-modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bgcolor="background.paper"
            p={4}
            borderRadius={1}
            boxShadow={3}
          >
            <CircularProgress color="secondary" />
           
          </Box>
        </Box>
      </Modal>
                <section className="seccion-video-crear">
                  <div className="card-video" style={{
                      background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 :  '#F0EDF3',
                    }}>
                  {video ? (
                 <div >  
                  <div>
          <ReactPlayer
            url={video}
            playing={isPlaying}
            controls={true}
            width="100%"
            height="100%"
            playIcon={<FaRegPlayCircle />}
          />
          
          </div>
          <div className="buttonvideoedit"> <button className="editvideogaleria" onClick={() => handleVideoClick()}>Editar video</button></div></div>
        ) : (
          <div className="no-video-message">
            <div className="inputsubir">
              <input
                id="filevideoinput"
                className="cardinputgaleria"
                name="file"
                type="file"
                accept="video/mp4"
                onChange={handleFileChange}
              />
              <div className="contienevideoicono">
                <FaArrowUpFromBracket className="imgicono" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
                <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Editar Video</span>
              </div>
            </div>
          </div>
        )}
                   
                  </div>
                </section>

              </section>
                      <div className="botonesgaleriayvideo">
                        <div className="siguiientevideoygaleria" >
                          <button  onClick={handleclickcontinuar} style={{
                      background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
                    }} >Siguiente</button>
                        </div>
                      </div>
                
                  

                  </Box>

                  
                </Container>
            
            
                <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

       {/* Popup de carga */}
       <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Modal open={enviandoAlertprogress} onClose={() => setenviandoAlertprogress(false)}>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '350px',
      height: 'auto',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 1,
      borderRadius: '10px',
      '@media (max-width: 500px)': {
        width: '90%',
      },
    }}
  >
    <section style={{ textAlign: 'center', padding: '1rem' }}>
      <CircularProgressWithLabel value={progress} style={{ color: '#694B81' }} />
      <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
        Procesando solicitud...
      </p>
    </section>
  </Box>
</Modal>
      </section>

   
  );
}