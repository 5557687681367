import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import '../Style/Registro/tipomemorialpagina.css';
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import full from "../Image/generales/full.jpg"
import basico from "../Image/generales/basico.jpg"

import uno from "../Image/generales/1.png"
import dos from "../Image/generales/2.png"
import tres from "../Image/generales/3.png"
import cuatro from "../Image/generales/4.png"
import cinco from "../Image/generales/5.png"
import seis from "../Image/generales/6.png"
import siete from "../Image/generales/7.png"
import ocho from "../Image/generales/8.png"
import nueve from "../Image/generales/moradoluto.png"

export const Seleccionmemorial = () => {
    const navigate = useNavigate();
    const handleClickfull = () => {
        navigate('/solicitarmemorialfull'); // Navegar a la ruta de inicio
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Desplazamiento suave
        });
      };

      useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

      const handleClickbasic = () => {
        navigate('/solicitarmemorialbasico'); // Navegar a la ruta de inicio
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // Desplazamiento suave
        });
      };
    return (
        <>
         <Helmet>
        <title> Bienvenido a Smart Memorial </title>
        <meta name="description" content=" Más cerca de ti" />
        
      </Helmet>
            <Container component="main" maxWidth="xs" >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <section className="titulodesolicitud">
                        
                        <h1>Solicitar Memorial</h1>
                    </section>

                    <section className="tectogenerado">
                        <span >Elija el servicio que se adapte

a sus necesidades.
                              </span>
                         
                    </section>

                    <section className="contieneseleciondememorial">
                        <section className="tituloselecion"  data-aos="fade-down">
                            <h3>Memorial Básico</h3>
                        </section> 
                        <section  data-aos="fade-down" >
                        <div className="recuadroinforcionmemorial">
                            <div style={{width:"100%", height:"100%", borderRadius:'10px'}}>
                                <img src={basico} alt="smart memorial full" style={{width:"100%", height:"100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
                            </div>
                            <section className="listaservicio">
                <div className="tituloservicioincluido" >
                  <h2>Servicio incluye</h2>
                </div>
                <div className="infolistaincluido">
                  <div className="iteminfoservicio" >
                    <div className="contieneimagensmart">
                      <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                      <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Código QR para descargar e imprimir</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Edición del memorial cuando desee</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Compartir memoriales con familiares y amigos</span>
                  </div>
                  <div className="iteminfofinalservicio">
                    <div className="contieneimagensmart">
                      <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de Obituario </span>
                  </div>
                </div>

              </section>
              <div className="solicitaryprecio"  >
                                 <div className="preciounasmart">
                                <div className="contienenpreciounico"> <p>$12.990</p></div>
                                 
                                </div>
                                <div className="botonsmartsolicitar" onClick={handleClickbasic}>
                                    <button > Solicitar</button>
                                </div>
                                
                            </div>

                            </div>

                        </section>
                        <section className="tituloselecion"  data-aos="fade-down">
                            <h3>Memorial Full</h3>
                        </section> 
                        <section  data-aos="fade-down" >
                        <div className="recuadroinforcionmemorial">
                            <div style={{width:"100%", height:"100%",  borderRadius:'10px'}}>
                                <img src={full} alt="smart memorial full" style={{width:"100%", height:"100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
                            </div>
                            <section className="listaservicio">
                <div className="tituloservicioincluido" >
                  <h2>Servicio incluye</h2>
                </div>
                <div className="infolistaincluido">
                  <div className="iteminfoservicio" >
                    <div className="contieneimagensmart">
                      <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                      <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">2 Placas metálicas con QR para lápida o ánfora</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Edición del memorial cuando desee</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Compartir memoriales con familiares y amigos</span>
                  </div>
                  <div className="iteminfofinalservicio">
                    <div className="contieneimagensmart">
                      <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de Obituario </span>
                  </div>
                </div>

              </section>
              <div className="solicitaryprecio"  >
                                 <div className="preciounasmart">
                                <div className="contienenpreciounico"> <p>$39.990</p></div>
                                 
                                </div>
                                <div className="botonsmartsolicitar" onClick={handleClickfull}>
                                    <button > Solicitar</button>
                                </div>
                                
                            </div>

                            </div>
                                  
                           
                            
                            
                        </section>
                       
                       
                        

                    </section>

                </Box>
            </Container>


        </>
    )
}