import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Box, Typography, Grid, Button,  Checkbox, FormControlLabel  } from '@mui/material';
import { useUserEmpresa } from '../../../../../../context/UsuarioEmpresa';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, IconButton } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

 const ComentarioReporte = ({ selectedOption, selectedOptioncliente, setselectedOptioncliente, setSelectedOptionsucursal,  selectedDate, setSelectedDate, empresa, id}) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { Token } = useUserEmpresa();
  const [comentarios, setComentarios] = useState([]);
  const [comentariosClientes, setComentariosClientes] = useState([]);
  const [mostrarFechas, setMostrarFechas] = useState(false); // Estado para mostrar todas las fechas
  const [mostrarFechascliente, setMostrarFechascliente] = useState(false);
 

 
  
  // Función para realizar la solicitud HTTP a la API
  const fetchComentarios = async () => {
    try {
      const response = await Axios.get(`${backendURL}/api/comentarios/${id}/${empresa.id}/4`, {
        headers: { 'Authorization': `Bearer ${Token}` },
      });
      // Extrae y formatea las fechas para obtener solo el mes y año
      const fechasUnicas = Array.from(new Set(response.data.map(comentario => comentario.fecha.slice(0, 7))));
      // Mapea las fechas únicas en el formato deseado
      const comentariosConFecha = fechasUnicas.map(mes => ({ mes }));
      setComentarios(comentariosConFecha);
      setMostrarFechas(true);
    } catch (error) {
      console.error(error.message);
    }
  };


  useEffect(() => {
    if (id) {
      fetchComentarios(); // Llamar a la función para obtener los comentarios cuando el id cambie
    }
  }, [id]);

  const fetchComentariosClientes = async () => {
    try {
      const response = await Axios.get(`${backendURL}/api/fechas/clientes/comentariosempresa/reporte/${empresa.id}`, {
        headers: { 'Authorization': `Bearer ${Token}` },
      });
      
       // Extrae y formatea las fechas únicas en el formato deseado
    const fechasUnicas = Array.from(new Set(response.data.fechas));
    
    // Mapea las fechas únicas en el formato adecuado
    const comentariosConFecha = fechasUnicas.map((mes) => ({ mes }));
    
      setComentariosClientes(comentariosConFecha);
      setMostrarFechascliente(true);
     
    } catch (error) {
      console.error(error.message);
    }
  };



  useEffect(() => {
    if (id) {
      fetchComentariosClientes(); // Llamar a la función para obtener los comentarios cuando el id cambie
    }
  }, [id]);
  

  const handlecomentarioSelect = (option) => {
    if (selectedOptioncliente === option) {
      setselectedOptioncliente(null);
      setMostrarFechas(false); // Oculta la tabla si se deselecciona la opción
    } else {
      setselectedOptioncliente(option);
      setSelectedOptionsucursal(null);
      setSelectedDate(null);
    }
  };


  const handleCheckboxChangeMonth = (mes) => {
    // Verifica si el mes seleccionado es el mismo que el mes actual
    const isMonthSelected = selectedDate?.mes === mes;
    
    // Si ya está seleccionado, lo deselecciona, de lo contrario lo selecciona
    setSelectedDate(isMonthSelected ? null : { mes });
  };


  return (
    <Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px' }}>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        {selectedOption ? `Tipo de reporte seleccionado: ${selectedOption}` : "No has seleccionado un tipo de reporte"}
      </Typography>
      <hr style={Linea} />
      <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: '90px' }}>
      <Grid item>
          <button
            className={`botonseleccion ${selectedOptioncliente === 'Clientes Comentarios' ? 'selected' : ''}`}
            onClick={() => handlecomentarioSelect('Clientes Comentarios')}
          >
            Comentarios memoriales clientes
          </button>
        </Grid>
        <Grid item>
          <button
            className={`botonseleccion ${selectedOptioncliente === 'Empresa Comentarios' ? 'selected' : ''}`}
            onClick={() => handlecomentarioSelect('Empresa Comentarios')} // Elimina el espacio extra aquí
          >
            Comentarios memoriales empresa
          </button>
        </Grid>
      </Grid>
      {selectedOptioncliente === 'Empresa Comentarios' && (
        <>
      <Typography variant="h6" sx={tituloStyledescripcion}>
        Selecciona la fecha para el reporte "Mes y Año"
      </Typography>
      <hr style={Linea} />

<Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px',display:'flex', alignItems:'center', justifyContent:'center' }}>
{mostrarFechas && (
              <TableContainer component={Paper} elevation={3} sx={{ width: '50%', borderRadius: '20px', background:'#ffff' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                        <IconButton color="secondary" aria-label="Fecha">
                          <CalendarTodayIcon fontSize="small" />
                        </IconButton>
                        Fecha
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comentarios.length > 0 ? (
                      comentarios.map((comentario, index) => {
                        const [year, month] = comentario.mes.split('-').map(Number);
                        // Crear la fecha usando el año y mes (restando 1 al mes)
                        const parsedDate = new Date(year, month - 1);
                        // Formatear la fecha en el formato deseado
                        const mesFormateado = parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' });
                        const isSelected = selectedDate?.mes === comentario.mes;
                        return (
                          <TableRow key={index}>
                            <TableCell>
                            <Checkbox
                              checked={isSelected}
                              onChange={() => handleCheckboxChangeMonth(comentario.mes)} // Pasar la fecha del mes al manejador
                            />
                              </TableCell>
                            <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{mesFormateado}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center" sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                          No hay comentarios registrados en este mes.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
      )}

{selectedOptioncliente === 'Clientes Comentarios' && (
        <>
            <Typography variant="h6" sx={tituloStyledescripcion}>
        Selecciona la fecha para el reporte "Mes y Año"
      </Typography>
      <hr style={Linea} />

<Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px',display:'flex', alignItems:'center', justifyContent:'center' }}>
{mostrarFechascliente && (
              <TableContainer component={Paper} elevation={3} sx={{ width: '50%', borderRadius: '20px', background:'#ffff' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>Seleccionar</TableCell>
                      <TableCell  sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                        <IconButton color="secondary" >
                          <CalendarTodayIcon fontSize="small" />
                        </IconButton>
                        Fecha
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {comentariosClientes.length > 0 ? (
                      comentariosClientes.map((comentario, index) => {
                        const [year, month] = comentario.mes.split('-').map(Number);
                        // Crear la fecha usando el año y mes (restando 1 al mes)
                        const parsedDate = new Date(year, month - 1);
                        // Formatear la fecha en el formato deseado
                        const mesFormateado = parsedDate.toLocaleDateString('es-ES', { year: 'numeric', month: 'long' });
                        const isSelected = selectedDate?.mes === comentario.mes;
                        return (
                          <TableRow key={index}>
                            <TableCell>
                            <Checkbox
                              checked={isSelected}
                              onChange={() => handleCheckboxChangeMonth(comentario.mes)} // Pasar la fecha del mes al manejador
                            />
                              </TableCell>
                            <TableCell sx={{ fontFamily: 'Poppins', color: '#585857' }}>{mesFormateado}</TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={2} align="center" sx={{ fontFamily: 'Poppins', color: '#585857' }}>
                          No hay comentarios registrados en este mes.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </>
)}
    </Box>
  );
};

export default ComentarioReporte;

const tituloStyledescripcion = {
  fontFamily:'Poppins',
  color:'#585857',
  fontSize:'0.8rem',
  fontWeight:'500',
  marginBottom:'0px',
  marginTop:'50px',
  display:'flex'
}

const Linea = {
  color:'#a8a6a6',
  background:'#a8a6a6',
  Border: '1px solid #a8a6a6',
  marginBottom:'50px'
}