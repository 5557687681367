import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Box, Typography,  CircularProgress, Modal, Button  } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { useUserEmpresa } from '../../../../../../context/UsuarioEmpresa';
import LinearProgress from '@mui/material/LinearProgress';
import * as XLSX from 'xlsx';


const VistaReporte = ({ selectedOption, selectedOptioncomentario, selectedDate, id, empresa,  selectedOptioncliente,  selectedOptionsucursal }) => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const { Token, UserDataMarca } = useUserEmpresa(); 
  const [progress, setProgress] = useState(0); // Estado para el progreso
  const [openModal, setOpenModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false); // Estado para el modal de éxito
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];




/* descargar en pdf  clientes*/

const generatePDFWithTable = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/empresa/reporte`, {
      params: {
        id_empresa: empresa.id,
         fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const doc = new jsPDF();
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta

    // Agregar el nombre de la empresa en la parte superior
    doc.setFontSize(10);
    doc.text('Reporte de clientes - empresa ' + empresaNombre, 14, 20);

    const tableData = response.data.map(cliente => [
      cliente.name,
      cliente.apellido,
      cliente.rut,
      cliente.email,
      cliente.telefono,
    ]);

    // Usamos autoTable para generar una tabla en el PDF
    doc.autoTable({
      head: [['Nombre', 'Apellido', 'Rut', 'Email', 'Telefono']],
      body: tableData,
      startY: 30, // La tabla empezará justo después del título (puedes ajustar este valor según sea necesario)
    });

    // Crear un nombre de archivo que incluya la fecha o mes seleccionado
    let fileName = `reporte_clientes__${empresaNombre}`;
    
    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Guardar el PDF con el nombre personalizado
    doc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};
/* descargar excel clientes*/
const generateExcelWithTable = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/empresa/reporte`, {
      params: {
        id_empresa: empresa.id,
        fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta

    // Crear el nombre del archivo
    let fileName = `reporte_clientes__${empresaNombre}`;

    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Obtener los datos de los clientes
    const clienteData = response.data;

    // Crear la data para la hoja de Excel
    const worksheetData = clienteData.map(cliente => ({
      "Nombre": cliente.name,
      "Apellido": cliente.apellido,
      "Rut": cliente.rut,
      "Teléfono": cliente.telefono,
      "Email": cliente.email,
    }));

    // Crear la hoja de trabajo y el libro de Excel
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Clientes");

    // Ajuste dinámico del ancho de las columnas
    const columnWidths = [];
    worksheetData.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        const columnWidth = cellValue.length > (columnWidths[colIndex] || 0) ? cellValue.length : columnWidths[colIndex];
        columnWidths[colIndex] = columnWidth;
      });
    });

    // Asignar el ancho de las columnas basado en el contenido
    worksheet['!cols'] = columnWidths.map((width) => ({ wch: width + 2 })); // Se agrega un margen extra

    // Generar el archivo Excel
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    
  } catch (error) {
    console.error('Error al generar el archivo Excel:', error);
  }
};

/* descargar en pdf clientes sucursal especifico */

const generatePDFWithTablesucursal = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/sucursal/reporte`, {
      params: {
        id_sucursal: selectedOptionsucursal.id,
        fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const doc = new jsPDF();
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta
    const sucursalempresa =  selectedOptionsucursal.nombre_sucursal;
    // Agregar el nombre de la empresa en la parte superior
    doc.setFontSize(10);
    doc.text('Reporte de clientes - empresa ' + empresaNombre + ' sucursal ' + sucursalempresa, 14, 20);

    const tableData = response.data.map(cliente => [
      cliente.name,
      cliente.apellido,
      cliente.rut,
      cliente.email,
      cliente.telefono,
    ]);

    // Usamos autoTable para generar una tabla en el PDF
    doc.autoTable({
      head: [['Nombre', 'Apellido', 'Rut', 'Email', 'Telefono']],
      body: tableData,
      startY: 30, // La tabla empezará justo después del título (puedes ajustar este valor según sea necesario)
    });

    // Crear un nombre de archivo que incluya la fecha o mes seleccionado
    let fileName = `reporte_clientes__${empresaNombre}_${sucursalempresa}`;
    
    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Guardar el PDF con el nombre personalizado
    doc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};
/* descargar excel sucursal especifico */
const generateExcelWithTablesucursal = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/sucursal/reporte`, {
      params: {
        id_sucursal: selectedOptionsucursal.id,
        fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta
    const sucursalempresa =  selectedOptionsucursal.nombre_sucursal;
    // Crear el nombre del archivo
    let fileName = `reporte_clientes__${empresaNombre}_${sucursalempresa}`;

    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Obtener los datos de los clientes
    const clienteData = response.data;

    // Crear la data para la hoja de Excel
    const worksheetData = clienteData.map(cliente => ({

      "Nombre": cliente.name,
      "Apellido": cliente.apellido,
      "Rut": cliente.rut,
      "Teléfono": cliente.telefono,
      "Email": cliente.email,
    }));

    // Crear la hoja de trabajo y el libro de Excel
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sucursal");

    // Ajuste dinámico del ancho de las columnas
    const columnWidths = [];
    worksheetData.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        const columnWidth = cellValue.length > (columnWidths[colIndex] || 0) ? cellValue.length : columnWidths[colIndex];
        columnWidths[colIndex] = columnWidth;
      });
    });

    // Asignar el ancho de las columnas basado en el contenido
    worksheet['!cols'] = columnWidths.map((width) => ({ wch: width + 2 })); // Se agrega un margen extra

    // Generar el archivo Excel
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    
  } catch (error) {
    console.error('Error al generar el archivo Excel:', error);
  }
};

/* descarga pdf sucursal todas */
const generatePDFWithTablesucursalAll = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/todassucursal/empresa/reporte`, {
      params: {
        id_empresa: empresa.id,
        fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const doc = new jsPDF();
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta
    const sucursalempresa =  selectedOptionsucursal.nombre_sucursal;
    // Agregar el nombre de la empresa en la parte superior
    doc.setFontSize(10);
    doc.text('Reporte de clientes - empresa ' + empresaNombre + ' sucursal ' + sucursalempresa, 14, 20);

    const tableData = response.data.map(cliente => [
      cliente.name,
      cliente.apellido,
      cliente.rut,
      cliente.email,
      cliente.telefono,
    ]);

    // Usamos autoTable para generar una tabla en el PDF
    doc.autoTable({
      head: [['Nombre', 'Apellido', 'Rut', 'Email', 'Telefono']],
      body: tableData,
      startY: 30, // La tabla empezará justo después del título (puedes ajustar este valor según sea necesario)
    });

    // Crear un nombre de archivo que incluya la fecha o mes seleccionado
    let fileName = `reporte_clientes__${empresaNombre}_${sucursalempresa}`;
    
    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Guardar el PDF con el nombre personalizado
    doc.save(`${fileName}.pdf`);
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};

const generateExcelWithTablesucursalAll = async () => {
  setOpenModal(true);
  setProgress(0);
  try {
    const response = await Axios.get(`${backendURL}/api/clientes/todassucursal/empresa/reporte`, {
      params: {
        id_empresa: empresa.id,
        fecha: selectedDate.dia || '',
        mes: selectedDate.mes || '', 
      },
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
    setOpenModal(false);
    setOpenSuccessModal(true);
    const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta
    const sucursalempresa =  selectedOptionsucursal.nombre_sucursal;
    // Crear el nombre del archivo
    let fileName = `reporte_clientes__${empresaNombre}_${sucursalempresa}`;

    // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
    if (selectedDate.dia) {
      fileName += `_${selectedDate.dia}`;
    } else if (selectedDate.mes) {
      fileName += `_${selectedDate.mes}`;
    }

    // Obtener los datos de los clientes
    const clienteData = response.data;

    // Crear la data para la hoja de Excel
    const worksheetData = clienteData.map(cliente => ({
      "sucursal":sucursalempresa,
      "Nombre": cliente.name,
      "Apellido": cliente.apellido,
      "Rut": cliente.rut,
      "Teléfono": cliente.telefono,
      "Email": cliente.email,
    }));

    // Crear la hoja de trabajo y el libro de Excel
    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sucursal");

    // Ajuste dinámico del ancho de las columnas
    const columnWidths = [];
    worksheetData.forEach((row) => {
      Object.keys(row).forEach((key, colIndex) => {
        const cellValue = row[key] ? row[key].toString() : '';
        const columnWidth = cellValue.length > (columnWidths[colIndex] || 0) ? cellValue.length : columnWidths[colIndex];
        columnWidths[colIndex] = columnWidth;
      });
    });

    // Asignar el ancho de las columnas basado en el contenido
    worksheet['!cols'] = columnWidths.map((width) => ({ wch: width + 2 })); // Se agrega un margen extra

    // Generar el archivo Excel
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    
  } catch (error) {
    console.error('Error al generar el archivo Excel:', error);
  }
};

/* descarga en pdf comentarios empresa */
  const handleDownload = () => {
    setOpenModal(true);
    setProgress(0);

    Axios.post(
      `${backendURL}/api/report/memorial/empresa/${empresa.id}`,
      {
        fecha: selectedDate.mes || '',
        tipo_memorial: "4",
      },
      {
        headers: { Authorization: `Bearer ${Token}` },
        responseType: 'blob',
        onDownloadProgress: (progressEvent) => {
          const totalLength = progressEvent.total;
          if (totalLength) {
            const currentProgress = Math.round((progressEvent.loaded * 100) / totalLength);
            setProgress(currentProgress);
          }
        },
      }
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reporte_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();

        setOpenModal(false);
        setOpenSuccessModal(true);
      })
      .catch((error) => {
        console.error('Error al descargar el reporte:', error);
        setOpenModal(false);
        setOpenErrorModal(true);
      });
  };

  /* descasrga de excel comentario empresa*/
  const handleDownloadexcel = () => {
    setOpenModal(true);
    setProgress(0);

    Axios.get(
      `${backendURL}/api/report/memorial/empresa/excel/${empresa.id}`,
      {
        params: { fecha: selectedDate.mes || '', tipo_memorial: "4" },
        headers: { Authorization: `Bearer ${Token}` },
      }
    )
      .then((response) => {
 // Crear y descargar el archivo Excel a partir de los datos recibidos
        createExcelFile(response.data.comentarios);
        setOpenModal(false);
        setOpenSuccessModal(true);
      })
      .catch((error) => {
        console.error('Error al descargar el reporte:', error);
        setOpenModal(false);
      });
  };
  
 const createExcelFile = (comentarios) => {
    // Convertir los datos en formato adecuado para Excel
    const worksheetData = comentarios.map((comentario) => ({
      "Sucursal": comentario.memorial?.nombre,
      "Fecha": comentario.fecha,
      "Creador": comentario.creador,
      "Teléfono": comentario.telefono,
      "Email": comentario.email,
      "Mensaje": comentario.mensaje,
    
    }));

    // Crear la hoja de trabajo y el libro de Excel
  const worksheet = XLSX.utils.json_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Comentarios");

  // Ajuste dinámico del ancho de las columnas
  const columnWidths = [];
  worksheetData.forEach((row) => {
    Object.keys(row).forEach((key, colIndex) => {
      const cellValue = row[key] ? row[key].toString() : '';
      const columnWidth = cellValue.length > (columnWidths[colIndex] || 0) ? cellValue.length : columnWidths[colIndex];
      columnWidths[colIndex] = columnWidth;
    });
  });

  // Asignar el ancho de las columnas basado en el contenido
  worksheet['!cols'] = columnWidths.map((width) => ({ wch: width + 2 })); // Se agrega un margen de 5 caracteres extra

 // Obtener la fecha del primer comentario (puedes modificar esta lógica si quieres usar otra fecha)
 const firstCommentDate = comentarios.length > 0 ? comentarios[0].fecha : new Date().toISOString().split('T')[0];
  
 // Formatear la fecha en formato YYYY-MM-DD si es necesario
 const formattedDate = firstCommentDate.split('T')[0]; // En caso de que la fecha venga en formato ISO 8601
 
 // Generar el archivo Excel con la fecha del primer comentario en el nombre
 const fileName = `comentarios${formattedDate}.xlsx`;
 XLSX.writeFile(workbook, fileName);
  };

  

     /* descarga comentario clientes excel */
     const generarComentariosClientExcel = async () => {
      setOpenModal(true);
      setProgress(0);
      try {
        const response = await Axios.get(`${backendURL}/api/clientes/Comentarios/empresa/reporte/${empresa.id} `, {
          params: {
            fecha: selectedDate.mes || '', 
          },
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        setOpenModal(false);
        setOpenSuccessModal(true);
        const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta

        // Crear el nombre del archivo
        let fileName = `reporte_clientes_comentarios__${empresaNombre}`;
    
        // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
        if (selectedDate.dia) {
          fileName += `_${selectedDate.dia}`;
        } else if (selectedDate.mes) {
          fileName += `_${selectedDate.mes}`;
        }
    
        // Obtener los datos de los clientes
        const clienteData = response.data.comentarios;
    
        // Crear la data para la hoja de Excel
        const worksheetData = clienteData.map(cliente => ({
          "creador": cliente.creador,
          "Teléfono": cliente.telefono,
          "Email": cliente.email,
          "Mensaje": cliente.mensaje,
        }));
    
        // Crear la hoja de trabajo y el libro de Excel
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sucursal");
    
        // Ajuste dinámico del ancho de las columnas
        const columnWidths = [];
        worksheetData.forEach((row) => {
          Object.keys(row).forEach((key, colIndex) => {
            const cellValue = row[key] ? row[key].toString() : '';
            const columnWidth = cellValue.length > (columnWidths[colIndex] || 0) ? cellValue.length : columnWidths[colIndex];
            columnWidths[colIndex] = columnWidth;
          });
        });
    
        // Asignar el ancho de las columnas basado en el contenido
        worksheet['!cols'] = columnWidths.map((width) => ({ wch: width + 2 })); // Se agrega un margen extra
    
        // Generar el archivo Excel
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
        
      } catch (error) {
        console.error('Error al generar el archivo Excel:', error);
      }
    };

    /* descargar comentarios clientes pdf */
    const generarComentariosClientPdf = async () => {
      setOpenModal(true);
      setProgress(0);
      try {
        const response = await Axios.get(`${backendURL}/api/clientes/Comentarios/empresa/reporte/${empresa.id}`, {
          params: {
            fecha: selectedDate.mes || '', 
          },
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        setOpenModal(false);
        setOpenSuccessModal(true);
        const doc = new jsPDF();
        const empresaNombre = empresa.nombre_empresa;  // Asegúrate de que el nombre de la empresa esté en la respuesta
      
        // Agregar el nombre de la empresa en la parte superior
        doc.setFontSize(10);
        doc.text('Reporte de clientes - empresa ' + empresaNombre , 14, 20);
    
        const tableData = response.data.comentarios.map(cliente => [
         cliente.creador,
         cliente.telefono,
         cliente.email,
         cliente.mensaje,
        ]);
    
        // Usamos autoTable para generar una tabla en el PDF
        doc.autoTable({
          head: [['Nombre creador', 'Telefono', 'Email', 'Mensaje']],
          body: tableData,
          startY: 30, // La tabla empezará justo después del título (puedes ajustar este valor según sea necesario)
        });
    
        // Crear un nombre de archivo que incluya la fecha o mes seleccionado
        let fileName = `reporte_clientes_comentario__${empresaNombre}`;
        
        // Si se seleccionó una fecha o mes, agregarlo al nombre del archivo
        if (selectedDate.dia) {
          fileName += `_${selectedDate.dia}`;
        } else if (selectedDate.mes) {
          fileName += `_${selectedDate.mes}`;
        }
    
        // Guardar el PDF con el nombre personalizado
        doc.save(`${fileName}.pdf`);
      } catch (error) {
        console.error('Error al generar el PDF:', error);
      }
    };








  let comentarioReporte = selectedOptioncomentario || 'Sin información';
  let optionSucursal = selectedOptionsucursal ? `Sucursal: ${selectedOptionsucursal.nombre_sucursal}` : 'No hay información de la sucursal';
  let Fechaselecion = selectedDate
  ? selectedDate.mes 
    ? `${meses[parseInt(selectedDate.mes.split('-')[1], 10) - 1]} del ${selectedDate.mes.split('-')[0]}`
    : selectedDate.dia
    ? `${selectedDate.dia}`  // Si solo hay día, lo muestra
    : 'No hay fecha seleccionada' 
  : 'No hay fecha seleccionada';
  
  if (selectedOptioncliente) {
    comentarioReporte = `${selectedOptioncliente}`;
  }

  if (selectedOptionsucursal) {
    optionSucursal = `${selectedOptionsucursal.nombre_sucursal}`;
  }
  const rows = [
    {
      id: 1,
      option: selectedOption || 'No seleccionada',
      reporte: comentarioReporte,
      sucursal: optionSucursal, // Asegurándote de incluir la sucursal si existe
      date: Fechaselecion,
      
    },
  ];

  const renderDownloadButtons = (params) => {
   
  
    if (selectedOptioncliente === 'Clientes') {
      return (
        <>
          {renderDownloadButton('Descargar PDF', 'pdf-button', generatePDFWithTable)}
          {renderDownloadButton('Descargar Excel', 'excel-button', generateExcelWithTable)}
        </>
      );
    } else if (selectedOptioncliente === 'Sucursal') {
      if (selectedOptionsucursal?.nombre_sucursal === 'Todas') {
        return (
          <>
            {renderDownloadButton('Descargar PDF', 'pdf-button', generatePDFWithTablesucursalAll)}
            {renderDownloadButton('Descargar Excel', 'excel-button', generateExcelWithTablesucursalAll)}
          </>
        );
      } else {
        return (
          <>
            {renderDownloadButton(`Descargar PDF ${selectedOptionsucursal?.nombre_sucursal}`, 'pdf-button', generatePDFWithTablesucursal)}
            {renderDownloadButton('Descargar Excel', 'excel-button', generateExcelWithTablesucursal)}
          </>
        );
      }
    } else if (selectedOptioncliente === 'Empresa Comentarios') {
      return (
        <>
          {renderDownloadButton('Descargar PDF', 'pdf-button', () => handleDownload(params.row))}
          {renderDownloadButton('Descargar Excel', 'excel-button', () => handleDownloadexcel(params.row))}
        </>
      );
    }
    else if (selectedOptioncliente === 'Clientes Comentarios') {
      return (
        <>
          {renderDownloadButton('Descargar PDF', 'pdf-button', generarComentariosClientPdf)}
          {renderDownloadButton('Descargar Excel', 'excel-button', generarComentariosClientExcel)}
        </>
      );
    }
    return null;
  };
  
  const columns = [
    { field: 'option', headerName: 'Reporte Seleccionado', width: 200 },
    { field: 'reporte', headerName: 'Tipo del Reporte', width: 200 },
    ...(selectedOptionsucursal
      ? [
          {
            field: 'sucursal',
            headerName: 'Sucursal',
            width: 150,
            renderCell: (params) => (
              <div>{params.value || 'No hay información de la sucursal'}</div>
            ),
          },
        ]
      : []),  
     { field: 'date', headerName: 'Fecha', width: 150 },
     {
      field: 'download',
      headerName: 'Descargar Reporte',
      width: 500,
      renderCell: (params) => (
        <div className="download-buttons-container">
          {renderDownloadButtons(params)}
        </div>
      )
    },
  ];


    
    return(
        <Box sx={{ textAlign: 'center', padding: '20px', marginBottom: '100px' }}>
              <Typography variant="h6" sx={tituloStyledescripcion}>
        Información del reporte Selección
      </Typography>
      <hr style={Linea} />
      <Box sx={{ height: 200, width: '100%' }}>
        <DataGrid 
        sx={{
          fontFamily:'Poppins',
          borderRadius:'10px',
          width:'100%',
            marginTop: '50px',
            fontFamily: 'Poppins',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
            borderRadius: '8px', // Borde redondeado (si lo deseas)
           
            '.MuiTablePagination-displayedRows ':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', 
            },
            '.MuiTablePagination-input ':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', 
            },
            '.MuiButtonBase-root-MuiIconButton-root':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', 
            },
            '& .MuiDataGrid-menuIcon':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', 
            },
            '& .MuiDataGrid-columnHeaders': {
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
              fontWeight: '600', // Estilo de fuente de la cabecera
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
              borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
            },
            '& .MuiDataGrid-cell': {
              color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
              borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
            },
            '& .MuiCheckbox-root': {
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
            },
            '& .MuiTablePagination-selectLabel':{
                color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            }
          }}
          rows={rows} 
          columns={columns} 
        />
      </Box>
       
        {/* Modal con CircularProgress */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            textAlign: 'center',
            boxShadow: 24,
            borderRadius: '10px',
          }}
        >
           <Typography variant="h6" sx={{ mb: 2 }}>Descargando...</Typography>
          <Box sx={{ width: '100%', mb: 2 }}>
          <LinearBuffer progress={progress} />
          </Box>
         
        </Box>
      </Modal>


      <Modal open={openSuccessModal} onClose={() => setOpenSuccessModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            textAlign: 'center',
            boxShadow: 24,
            borderRadius: '10px',
          }}
        >
            <FaRegCircleCheck style={{fontSize:'2rem', color:'green'}}/>
          <Typography variant="h6" sx={{ color: '#585857', fontFamily:'Poppins', fontSize:'1rem' }}>
            ¡Reporte descargado con éxito!
          </Typography>
          <Button onClick={() => setOpenSuccessModal(false)} color="primary">
            Cerrar
          </Button>
        </Box>
      </Modal>

      {/* Modal de error */}
      <Modal open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            textAlign: 'center',
            boxShadow: 24,
            borderRadius: '10px',
          }}
          
        >    <BiErrorCircle style={{fontSize:'2rem', color:'red'}}/>
          <Typography variant="h6"  sx={{ color: '#585857', fontFamily:'Poppins', fontSize:'1rem' }}>
            ¡Error al descargar el reporte!
          </Typography>
          <Button onClick={() => setOpenErrorModal(false)} color="primary">
            Cerrar
          </Button>
        </Box>
      </Modal>
        </Box>
    )
}

export default VistaReporte;

const renderDownloadButton = (label, iconClass, onClick) => (
  

  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', margin: '2px' }}>
    <button className={`download-btn-${iconClass} pixel-corners`} onClick={onClick}>
  <div className={`button-content-${iconClass}`}>
    <div className={`svg-container-${iconClass}`}>
      <svg className={`download-icon-${iconClass}`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479 6.908l-4-4h3v-4h2v4h3l-4 4z"></path>
      </svg>
    </div>
    <div className={`text-container-${iconClass}`}>
      <div className="text">{label}</div>
    </div>
  </div>
</button>
</div>
);

const LinearBuffer = ({ progress }) => {
  const [buffer, setBuffer] = useState(10);

  useEffect(() => {
    if (progress >= 100) {
      setBuffer(100);
    } else {
      setBuffer(progress + 10 + Math.random() * 10);
    }
  }, [progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
    </Box>
  );
};


const tituloStyledescripcion = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'0.8rem',
    fontWeight:'500',
    marginBottom:'0px',
    marginTop:'50px',
    display:'flex'
  }
  
  const Linea = {
    color:'#a8a6a6',
    background:'#a8a6a6',
    Border: '1px solid #a8a6a6',
    marginBottom:'50px'
  }

  