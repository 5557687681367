import React, { useState, useEffect} from "react";
import axios from "axios";
import { useAuth } from "../../../context/usecontext";
import { useNavigate, useParams, Link } from "react-router-dom"; 
import "../../../../Style/admi/adminuser.css";

/* libreria UI */
import { Container,  Box, Grid, CircularProgress, Modal } from '@mui/material';
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* react iconos */
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosArrowBack } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { BiError } from "react-icons/bi";


/* imagenes */
import bordeuno from "../../../../Image/administrador/borde1.png";
import bordedos from "../../../../Image/administrador/borde2.png";
import luto from "../../../../Image/administrador/moradoluto.png";
import casa from "../../../../Image/administrador/casasucursal.png";

export const SucursalSelecion = ()=>{
    const { adminToken, setAdminAuthToken } = useAuth();
    const { id } = useParams();  // Accedemos al parámetro "id" de la URL
    const navigate = useNavigate();
    const backendURL = process.env.REACT_APP_BACKEND_URL; 
    const [sucursal, setSucursal] = useState(null);
    const [sucursales, setSucursales] = useState([]);
    const [empresa, setEmpresa] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tareasEmpresas, setTareasEmpresas] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('adminToken');
        if (storedToken) {
            setAdminAuthToken(storedToken);
        } else {
            navigate("/");
        }
    }, [setAdminAuthToken, navigate]);
    
    useEffect(() => {
        if (!adminToken) return; // Espera a que adminToken esté disponible
        setLoading(true);
        
        // Función para obtener la sucursal por ID
        const fetchSucursal = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/sucursalespecifica/${id}`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`,
                    },
                });
               
                setSucursal(response.data.sucursal);
                setEmpresa(response.data.sucursal.empresa);
                setLoading(false);
                fetchSucursalextraempresa(response.data.sucursal.id_empresa);
                fetchEmpresastareasSuscripcion(response.data.sucursal.id_empresa); // Llama a la segunda función aquí
            } catch (err) {
                setLoading(false);
                console.error('Error fetching sucursal:', err);
            }
        };

        const fetchEmpresastareasSuscripcion = async (id_empresa) => {
            try {
                const response = await axios.get(`${backendURL}/api/empresasalltareasuscripcion/${id_empresa}`, {
                    headers: {
                        Authorization: `Bearer ${adminToken}`
                    }
                });
    
                // Actualiza el estado de tareasEmpresas con la respuesta
                setTareasEmpresas(response.data.tareas);
            } catch (error) {
                console.error('Error fetching empresas:', error);
            }
        };
        const fetchSucursalextraempresa = async (id_empresa) => {
            setLoading(true);
            setError(null);
  
            try {
              const response = await axios.get(`${backendURL}/api/sucursal/${id_empresa}`, {
                headers: {
                  Authorization: `Bearer ${adminToken}`,
                },
              });
  
              if (response.data.sucursales) {
                setSucursales(response.data.sucursales);
              } else {
                setSucursales(null);
                setError("No hay sucursal afiliada a la empresa");
              }
            } catch (error) {
              if (error.response && error.response.status === 404) {
                setError("No hay sucursal afiliada a la empresa");
                setSucursal(null);
              } else {
                console.error("Error al obtener la sucursal:", error.message);
                setError("Ocurrió un error al obtener los datos de la sucursal");
              }
            } finally {
              setLoading(false);
            }
          };
       

        if (id) {
            fetchSucursal();
        }
    }, [id, backendURL, adminToken]);

    const handleDeleteSucursal = async () => {
        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const response = await axios.delete(`${backendURL}/api/eliminarsucursal/${id}`, {
                headers: {
                    Authorization: `Bearer ${adminToken}`,
                },
            });

            // Manejar la respuesta exitosa
            setSuccessMessage(response.data.message);
            navigate('/sucursal-index'); 
            // Aquí puedes hacer algo más, como actualizar la lista de sucursales

        } catch (err) {
            console.error("Error al eliminar la sucursal:", err);
            setError("Ocurrió un error al eliminar la sucursal.");
        } finally {
            setLoading(false);
            setModalOpen(false); // Cerrar el modal después de eliminar
        }
    };


    if (loading) {
        return (
            <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                    <CircularProgress sx={{
                        color:"D26999"
                    }}/>
                </Box>
            </Container>
        );
    }

   

    return(
        <>
           <Container component="main" maxWidth="md" sx={{ position: "relative" }}>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "50px"
                    }}
                >

                   <section className="sectionempresaespecifica">
                        <div className="logoempresaespecifico"> 
                       {empresa.logo && (
                             <img src={`${backendURL}/${empresa.logo}`} alt="smart memorial" title="smart memorial" />
                        )} 
                        </div>
                        <div className="nombreespecificoempresa">
                            <div className="imagenbordetodosempresasuno"> 
                                <img src={bordeuno} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                            </div>
                            <div className="tituloallempresas">
                              <h2> Empresa {empresa.id_tipo_empresa == 1 ? 'Funeraria' : empresa.id_tipo_empresa == 2 ? 'Cementerio' : 'Otro'}  {empresa.nombre_empresa}</h2> 
                                <h2>Sucursal {sucursal.nombre}</h2>
                            </div>
                            <div className="imagenbordetodosempresasdos">
                                <img src={bordedos} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                            </div>
                        </div>
                    </section>
                  
                   <section className="informacionemmpresaespecifica">
                    <div className="card-infoempresaespecifica">
                        <div className="titulocard-infoespecifico">
                            <div className="herramienta-info-especifico-empresa">
                                <div className="immagenluto">
                                    <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                    </div>
                                    <h3>Información Sucursal</h3>
                                    </div>
                                <div>
                            </div>
                            <div className="editarempresa"> <FaEdit/></div>
                        </div>
                        <div className="cardblancoinfo">
                            <Grid container >
                                <Grid item xs={4}>
                                    <div className="iteminfoempresa">
                                        <h3>Nombre</h3>
                                       <h4>{sucursal.nombre_sucursal}</h4> 
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="iteminfoempresa">
                                            <h3>Empresa</h3>
                                       <h5 style={{textTransform:'capitalize'}}>{empresa.nombre_empresa}</h5> 
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className="iteminfoempresa">
                                        <h3>Correo electrónico</h3>
                                        <h5 style={{textTransform:'capitalize'}}>{sucursal.correo}</h5>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                <div className="iteminfoempresa">
                                        <h3>Dirección</h3>
                                        <h5>  {sucursal.pais}, {sucursal.region}, {sucursal.comuna}, {sucursal.direccion}</h5>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                <div className="iteminfoempresa">
                                        <h3>Teléfono</h3>
                                        <h4>  {sucursal.telefono}</h4>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                   </section>
                   <section className="informacionemmpresaespecifica">
                        <div className="card-infoempresaespecifica">
                                <div className="titulocard-infoespecifico">
                                    <div className="herramienta-info-especifico-empresa">
                                        <div className="immagenluto">
                                            <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                            </div>
                                            <h3>Tareas afiliadas a la sucursal</h3>
                                            </div>
                                        <div>
                                    </div>
                                    
                                </div>
                                <div className="cardblancoinfo">
                                    <Grid container>
                                                            
                                                            {loading ? (
                                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                                                <CircularProgress sx={{ color: "D26999" }} />
                                            </Box>
                                            ) : tareasEmpresas.length === 0 ? (
                                                <Grid item xs={12} sx={{padding:'20px'}}>
                                                            <div className="editarempresa" > <BiError />  <p>No hay tarea asignadas</p></div>
                                                            </Grid>
                                            ) : (
                                                tareasEmpresas.map(tareaEmpresa => {
                                                    // Extraer la tarea
                                                    const { tarea, } = tareaEmpresa;
                                    
                                                    return (
                                                        <Grid item xs={4} key={tarea.id}>
                                                            <div className="cardtarea">
                                                                <div className="contieneimagentitulotareaempresasmartmemorial">
                                                                    <div className="contienenimagentareaadmismarttarea">
                                                                        <img src={`${backendURL}/imagenes/tareas/${tarea.logo}`} alt={tarea.nombre} title={tarea.nombre} />
                                                                    </div>
                                                                    <h4>{tarea.nombre}</h4>
                                                                </div>
                                                               
                                                            </div>
                                                        </Grid>
                                                    );
                                                })
                                            )}
                                    </Grid>
                                </div>
                            </div>    
                   </section>     
              
                   <section className="informacionemmpresaespecifica">
                       {/* agregarqr */}
                        <div className="card-infoempresaespecifica">
                                <div className="titulocard-infoespecifico">
                                    <div className="herramienta-info-especifico-empresa">
                                        <div className="immagenluto">
                                            <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                            </div>
                                            <h3>Codigos QR Asignado</h3>
                                            </div>
                                        <div>
                                    </div> 
                                </div>
                                <div className="cardblancoinfo" >
                                <Grid item xs={12} sx={{padding:'20px'}}>
                                    <div className="editarempresa" > <BiError /> <p> No se asignado cantidad QR para esta sucursal</p></div>
                                </Grid>
                                    </div>
                            </div>
                   </section>
                   <section className="informacionemmpresaespecifica">
                       {/* agregarqr */}
                        <div className="card-infoempresaespecifica">
                                <div className="titulocard-infoespecifico">
                                    <div className="herramienta-info-especifico-empresa">
                                        <div className="immagenluto">
                                            <img src={luto} alt="smart memorial" title="smart memorial" width="100%" />
                                            </div>
                                            <h3>Suscripción Smart Memorial</h3>
                                            </div>
                                        <div>
                                    </div> 
                                </div>
                                <div className="cardblancoinfo" >
                                    <Grid container sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'row', padding:'15px 0px'}}>
                                <Grid item xs={3} sx={{padding:'20px',   borderRight: '2px solid #431E62',}}>
                                    <div className="contendormontocontableadmismart" > <p>Costo Total del uso del administrador mes </p></div>
                                </Grid>
                                <Grid item xs={6} sx={{padding:'20px',display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'row',  borderRight: '2px solid #431E62'}}>
                                    <div className="contendormontocontableadmismart" >  <p> Total</p> <h3> </h3></div>
                                    <div className="contendormontocontableadmismart" >  <p> Interes Total</p> <h3> </h3></div>
                                </Grid>
                                <Grid item xs={3} sx={{padding:'20px'}}>
                                    <div className="contendormontocontableadmismart" > <p> Total UF Smart</p> <h3> </h3></div>
                                </Grid>
                                </Grid>
                                    </div>
                            </div>
                   </section>
                   <section className="informacionemmpresaespecifica">
                        <div className="nombreespecificoempresa">
                                    <div className="imagenbordetodosempresasuno"> 
                                        <img src={bordeuno} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                                    </div>
                                    <div className="tituloallempresas">
                                        <h2> Sucursales de la empresa asociada</h2>
                                    </div>

                                    <div className="imagenbordetodosempresasdos">
                                        <img src={bordedos} className="bordetodaslasempresas" alt="smart memorial" title="smart memorial"/>
                                    </div>
                        </div>
                        <div className="contienensucursalesasignadasempresa">
                            <Grid container sx={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    {sucursales.length > 0 ? (
                                        <>
                                            {sucursales.map((sucursalItem) => (
                                                <Grid item xs={4} key={sucursalItem.id} sx={{padding:"10px"}}>
                                                    <Link to={`/sucursal-admin-smart/${sucursalItem.id}`} style={{width:'100%'}}>
                                                    <button className="cardsucursaldesdeempresa" style={{width:'100%'}}>
                                                        <div className="imagensucursalvistaempresaadmismart">
                                                            <img src={casa} width={'100%'} alt="smart memorial" title="smart memorial"/>
                                                        </div>
                                                        <p> {sucursalItem.nombre_sucursal}</p>
                                                    </button></Link>
                                                </Grid>
                                            ))}
                                        </>
                                    ) : (
                                        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className="editarempresa" >
                                                
                                                <p style={{fontSize:'1rem'}}> No existen más sucursales asociadas</p>
                                            </div>
                                        </Grid>
                                    )}
                            </Grid>
                        </div>
                        </section>
                        <section className="informacionemmpresaespecifica">
                            <button className="buttoneliminarsucursal"  onClick={() => setModalOpen(true)}>
                                
                                    <img src={casa} alt="smart memorial" title="smart memorial" />
                                
                                <p>Eliminar Sucursal</p>
                            </button>
                        </section>
                </Box>
            </Container>
            <Modal
  open={modalOpen}
  onClose={() => setModalOpen(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box 
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      textAlign: 'center',
    }}
  >
    <h2 id="modal-modal-title">Confirmar Eliminación</h2>
    <p id="modal-modal-description">
      ¿Estás seguro de que deseas eliminar esta sucursal?
    </p>
    <div className="contienetodoslosbotonesediteliminar">
      <button
        onClick={handleDeleteSucursal}
         className="sieli"
      >
        Eliminar
      </button>
      <button
        onClick={() => setModalOpen(false)}
        className="noeli"
      >
        Cancelar
      </button>
    </div>
  </Box>
</Modal>
        </>
    )
}