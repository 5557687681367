import React from "react";
import { useState, useEffect } from 'react'
import { useLocation, useNavigate, Link } from "react-router-dom";
import "../../../../Style/usuario/crear-familiar.css";
import "../../../../Style/usuario/inicio-usuario.css";
/* elmentos */
import axios from 'axios';
import Box from "@mui/material/Box";
import { RxCalendar } from "react-icons/rx";
import { ImSearch } from "react-icons/im";
import Modal from '@mui/material/Modal';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { PiScrollFill } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";
import dayjs from 'dayjs';
import { Container } from "@mui/material";
import { CircularProgress } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilos por defecto
import { IoIosArrowForward } from "react-icons/io";
import { useUsers } from "../../../context/userscontexts";
import { MdGroups } from "react-icons/md";
import { BiSolidBookHeart } from "react-icons/bi";


/* imagenes */

import logomemorialdos from "../../../../Image/generales/grupo-familia-2.png";
import perfil from "../../../../Image/generales/foto-predeterminada-memorial.jpg"
import crear from "../../../../Image/generales/crear-memorial.png";




export const Memorialcrear = ( ) =>{
  const location = useLocation();
  const navigate = useNavigate();
  const ClientToken = location.state.user; // Obtener la información del usuario desde las props
  const grupoFamiliar = location.state.familia;
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");
  const [nombreFallecido, setNombreFallecido] = useState('');
  const [fechaNacimiento, setFechaNacimiento] = useState('');
  const [fechaFallecimiento, setFechaFallecimiento] = useState('');
  const [cementerio, setCementerio] = useState('');
  const [imagenPerfil, setImagenPerfil] = useState(null);
  const [imagenPreview, setImagenPreview] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [historia, setHistoria] = useState('');
  const fechaNacimientoFormateada = fechaNacimiento ? dayjs(fechaNacimiento).format('YYYY/MM/DD') : '';
  const fechaFallecimientoFormateada = fechaFallecimiento ? dayjs(fechaFallecimiento).format('YYYY/MM/DD') : '';
  const [guardadoParcial, setGuardadoParcial] = useState(false);
  const [memorialId, setMemorialId] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [hasMemorials, setHasMemorials] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [errorAlertOpens, setErrorAlertOpens] = useState(false);
  const [enviandoAlert, setenviandoAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertMessages, setAlertMessages] = useState('');
  const [newgruopfamily, setNewgruopfamily] = useState(null)
  const [link, setLink] = useState(null);
  const [funerariaLoaded, setFunerariaLoaded] = useState(false);
  const [imagenPredeterminadaBlob, setImagenPredeterminadaBlob] = useState(null);
  const [showCreateObituary, setShowCreateObituary] = useState(false);
  const {  userData } = useUsers(); 

  


  useEffect(() => {
    if (fechaFallecimiento) {
      const fechaActual = dayjs();
      const fechaFallecimientoDate = dayjs(fechaFallecimiento);
      const diferenciaDias = fechaActual.diff(fechaFallecimientoDate, 'day');

      if (diferenciaDias <= 3) {
        setAlertMessages('Debido a que la fecha de fallecimiento de su ser querido es reciente');
        setShowCreateObituary(true);
       
      }
    }
  }, [fechaFallecimiento]);

  const handleAccept = () => {
      setSuccessAlertOpen(false);
      const state = { user: ClientToken, familia: newgruopfamily, memorial: memorialId };
      navigate('/crearmemorial/galeriavideo', { state });
  };

  const handleAccepts = () => {
    setSuccessAlertOpen(false);
    const state = { user: ClientToken, familia: newgruopfamily, memorial: memorialId };
    navigate('/crear-obituario-memorial', { state });
  }

  useEffect(() => {
    if (grupoFamiliar && grupoFamiliar.memorial) {
      setHasMemorials(grupoFamiliar.memorial.length > 0);
    }
  }, [grupoFamiliar]);

  const handleClick = () => {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  }; 
  const handlePart1Submit = async (e) => {
    e.preventDefault();
    const fechaNacimientoFormateada = fechaNacimiento ? dayjs(fechaNacimiento).format('YYYY/MM/DD') : '';
    const fechaFallecimientoFormateada = fechaFallecimiento ? dayjs(fechaFallecimiento).format('YYYY/MM/DD') : '';
    
    // Validar que todos los campos requeridos estén completos
    if (nombreFallecido && fechaNacimientoFormateada && fechaFallecimientoFormateada) {
        // Si todos los campos están completos, avanzar a la siguiente página
        setCurrentPage(currentPage + 1);
        setGuardadoParcial(true);
    } else {
        // Si falta algún campo, mostrar un mensaje de error
        setError("Todos los campos son requeridos");
    }
};

const handlePart2Submit = async (e) => {
  e.preventDefault();
  setIsSubmitting(true);
 // Usar imagen predeterminada si no se seleccionó ninguna imagen
 if (!imagenPerfil && !imagenPredeterminadaBlob) {
  setErrorAlertOpens(true);
  setAlertMessage('Debe subir una imagen de perfil.');
  setIsSubmitting(false);
  return; // Detener la ejecución si no hay imagen
}

  const formData = new FormData();
  formData.append('nombre', nombreFallecido);
  formData.append('cementerio', cementerio);
  formData.append('fecha_nacimiento', fechaNacimientoFormateada);
  formData.append('fecha_defuncion', fechaFallecimientoFormateada);
  // Solo añadir la imagen si ha sido seleccionada
  if (imagenPerfil) {
    formData.append('imagen_perfil', imagenPerfil);
  } else if (imagenPredeterminadaBlob) {
    formData.append('imagen_perfil', imagenPredeterminadaBlob, 'foto-predeterminada-memorial.jpg');
  }
  formData.append('historia', historia);

  try {
    setenviandoAlert(true);
    const response = await axios.post(`${backendURL}/api/memorialStore/${grupoFamiliar.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${ClientToken.token}` // Asegúrate de tener el token de autenticación disponible
      }
    });
    const memorialId = response.data.id;

    // Actualizar el estado del componente con el ID del memorial creado
    setMemorialId(memorialId);
        setenviandoAlert(false);
      setSuccessAlertOpen(true);
      setAlertMessage('El memorial se a creado con exito.')
    
    const updatedGrupoFamiliar = {
      ...grupoFamiliar,
      memorial: [...grupoFamiliar.memorial, response.data]
    };

    setNewgruopfamily(updatedGrupoFamiliar)

      // Aquí puedes manejar la respuesta como desees
  } catch (error) {
    const errorMessage = error.response && error.response.data && error.response.data.message 
    ? error.response.data.message 
    : 'Error al crear el memorial.';

  setErrorAlertOpen(true);
  setAlertMessage(errorMessage);
 
  }
  finally {
    setIsSubmitting(false); // Ocultar el CircularProgress al finalizar el envío del formulario
    setenviandoAlert(false);
  }
  // Simulación de tiempo de carga
 setTimeout(() => {
  handleCloseModal();
}, 3000);

// Simulación de tiempo de carga
setTimeout(() => {

  setErrorAlertOpen(false);
}, 2000);
}; 

useEffect(() => {
  if (!errorAlertOpen && alertMessage) {
    const state = { user: ClientToken, familia: grupoFamiliar };
    navigate('/usuariosmart', { state });
  }
}, [errorAlertOpen]);



const handleCloseModal = () => {
  setenviandoAlert(false);
};


/* historia */
const handleChange = (value) => {
  setHistoria(value);
};

/* imagen perfil */

useEffect(() => {
  // Cargar la imagen predeterminada como Blob
  fetch(perfil)
    .then(response => response.blob())
    .then(blob => setImagenPredeterminadaBlob(blob));
}, []);

const handleImagenSeleccionada = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagenPreview(reader.result);
    };
    reader.readAsDataURL(file);
    setImagenPerfil(file);
  }
};




/* paginas */

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };


  /* funeraria Logo traer */
  useEffect(() => {
    // Función para obtener datos de la funeraria
    const fetchFuneraria = async () => {
      try {
        // Verificar si ya se cargó la funeraria para evitar más llamadas
        if (!funerariaLoaded && grupoFamiliar && grupoFamiliar.id_funeraria) {
          const response = await axios.get(`${backendURL}/api/funeraria/${grupoFamiliar.id_funeraria}`);
          const funerariaData = response.data;
          setLink(funerariaData.web) 
          setFunerariaLoaded(true); // Marcar que se ha cargado la funeraria correctamente

          if (funerariaData.nombre === 'smart memorial') {
            setLogoUrl(null); // No mostrar logo
          } else if (funerariaData.logo) {
          setLogoUrl(funerariaData.logo);
          } else {
            setLogoUrl(null); // No hay logo disponible
          }
      
        } else {
          
        }
      } catch (error) {
        
      }
    };

    fetchFuneraria(); // Llamar a la función de carga de funeraria al montar el componente
  }, [funerariaLoaded, grupoFamiliar, backendURL]);


   

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };


  

    return(
      

        <section className="contienenpaginainiciousuario" >
    {logoUrl && (
          <Link to={link} className="logofunerariacrearmemorial" target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}
          <section className={`pagina-${currentPage}`}>

            {currentPage === 1 && (
                
                 <Container component="main" maxWidth="xs">
                   <Box
          sx={{
            width:"100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent:'center',
            alignItems:'center'
           
          }}
        > 
       
          <div className="pasosmemorial" >
          <div className="titulopasos"  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#E1DBE6'
  }}><h1>PASO 1:</h1>
          <span>Cree un Memorial para su ser querido</span>
          </div></div>
                 <section className="portada-familia-crear">
                  
                    
                   <section className="titulo-crear-familia">
                     <h1 className="titulofamiliafinal" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Grupo Familiar</h1>
                     <div className="apellido-crear">
                     <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Familia {grupoFamiliar.nombre_familia}</h1>
                     
                     <MdGroups style={{fontSize:'2.5rem', paddingLeft:'5px', color:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#D26999;'}} />
                     </div>
                   </section>
                 </section>
                 <section className="contienetodocrearmemorial">
                   
                   <div className="titulopartedos">
                   <BiSolidBookHeart style={{fontSize:'3rem',color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
                     <h1 className="titulo-memoria" style={{ margin:0,paddingBottom:'30px',
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Crear Memorial</h1>
                   </div>
                   
                    <section  className="memorialaviso" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}><span>Recuerde que son 5 memoriales como máximo</span> </section>

                   <form className="formcrearmemorial" onSubmit={handlePart1Submit}>
                   <div className="inputregistorform">
                       <label>Nombre del fallecido</label>
          <div className="contieneinputmemorial" style={{width:"100%"}}>
          <input 
          style={{
            color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}value={nombreFallecido} className={`inputregistrofallecido ${error && 'input-error'}`}   name="nombres"
                         id="name" onChange={(e) => setNombreFallecido(e.target.value)} />
               </div>           {error && <div className="errorinput"><FiAlertCircle />{error}</div>}
          </div>
          <div className="inputregistorform">
                       <label htmlFor="editfecha">Fecha de Nacimiento</label>
                       <div className="inputcreacioncontainer">
        <input 
        style={{
          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}
            type="date"  
            placeholder=""
            className={`crearinputperfil ${error && 'input-error'}`}
            name='fechaNacimiento'  
            value={fechaNacimiento} 
            onChange={(e) => setFechaNacimiento(e.target.value)} 
        />
        <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
        </div>
    </div>
          {error && <div className="errorinput"><FiAlertCircle />{error}</div>}                
                     </div>
                     <div className="inputregistorform">
                       <label htmlFor="editfecha">Fecha de Fallecimiento</label>
                       <div className="inputcreacioncontainer">
        <input 
            type="date"  
            style={{
              color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}
            className={`crearinputperfil ${error && 'input-error'}`}
            name='fechaNacimiento'  
            onChange={(e) => setFechaFallecimiento(e.target.value)} 
             value={fechaFallecimiento}
        />
        <div className="calendariocreacionicono">
        <RxCalendar className="iconocrearmemorialcalendario" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} />
        </div>
    </div>
        {error && <div className="errorinput"><FiAlertCircle />{error}</div>}                  
                     </div>
                     

                     <div className="inputregistorform">
                     <label>Cementerio (opcional)</label>
                     <div className="input-cementerio">
     
       
          <input
          style={{
            color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}
            type='text'
            className="inputregistrofallecido"
            name="cementerio"
            id="cementerio"
            placeholder="Indicar nombre del cementerio"
            value={cementerio} onChange={(e) => setCementerio(e.target.value)}
          />
          <span className="buscador">
            <ImSearch  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}/>
          </span>
          
                 
                </div>  
               
                </div>   
                <div className="botones">
                     <div>
      {hasMemorials ? (
        <div className="botonomitir">
          <button onClick={handleClick}
          style={{
            border: `1px solid ${(userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#B4A5C0'}`,
            background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#B4A5C0'
          }}>Cancelar </button>
        </div>
      ) : (
        <div className="botonomitir">
          <button onClick={handleClick} style={{
    background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Omitir</button>
        </div>
      )}
    </div>
                     
                     <div className="contiene-boton-siguiente">
                      
                         <div className="botonregistro">
                           <button type="submit"  style={{
    background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>Siguiente</button>
                         </div>
                    
                     </div>
                     </div>

          
        </form></section>
                 
                 </Box>
                 </Container>
               
                 )}
                 
                  {currentPage === 2 && (
                  
                      <Container className="contieneregistro"component="main" maxWidth="xs">
                             <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      background: "#ffff",
                      justifyContent:'center',
                      alignItems:'center'
                     
                    }}
                  >
                    
                    <div className="pasosmemorial">
                    <div className="titulopasos" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
    background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#E1DBE6'
  }}><h1>PASO 2:</h1>
                    <span>Elija una foto de perfil y escriba su historia</span>
                    </div>
                    </div>
                    {!hasMemorials && (
        <div className="alertaeditarmemorial"> 
          <BiErrorCircle style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
          <div className="infoalertamemorial">
            <h4>Si usted no posee la información necesaria en este</h4>
            <h4>momento, puede editar el memorial más tarde</h4>
          </div>
        </div>
      )}
              <section className="portadafamiliacrear">
               
                <div className="titulo-fallecido">
                  <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Memorial</h1>
                </div>
                <div className="nombre-fallecido">
                  <h1>
                    <strong style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>{nombreFallecido}</strong>
                  </h1>

                  <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>{formatDate(fechaNacimientoFormateada)} • {formatDate(fechaFallecimientoFormateada)}</span>
                </div>
                <form onSubmit={handlePart2Submit} style={{width:"100%"}}>
       
                <section className="contiene-input-imagen-perfil">
                {showCreateObituary ? ( <label htmlFor="file-input" className="input-subir" style={{
  border: `7px solid ${(userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#F0EDF3'}`
}}>
          <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
          <div className="contiene-icono">
            <FaArrowUpFromBracket className="imgicono" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
            <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir Foto</span>
          </div>
          <img src={imagenPreview || perfil} alt="Vista previa de la imagen" />
          
        </label>
                ):(
        <label htmlFor="file-input" className="input-subir" style={{
          border: `7px solid ${(userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#F0EDF3'}`
        }}>
          <input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada}  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
          <div className="contiene-icono">
            <FaArrowUpFromBracket className="imgicono" style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }} />
            <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir Foto</span>
          </div>
          {imagenPreview && <img src={imagenPreview} alt="Vista previa de la imagen" />}
          
        </label>)}
        <div >  {showCreateObituary ? (<div style={{display:"flex", alignItems:"center", justifyContent:"center", marginBottom:"70px",marginTop:"-40px"}}>
      
            <label className="buttonsubirfotomemorial">
              <input input id="file-input" name="file" type="file" onChange={handleImagenSeleccionada} />
            <FaArrowUpFromBracket  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
            <span style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Subir una foto</span>
            </label>
          </div>

       ):(<></>)}</div>
      </section>
    <section className="historia-crear">
                  <div className="titulohitoriacrear">
                    <PiScrollFill  style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}/>
                    <h1 style={{
    color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
  }}>Su historia</h1>
                  </div>
                  <ReactQuill
                  
        className="input-historia"
        placeholder="Escriba hechos importantes en la vida de su ser querido..."
        style={{
          color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81',
        }}
        value={historia}
        onChange={handleChange}
        modules={{
          toolbar: [
            [],
            [],
            ['link'],
           
          ],
        }}
        formats={[
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]}
      />
                </section>  

                <div className="botones">
                <div className="boton-regreso-crear">
                       {currentPage !== 1 && (
                         
                         <div className="botonomitir">
                           <button onClick={handlePrevPage} style={{
            border: `1px solid ${(userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#B4A5C0'}`,
            background: (userData?.color_3 && userData.color_3 !== 'undefined') ? userData.color_3 : '#B4A5C0'
          }}>Anterior</button>
                         </div>
                       )}
                     </div>
                     <div className="botonregistro">     
          <button style={{
    background: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}} type="submit">{isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Siguiente'}</button>
        </div>  
                    </div>
        </form>
              </section> 
              </Box>
              </Container>
                     
             
            )}
           
                 </section>

                 
      <Modal open={successAlertOpen} onClose={() => handleAccept}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
          {showCreateObituary ? (
            <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                  <div className="contienenmensajeobituario" >
                   <BiErrorCircle  style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '2rem', marginBottom: '0rem', textAlign:"center" }} />
                   <h3 style={{ color:  (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '1.3rem', marginTop:"0px", fontWeight:"500", textAlign:"center", marginBottom:"0px" }}>Crear un Obituario</h3>
                    <p style={{ color: '#585857', fontSize: '0.9rem', marginTop:"0px", fontWeight:"600", textAlign:"center", marginBottom:"10px" }}>{alertMessages}</p>
                    <p  className="infomensajeobituario">Usted puede crear un aviso donde se
                    mostrará la información sobre el:</p>
                    <h4 style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '1.2rem', marginTop:"0px", fontWeight:"500", textAlign:"center"}}>Velatorio y Funeral</h4>
                    <span style={{ color: '#585857', fontSize: '0.9rem', marginTop:"-15px", fontWeight:"500", textAlign:"center" }}> Puede crear más tarde en</span>
                    <span style={{ color: '#585857', fontSize: '0.9rem', marginTop:"0px", fontWeight:"600", textAlign:"center", marginBottom:"30px", display:"flex", alignItems:"center", justifyContent:"center" }}> Grupo familiar <IoIosArrowForward /> Memoriales</span>
                    </div>
                    <div className="contienebotonesobituario">
                     <button className="botonmastarde" onClick={handleAccept}>
                        Más tarde
                      </button>
                       <button className="botonaceptar" onClick={handleAccepts}>
                       Crear Obituario
                      </button>
                      
                    </div>
                  </div>
                ) : (
                  <div style={{display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
                    <FaRegCircleCheck
                      style={{
                        color: 'green',
                        fontSize: '2rem',
                        marginBottom: '1rem',
                      }}
                    />
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        color: '#585857',
                        fontSize: '1rem',
                        fontWeight: '600',
                        marginTop: '-5px',
                      }}
                    >
                      {alertMessage}
                    </p>
                    <p
                      style={{
                        fontFamily: 'Poppins',
                        color: '#585857',
                        fontSize: '1rem',
                        fontWeight: '600',
                        marginTop: '-5px',
                      }}
                    >
                      Recuerda que si retrocedes nuevamente a paso 1 y paso 2 se
                      realizará nueva creación de memorial.
                    </p>
                    <div className="contienebotonaceptar">
                      <button className="botonaceptar" onClick={handleAccept} style={{background:(userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81'}}>
                        Aceptar
                      </button>
                    </div>
                  </div>
                )}
              </section>
        </Box>
      </Modal>
      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      <Modal open={errorAlertOpens} onClose={() => setErrorAlertOpens(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpens(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: (userData?.color_2 && userData.color_2 !== 'undefined') ? userData.color_2 :  '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
             
           </section>
        
      
    )
}